import { memo, useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import { MenuButton } from '../menu';
import type { NodeManagementProps } from '../SideMenu';

const { DownArrow, RightArrow } = Icon16;
const EXCLUDED_NODES = ['groupNode', 'floatingTextNode', 'fileNode'];

export const CollapseMenu = memo((props: NodeManagementProps) => {
  const { setNodes, selectedNodeItems, selectedNodes, patch } = props;

  const isFolded = useMemo(() => {
    if (selectedNodeItems.length !== 1 || !selectedNodeItems[0]) return undefined;
    const targetNode = selectedNodeItems[0];

    if (EXCLUDED_NODES.includes(targetNode.type ?? '')) return undefined;

    return !targetNode.data.expanded;
  }, [selectedNodeItems]);

  const handleCollapse = useCallback(() => {
    const updatedNodes = setNodes((nodes) => {
      return nodes.map((node) => {
        if (!selectedNodes.includes(node.id)) return node;
        if (EXCLUDED_NODES.includes(node.type ?? '')) return node;

        const newHeight =
          node.height || (node.data.expanded ? node.measured?.height : null);

        return {
          ...node,
          height: undefined,
          data: {
            ...node.data,
            expanded: false,
            height: newHeight ?? null,
          },
        };
      });
    });

    patch({ nodes: updatedNodes });
  }, [selectedNodeItems, setNodes, patch]);

  const handleExpand = useCallback(() => {
    const selectedNodeIds = selectedNodeItems.map((node) => node.id);

    const updatedNodes = setNodes((nodes) => {
      return nodes.map((node) => {
        if (!selectedNodeIds.includes(node.id)) return node;
        if (EXCLUDED_NODES.includes(node.type ?? '')) return node;

        return {
          ...node,
          data: {
            ...node.data,
            expanded: true,
          },
        };
      });
    });

    patch({ nodes: updatedNodes });
  }, [selectedNodeItems, setNodes, patch]);

  const isFoldActive = useMemo(() => {
    return isFolded === true;
  }, [isFolded]);

  const isFoldInactive = useMemo(() => {
    // Differentiate between false and undefined
    return isFolded === false;
  }, [isFolded]);

  return (
    <>
      <MenuButton
        isActive={isFoldInactive}
        onClick={handleExpand}
        tooltipContent="Expand"
      >
        <DownArrow />
      </MenuButton>
      <MenuButton
        isActive={isFoldActive}
        onClick={handleCollapse}
        tooltipContent="Fold"
      >
        <RightArrow />
      </MenuButton>
    </>
  );
});
