import type { MediaGroupDetailDTO } from '@spaceduck/api';

import Image from '../media/Image';
import styles from './ScreenshotModeView.module.scss';
import clsx from 'clsx';
import { MediaType } from '@spaceduck/utils';

export const ScreenshotModeView = ({
  className,
  mediaGroup,
}: {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
}) => {
  if (!mediaGroup.media || !mediaGroup.media.mediaType.startsWith('image')) return null;

  const { assetName, assetUrl, width, height } = mediaGroup.media;

  return (
    <div className={clsx(styles.imgWrapper, className)}>
      <Image
        alt={assetName}
        isSvg={mediaGroup.media.mediaType === MediaType.SVG}
        height={height}
        src={assetUrl}
        width={width}
      />
    </div>
  );
};
