import { DropdownMenuCheckboxItem } from '@radix-ui/react-dropdown-menu';
import { Icon24 } from '@spaceduck/icons';
import { useCallback, useState } from 'react';

import { MediaGroupCategory } from '@components/icons';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import { InfiniteDropdown } from '@ui/InfiniteDropdown';
import styles from './CategoryMenu.module.scss';
import { useListMediaGroupCategories } from '@/api/project';

type CategoryMenuProps = {
  handleCategoryChange: (id: string | null) => void;
  selectedCategoryId?: string | null;
  triggerContent?: React.ReactNode;
  projectId: string | null;
};

const { CategoryFilter } = Icon24;

export default function CategoryMenu({
  handleCategoryChange,
  selectedCategoryId = null,
  triggerContent,
  projectId,
}: CategoryMenuProps) {
  const [categoryFilterText, setCategoryFilterText] = useState('');
  const {
    data: categoryListing,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useListMediaGroupCategories(projectId ?? null, categoryFilterText);

  return (
    <InfiniteDropdown
      className={styles.categoryMenu}
      onFilterTextChange={setCategoryFilterText}
      filterTextDebounce={250}
      initialFilterText=""
      triggerContent={
        triggerContent ? (
          triggerContent
        ) : (
          <Button
            size="sm"
            variant="outlined"
            iconBefore={<CategoryFilter size={16} />}
          >
            Category
          </Button>
        )
      }
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      width={260}
      isPadded
    >
      <DropdownMenuCheckboxItem
        onSelect={() => handleCategoryChange(null)}
        className={styles.menuItem}
      >
        <Checkbox
          defaultChecked={selectedCategoryId === null}
          icon={<MediaGroupCategory size={20} />}
          singleSelection={true}
          asMenuItem
        >
          Uncategorized
        </Checkbox>
      </DropdownMenuCheckboxItem>
      {categoryListing?.pages
        .flatMap((page) => page.categories)
        .map(({ id, label }) => (
          <DropdownMenuCheckboxItem
            onSelect={() => handleCategoryChange(id)}
            className={styles.menuItem}
            key={id}
          >
            <Checkbox
              defaultChecked={selectedCategoryId === id}
              icon={<MediaGroupCategory size={20} />}
              singleSelection={true}
              asMenuItem
            >
              {label}
            </Checkbox>
          </DropdownMenuCheckboxItem>
        ))}
    </InfiniteDropdown>
  );
}

type CategoryMenuItemProps = {
  asLabel?: boolean;
  className?: string;
  handleCategoryChange: (id: string | null) => void;
  label: string;
  selectedCategoryId?: string | null;
  value: string | null;
};

export const CategoryMenuItem = ({
  asLabel = false,
  className,
  handleCategoryChange,
  label,
  selectedCategoryId = null,
  value,
}: CategoryMenuItemProps) => {
  const handleChange = useCallback(() => {
    handleCategoryChange?.(value);
  }, [handleCategoryChange, value]);

  const checkbox = (
    <Checkbox
      className={className}
      defaultChecked={selectedCategoryId === value}
      onClick={asLabel ? handleChange : undefined}
      icon={<MediaGroupCategory size={20} />}
      singleSelection={true}
      value={value ?? '-'}
    >
      {label}
    </Checkbox>
  );

  if (asLabel) {
    return checkbox;
  }

  return (
    <DropdownMenuCheckboxItem className={styles.menuItem} onSelect={handleChange}>
      {checkbox}
    </DropdownMenuCheckboxItem>
  );
};
