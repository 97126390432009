import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';

import type {
  AiProvider,
  Chat,
  ChatMediaGroupSource,
  ChatSessionMode,
} from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { useCreateChatSession } from '@api/ai';
import { useProject } from '@api/project';
import { toastApiErrorOr } from '@api/util';
import { ChatHistory } from '@components/ai/ChatHistory';
import ChatMessageInput from '@components/ai/ChatMessageInput';
import Sidebar from '@components/ai/Sidebar';
import Head from '@components/Head';
import Button from '@ui/Button';
import PageHeader from '../projects/common/PageHeader';
import ProjectTabs from '../projects/common/ProjectTabs';
import sharedStyles from './SharedStyles.module.scss';
import styles from './ResearchAssistantIndex.module.scss';
import { ChatContent } from './ResearchAssistant';
import { urlFor } from '@/urls';
import { useInteractWithChatMessage } from '@/hooks/useInteractWithChatMessage';

const { ChatHistory: ChatHistoryIcon } = Icon24;

export default function ResearchAssistantIndex() {
  const { mutateAsync: createChatSessionInternal } = useCreateChatSession();
  const [provider, setProvider] = useState<AiProvider>('open-ai');
  const {
    mutateAsync: createChatSession,
    status,
    data: chatSessionData,
    reset,
  } = useInteractWithChatMessage(createChatSessionInternal, provider);
  const navigate = useNavigate();
  const [temporaryChatSession, setTemporaryChatSession] = useState<Chat>();
  const [showChatHistory, setShowChatHistory] = useState(false);
  const projectId = useParams<{ projectId: string }>().projectId!;
  const [chatSessionMode, setChatSessionMode] = useState<ChatSessionMode>('library');

  const { data: project } = useProject(projectId);
  const submitChatSession = useCallback(
    async (query: string, mediaGroups: ChatMediaGroupSource[]) => {
      if (!projectId) {
        return false;
      }
      setTemporaryChatSession({
        projectId,
        id: 'temp',
        label: query,
        mediaGroups,
        messages: [
          {
            id: '',
            pending: true,
            query,
            response: {
              parts: [],
            },
          },
        ],
        provider,
      });
      try {
        await createChatSession({
          projectId,
          provider,
          mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
          initialQuery: query,
          mode: chatSessionMode,
        });
      } catch (error) {
        toastApiErrorOr(error, 'Failed to create new chat session', {
          iconVariant: 'warning',
          titleText: 'Error creating chat session',
          bodyText:
            'An unknown error occurred starting your assistant chat session. Please try again later',
        });
        setTemporaryChatSession(undefined);
        return false;
      }
      return true;
    },
    [projectId, chatSessionMode, provider]
  );

  useEffect(() => {
    if (chatSessionData?.chatSessionId) {
      navigate(
        urlFor('researchAssistantChat', {
          chatId: chatSessionData.chatSessionId,
        }),
        {
          state: { fromIndex: true },
        }
      );
    }
  }, [chatSessionData]);

  if (!projectId) {
    return;
  }

  const isDisabled = status === 'success' || status === 'pending';

  return (
    <>
      <Head title={`${project?.project?.label || 'Space'} Research Assistant`} />
      <PageHeader />
      <ProjectTabs
        showSidebarToggle={false}
        cta={
          <>
            <Button
              onClick={() => {
                setTemporaryChatSession(undefined);
                reset();
              }}
              size="sm"
              variant="outlined"
            >
              New chat
            </Button>
            <Button
              onClick={() => setShowChatHistory(!showChatHistory)}
              size="sm"
              variant="outlined"
              isSquare
            >
              <ChatHistoryIcon size={20} />
            </Button>
          </>
        }
      />
      <div className={styles.content}>
        <div
          className={clsx(
            styles.chatContent,
            temporaryChatSession ? styles.tempChat : undefined
          )}
        >
          {temporaryChatSession && (
            <div className={styles.tempChat}>
              <ChatContent
                messages={temporaryChatSession.messages}
                sources={temporaryChatSession?.mediaGroups}
                chatId={temporaryChatSession.id}
                chatProvider={temporaryChatSession.provider}
              />
            </div>
          )}
          <div className={clsx(styles.createChat, isDisabled && styles.disabled)}>
            <h1>What would you like to know?</h1>
            <ChatMessageInput
              provider={provider}
              onChangeProvider={setProvider}
              projectId={projectId}
              readOnly={isDisabled}
              onSubmit={submitChatSession}
              showTools
              onChangeMode={setChatSessionMode}
            />
          </div>
        </div>
        <Sidebar open={showChatHistory}>
          <h5 className={sharedStyles.chatHistoryTitle}>Chat history</h5>
          <ChatHistory filterParams={{ kind: 'project', projectId }} />
        </Sidebar>
      </div>
    </>
  );
}
