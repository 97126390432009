import { discriminatedUnion, z } from 'zod';
import { successfulResponseSchema } from '../schema';
import type { ProjectStackDTO } from './project';

export const mediaGroupKindSchema = z.enum([
  'board',
  'bookmark',
  'document',
  'extract',
  'gallery',
]);
export type MediaGroupKind = z.infer<typeof mediaGroupKindSchema>;

export const capabilityReasonSchema = z.enum(['plan', 'role']);
export type CapabilityReason = z.infer<typeof capabilityReasonSchema>;

export const createCapabilitySchema = <T extends z.ZodTypeAny>(capabilitySchema: T) => {
  const safeCapability = capabilitySchema.nullable().catch(({ input }) => {
    console.error('Unknown capability', { input });
    return null;
  });
  const capableSchema = z.object({
    capable: z.literal(true),
    capability: safeCapability,
    reason: z.nullable(capabilityReasonSchema),
    limitLeft: z.nullable(z.number()),
  });

  const incapableSchema = z.object({
    capable: z.literal(false),
    capability: safeCapability,
    reason: capabilityReasonSchema,
    limitLeft: z.nullable(z.number()),
  });

  return z.discriminatedUnion('capable', [capableSchema, incapableSchema]);
};

export type CapabilitySchema<T> = z.infer<
  ReturnType<typeof createCapabilitySchema<z.ZodType<T>>>
>;

export const contentTypeSchema = z.enum([
  'image',
  'video',
  'mixed',
  'document',
  'file',
  'pdf',
  'bookmark',
  'article',
  'wiki',
  'social',
  'film',
  'audio',
  'quote',
  'highlight',
  'board',
]);

export const mediaGroupCapability = z.enum(['comment', 'edit', 'create', 'rethumb']);
export type MediaGroupCapability = z.infer<typeof mediaGroupCapability>;

export const projectStackCapabilitySchema = z.enum(['editStack', 'deleteStack']);

export const projectCapabilitySchema = z.enum(['edit', 'delete']);
export const projectsCapabilitySchema = z.enum(['create', 'createStack']);

export const assetCapabilitySchema = z.enum(['upload', 'useStorage']);

export type AssetCapability = z.infer<typeof assetCapabilitySchema>;

export const workspaceMemberCapabilitySchema = z.enum([
  'assignOwner',
  'assignAdmin',
  'assignEditor',
  'assignViewer',
  'revokeOwner',
  'revokeAdmin',
  'revokeEditor',
  'revokeViewer',
]);
export type WorkspaceMemberCapability = z.infer<typeof workspaceMemberCapabilitySchema>;

export const projectMemberCapabilitySchema = z.enum([
  'assignOwner',
  'assignAdmin',
  'assignEditor',
  'assignViewer',
  'revokeOwner',
  'revokeAdmin',
  'revokeEditor',
  'revokeViewer',
  'dutySetLead',
  'dutyClearLead',
  'dutySetStakeholder',
  'dutyClearStakeholder',
  'delete',
]);
export type ProjectMemberCapability = z.infer<typeof projectMemberCapabilitySchema>;

export const workspaceCapabilitySchema = z.enum([
  'workspaceEdit',
  'workspaceDelete',
  'workspaceSubscribe',
]);
export type WorkspaceCapability = z.infer<typeof workspaceCapabilitySchema>;

export const tagDTO = z.object({
  id: z.string(),
  label: z.string(),
  source: z.enum(['user', 'ai']),
});

export const tagCapabilitySchema = z.enum(['create', 'edit', 'delete']);
export type TagCapability = z.infer<typeof tagCapabilitySchema>;

const bunnyStreamSourceSchema = z.object({
  kind: z.literal('bunny'),
  embedUrl: z.string(),
});

const sourceSchema = z.discriminatedUnion('kind', [bunnyStreamSourceSchema]);
export type MediaSource = z.infer<typeof sourceSchema>;

const mediaDTO = z.object({
  id: z.string(),
  assetUrl: z.string(),
  // TODO(BACK): remove default
  originalAssetUrl: z.string().nullable().default(null),
  assetName: z.string(),
  posterUrl: z.string().nullable(),
  source: sourceSchema.nullable(),
  width: z.number(),
  height: z.number(),
  mediaType: z.string(),
  sizeBytes: z.number().nullable(),
  dominantColor: z.string().nullable(),
});
export type MediaDTO = z.infer<typeof mediaDTO>;

export const thumbnailSourceUrlSchema = z.object({
  kind: z.literal('url'),
  url: z.string(),
});
export const thumbnailSourceFileIconSchema = z.object({
  kind: z.literal('fileIcon'),
  mediaType: z.string(),
  label: z.string().nullable(),
});
export const thumbnailSourceDocumentSchema = z.object({
  kind: z.literal('document'),
  document: z.any().nullable(),
});
export const thumbnailSourceExtractSchema = z.object({
  kind: z.literal('extract'),
  text: z.string(),
});
export const thumbnailSourceBoardSchema = z.object({
  kind: z.literal('board'),
  documents: z.number(),
  images: z.number(),
});

export const mediaGroupThumbnailDTO = z.object({
  source: z.discriminatedUnion('kind', [
    thumbnailSourceUrlSchema,
    thumbnailSourceFileIconSchema,
    thumbnailSourceDocumentSchema,
    thumbnailSourceBoardSchema,
    thumbnailSourceExtractSchema,
  ]),
  color: z.string().nullable(),
  width: z.number().nullable(),
  height: z.number().nullable(),
  isPlaceholder: z.boolean(),
});

export type MediaGroupThumbnailDTO = z.infer<typeof mediaGroupThumbnailDTO>;

const colorDTO = z.object({
  hex: z.string(),
  label: z.string(),
});

export const mediaGroupSearchFilterProperties = [
  'title',
  'tag',
  'contentType',
  'source',
  'text',
  'category',
  'status',
  'color',
  'project',
  'author',
] as const;

export const mediaGroupSearchFilterPropertySchema = z.enum(
  mediaGroupSearchFilterProperties
);
const projectSearchSuggestionDTO = z.object({
  filter: z.literal('project'),
  label: z.string(),
  id: z.string(),
  isPrivate: z.boolean(),
});
const authorSearchSuggestionDTO = z.object({
  filter: z.literal('author'),
  label: z.string(),
  id: z.string(),
  avatarUrl: z.string().nullable(),
});

const statusSearchSuggestionDTO = z.object({
  filter: z.literal('status'),
  label: z.string(),
  id: z.string().nullable(),
  color: z.string().optional(),
});
const otherSearchSuggestionDTO = z.object({
  filter: mediaGroupSearchFilterPropertySchema.exclude(['author', 'status', 'project']),
  label: z.string(),
  id: z.string(),
});
const searchSuggestionDTO = discriminatedUnion('filter', [
  projectSearchSuggestionDTO,
  authorSearchSuggestionDTO,
  statusSearchSuggestionDTO,
  otherSearchSuggestionDTO,
]);
export const searchSuggestionsSchema = z.object({
  suggestions: searchSuggestionDTO.array(),
});

export const searchDateSuggestionSchema = z.object({
  maxDatetime: z.coerce.date(),
  minDatetime: z.coerce.date(),
});
export type SearchSuggestions = z.infer<typeof searchSuggestionsSchema>;

export type SearchSuggestionDTO = z.infer<typeof searchSuggestionDTO>;

export type ProjectSearchSuggestionDTO = z.infer<typeof projectSearchSuggestionDTO>;
export type StatusSearchSuggestionDTO = z.infer<typeof statusSearchSuggestionDTO>;
export type OtherSearchSuggestionDTO = z.infer<typeof otherSearchSuggestionDTO>;

export type MediaGroupSearchFilterProperties = z.infer<
  typeof mediaGroupSearchFilterPropertySchema
>;

export type MediaGroupSuggestionFilterProperties = Exclude<
  MediaGroupSearchFilterProperties,
  'text' | 'date'
>;

export const mediaGroupSearchFilterLabels: Record<
  MediaGroupSearchFilterProperties | 'date',
  string
> = {
  category: 'category',
  contentType: 'kind',
  source: 'source',
  status: 'status',
  color: 'color',
  project: 'space',
  tag: 'tag',
  text: 'text',
  title: 'title',
  date: 'date',
  author: 'author',
} as const;

export type ExcludeMediaGroupSearchFilterProperties =
  | 'categoryNot'
  | 'contentTypeNot'
  | 'sourceNot'
  | 'statusNot'
  | 'projectNot'
  | 'tagNot'
  | 'titleNot'
  | 'textNot'
  | 'colorNot'
  | 'authorNot';

export type MediaGroupFiltersSchema = Partial<
  Record<
    | Exclude<MediaGroupSearchFilterProperties, 'date' | 'status'>
    | Exclude<ExcludeMediaGroupSearchFilterProperties, 'statusNot'>,
    string[]
  > & {
    maxDatetime: Date;
    minDatetime: Date;
    similarTo: string;
  } & Record<'status' | 'statusNot', Array<string | null>>
>;

export const mediaGroupSortOptions = ['newest', 'oldest', 'name'] as const;
export type MediaGroupSortOption = (typeof mediaGroupSortOptions)[number];

export type MediaGroupQuerySchema = MediaGroupFiltersSchema & {
  query?: string;
  workspace: string | null;
  excludeProjectLibraries?: boolean;
  sort?: MediaGroupSortOption;
  isDeleted?: boolean;
  isStarred?: boolean;
};

const authorSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatarUrl: z.string().nullable(),
});
export type Author = z.infer<typeof authorSchema>;

const noMediaGroupStatusDTO = z.object({
  id: z.null(),
  label: z.string(),
  itemsCount: z.number().nullable().optional(),
});

const existentMediaGroupStatusDTO = z.object({
  id: z.string(),
  label: z.string(),
  color: z.string().optional(),
  itemsCount: z.number().nullable().optional(),
});

export type ExistingMediaGroupStatus = z.infer<typeof existentMediaGroupStatusDTO>;

export const mediaGroupStatusDTO = z.union([
  existentMediaGroupStatusDTO,
  noMediaGroupStatusDTO,
]);

const existentCategoryDTO = z.object({ id: z.string(), label: z.string() });
const uncategorizedDTO = z.object({ id: z.null() });

export const mediaGroupCategoryDTO = z.union([existentCategoryDTO, uncategorizedDTO]);

const YoutubeEmbedDTO = z.object({
  kind: z.literal('youtube'),
  videoId: z.string(),
});

// `id` is intentionally left out
const UNKNOWN_USER = { name: 'Unknown user', avatarUrl: null };

export const mediaGroupDTO = z.intersection(
  z.object({
    id: z.string(),
    label: z.string(),
    author: authorSchema.nullable().transform((value) => value ?? UNKNOWN_USER),
    kind: mediaGroupKindSchema,
    workspace: z.object({
      id: z.string(),
      label: z.string(),
      avatarUrl: z.string().nullable(),
    }),
    createdAt: z.string(),
    // TODO: Remove default
    isDeleted: z.boolean().nullable().default(null),
    thumbnail: mediaGroupThumbnailDTO,
    linkUrl: z.string().nullable(),
    linkUrlSource: z.string().nullable(),
    userCapabilities: createCapabilitySchema(mediaGroupCapability).array().nullable(),
    isGeneratingContent: z.boolean(),
    contentType: contentTypeSchema.catch((value) => {
      console.warn('Unknown media group content type, using fallback.', {
        got: value.input,
      });
      return 'file';
    }),
    text: z.string(),
    media: mediaDTO.nullable().default(null),
    embed: YoutubeEmbedDTO.nullable().default(null),
    isResearchAssistantReady: z.boolean(),
    isStarred: z.boolean(),
  }),
  z.union([
    z.object({
      project: z.object({
        id: z.string(),
        label: z.string(),
      }),
      status: mediaGroupStatusDTO,
      category: mediaGroupCategoryDTO.nullable(),
    }),
    z.object({
      project: z.null(),
      status: z.null(),
      category: z.null(),
    }),
  ])
);

export type MediaGroupContentType = z.infer<typeof contentTypeSchema>;
export type MediaGroupDTO = z.infer<typeof mediaGroupDTO>;
export type MediaGroupStatus = z.infer<typeof mediaGroupStatusDTO>;
export type CreateMediaGroupStatus = {
  projectId: string;
  color: string;
  label: string;
};

const tinyUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatarUrl: z.string().nullable(),
});

const pointAtSchema = z.object({
  x: z.number(),
  y: z.number(),
  mediaId: z.string(),
});

const commentAttachmentDTO = z.object({
  id: z.string(),
  url: z.string(),
  filename: z.string(),
});

export type CommentAttachment = z.infer<typeof commentAttachmentDTO>;

const baseCommentDTO = z.object({
  kind: z.literal('alive'),
  id: z.string(),
  content: z.string(),
  document: z.any().nullable(),
  createdAt: z.string(),
  createdBy: tinyUserSchema.nullable().transform((value) => value ?? UNKNOWN_USER),
  // TODO(back): Remove optional
  attachments: commentAttachmentDTO.array().nullable().optional(),
  resolved: z
    .object({
      by: tinyUserSchema.nullable(),
      at: z.string(),
    })
    .nullable(),
  reactions: z
    .object({
      emoji: z.string(),
      count: z.number(),
      reactedBy: tinyUserSchema.array(),
    })
    .array(),
  pointAt: pointAtSchema.nullable(),
});

export const deletedCommentDTO = z.object({
  kind: z.literal('deleted'),
  id: z.string(),
  deletedAt: z.string(),
  replies: z.lazy(() => baseCommentDTO.array()).nullish(),
  pointAt: pointAtSchema.nullable(),
});

export const aliveCommentDTO = baseCommentDTO.extend({
  replies: z.lazy(() => baseCommentDTO.array()).nullish(),
});

export type AliveCommentDTO = z.infer<typeof aliveCommentDTO>;

export const commentDTO = z.union([deletedCommentDTO, aliveCommentDTO]);

export type CommentDTO = z.infer<typeof commentDTO>;

const documentEmbedDTO = z.object({
  id: z.string(),
  assetUrl: z.string(),
  assetId: z.string(),
  width: z.number(),
  height: z.number(),
  mediaType: z.string(),
  sizeBytes: z.number(),
});

const baseFieldInstance = z.object({
  instanceId: z.string().optional(),
});

export const tagColors = [
  'neutral',
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose',
] as const;

export const mediaGroupCategoryFieldTagColorsSchema = z.enum(tagColors);
export type TagColor = z.infer<typeof mediaGroupCategoryFieldTagColorsSchema>;

export const tagFieldInstance = baseFieldInstance.extend({
  tag: z.string(),
});

export const textFieldInstance = baseFieldInstance.extend({
  value: z.string(),
});

export const dateFieldInstance = baseFieldInstance.extend({
  date: z.string().date(),
});

export const validateDecimal = (value: string) => {
  const positions = value.split('.');
  if (positions.length > 2) {
    return false;
  }
  if (!/^-?\d+$/.test(positions[0]!)) {
    return false;
  }
  if (positions.length === 2 && !/^\d+$/.test(positions[1]!)) {
    return false;
  }
  return true;
};

export const numberFieldInstance = baseFieldInstance.extend({
  value: z.string().refine(validateDecimal),
});

export const relationshipFieldInstance = baseFieldInstance.extend({
  mediaGroupId: z.string(),
  label: z.string().optional(),
  contentType: contentTypeSchema.optional(),
});

export const linkFieldInstance = baseFieldInstance.extend({
  text: z.string().optional(),
  url: z.string(),
});

export const booleanFieldInstance = baseFieldInstance.extend({
  value: z.boolean(),
});

export type TagFieldInstance = z.infer<typeof tagFieldInstance>;
export type TextFieldInstance = z.infer<typeof textFieldInstance>;
export type DateFieldInstance = z.infer<typeof dateFieldInstance>;
export type NumberFieldInstance = z.infer<typeof numberFieldInstance>;
export type RelationshipFieldInstance = z.infer<typeof relationshipFieldInstance>;
export type LinkFieldInstance = z.infer<typeof linkFieldInstance>;
export type BooleanFieldInstance = z.infer<typeof booleanFieldInstance>;
export const fieldInstance = z
  .union([
    linkFieldInstance,
    relationshipFieldInstance,
    dateFieldInstance,
    numberFieldInstance,
    tagFieldInstance,
    textFieldInstance,
    booleanFieldInstance,
  ])
  .nullable();
export type FieldInstance = z.infer<typeof fieldInstance>;
export const mediaGroupCategoryFieldKinds = [
  'text',
  'number',
  'select',
  'multi-select',
  'date',
  'checkbox',
  'url',
  'phone',
  'email',
  'relationship',
] as const;

const mediaGroupCategoryFieldKindSchema = z.enum(mediaGroupCategoryFieldKinds);
export const mediaGroupWithCategoryFields = z.object({
  id: z.string(),
  label: z.string(),
  contentType: contentTypeSchema.catch((value) => {
    console.warn('Unknown media group content type, using fallback.', {
      got: value.input,
    });
    return 'file';
  }),
  // TODO: remove optional
  createdAt: z.string().datetime().optional(),
  fields: z
    .object({
      fieldId: z.string(),
      label: z.string(),
      kind: mediaGroupCategoryFieldKindSchema,
      instances: fieldInstance.array(),
    })
    .array(),
});
export type MediaGroupWithCategoryFields = z.infer<typeof mediaGroupWithCategoryFields>;
const boardDTO = z.object({
  edges: z.any().array().optional(),
  nodes: z.any().array().optional(),
  lastUpdated: z.string().optional(),
});

export type BoardDTO = z.infer<typeof boardDTO>;

export const mediaGroupDetailDTO = z.intersection(
  mediaGroupDTO,
  z.object({
    description: z.string(),
    tags: tagDTO.array(),
    commentCount: z.number(),
    colors: colorDTO.array(),
    document: z.any().nullable(),
    links: z.array(mediaGroupDTO).nullable(),
    backLinks: z.array(mediaGroupDTO).nullable(),
    documentEmbeds: z.array(documentEmbedDTO).nullable(),
    categoryFields: z
      .object({
        fieldId: z.string(),
        label: z.string(),
        kind: mediaGroupCategoryFieldKindSchema,
        instances: fieldInstance.array(),
      })
      .array(),
    sizeBytes: z.number(),
    board: boardDTO.nullable().optional().default(null),
    // TODO(BACK): Remove catch
    references: mediaGroupDTO.array().catch([]),
  })
);

export type DocumentEmbedDTO = z.infer<typeof documentEmbedDTO>;

export type MediaGroupDetailDTO = z.infer<typeof mediaGroupDetailDTO>;

export const mediaGroupReadableSummaryNotAvailablePayloadSchema = z.object({
  kind: z.literal('readableSummaryNotAvailable'),
  pending: z.boolean(),
});
export type MediaGroupReadableSummaryNotAvailablePayload = z.infer<
  typeof mediaGroupReadableSummaryNotAvailablePayloadSchema
>;

export const mediaGroupReadableSummaryPayloadSchema = successfulResponseSchema.extend({
  html: z.string(),
});
export type MediaGroupReadableSummaryPayload = z.infer<
  typeof mediaGroupReadableSummaryPayloadSchema
>;

const mediaGroupAiSummaryMarkdownDTOSchema = z.object({
  kind: z.literal('markdown'),
  markdown: z.string(),
});
const mediaGroupAiSummaryNoneDTOSchema = z.object({ kind: z.literal('none') });
const mediaGroupAiSummaryPendingDTOSchema = z.object({ kind: z.literal('pending') });
const mediaGroupAiSummaryFailedDTOSchema = z.object({ kind: z.literal('failed') });
const mediaGroupAiSummaryRejectedDTOSchema = z.object({ kind: z.literal('rejected') });

export const mediaGroupAiSummarySuccessPayloadSchema = successfulResponseSchema.extend({
  summary: z.discriminatedUnion('kind', [
    mediaGroupAiSummaryMarkdownDTOSchema,
    mediaGroupAiSummaryNoneDTOSchema,
    mediaGroupAiSummaryPendingDTOSchema,
    mediaGroupAiSummaryFailedDTOSchema,
    mediaGroupAiSummaryRejectedDTOSchema,
  ]),
});
export type MediaGroupAiSummarySuccessPayload = z.infer<
  typeof mediaGroupAiSummarySuccessPayloadSchema
>;
export const mediaGroupAiSummaryNotPossiblePayloadSchema = z.object({
  kind: z.literal('aiSummaryNotPossible'),
});
export type MediaGroupAiSummaryNotPossiblePayload = z.infer<
  typeof mediaGroupAiSummaryNotPossiblePayloadSchema
>;

const transcriptionContentEntrySchema = z.object({
  speaker: z.number(),
  words: z
    .object({
      at: z.number(),
      text: z.string(),
    })
    .array(),
});
export type TranscriptionContentEntry = z.infer<typeof transcriptionContentEntrySchema>;
const mediaGroupTranscriptionSuccessDTOSchema = z.object({
  kind: z.literal('success'),
  content: transcriptionContentEntrySchema.array(),
});
const mediaGroupTranscriptionNoneDTOSchema = z.object({ kind: z.literal('none') });
const mediaGroupTranscriptionPendingDTOSchema = z.object({
  kind: z.literal('pending'),
});
const mediaGroupTranscriptionFailedDTOSchema = z.object({ kind: z.literal('failed') });
const mediaGroupTranscriptionRejectedDTOSchema = z.object({
  kind: z.literal('rejected'),
});

export const mediaGroupTranscriptionSuccessPayloadSchema =
  successfulResponseSchema.extend({
    transcription: z.discriminatedUnion('kind', [
      mediaGroupTranscriptionSuccessDTOSchema,
      mediaGroupTranscriptionNoneDTOSchema,
      mediaGroupTranscriptionPendingDTOSchema,
      mediaGroupTranscriptionFailedDTOSchema,
      mediaGroupTranscriptionRejectedDTOSchema,
    ]),
  });
export type MediaGroupTranscriptionSuccessPayload = z.infer<
  typeof mediaGroupTranscriptionSuccessPayloadSchema
>;
export const mediaGroupTranscriptionNotPossiblePayloadSchema = z.object({
  kind: z.literal('TranscriptionNotPossible'),
});
export type MediaGroupTranscriptionNotPossiblePayload = z.infer<
  typeof mediaGroupTranscriptionNotPossiblePayloadSchema
>;

export const mediaGroupCategoryCollectionCapabilitySchema = z.enum(['create']);
export type MediaGroupCategoryCollectionCapability = z.infer<
  typeof mediaGroupCategoryCollectionCapabilitySchema
>;
export const mediaGroupCategoryCapabilitySchema = z.enum(['edit', 'delete']);
export type MediaGroupCategoryCapability = z.infer<
  typeof mediaGroupCategoryCapabilitySchema
>;
export const mediaGroupCategorySchema = z.object({
  id: z.string(),
  label: z.string(),
  propertiesCount: z.number(),
  // TODO(BACK): remove optional.
  projectLabel: z.string().optional(),
  // TODO(BACK): remove null/optional
  mediaGroupCount: z.number().nullable().default(null).optional(),
  lastUpdated: z.string().datetime(),
  capabilities: createCapabilitySchema(mediaGroupCategoryCapabilitySchema)
    .array()
    .optional(),
});
export type MediaGroupCategory = z.infer<typeof mediaGroupCategorySchema>;

const tagOption = z.object({
  id: z.string().optional(),
  label: z.string(),
  color: mediaGroupCategoryFieldTagColorsSchema,
});

export type TagOption = z.infer<typeof tagOption>;

export type MediaGroupCategoryFieldKind = z.infer<
  typeof mediaGroupCategoryFieldKindSchema
>;

const mediaGroupCategoryFieldSettingsRestSchema = z.object({
  kind: z.enum([
    mediaGroupCategoryFieldKindSchema.enum.checkbox,
    mediaGroupCategoryFieldKindSchema.enum.date,
    mediaGroupCategoryFieldKindSchema.enum.email,
    mediaGroupCategoryFieldKindSchema.enum.number,
    mediaGroupCategoryFieldKindSchema.enum.phone,
    mediaGroupCategoryFieldKindSchema.enum.text,
    mediaGroupCategoryFieldKindSchema.enum.url,
  ]),
  settings: z.null(),
});

const mediaGroupCategoryFieldSettingsRelationshipSchema = z.object({
  kind: z.literal(mediaGroupCategoryFieldKindSchema.enum.relationship),
  settings: z.object({
    mediaGroupCategoryId: z.string(),
  }),
});

const mediaGroupCategoryFieldSettingsTagSchema = z.object({
  kind: z.enum([
    mediaGroupCategoryFieldKindSchema.enum.select,
    mediaGroupCategoryFieldKindSchema.enum['multi-select'],
  ]),
  settings: z.object({
    options: tagOption.array(),
  }),
});

const categoryFieldKindSettings = z.discriminatedUnion('kind', [
  mediaGroupCategoryFieldSettingsRestSchema,
  mediaGroupCategoryFieldSettingsRelationshipSchema,
  mediaGroupCategoryFieldSettingsTagSchema,
]);

export type CategoryFieldKindSettings = z.infer<typeof categoryFieldKindSettings>;

export const mediaGroupCategoryFieldSchema = z.intersection(
  z.object({
    id: z.string(),
    label: z.string(),
    // TODO: Remove optional
    order: z.number().optional().default(0),
    width: z.number().optional().nullable(),
  }),
  categoryFieldKindSettings
);

export type MediaGroupCategoryField = z.infer<typeof mediaGroupCategoryFieldSchema>;

export const mediaGroupCategoryDetailSchema = mediaGroupCategorySchema.extend({
  createdBy: tinyUserSchema.nullable(),
  project: z.object({ id: z.string(), label: z.string() }),
  // TODO: Remove optional
  labelAlias: z.string().optional().default(''),
  // TODO: Remove optional
  labelWidth: z.number().optional().nullable(),
  fields: mediaGroupCategoryFieldSchema.array(),
});
export type MediaGroupCategoryDetail = z.infer<typeof mediaGroupCategoryDetailSchema>;

export const roleSchema = z.enum(['V', 'E', 'A', 'O']);
export const inviteRoleSchema = z.enum(['V', 'E']);
export type Role = z.infer<typeof roleSchema>;
export type InviteRole = z.infer<typeof inviteRoleSchema>;

export const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatarUrl: z.string().nullable(),
});

export const memberDTO = userSchema.extend({
  role: roleSchema,
});

export const projectDutySchema = z.enum(['L', 'S']);
export type ProjectDuty = z.infer<typeof projectDutySchema>;

export const projectRoleDutySummarySchema = z.enum(['L', 'C', 'V']);
export type ProjectRoleDutySummary = z.infer<typeof projectRoleDutySummarySchema>;

export const projectStatusSchema = z.enum([
  'none',
  'planned',
  'inProgress',
  'paused',
  'canceled',
  'completed',
]);
export type ProjectStatus = z.infer<typeof projectStatusSchema>;

// TODO(BACK): Remove transform
export const projectModeSchema = z
  .enum(['open', 'default', 'private'])
  .transform((value) => (value === 'default' ? 'open' : value));
export type ProjectMode = z.infer<typeof projectModeSchema>;

const projectDTOBase = z.object({
  createdAt: z.string(),
  id: z.string(),
  label: z.string(),
  description: z.string(),
  mode: projectModeSchema,
  status: projectStatusSchema,
  role: roleSchema,
  isFollowing: z.boolean(),
  isStarred: z.boolean(),
  mediaGroupStatuses: mediaGroupStatusDTO.array(),
  isDeleted: z.boolean(),
  capabilities: createCapabilitySchema(projectCapabilitySchema).array(),
  // TODO(BACK): Remove optional
  iconName: z.string().nullable().optional().catch(null),
  iconColor: z.string().nullable().optional().catch(null),
});

export const projectMemberDTO = memberDTO.extend({
  duty: projectDutySchema.nullable(),
  roleDutySummary: projectRoleDutySummarySchema,
});

export const webLinkSchema = z.object({
  id: z.string(),
  label: z.string(),
  url: z.string(),
});

export const isProject = (value: ProjectDTO | ProjectStackDTO): value is ProjectDTO =>
  value.kind === 'project';

export const projectDTO = projectDTOBase.extend({
  membersPreview: memberDTO.array(),
  targetDate: z.string().nullable(),
  totalMembers: z.number(),
  totalPosts: z.number(),
  kind: z.literal('project'),
  // TODO(BACK): Remove default
  stackId: z.string().nullable().default(null),
});

export const projectMediaGroupCategoryDetailSchema = z.object({
  id: z.string(),
  label: z.string(),
  usageCount: z.number(),
});

export type ProjectMediaGroupCategoryDetail = z.infer<
  typeof projectMediaGroupCategoryDetailSchema
>;

export const projectDetailDTO = projectDTOBase.extend({
  members: projectMemberDTO.array(),
  targetDate: z.string().nullable(),
  mode: projectModeSchema,
  workspace: z.object({
    id: z.string(),
    label: z.string(),
    role: roleSchema,
  }),
  links: webLinkSchema.array(),
  categories: projectMediaGroupCategoryDetailSchema.array(),
  aiSettings: z
    .object({
      memberProfession: z.string(),
      focus: z.string(),
    })
    .nullable()
    .default(null),
});

export type ProjectDTO = z.infer<typeof projectDTO>;
export type ProjectDetailDTO = z.infer<typeof projectDetailDTO>;

export const billingPlanSchema = z.enum([
  'starter', // TODO(BACK): Remove starter
  'team', // TODO(BACK): Remove team
  'pro',
  'enterprise',
]);
export type BillingPlan = z.infer<typeof billingPlanSchema>;

export const billingIntervalSchema = z.enum(['month', 'year']);
export type BillingInterval = z.infer<typeof billingIntervalSchema>;

const tinyWorkspaceDTO = z.object({
  id: z.string(),
  label: z.string(),
  avatarUrl: z.string().nullable(),
});

const workspaceEffectLeaveDTO = z.object({
  kind: z.literal('leave'),
  workspace: tinyWorkspaceDTO,
  wasOwner: z.boolean(),
});

const workspaceEffectRemovalDTO = z.object({
  kind: z.literal('removal'),
  workspace: tinyWorkspaceDTO,
});

const workspaceEffectInterventionDTO = z.object({
  kind: z.literal('intervention'),
  workspace: tinyWorkspaceDTO,
  reason: z.literal('transferOrDelete'),
});

const workspaceEffectSchema = z.discriminatedUnion('kind', [
  workspaceEffectLeaveDTO,
  workspaceEffectRemovalDTO,
  workspaceEffectInterventionDTO,
]);

export const userDeactivationInfoPayload = successfulResponseSchema.extend({
  workspaceEffects: workspaceEffectSchema.array(),
});
