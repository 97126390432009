import { useEffect, useRef, useState } from 'react';

import type { MediaGroupDTO } from '@spaceduck/api';

import { useCreateMediaGroup } from '@api/mediaGroup';
import {
  newItemOptions,
  type KeyDownHandler,
  type NewItemOption,
} from '@components/MediaGroupPreviewList';
import { ContentType } from '@components/icons';
import useWorkspaceId from '@hooks/useWorkspaceId';
import {
  ContentBlockListItem,
  MenuContainer,
  NewMediaGroupButton,
  NewMediaGroupLoading,
} from './index';
import styles from './NoSearchResultWithCreateMenu.module.scss';

export default function NoSearchResultWithCreateMenu({
  closeParentPopup,
  handleSelectItem,
  previousDownHandler,
  searchValue,
  setOnKeyDown,
}: {
  closeParentPopup?: () => void;
  handleSelectItem: (item: MediaGroupDTO) => void;
  previousDownHandler: () => KeyDownHandler;
  searchValue: string;
  setOnKeyDown: (setHandler: () => KeyDownHandler) => void;
}) {
  const newItemContainerRef = useRef<HTMLDivElement>(null);
  const [activeOption, setActiveOption] = useState<NewItemOption>('document');
  const _activeOption = useRef<NewItemOption>('document');
  const workspaceId = useWorkspaceId();
  const [newMediaGroupId, setNewMediaGroupId] = useState<string | null>(null);
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();

  const handleCreate = async (kind: NewItemOption) => {
    const { mediaGroupId } = await createMediaGroup({
      kind,
      workspaceId: workspaceId,
      label: searchValue,
    });

    if (mediaGroupId) {
      setNewMediaGroupId(mediaGroupId);
    }
  };

  useEffect(() => {
    _activeOption.current = activeOption;
  }, [activeOption]);

  const upHandler = () => {
    setActiveOption((currentValue) => {
      const currentIndex = newItemOptions.indexOf(currentValue);

      if (currentIndex - 1 < 0) {
        return newItemOptions.at(-1)!;
      }

      return newItemOptions.at(currentIndex - 1)!;
    });
  };

  const downHandler = () => {
    setActiveOption((currentValue) => {
      const currentIndex = newItemOptions.indexOf(currentValue);

      if (currentIndex + 1 > newItemOptions.length - 1) {
        return newItemOptions.at(0)!;
      }

      return newItemOptions.at(currentIndex + 1)!;
    });
  };

  const enterHandler = () => {
    handleCreate(_activeOption.current);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (newItemContainerRef.current?.checkVisibility?.()) {
      if (event.key === 'ArrowUp') {
        upHandler();
        event.preventDefault();
        return true;
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    setOnKeyDown(() => handleKeyDown);

    return () => setOnKeyDown(previousDownHandler);
  }, []);

  return (
    <MenuContainer onClick={closeParentPopup} ref={newItemContainerRef}>
      <div className={styles.mediaGroupList}>
        <h3>Actions</h3>
        {newMediaGroupId && (
          <NewMediaGroupLoading
            mediaGroupId={newMediaGroupId}
            handleSelectItem={handleSelectItem}
            closeParentPopup={closeParentPopup}
          />
        )}
        {!newMediaGroupId &&
          newItemOptions.map((option) => {
            return (
              <ContentBlockListItem
                key={option}
                bucket="Drafts"
                isActive={option === activeOption}
                icon={<ContentType contentType={option} size={24} />}
                label={`Create ${option}${searchValue ? ` "${searchValue}"` : ''}`}
                onClick={() => setActiveOption(option)}
                onDoubleClick={() => {
                  setActiveOption(option);
                  handleCreate(option);
                }}
              />
            );
          })}
      </div>
      <div className={styles.focusedItem}>
        {!newMediaGroupId && (
          <NewMediaGroupButton
            onClick={(ev) => {
              ev.preventDefault();
              handleCreate(_activeOption.current);
            }}
          />
        )}
      </div>
    </MenuContainer>
  );
}
