import { useModalManager } from '@/context/ModalManagerContext';
import Dialog from '../ui/Dialog';
import styles from './StackManagementModal.module.scss';
import { Icon16 } from '../../../packages/icons/src';
import Button from '../ui/Button';
import { Controller, useForm } from 'react-hook-form';
import type { ProjectDTO, ProjectStackDTO, ProjectStackPatch } from '@spaceduck/api';
import {
  useCreateProjectStack,
  useDeleteProjectStack,
  usePatchProjectStack,
  useProjectStackDetail,
} from '@/api/project';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { ConfirmModal } from '../ui/ConfirmModal';
import DropdownMenu from '../ui/DropdownMenu';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { Remove } from '../../../packages/icons/src/16';
import { useCallback, useEffect } from 'react';
import SpaceCard from '../SpaceCard';

const { Stack, MenuMore } = Icon16;

const StackManagementModal = ({
  closeModal,
  stack,
  projects,
}: {
  closeModal: () => void;
  stack?: StackManagementModalProps;
  projects?: ProjectDTO[];
}) => {
  'use no memo';
  const { data: stackData } = useProjectStackDetail(stack?.id || null);
  const modalProjects = projects ?? (stackData?.projectStack?.projects || []);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<ProjectStackPatch>({
    defaultValues: {
      id: stack?.id,
      label: stack?.label,
      projectIds: modalProjects.map((project) => project.id),
    },
  });
  useEffect(() => {
    if (stackData !== undefined) {
      setValue(
        'projectIds',
        stackData.projectStack.projects.map((p) => p.id)
      );
    }
  }, [stackData]);
  const workspaceId = useWorkspaceId();
  const { mutateAsync: patchProjectStack } = usePatchProjectStack();
  const { mutateAsync: createProjectStack } = useCreateProjectStack();
  const { open: openDeleteStackModal } = useDeleteStackModal();
  const canEditStack =
    stack?.capabilities?.find((c) => c.capability === 'editStack')?.capable || false;
  const canDeleteStack =
    stack?.capabilities?.find((c) => c.capability === 'deleteStack')?.capable || false;
  const onSubmit = useCallback(
    async (data: ProjectStackPatch) => {
      if (data.id) {
        patchProjectStack(data);
        closeModal();
        return;
      }
      if (workspaceId) {
        createProjectStack({ ...data, workspaceId });
        closeModal();
      }
    },
    [patchProjectStack, createProjectStack, closeModal]
  );
  const stackId = stack?.id;
  return (
    <Dialog
      className={styles.stackManagementModal}
      isOpen
      breadcrumb={[
        {
          icon: <Stack />,
          text: 'Stack',
        },
      ]}
      closeModal={closeModal}
      modalHeadingExtra={
        <div className={styles.headingExtraWrapper}>
          {canDeleteStack && stackId && (
            <DropdownMenu
              align="end"
              className={styles.stackActionMenu}
              triggerContent={
                <Button type="button" variant="icon" size="sm">
                  <MenuMore size={20} />
                </Button>
              }
            >
              <DropdownMenuItem
                asChild
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteStackModal(stackId);
                }}
              >
                <Button type="button" variant="menu" iconBefore={<Remove size={20} />}>
                  Empty & Delete Stack
                </Button>
              </DropdownMenuItem>
            </DropdownMenu>
          )}
        </div>
      }
    >
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formGroup">
            <label htmlFor="label">Stack name</label>
            <input
              placeholder="Stack name..."
              autoComplete="off"
              {...register('label', {
                required: 'Stack name is required',
                validate: (value) => {
                  if (value.trim().length === 0) {
                    return 'Cannot be empty';
                  }
                },
              })}
              id="label"
              type="text"
            />

            {errors?.label?.message && (
              <p className="errorMessage">{errors.label.message}</p>
            )}
            <input {...register('id')} type="hidden" />
          </div>
          <div className={styles.projectList}>
            <Controller
              control={control}
              name="projectIds"
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    {value?.map((projectId) => {
                      const project = modalProjects.find((p) => p.id === projectId);
                      if (!project) {
                        return null;
                      }
                      const canEditProject =
                        project.capabilities.find((c) => c.capability === 'edit')
                          ?.capable || false;
                      return (
                        <div key={project.id} className={styles.projectCard}>
                          <SpaceCard
                            project={project}
                            onClick={closeModal}
                            stackMode={
                              canEditStack && canEditProject ? 'remove' : undefined
                            }
                            removeFromStack={() => {
                              onChange(value.filter((pid) => pid !== projectId));
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                );
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button type="button" onClick={closeModal} size="sm" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" size="sm" variant="primary">
              Save changes
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

type StackManagementModalProps = Partial<ProjectStackDTO>;

export const useStackManagementModal = () => {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      stack,
      projects,
    }: { stack?: StackManagementModalProps; projects?: ProjectDTO[] }) => {
      openModal({
        component: (
          <StackManagementModal
            stack={stack}
            closeModal={closeModal}
            projects={projects}
          />
        ),
      });
    },
    close: closeModal,
  };
};

export const useDeleteStackModal = () => {
  const { openModal, closeModal } = useModalManager();
  const { mutateAsync: deleteStack } = useDeleteProjectStack();
  return {
    open: (stackId: string) => {
      openModal({
        component: (
          <ConfirmModal
            title="Empty and Delete stack"
            onConfirm={() => {
              deleteStack(stackId);
              closeModal();
            }}
            closeModal={closeModal}
          >
            <p className={styles.deleteStackModalBody}>
              Are you sure you want to delete this stack? All spaces in this stack will
              be moved to the main Spaces area.
            </p>
          </ConfirmModal>
        ),
      });
    },
    close: closeModal,
  };
};
