import clsx from 'clsx';
import { forwardRef, type ReactNode } from 'react';
import type { Placement } from 'tippy.js';

import { MenuSeparator } from './Separator';
import { MenuTrigger } from './Trigger';
import { WrapWithTooltip } from './WrapWithTooltip';
import styles from './Item.module.scss';

export type MenuItem = {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  label?: string;
  menuItems?: MenuItem[];
  onClick?: (ev: React.MouseEvent) => void;
  title?: string;
  tooltip?: string;
  disabled?: boolean;
  isActive?: boolean;
  isSeparator?: boolean;
  placement?: Placement;
  removeMenuItemStyle?: boolean;
  showCheck?: boolean;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
};

export type MenuItemProps = { className?: string } & Pick<
  MenuItem,
  | 'isActive'
  | 'disabled'
  | 'icon'
  | 'label'
  | 'onClick'
  | 'tooltip'
  | 'isSeparator'
  | 'showCheck'
>;

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      isActive,
      className,
      disabled = false,
      icon,
      label,
      onClick,
      tooltip,
      isSeparator,
      showCheck,
    }: MenuItemProps,
    ref
  ) => {
    if (isSeparator) {
      return <MenuSeparator />;
    }

    return (
      <WrapWithTooltip tooltip={tooltip}>
        <button
          className={clsx(
            'menuItem',
            styles.menuItem,
            isActive && styles.isActive,
            className
          )}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          type="button"
        >
          <MenuTrigger icon={icon} label={label} showCheck={showCheck} />
        </button>
      </WrapWithTooltip>
    );
  }
);
