import { Icon16, Icon32 } from '@spaceduck/icons';
import { useState } from 'react';

import SpaceEmptySection from '@assets/img/onboarding/space-empty-section.png';
import Button from '@ui/Button';
import styles from './EmptySpaces.module.scss';
import Card, { CardGrid } from './empty/Card';
import Lightbox from './empty/Lightbox';

const { Add } = Icon16;
const { Play } = Icon32;

const VIDEO_URL = null;

export default function EmptyCollection({
  openCreateProjectModal,
}: {
  openCreateProjectModal?: () => void;
}) {
  const [showLightbox, setShowLightbox] = useState(false);

  return (
    <>
      <div className={styles.emptyCollectionContainer}>
        <div className={styles.text}>
          <h1>Your Hub for Research, Inspiration, and Organization</h1>
          <p>
            Spaces let you kickstart a research project, curate a collection of
            inspiration, or effortlessly organize your content.
          </p>
        </div>
        {!!VIDEO_URL && (
          <div className={styles.video}>
            <button className={styles.iconButton} onClick={() => setShowLightbox(true)}>
              <Play />
            </button>
            <button className={styles.textButton} onClick={() => setShowLightbox(true)}>
              Learn how Spaces work
            </button>
          </div>
        )}
        <CardGrid>
          <Card
            button={
              <Button
                iconBefore={<Add size={12} />}
                onClick={() => openCreateProjectModal?.()}
                size="sm"
                variant="outlined"
              >
                New Space
              </Button>
            }
          >
            <img className={styles.paddedImage} alt="" src={SpaceEmptySection} />
            <h3>Create a space to get started</h3>
            <p>
              Spaces helps you organize, research, share, and track progress with your
              team or yourself.
            </p>
          </Card>
        </CardGrid>
      </div>
      {!!VIDEO_URL && (
        <Lightbox
          close={() => setShowLightbox(false)}
          embedUrl={VIDEO_URL}
          open={showLightbox}
        />
      )}
    </>
  );
}
