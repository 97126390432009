import { Icon16 } from '@spaceduck/icons';

import { BROWSER_EXT_URL, RELEASE_NOTES_URL } from '@/const';
import { useFeedbackModal } from '@ui/FeedbackModal';
import MenuLink from './MenuLink';
import styles from './MenuMoreSection.module.scss';

const { BrowserExtension, Changelog, Feedback } = Icon16;

export default function MenuMoreSection() {
  const { open: openFeedbackModal } = useFeedbackModal();
  return (
    <nav className={styles.more}>
      <div className={styles.menuHeading}>More</div>
      <MenuLink icon={<BrowserExtension />} url={BROWSER_EXT_URL} target="_blank">
        Browser Extension
      </MenuLink>
      <MenuLink icon={<Feedback />} asButton onClick={openFeedbackModal}>
        Give Feedback
      </MenuLink>
      <MenuLink icon={<Changelog />} url={RELEASE_NOTES_URL} target="_blank">
        Changelog
      </MenuLink>
    </nav>
  );
}
