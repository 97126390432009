import { type MouseEvent, type MutableRefObject, useCallback, useRef } from 'react';
import clsx from 'clsx';

import type { MediaGroupDetailDTO, TranscriptionContentEntry } from '@spaceduck/api';
import { Icon12, Icon16, Icon64 } from '@spaceduck/icons';

import {
  useEnqueueMediaGroupTranscription,
  useMediaGroupTranscription,
} from '@api/mediaGroup';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import NoEntries from '@components/NoEntries';
import { AudioPlayer } from '@detailsModal/media/AudioPlayer';
import { canHaveTranscription } from '@detailsModal/views';
import { usePoll } from '@hooks/usePoll';
import { useRotatingChoice } from '@hooks/useRotatingChoice';
import { css } from '@lib/css';
import AlertBanner from '@ui/AlertBanner';
import Button from '@ui/Button';
import BunnyStreamEmbed from '@ui/BunnyStreamEmbed';
import { getBunnyDisplayHeight, getBunnyDisplayWidth } from '@utils/carouselVideos';
import createToast from '@utils/createToast';
import { humanizeSeconds } from '@utils/humanize';
import styles from './TranscriptionModeView.module.scss';

const { Summary } = Icon64;
const { Magic } = Icon16;
const { Play } = Icon12;

export const FALLBACK_VIDEO_WIDTH = 690;
export const FALLBACK_VIDEO_HEIGHT = 180;
export const LANDSCAPE_MAX_WIDTH = 690;

const LOADING_TEXTS = [
  'Untangling the audio waves',
  'Sharpening my hearing',
  'Finding the perfect headphones',
  'Tuning in to the soundscape',
  'Checking for hidden whispers',
  'Adjusting the volume',
  'Deciphering the sound hieroglyphs',
  'Listening closely... and then closer',
  'Isolating the vocals from the noise',
  'Breaking down beats and syllables',
  'Hunting for the rewind button',
  'Spotting punctuation in sound',
  'Humming along to the audio',
  'Synchronizing with the speaker',
  'Pondering every pause and sigh',
  'Mapping the sound waves',
  'Counting the ums and ahs',
  'Tracing echoes through the void',
  'Asking the audio gods for clarity',
  'Filling in the blanks with context',
  'Switching to my listening ears',
  'Adjusting for regional accents',
  'Decoding mysterious murmurs',
  'Converting vibrations into words',
  'Listening with both wings raised',
  'Diving into the audio depths',
  'Checking for underwater conversations',
  'Rewinding... and fast-forwarding',
  'Finding the rhythm in the speech',
  'Cross-referencing with my sound library',
  'Turning up the treble',
  'Listening one syllable at a time',
  'Ensuring no quack goes unheard',
  'Distilling words from the sound brew',
  'Aligning subtitles with reality',
];

type SeekFn = (to: number, autoplay?: boolean) => Promise<boolean>;

const Loading = () => {
  const message = useRotatingChoice(LOADING_TEXTS, 2000);

  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        <LoadingPlaceholder message={`${message}...`} />
      </div>
    </div>
  );
};

const ErrorPanel = ({ onRetry }: { onRetry: () => Promise<void> }) => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>Error fetching transcription. Please try again later.</div>
      <div className={styles.actions}>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  </div>
);

const InvalidPanel = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>Transcription are not available for this content.</div>
    </div>
  </div>
);

const Empty = ({
  collapsed,
  mediaGroupId,
}: {
  collapsed?: boolean;
  mediaGroupId: string;
}) => {
  const { mutateAsync: enqueueMediaGroupTranscription, status } =
    useEnqueueMediaGroupTranscription();

  const handleEnqueue = useCallback(async () => {
    await enqueueMediaGroupTranscription(mediaGroupId);
  }, []);

  if (status === 'pending') {
    return <Loading />;
  }

  return (
    <div className={clsx(styles.pane, styles.empty)}>
      <NoEntries className={styles.noEntries} collapsed={collapsed} icon={<Summary />}>
        <h1>Generate transcription...</h1>
        <p>
          Your item is ready for transcription. Click below to generate a searchable and
          shareable transcript.
        </p>
        <Button
          iconBefore={<Magic />}
          onClick={handleEnqueue}
          size="sm"
          variant="primary"
        >
          Transcribe now
        </Button>
      </NoEntries>
    </div>
  );
};

const Failed = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { mutateAsync: enqueueMediaGroupTranscription, status } =
    useEnqueueMediaGroupTranscription();

  const handleEnqueue = useCallback(async () => {
    await enqueueMediaGroupTranscription(mediaGroupId);
  }, []);

  if (status === 'pending') {
    return <Loading />;
  }

  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        <div>Failed to generate transcription. Please try again later.</div>
        <div className={styles.actions}>
          <Button onClick={handleEnqueue}>Retry</Button>
        </div>
      </div>
    </div>
  );
};

type MediaPlayerProps = {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
  seekRef: MutableRefObject<SeekFn | null>;
};

const MediaPlayer = ({ className, mediaGroup, seekRef }: MediaPlayerProps) => {
  const { media, contentType } = mediaGroup;

  if (!media) {
    return null;
  }

  const assetUrl = media.assetUrl;
  const maxDisplayWidth = Math.min(LANDSCAPE_MAX_WIDTH, media.width);
  const maxDisplayHeight = (media.height / media.width) * maxDisplayWidth;
  const isVideo = contentType === 'video';
  const bunnyDisplayWidth = getBunnyDisplayWidth(maxDisplayWidth);
  const bunnyDisplayHeight = getBunnyDisplayHeight(maxDisplayWidth, maxDisplayHeight);
  const displayWidth = isVideo ? bunnyDisplayWidth : maxDisplayWidth;
  const displayHeight = isVideo ? bunnyDisplayHeight : maxDisplayHeight;

  return (
    <div
      className={clsx(
        styles.player,
        media.height > media.width ? styles.portrait : styles.landscape,
        className
      )}
      style={css({ '--max-height': displayHeight, '--max-width': displayWidth })}
    >
      {contentType === 'audio' && <AudioPlayer src={assetUrl} seekRef={seekRef} />}
      {contentType === 'video' && (
        <div className={styles.videoBg}>
          <BunnyStreamEmbed
            seekRef={seekRef}
            height={displayHeight || FALLBACK_VIDEO_HEIGHT}
            preview={media.posterUrl}
            responsive={true}
            source={media.source}
            width={displayWidth || FALLBACK_VIDEO_WIDTH}
            containerClassName={styles.video}
          />
        </div>
      )}
    </div>
  );
};

type TranscriptionModeViewProps = {
  className?: string;
  containerClassName?: string;
  isPreviewCardView?: boolean;
  mediaGroup: MediaGroupDetailDTO;
  nonStickyPlayer?: boolean;
  playerClassName?: string;
};

export const TranscriptionModeView = (props: TranscriptionModeViewProps) => {
  if (!canHaveTranscription(props.mediaGroup)) {
    return <InvalidPanel />;
  }

  return <TranscriptionMode {...props} />;
};

const TranscriptionMode = ({
  className,
  containerClassName,
  isPreviewCardView,
  mediaGroup,
  nonStickyPlayer,
  playerClassName,
}: TranscriptionModeViewProps) => {
  const { data, status, refetch } = useMediaGroupTranscription(mediaGroup.id);
  const handleRetry = useCallback(async () => {
    await refetch();
  }, []);

  const shouldPoll =
    status === 'success' &&
    data.kind === 'success' &&
    data.transcription.kind === 'pending';
  const pollFn = useCallback(() => refetch(), []);
  usePoll(5000, shouldPoll, pollFn);

  const seekRef = useRef<SeekFn | null>(null);
  const seek = useCallback<SeekFn>(async (toSeconds: number) => {
    const result = seekRef.current?.(toSeconds) ?? false;

    if (!result) {
      createToast({
        iconVariant: 'danger',
        titleText: 'Playback failed',
        bodyText: 'Failed to start media playback. Please try again later.',
      });
    }

    return result;
  }, []);

  if (status === 'pending') {
    return <Loading />;
  }
  if (status === 'error') {
    return <ErrorPanel onRetry={handleRetry} />;
  }

  if (data.transcription.kind === 'none') {
    return <Empty collapsed={isPreviewCardView} mediaGroupId={mediaGroup.id} />;
  }

  if (data.transcription.kind === 'failed') {
    return <Failed mediaGroupId={mediaGroup.id} />;
  }

  if (data.transcription.kind === 'pending') {
    return <Loading />;
  }

  return (
    <div className={clsx(styles.transcriptionModeView, containerClassName)}>
      <MediaPlayer
        className={clsx(playerClassName, nonStickyPlayer && styles.nonSticky)}
        mediaGroup={mediaGroup}
        seekRef={seekRef}
      />
      <div className={className}>
        <div className={styles.utterances}>
          {data.transcription.kind === 'success' &&
            data.transcription.content.map((group, i) => {
              return <Utterance key={i} group={group} seek={seek} />;
            })}
          {data.transcription.kind === 'rejected' && (
            <AlertBanner variant="info" title="No audio available">
              This video does not contain audio for transcription.
            </AlertBanner>
          )}
        </div>
      </div>
    </div>
  );
};

type UtteranceProps = { group: TranscriptionContentEntry; seek: SeekFn };
const Utterance = ({ group, seek }: UtteranceProps) => {
  const firstWord = group.words[0];

  if (firstWord === undefined) {
    return null;
  }

  return (
    <div>
      <SpeakerLabel
        label={`Speaker ${group.speaker}`}
        start={firstWord.at}
        seek={seek}
      />
      <div className={styles.transcription}>
        {group.words.map((word, index) => (
          <TranscriptionWord
            key={index}
            word={word}
            seek={seek}
            addSpacing={index > 0}
          />
        ))}
      </div>
    </div>
  );
};

type SpeakerLabelProps = { label: string; start: number; seek: SeekFn };
const SpeakerLabel = ({ label, start, seek }: SpeakerLabelProps) => {
  const handleSeekToStart = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      return seek(start);
    },
    [start, seek]
  );

  return (
    <div className={styles.speakerLabel}>
      <span>{label}</span>
      <Button
        size="xs"
        variant="outlined"
        onClick={handleSeekToStart}
        iconBefore={<Play />}
      >
        {humanizeSeconds(start)}
      </Button>
    </div>
  );
};

type TranscriptionWordProps = {
  word: { at: number; text: string };
  seek: SeekFn;
  addSpacing: boolean;
};
const TranscriptionWord = ({ word, seek, addSpacing }: TranscriptionWordProps) => {
  const { at, text } = word;
  const handleSeek = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      seek(at);
    },
    [at]
  );

  return (
    <>
      {addSpacing && ' '}
      <span className={styles.word} onClick={handleSeek}>
        {text}
      </span>
    </>
  );
};
