import { memo, useCallback, type MouseEvent } from 'react';

import styles from './Separator.module.scss';

export const MenuSeparator = memo(() => {
  const handleMouseDown = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  return <div className={styles.separator} onMouseDown={handleMouseDown} />;
});
