import { useGetSubscriptionInfo } from '@/api/billing';
import { urlFor } from '@/urls';
import useWorkspaceId from '@hooks/useWorkspaceId';
import MenuLink from '@layouts/sidebar/MenuLink';

// TODO: Disabled pending feature decision @ari
const ENABLE_NOTIFICATIONS_MENU_LINK = false;

export default function MenuWorkspaceSettingsSection() {
  const workspaceId = useWorkspaceId();

  const { data: subscriptionData } = useGetSubscriptionInfo(workspaceId);
  if (!workspaceId || !subscriptionData) {
    return null;
  }
  const subscriptionActive = subscriptionData.active;
  return (
    <>
      <MenuLink url={urlFor('workspaceSettingsGeneral', { workspaceId })}>
        General
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsPeople', { workspaceId })}>
        People
      </MenuLink>
      <MenuLink url={urlFor('workspaceSettingsAI', { workspaceId })}>
        AI Settings
      </MenuLink>
      {ENABLE_NOTIFICATIONS_MENU_LINK && subscriptionActive && (
        <MenuLink url={urlFor('workspaceSettingsNotifications', { workspaceId })}>
          Notifications
        </MenuLink>
      )}
      {subscriptionActive && (
        <MenuLink url={urlFor('workspaceSettingsTags', { workspaceId })}>Tags</MenuLink>
      )}
      {subscriptionActive && (
        <MenuLink url={urlFor('workspaceSettingsSpaces', { workspaceId })}>
          Spaces
        </MenuLink>
      )}
      <MenuLink url={urlFor('workspaceSettingsBillingAndPlan', { workspaceId })}>
        Billing & Plan
      </MenuLink>
      {subscriptionActive && (
        <MenuLink url={urlFor('workspaceSettingsIntegrations', { workspaceId })}>
          Integrations
        </MenuLink>
      )}
    </>
  );
}
