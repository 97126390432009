import type { Workspace } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router';

import { urlFor } from '@/urls';
import {
  usePatchUserSelf,
  useUserDeactivateSelf,
  useUserDeactivationInfo,
} from '@api/users';
import { toastApiErrorOr } from '@api/util';
import { useModalManager } from '@context/ModalManagerContext';
import { useDestroySession, useRefreshSession, useUserInfo } from '@hooks/useAuth';
import { type ProcessingResult, useProcessAssets } from '@hooks/useProcessAssets';
import {
  acceptedProfileImageMediaTypes,
  acceptedProfileImagePattern,
} from '@lib/const';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import Dialog from '@ui/Dialog';
import Spinner from '@ui/Spinner';
import UserAvatar, { WorkspaceAvatar } from '@ui/UserAvatar';
import createToast from '@utils/createToast';
import styles from './ProfileManager.module.scss';
import sharedStyles from './Settings.module.scss';
import { Container, Header, TopNav } from './common';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { pluralize } from '@spaceduck/utils';

const { Close } = Icon16;
const { Right } = Icon24;

type ProfileData = {
  image?: FileList | null;
  name: string;
};

export default function ProfileManagerPage() {
  'use no memo';
  const user = useUserInfo();
  const { open: openAccountDeactivationModal } = useConfirmAccountDeactivationModal();
  const [newAvatarAssetId, setNewAvatarAssetId] = useState<string | null>();
  const refreshSession = useRefreshSession();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const {
    clearErrors,
    control,
    formState: { dirtyFields, errors, isSubmitting },
    handleSubmit,
    reset,
    register,
    setError,
  } = useForm<ProfileData>({
    values: {
      name: user?.preferredName || '',
    },
  });
  const setImageUploadError = (message: string) => {
    setIsImageUploading(false);
    setError('image', {
      type: 'validate',
      message,
    });
    createToast({
      titleText: 'Could not upload image',
      iconVariant: 'warning',
      bodyText: message,
    });
  };

  const onSuccess = useCallback((item: ProcessingResult) => {
    if (item.request.result?.url) {
      setImageSrc(item.request.result.url);
      clearErrors('image');
      setNewAvatarAssetId(item.request.result.id);
      setIsImageUploading(false);
    }
  }, []);
  const onError = useCallback(() => {
    setImageUploadError('Error uploading image. Please try again.');
  }, []);
  const onReject = useCallback((result: ProcessingResult) => {
    setImageUploadError(result.request.message);
  }, []);
  const onFail = useCallback((result: ProcessingResult) => {
    setImageUploadError(result.request.message);
  }, []);

  const { insert } = useProcessAssets({
    pollInterval: 1000,
    onSuccess,
    onError,
    onReject,
    onFail,
  });

  const formId = useId();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    reset();
    setImageSrc(user?.avatarUrl ?? null);
  }, [user]);

  const handleFileChange = useCallback(
    async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const files = ev.currentTarget.files;
      const file = files?.[0] ?? null;
      if (file === null) {
        return null;
      }
      if (!acceptedProfileImageMediaTypes.has(file.type)) {
        setError('image', {
          type: 'validate',
          message: 'File type is not allowed. Please upload an image.',
        });
        return null;
      }
      setIsImageUploading(true);
      insert({
        files: [file],
        kind: 'avatar',
      });
    },
    [insert]
  );

  const { mutateAsync: patchUserSelf } = usePatchUserSelf();
  const { mutateAsync: userDeactivateSelf } = useUserDeactivateSelf();
  const openImageUpload = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault();
    imageRef.current?.click();
  }, []);

  const clearImageUpload = useCallback(() => {
    setImageSrc(null);
    setNewAvatarAssetId(null);
  }, []);

  const onSubmit: SubmitHandler<ProfileData> = async (formData) => {
    const { name } = formData;

    if (!user) return true;

    const nameChanged = user.preferredName !== name;

    if (newAvatarAssetId === undefined && !nameChanged) {
      return true;
    }

    const patch: {
      avatarAsset?: string | null;
      preferredName?: string;
    } = {};

    if (newAvatarAssetId !== undefined) {
      patch.avatarAsset = newAvatarAssetId;
    }

    if (nameChanged) {
      patch.preferredName = name;
    }
    await patchUserSelf(patch);
    await refreshSession();
    reset();

    const toastText =
      newAvatarAssetId && nameChanged
        ? 'Profile picture and name'
        : newAvatarAssetId
          ? 'Profile picture'
          : 'Name';

    createToast({
      iconVariant: 'success',
      titleText: `${toastText} updated!`,
      bodyText: `${toastText} changed successfully`,
    });
    setNewAvatarAssetId(undefined);
  };

  const destroySession = useDestroySession();

  if (!user) return null;

  return (
    <>
      <TopNav
        owner="account"
        title="Manage Profile"
        currentBreadcrumb="Profile"
        buttonText="Sign Out"
        buttonOnClick={destroySession}
      />
      <Container>
        <Header>
          <h1>Profile</h1>
          <p>
            Changes to these settings will apply to all workspaces for "{user.email}".
          </p>
        </Header>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx('formGroup', sharedStyles.formGroup)}>
            <label htmlFor={`${formId}Image`}>Profile picture</label>
            <div className={styles.imageUpload}>
              <div className={styles.imageViewer}>
                {imageSrc && (
                  <Button
                    className={styles.btnRemoveImage}
                    onClick={clearImageUpload}
                    size="xs"
                    type="button"
                    variant="icon"
                  >
                    <Close size={12} />
                  </Button>
                )}
                <div className={styles.imagePreview}>
                  <div className={styles.hiddenField}>
                    <Controller
                      control={control}
                      name={'image'}
                      render={({ field: { onChange } }) => {
                        return (
                          <input
                            {...register('image')}
                            id={`${formId}Image`}
                            accept={acceptedProfileImagePattern}
                            ref={imageRef}
                            type="file"
                            multiple={false}
                            onChange={(ev) => onChange(handleFileChange(ev))}
                          />
                        );
                      }}
                    />
                  </div>
                  <span className={styles.icon} onClick={openImageUpload}>
                    {user.preferredName && !isImageUploading ? (
                      <UserAvatar
                        name={user.preferredName}
                        size="xxl"
                        imageUrl={imageSrc}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </span>
                </div>
              </div>
              <div className={styles.imageControl}>
                <Button
                  onClick={openImageUpload}
                  size="sm"
                  variant="outlined"
                  type="button"
                >
                  {isImageUploading ? (
                    <>
                      <Spinner size={16} /> Processing...
                    </>
                  ) : (
                    'Replace'
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className={clsx('formGroup', sharedStyles.formGroup)}>
            <label htmlFor={`${formId}Name`}>Name</label>
            <input
              {...register('name', { required: 'Name cannot be empty' })}
              className={clsx(errors.name && 'hasError')}
              defaultValue={user.preferredName}
              id={`${formId}Name`}
              placeholder="e.g. Jane Doe"
              type="text"
            />
            {errors.name?.message && (
              <p className="errorMessage">{errors.name.message}</p>
            )}
          </div>
          <div className={clsx('formGroup', sharedStyles.formGroup)}>
            <label htmlFor={`${formId}Email`}>Email</label>
            <input id={`${formId}Email`} type="email" value={user.email} disabled />
            <p className={clsx('notes', sharedStyles.notes)}>
              Contact <a href="mailto:support@spaceduck.com">support@spaceduck.com</a>{' '}
              if you would like to change this
            </p>
          </div>
          <div className={styles.formFooter}>
            <Button
              size="sm"
              type="submit"
              variant="primary"
              disabled={
                !(Object.keys(dirtyFields).length || imageSrc !== user.avatarUrl) ||
                isSubmitting ||
                isImageUploading
              }
            >
              Update
            </Button>
          </div>
        </form>
        <hr />
        <div className={styles.deactivate}>
          <h2 className="subtitle5">Deactivate account</h2>
          <p>
            This will remove you from all workspaces. If you only want to leave a
            workspace go to{' '}
            <Link to={urlFor('userSettingsWorkspaces')}>Workspaces</Link>.
          </p>
          <Button
            className={styles.deactivateButton}
            onClick={() => {
              openAccountDeactivationModal({
                onConfirm: async (workspaceCascadeIds) => {
                  try {
                    await userDeactivateSelf({ workspaceCascadeIds });
                  } catch (error) {
                    return toastApiErrorOr(error, 'Failed to deactivate self', {
                      iconVariant: 'warning',
                      titleText: 'Account Deactivation failed',
                      bodyText:
                        'An unknown error occurred while deactivating account. Please try again later, or contact support (support@spaceduck.com).',
                    });
                  }
                  refreshSession();
                },
              });
            }}
            size="sm"
            variant="outlined"
          >
            Deactivate account
          </Button>
        </div>
      </Container>
    </>
  );
}

const WorkspaceLink = ({
  onClick,
  workspace,
}: {
  onClick?: () => void;
  workspace: Pick<Workspace, 'id' | 'label' | 'avatarUrl'>;
}) => {
  return (
    <Link
      className={styles.workspaceLink}
      to={urlFor('workspaceSettingsPeople', { workspaceId: workspace.id })}
      onClick={onClick}
    >
      <div className={styles.avatar}>
        <WorkspaceAvatar
          className={styles.textLogo}
          size="md"
          workspaceName={workspace.label}
          workspaceAvatar={workspace.avatarUrl}
        />
      </div>
      <div className={styles.label}>{workspace.label}</div>
      <div className={styles.icon}>
        <Right />
      </div>
    </Link>
  );
};

const DeactivationInfoLoading = () => {
  return (
    <div className={styles.loading}>
      <LoadingPlaceholder message="Preparing account deletion information..." />
    </div>
  );
};
const DeactivationInfoError = ({ refetch }: { refetch: () => unknown }) => {
  const handleClick = useCallback(() => {
    refetch();
  }, [refetch]);

  const supportLink = useMemo(() => {
    const subject = 'Account Deactivation';
    const body =
      "I've encountered an error while attempting to deactivate my spaceduck account. Can you please process the deactivation for my account?";
    return `mailto:support@spaceduck.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}}`;
  }, []);

  return (
    <div className={styles.error}>
      <p>
        Error fetching account deletion information. Please try again later. If this
        error persists, contact <Link to={supportLink}>support@spaceduck.com</Link> and
        request for manual account deactivation.
      </p>
      <div className={styles.actions}>
        <Button onClick={handleClick}>Retry</Button>
      </div>
    </div>
  );
};

const WorkspaceList = ({
  workspaces,
  onClick,
}: {
  workspaces: Pick<Workspace, 'id' | 'label' | 'avatarUrl'>[];
  onClick: (workspaceId: string) => unknown;
}) => {
  return (
    <div className={styles.workspaceList}>
      {workspaces.map((workspace) => (
        <WorkspaceLink
          key={workspace.id}
          onClick={() => onClick(workspace.id)}
          workspace={workspace}
        />
      ))}
    </div>
  );
};

const DeactivationDetails = ({
  details,
  closeModal,
  onConfirm,
}: {
  details: Exclude<ReturnType<typeof useUserDeactivationInfo>['data'], undefined>;
  closeModal?: () => void;
  onConfirm?: () => void;
}) => {
  const [userHasAgreed, setUserHasAgreed] = useState(false);

  const leaveEffects = useMemo(() => {
    return details.workspaceEffects.filter((effect) => effect.kind === 'leave');
  }, [details]);
  const removalEffects = useMemo(() => {
    return details.workspaceEffects.filter((effect) => effect.kind === 'removal');
  }, [details]);
  const interventionEffects = useMemo(() => {
    return details.workspaceEffects.filter((effect) => effect.kind === 'intervention');
  }, [details]);

  const clickWorkspace = useCallback(
    (_workspaceId: string) => {
      closeModal?.();
    },
    [closeModal]
  );

  if (interventionEffects.length > 0) {
    return (
      <div className={styles.content}>
        <p>
          As you're the owner of the following workspace
          {pluralize(interventionEffects.length !== 1, 's')}, you'll need to transfer
          ownership or delete the workspace before you can deactivate your account.
        </p>
        <WorkspaceList
          workspaces={interventionEffects.map((effect) => effect.workspace)}
          onClick={clickWorkspace}
        />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <p>If you no longer need your account on Spaceduck, you can deactivate it.</p>
      <div className={styles.form}>
        {leaveEffects.length > 0 && (
          <div>
            <p>You will leave the following workspaces:</p>
            <WorkspaceList
              workspaces={leaveEffects.map((effect) => effect.workspace)}
              onClick={clickWorkspace}
            />
          </div>
        )}
        {removalEffects.length > 0 && (
          <div>
            <p>The following workspaces will be deleted:</p>
            <WorkspaceList
              workspaces={removalEffects.map((effect) => effect.workspace)}
              onClick={clickWorkspace}
            />
          </div>
        )}
        <Checkbox
          checked={userHasAgreed}
          className={styles.checkbox}
          onChange={(ev) => setUserHasAgreed(ev.currentTarget.checked)}
          size="md"
        >
          I acknowledge that all of account related data will be deleted and this action
          is irreversible.
        </Checkbox>
        <Button
          className={styles.confirmButton}
          disabled={!userHasAgreed}
          onClick={() => {
            onConfirm?.();
            closeModal?.();
          }}
          size="md"
          type="button"
          variant="outlined"
        >
          Deactivate account
        </Button>
      </div>
    </div>
  );
};

const ConfirmAccountDeactivationModal = ({
  closeModal,
  isOpen = true,
  onConfirm,
}: {
  closeModal?: () => void;
  isOpen?: boolean;
  onConfirm: (workspaceCascadeIds: string[]) => void;
}) => {
  const { data, status, refetch } = useUserDeactivationInfo();

  const heading = useMemo(() => {
    return status === 'success' &&
      data.workspaceEffects.find((effect) => effect.kind === 'intervention')
      ? 'Transfer workspace ownership'
      : 'Deactivate account';
  }, [status, data]);

  const workspaceCascadeIds = useMemo(() => {
    if (status !== 'success') {
      return [];
    }
    return data.workspaceEffects
      .filter((effect) => effect.kind === 'removal')
      .map((effect) => effect.workspace.id);
  }, [status, data]);

  const handleConfirm = useCallback(() => {
    onConfirm(workspaceCascadeIds);
  }, [onConfirm, workspaceCascadeIds]);

  return (
    <Dialog
      className={styles.confirmAccountDeactivationModal}
      closeModal={closeModal}
      maxWidth="30rem"
      modalHeading={heading}
      isOpen={isOpen}
      padding="lg"
    >
      {/* TODO(@dbowring): loading content */}
      {status === 'pending' && <DeactivationInfoLoading />}
      {/* TODO(@dbowring): error content */}
      {status === 'error' && <DeactivationInfoError refetch={refetch} />}
      {status === 'success' && (
        <DeactivationDetails
          details={data}
          onConfirm={handleConfirm}
          closeModal={closeModal}
        />
      )}
    </Dialog>
  );
};

const useConfirmAccountDeactivationModal = () => {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({ onConfirm }: { onConfirm: (workspaceCascadeIds: string[]) => void }) => {
      openModal({
        component: <ConfirmAccountDeactivationModal onConfirm={onConfirm} />,
      });
    },
    close: closeModal,
  };
};
