import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { memo, type MouseEvent, useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import type { TextColor } from '@reactFlow/types/colors';
import { MenuButton } from './Button';
import styles from './TextSwatchMenu.module.scss';

type TextSwatchMenuComponentProps<T extends string> = {
  active?: T;
  colors: ReadonlyArray<string>;
  onClick?: (color: Readonly<string>) => void;
};

const { ShapeColor } = Icon16;

const TextSwatchMenuComponent = memo(
  (props: TextSwatchMenuComponentProps<TextColor>) => {
    return (
      <>
        {props.colors.map((color) => {
          return <TextColorButton key={color} {...props} color={color} />;
        })}
      </>
    );
  }
);

const TextColorButton = memo(
  (
    props: Omit<TextSwatchMenuComponentProps<TextColor>, 'color'> & { color: string }
  ) => {
    const { active, onClick, color } = props;

    const tooltipContent = useMemo(() => {
      return upperFirst(color.replace(/([0-9])/, ' $1'));
    }, [color]);

    const handleMouseDown = useCallback((ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
    }, []);

    return (
      <MenuButton
        key={color}
        className={styles[color]}
        isActive={active === color}
        onClick={() => onClick?.(color)}
        onMouseDown={handleMouseDown}
        tooltipContent={tooltipContent}
      >
        <ShapeColor className={clsx(styles.swatch, styles[color])} />
      </MenuButton>
    );
  }
);

export const TextSwatchMenu = memo(TextSwatchMenuComponent);
