import {
  createColumnHelper,
  type ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router';

import {
  joinProject,
  removeUserFromProject,
  type ProjectDTO,
  type ProjectStackDTO,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { pluralize } from '@spaceduck/utils';

import {
  usePatchProject,
  usePatchProjectStack,
  useProjectStackDetail,
} from '@api/project';
import { useWorkspaceProjects } from '@api/workspace';
import {
  projectModes,
  useEditProjectModal,
} from '@components/admin/CreateProjectsModal';
import { ProjectMode, SpaceIconOrProjectMode } from '@components/icons';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { StackBrowser } from '@components/SpaceCard';
import StatusBadge from '@components/StatusBadge';
import { useDeleteStackModal } from '@components/projects/StackManagementModal';
import { useUserInfo } from '@hooks/useAuth';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { trackEvent } from '@lib/analytics/google';
import Button from '@ui/Button';
import DropdownMenu, {
  DropdownMenuItem,
  RecursiveDropdownMenuItem,
} from '@ui/DropdownMenu';
import UserAvatar from '@ui/UserAvatar';
import createToast from '@utils/createToast';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { getDisplayDate } from '@utils/date';
import { knownErrors } from '@/const';
import { absoluteUrlFor, urlFor } from '@/urls';
import styles from './SpacesListView.module.scss';

type SpaceListViewProps = {
  className?: string;
  objects: Array<ProjectDTO | ProjectStackDTO>;
};

type Row = {
  item: ProjectDTO | ProjectStackDTO | { kind: 'spacer' };
  children: Row[];
};

const { MenuMore, RepositoryBrowse, Time } = Icon16;
const { Favorite, FollowSubscribe, Link: LinkIcon, Settings, Stack, Remove } = Icon24;

export default function SpaceListView(props: SpaceListViewProps) {
  const workspaceId = useWorkspaceId();
  const {
    data: flattenedData,
    status: projectListingStatus,
    error: projectListingError,
    isLoading,
  } = useWorkspaceProjects(workspaceId, {
    onlyStacks: false,
    flattenStacks: true,
    sort: ['open'],
  });

  if (projectListingStatus === 'error') {
    throw new Error(knownErrors.projectError, { cause: projectListingError });
  }

  const projectsAndFilledStacks: Row[] = useMemo(() => {
    if (!flattenedData?.projects) return [];

    return props.objects.flatMap((obj) => {
      const item = {
        item: obj,
        children:
          obj.kind === 'project'
            ? []
            : [
                ...(
                  flattenedData.projects.filter(
                    (project) =>
                      project.kind === 'project' && project.stackId === obj.id
                  ) as ProjectDTO[]
                ).map((obj) => ({
                  item: obj,
                  children: [],
                })),
              ],
      };

      if (obj.kind === 'projectStack') {
        return [
          {
            item: { kind: 'spacer' },
            children: [],
          },
          item,
          {
            item: { kind: 'spacer' },
            children: [],
          },
        ];
      }

      return [item];
    });
  }, [flattenedData, props.objects]);

  if (isLoading || !flattenedData?.projects) {
    return <LoadingPlaceholder center />;
  }

  return <ProjectTable className={props.className} data={projectsAndFilledStacks} />;
}

const columnHelper = createColumnHelper<Row>();

const ProjectTable = ({
  className,
  data,
}: {
  className?: string;
  data: Row[];
}) => {
  'use no memo';

  const { open: openDeleteStackModal } = useDeleteStackModal();
  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const trackSelect = useCallback(
    (id: string) => {
      trackEvent('select_content', {
        content_type: 'project',
        content_id: id,
      });
    },
    [trackEvent]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('item', {
        id: 'before',
        header: () => null,
        cell: () => null,
        size: 24,
        enableResizing: false,
      }),
      columnHelper.accessor('item', {
        id: 'label',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;

          const url = urlFor('space', { projectId: obj.id });

          return (
            <div className={styles.label}>
              <SpaceIconOrProjectMode project={obj} size={16} />
              <span>
                <Link to={url} onClick={() => trackSelect(obj.id)}>
                  {obj.label}
                </Link>
              </span>
            </div>
          );
        },
        size: 466,
        enableResizing: true,
      }),
      columnHelper.accessor('item', {
        id: 'totalPosts',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;

          return (
            <div className={styles.info}>
              <RepositoryBrowse />
              <span
                className={styles.value}
              >{` ${obj.totalPosts} ${pluralize(obj.totalPosts !== 1, 'items', 'item')}`}</span>
            </div>
          );
        },
        size: 192,
        enableResizing: true,
      }),
      columnHelper.accessor('item', {
        id: 'mode',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;

          return (
            <div className={styles.info}>
              <ProjectMode mode={obj.mode} />
              <span className={styles.value}>
                {projectModes[obj.mode]?.label ?? upperFirst(obj.mode)}
              </span>
            </div>
          );
        },
        size: 192,
        enableResizing: true,
      }),
      columnHelper.accessor('item', {
        id: 'targetDate',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;
          if (!obj.targetDate) return null;

          return (
            <div className={styles.info}>
              <Time />
              <span>{getDisplayDate(new Date(obj.targetDate))}</span>
            </div>
          );
        },
        size: 192,
        enableResizing: true,
      }),
      columnHelper.accessor('item', {
        id: 'space',
        header: () => null,
        cell: () => null,
        size: 0,
        enableResizing: false,
      }),
      columnHelper.accessor('item', {
        id: 'menu',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;

          return (
            <ProjectMenu project={obj} stackMode={obj.stackId ? 'remove' : 'add'} />
          );
        },
        size: 40,
        minSize: 40,
        maxSize: 40,
        enableResizing: false,
      }),
      columnHelper.accessor('item', {
        id: 'status',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();
          if (obj.kind !== 'project') return null;

          if (!obj.status) return null;

          return (
            <StatusBadge
              background={2}
              color={obj.status === 'none' ? 3 : 2}
              status={obj.status}
            />
          );
        },
        size: 110,
        enableResizing: true,
      }),
      columnHelper.accessor('item', {
        id: 'owner',
        header: () => null,
        cell: ({ getValue }) => {
          const obj = getValue();

          if (obj.kind !== 'project') return null;
          if (!obj.membersPreview?.length) return null;

          const member = obj.membersPreview[0];
          if (!member) return null;

          return (
            <UserAvatar imageUrl={member.avatarUrl} name={member.name} size="xxs" />
          );
        },
        size: 32,
        minSize: 32,
        maxSize: 32,
        enableResizing: false,
      }),
      columnHelper.accessor('item', {
        id: 'after',
        header: () => null,
        cell: () => null,
        size: 24,
        minSize: 24,
        maxSize: 24,
        enableResizing: false,
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    manualExpanding: false,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.children,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    columnResizeMode: 'onChange',
  });

  if (!data) return <LoadingPlaceholder center />;
  const tableModel = table.getRowModel();

  return (
    <div className={styles.spacesListViewContainer}>
      <table className={clsx(styles.table, className)}>
        <tbody>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize() || undefined,
                    },
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanResize() && (
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: clsx(
                          styles.resizer,
                          header.column.getIsResizing() && styles.isResizing
                        ),
                      }}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
          {tableModel.rows.map((row, idx) => {
            const previousRow = idx > 0 ? tableModel.rows[idx - 1] : undefined;
            const previousRowIsSpacer = !!(
              previousRow?.original.item.kind === 'spacer'
            );
            const isFirstRow = idx === 0;
            const isLastRow = idx === tableModel.rows.length - 1;

            const { item } = row.original;
            const visibleRows = row.getVisibleCells();

            if (item.kind === 'spacer') {
              if (previousRowIsSpacer || isFirstRow || isLastRow) return null;

              return (
                <tr key={row.id} className={clsx(styles.row, styles.spacerRow)}>
                  <td colSpan={visibleRows.length} />
                </tr>
              );
            }

            if (item.kind === 'projectStack') {
              return (
                <tr key={row.id} className={clsx(styles.row, styles.stackRow)}>
                  <td style={{ width: '24px' }} />
                  <td className={styles.stackLabel} colSpan={visibleRows.length - 3}>
                    {`${item.label} • ${item.projectCount} ${pluralize(item.projectCount, 'Space', 'Spaces')}`}
                  </td>
                  <td className={styles.stackAction}>
                    <DropdownMenu
                      align="end"
                      className={styles.stackActionMenu}
                      triggerContent={
                        <Button className={styles.menuTrigger} variant="icon" size="sm">
                          <MenuMore size={20} />
                        </Button>
                      }
                    >
                      <DropdownMenuItem
                        asChild
                        onClick={(e) => {
                          e.stopPropagation();
                          openDeleteStackModal(item.id);
                        }}
                      >
                        <Button variant="menu" iconBefore={<Remove size={20} />}>
                          Empty & Delete Stack
                        </Button>
                      </DropdownMenuItem>
                    </DropdownMenu>
                  </td>
                  <td style={{ width: '24px' }} />
                </tr>
              );
            }

            return (
              <tr key={row.id} className={styles.row}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ProjectMenu = ({
  project,
  stackMode,
}: {
  stackMode: 'add' | 'remove';
  project: ProjectDTO;
}) => {
  const user = useUserInfo();
  const { mutateAsync: patchProject } = usePatchProject();
  const [stackSearchQuery, setStackSearchQuery] = useState('');
  const editCapability = project.capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;
  const { open: openEditProjectModal } = useEditProjectModal(project.id);
  const { mutateAsync: patchProjectStack } = usePatchProjectStack();
  const { data: stackData } = useProjectStackDetail(project.stackId);

  const setFollowing = async (newIsFollowing: boolean) => {
    if (!user) {
      return;
    }

    if (newIsFollowing) {
      await joinProject({ projectId: project.id });
      createToast({
        titleText: 'Following space',
        bodyText: `Joined "${project.label}"`,
        iconVariant: 'success',
      });
    } else {
      await removeUserFromProject({ projectId: project.id, userId: user.id });
      createToast({
        titleText: 'Stopped following space',
        bodyText: `left "${project.label}"`,
        iconVariant: 'success',
      });
    }
  };

  const setIsStarred = async (isStarred: boolean) => {
    await patchProject({ id: project.id, patch: { isStarred } });
    createToast({
      titleText: 'Favorites Updated',
      bodyText: isStarred
        ? `Favorited "${project.label}"`
        : `Unfavorited "${project.label}"`,
      iconVariant: 'success',
    });
  };

  const handleInputFocus = (ev: React.KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      ev.stopPropagation();
    }
  };

  const removeFromStack = useCallback(
    (projectId: string) => {
      if (!projectId || !stackData?.projectStack) return;

      const { id, label, projects } = stackData.projectStack;

      patchProjectStack({
        id,
        label,
        projectIds: projects
          .map((project) => project.id)
          .filter((pid) => pid !== projectId),
      });
    },
    [patchProjectStack, stackData]
  );

  return (
    <DropdownMenu
      align="end"
      className={styles.projectDropdownMenu}
      triggerContent={
        <Button className={styles.menuTrigger} size="sm" variant="icon">
          <MenuMore size={20} />
        </Button>
      }
    >
      <div className={clsx('menu', styles.projectMenu)}>
        <DropdownMenuItem asChild onSelect={() => setFollowing?.(!project.isFollowing)}>
          <Button
            variant="menu"
            iconBefore={<FollowSubscribe active={project.isFollowing} size={20} />}
          >
            {project.isFollowing ? 'Following' : 'Follow'}
          </Button>
        </DropdownMenuItem>
        <DropdownMenuItem asChild onSelect={() => setIsStarred?.(!project.isStarred)}>
          <Button
            variant="menu"
            iconBefore={<Favorite active={project.isStarred} size={20} />}
          >
            {project.isStarred ? 'Favorited' : 'Favorite'}
          </Button>
        </DropdownMenuItem>
        <DropdownMenuItem
          asChild
          onSelect={() =>
            copyTextToClipboard(
              absoluteUrlFor('space', { projectId: project.id }).toString(),
              {
                titleText: undefined,
                bodyText: 'Space link copied to your clipboard!',
              }
            )
          }
        >
          <Button variant="menu" iconBefore={<LinkIcon size={20} />}>
            Copy Link
          </Button>
        </DropdownMenuItem>
        {canEdit && (
          <DropdownMenuItem
            asChild
            onSelect={() => {
              openEditProjectModal?.();
            }}
          >
            <Button variant="menu" iconBefore={<Settings size={20} />}>
              Settings
            </Button>
          </DropdownMenuItem>
        )}
        {stackMode === 'add' && (
          <RecursiveDropdownMenuItem
            isPadded
            item={{
              content: (
                <Button
                  className={styles.submenuTrigger}
                  variant="menu"
                  iconBefore={<Stack size={20} />}
                >
                  Add to Stack
                </Button>
              ),
              subMenu: [
                {
                  content: (
                    <StackBrowser
                      onChange={(ev) => setStackSearchQuery(ev.currentTarget.value)}
                      value={stackSearchQuery}
                      onKeyDown={handleInputFocus}
                      placeholder="Search stacks..."
                      project={project}
                    />
                  ),
                  isMenuItem: false,
                  className: styles.sticky,
                },
              ],
            }}
          />
        )}
        {stackMode === 'remove' && canEdit && project.stackId && (
          <DropdownMenuItem
            asChild
            onSelect={() => {
              removeFromStack(project.id);
            }}
          >
            <Button variant="menu" iconBefore={<Stack size={20} />}>
              Remove from stack
            </Button>
          </DropdownMenuItem>
        )}
      </div>
    </DropdownMenu>
  );
};
