import type { LanguageFn } from 'highlight.js';
import type { createLowlight } from 'lowlight';
import ada from 'highlight.js/lib/languages/ada';
import bash from 'highlight.js/lib/languages/bash';
import c from 'highlight.js/lib/languages/c';
import csharp from 'highlight.js/lib/languages/csharp';
import cpp from 'highlight.js/lib/languages/cpp';
import css from 'highlight.js/lib/languages/css';
import dart from 'highlight.js/lib/languages/dart';
import delphi from 'highlight.js/lib/languages/delphi';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import elixir from 'highlight.js/lib/languages/elixir';
import go from 'highlight.js/lib/languages/go';
import graphql from 'highlight.js/lib/languages/graphql';
import haskell from 'highlight.js/lib/languages/haskell';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import julia from 'highlight.js/lib/languages/julia';
import kotlin from 'highlight.js/lib/languages/kotlin';
import latex from 'highlight.js/lib/languages/latex';
import less from 'highlight.js/lib/languages/less';
import lua from 'highlight.js/lib/languages/lua';
import markdown from 'highlight.js/lib/languages/markdown';
import matlab from 'highlight.js/lib/languages/matlab';
import objectivec from 'highlight.js/lib/languages/objectivec';
import perl from 'highlight.js/lib/languages/perl';
import php from 'highlight.js/lib/languages/php';
import powershell from 'highlight.js/lib/languages/powershell';
import python from 'highlight.js/lib/languages/python';
import r from 'highlight.js/lib/languages/r';
import ruby from 'highlight.js/lib/languages/ruby';
import rust from 'highlight.js/lib/languages/rust';
import scala from 'highlight.js/lib/languages/scala';
import scss from 'highlight.js/lib/languages/scss';
import shell from 'highlight.js/lib/languages/shell';
import sql from 'highlight.js/lib/languages/sql';
import swift from 'highlight.js/lib/languages/swift';
import typescript from 'highlight.js/lib/languages/typescript';
import vbnet from 'highlight.js/lib/languages/vbnet';
import xml from 'highlight.js/lib/languages/xml';

export const supportedLanguages = new Map<
  string,
  {
    label: string;
    value: LanguageFn | undefined;
  }
>();
supportedLanguages.set('plaintext', { label: 'Plain Text', value: undefined });
supportedLanguages.set('ada', { label: 'Ada', value: ada });
supportedLanguages.set('bash', { label: 'Bash', value: bash });
supportedLanguages.set('c', { label: 'C', value: c });
supportedLanguages.set('csharp', { label: 'C#', value: csharp });
supportedLanguages.set('cpp', { label: 'C++', value: cpp });
supportedLanguages.set('css', { label: 'CSS', value: css });
supportedLanguages.set('dart', { label: 'Dart', value: dart });
supportedLanguages.set('delphi', { label: 'Delphi', value: delphi });
supportedLanguages.set('dockerfile', { label: 'Dockerfile', value: dockerfile });
supportedLanguages.set('elixir', { label: 'Elixir', value: elixir });
supportedLanguages.set('go', { label: 'Go', value: go });
supportedLanguages.set('graphql', { label: 'GraphQL', value: graphql });
supportedLanguages.set('haskell', { label: 'Haskell', value: haskell });
supportedLanguages.set('html', { label: 'HTML', value: xml });
supportedLanguages.set('java', { label: 'Java', value: java });
supportedLanguages.set('javascript', { label: 'JavaScript', value: javascript });
supportedLanguages.set('json', { label: 'JSON', value: json });
supportedLanguages.set('julia', { label: 'Julia', value: julia });
supportedLanguages.set('kotlin', { label: 'Kotlin', value: kotlin });
supportedLanguages.set('latex', { label: 'LaTeX', value: latex });
supportedLanguages.set('less', { label: 'Less', value: less });
supportedLanguages.set('lua', { label: 'Lua', value: lua });
supportedLanguages.set('markdown', { label: 'Markdown', value: markdown });
supportedLanguages.set('matlab', { label: 'MATLAB', value: matlab });
supportedLanguages.set('objectivec', { label: 'Objective-C', value: objectivec });
supportedLanguages.set('perl', { label: 'Perl', value: perl });
supportedLanguages.set('php', { label: 'PHP', value: php });
supportedLanguages.set('powershell', { label: 'PowerShell', value: powershell });
supportedLanguages.set('python', { label: 'Python', value: python });
supportedLanguages.set('r', { label: 'R', value: r });
supportedLanguages.set('ruby', { label: 'Ruby', value: ruby });
supportedLanguages.set('rust', { label: 'Rust', value: rust });
supportedLanguages.set('scala', { label: 'Scala', value: scala });
supportedLanguages.set('scss', { label: 'SCSS', value: scss });
supportedLanguages.set('shell', { label: 'Shell', value: shell });
supportedLanguages.set('sql', { label: 'SQL', value: sql });
supportedLanguages.set('swift', { label: 'Swift', value: swift });
supportedLanguages.set('typescript', { label: 'TypeScript', value: typescript });
supportedLanguages.set('vbnet', { label: 'VB.NET', value: vbnet });
supportedLanguages.set('xml', { label: 'XML', value: xml });

const noop: LanguageFn = () => {
  return {
    name: 'plainText',
    contains: [],
  };
};

export function register(
  lowLightInstance: ReturnType<typeof createLowlight>,
  languagesToLoad: string[] | 'all'
) {
  if (languagesToLoad === 'all') {
    supportedLanguages.forEach(({ value }, key) => {
      lowLightInstance.register(key, value ?? noop);
    });
    return;
  }

  const languagesByName = new Map<
    string,
    {
      key: string;
      value: LanguageFn | undefined;
    }
  >();
  supportedLanguages.forEach(({ label, value }, key) =>
    languagesByName.set(label, { key, value })
  );

  languagesToLoad.forEach((language) => {
    if (languagesByName.has(language)) {
      const { key, value } = languagesByName.get(language)!;
      lowLightInstance.register(key, value ?? noop);
    }
  });
}
