import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import type { WorkspaceDetail } from '@spaceduck/api';

import { toastApiErrorOr } from '@api/util';
import { usePatchWorkspace, useWorkspace } from '@api/workspace';
import ErrorDuck from '@assets/img/ErrorDuck';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import Button from '@ui/Button';
import createToast from '@utils/createToast';
import { Container, Header, Form, SettingsError, TopNav } from './common';
import styles from './AISettingsPage.module.scss';

export const AISettingsPage = () => {
  const { workspaceId } = useParams();

  if (!workspaceId) {
    return (
      <Wrapper className={styles.fullHeight}>
        <SettingsError>
          <ErrorDuck />
          <h2 className="h6">Uh-oh something went wrong.</h2>
          <p>Workspace not found.</p>
        </SettingsError>
      </Wrapper>
    );
  }

  return <AISettings workspaceId={workspaceId} />;
};

const AISettings = ({ workspaceId }: { workspaceId: string }) => {
  const { data, status } = useWorkspace(workspaceId);

  if (status === 'error') {
    return (
      <Wrapper className={styles.fullHeight}>
        <SettingsError />
      </Wrapper>
    );
  }

  if (status === 'pending') {
    return (
      <Wrapper className={styles.fullHeight}>
        <LoadingPlaceholder center />
      </Wrapper>
    );
  }

  if (!data) {
    return (
      <Wrapper>
        <SettingsError />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <h1>AI Settings</h1>
        <p>Tailor the AI settings to your specific needs.</p>
      </Header>
      {data.workspace && <WorkspaceEditForm workspace={data.workspace} />}
    </Wrapper>
  );
};

type WorkspaceEditFormData = {
  industry: string;
  memberProfession: string;
};

const WorkspaceEditForm = ({
  workspace,
}: {
  workspace: WorkspaceDetail;
}) => {
  const { mutateAsync: patchWorkspace } = usePatchWorkspace();
  const { id: workspaceId, aiSettings } = workspace;
  const { industry, memberProfession } = aiSettings;

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
    register,
    resetField,
  } = useForm<WorkspaceEditFormData>({
    defaultValues: {
      industry,
      memberProfession,
    },
  });

  useEffect(() => {
    resetField('industry', { defaultValue: industry });
  }, [industry, memberProfession]);

  useEffect(() => {
    resetField('memberProfession', { defaultValue: memberProfession });
  }, [memberProfession]);

  const submitHandler = useCallback(
    async (aiSettings: { industry?: string; memberProfession?: string }) => {
      try {
        await patchWorkspace({
          workspaceId,
          patch: {
            aiSettings,
          },
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to patch project', {
          iconVariant: 'warning',
          titleText: 'Space update failed',
          bodyText:
            'An unknown error occurred while updating your space. Please try again later',
        });
      }

      createToast({
        iconVariant: 'success',
        titleText: 'Saved Changes',
        bodyText: 'Your changes have been saved successfully.',
      });
    },
    [workspaceId, patchWorkspace]
  );

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <h2>Tagging behavior</h2>
      <p>
        Change how the system tags your content based on your criteria. These settings
        apply globally unless modified in Space settings.
      </p>
      <h3>Industry of your workspace or organization</h3>
      <p>Describe your workspace's role so we can tailor tags for everyone in it.</p>
      <Form.FieldWrapper error={errors.industry}>
        <input
          autoComplete="off"
          placeholder="e.g. “Psychology Research Firm”, “Furniture eCommerce”, “Architecture Firm”"
          type="text"
          {...register('industry')}
        />
      </Form.FieldWrapper>
      <h3>Your role</h3>
      <p>Describe your role to tailor tags and content you upload to your expertise.</p>
      <Form.FieldWrapper error={errors.memberProfession}>
        <input
          autoComplete="off"
          placeholder="e.g. “Psychology Researcher”, “UX Designer”, “Documentary Filmmaker”, “Student”"
          type="text"
          {...register('memberProfession')}
        />
      </Form.FieldWrapper>
      <Form.Footer>
        <Button
          disabled={!isDirty || isSubmitting}
          size="sm"
          type="submit"
          variant="primary"
        >
          {isSubmitting ? 'Saving' : 'Save Changes'}
        </Button>
      </Form.Footer>
    </form>
  );
};

const Wrapper = ({
  children,
  className,
}: { children: React.ReactNode; className?: string }) => {
  return (
    <>
      <TopNav title="AI Settings" currentBreadcrumb="AI Settings" owner="workspace" />
      <Container className={className}>{children}</Container>
    </>
  );
};
