import * as RadixPopover from '@radix-ui/react-popover';
import clsx from 'clsx';

import type { AllOrNone } from '@spaceduck/utils';

import styles from './Popover.module.scss';

export default function Popover({
  children,
  className,
  closeIcon,
  open,
  onOpenChange,
  popoverContentProps,
  showArrow = true,
  trigger,
}: {
  children: React.ReactNode;
  className?: string;
  closeIcon?: React.ReactNode;
  popoverContentProps?: RadixPopover.PopoverContentProps;
  showArrow?: boolean;
  trigger: React.ReactNode;
} & AllOrNone<{
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
  return (
    <RadixPopover.Root open={open} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          {...popoverContentProps}
          className={clsx(styles.popoverContent, popoverContentProps?.className)}
        >
          <div className={clsx(styles.container, className)}>{children}</div>
          {!!closeIcon && (
            <RadixPopover.Close aria-label="Close" asChild>
              {closeIcon}
            </RadixPopover.Close>
          )}
          {showArrow && <RadixPopover.Arrow className={styles.popoverArrow} />}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
