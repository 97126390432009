import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { MediaType } from '@spaceduck/utils';

import FileBlock from '@components/FileBlock';
import { AudioPlayer } from '@detailsModal/media/AudioPlayer';
import Video from '@detailsModal/media/Video';
import { css } from '@lib/css';
import styles from './MediaView.module.scss';

export const MediaView = ({
  className,
  mediaGroup,
}: {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
}) => {
  if (mediaGroup.embed) {
    if (mediaGroup.embed.kind === 'youtube' && mediaGroup.embed.videoId) {
      return (
        <Video
          className={clsx(styles.fullWidthVideo, className)}
          mediaGroup={mediaGroup}
        />
      );
    }
  }

  const media = mediaGroup.media;

  if (!media) return null;

  if (mediaGroup.contentType === 'file') {
    return <FileBlock mediaGroup={mediaGroup} orientation="landscape" />;
  }

  if (mediaGroup.contentType === 'audio') {
    if (!media?.mediaType.startsWith('audio/')) return null;

    return <AudioPlayer src={media.assetUrl} />;
  }

  const { assetName, assetUrl } = media;

  switch (media.mediaType) {
    case MediaType.GIF:
    case MediaType.JPEG:
    case MediaType.PNG:
    case MediaType.SVG:
    case MediaType.WEBP:
      return (
        <div className={clsx(styles.image, className)}>
          <img src={assetUrl} alt={assetName ?? ''} />
        </div>
      );
    case MediaType.MP4:
      return (
        <div
          className={styles.scaledVideoContainer}
          style={css({
            '--video-width': mediaGroup.media?.width,
            '--video-height': mediaGroup.media?.height,
          })}
        >
          <Video
            className={clsx(styles.scaledVideo, className)}
            mediaGroup={mediaGroup}
          />
        </div>
      );
  }

  return null;
};
