import type { ReactNode } from 'react';

import { MenuGridItem } from './GridItem';
import type { MenuItem } from './Item';
import styles from './Grid.module.scss';

type MenuGridProps = {
  children?: ReactNode;
  menuItems?: MenuItem[];
};

export const MenuGrid = ({ children, menuItems }: MenuGridProps) => {
  return (
    <div className={styles.menuGrid}>
      {menuItems?.map(
        ({ disabled, icon, isActive, onClick, ref, tooltip }, idx: number) => {
          return (
            <MenuGridItem
              disabled={disabled}
              icon={icon}
              isActive={isActive}
              key={idx}
              onClick={onClick}
              ref={ref}
              tooltip={tooltip}
            />
          );
        }
      )}
      {children}
    </div>
  );
};
