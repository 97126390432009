import type { ChangeEvent, MutableRefObject } from 'react';

import { Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';

type FileUploadProps = {
  addFiles: (ev: ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
};

const DISPLAY_NONE_STYLE = { display: 'none' };
const { Attach } = Icon24;

export default function FileUpload({ addFiles, fileInputRef }: FileUploadProps) {
  const openFileExplorer = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Button size="sm" variant="icon" onClick={openFileExplorer}>
        <Attach size={20} />
      </Button>
      <input
        onChange={addFiles}
        multiple
        ref={fileInputRef}
        type="file"
        style={DISPLAY_NONE_STYLE}
      />
    </>
  );
}
