import { type ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';

import { exists } from '@spaceduck/api';

import { useArray } from '@hooks/useArray';
import { useMap } from '@hooks/useMap';
import {
  type ProcessingResult,
  type UploadingItem,
  useProcessAssets,
} from '@hooks/useProcessAssets';
import useWorkspaceId from '@hooks/useWorkspaceId';
import createToast from '@utils/createToast';

export const useFileUpload = () => {
  const [attachmentAssets, setAttachmentAssets] = useArray<ProcessingResult>();
  const [selectedFiles, setSelectedFiles] = useMap<string, File>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const workspaceId = useWorkspaceId();

  const onSuccess = useCallback((created: ProcessingResult) => {
    const assetId = created.request.result?.id;
    if (assetId) {
      setAttachmentAssets((assets) => [...assets, created]);
    }
  }, []);

  const onError = useCallback((item: UploadingItem) => {
    createToast({
      titleText: 'Upload failed',
      bodyText: 'Please try again later.',
      iconVariant: 'danger',
    });

    setSelectedFiles((files) => {
      const updatedFiles = new Map(files);
      updatedFiles.delete(item.key);
      return updatedFiles;
    });
  }, []);

  const { insert, abort } = useProcessAssets({
    pollInterval: 1500,
    onSuccess: onSuccess,
    onError: onError,
  });

  const addFile = useCallback(async (file: File, id: string) => {
    setSelectedFiles((files) => new Map(files).set(id, file));
  }, []);

  const addFiles = useCallback(
    async (ev: ChangeEvent<HTMLInputElement>) => {
      const { files } = ev.currentTarget;
      if (!files?.length) return true;
      if (!workspaceId) return true;

      const keys: string[] = [];
      for (const file of files) {
        const key = v4();
        keys.push(key);
        addFile(file, key);
      }

      insert({
        keys,
        files,
        workspaceId,
        kind: 'comment_attachment',
      });

      ev.target.value = '';

      return true;
    },
    [addFile, workspaceId, insert]
  );

  const removeFile = useCallback((id: string) => {
    abort(id);
    setSelectedFiles((files) => {
      const updatedFiles = new Map([...files]);
      updatedFiles.delete(id);
      return updatedFiles;
    });
  }, []);

  const processedFiles = useMemo(() => {
    const completedFiles = [...selectedFiles.keys()]
      .map((key) => attachmentAssets.find((asset) => asset.key === key))
      .filter(exists);

    return completedFiles;
  }, [attachmentAssets, selectedFiles]);

  const remainingFileCount = useMemo(() => {
    const remainingFiles = [...selectedFiles.keys()].filter(
      (key) => !attachmentAssets.find((asset) => asset.key === key)
    );

    return remainingFiles.length;
  }, [attachmentAssets, selectedFiles]);

  const clear = useCallback(() => {
    setAttachmentAssets([]);
    setSelectedFiles(new Map());
  }, [setAttachmentAssets, setSelectedFiles]);

  return {
    addFiles,
    clear,
    fileInputRef,
    processedFiles,
    remainingFileCount,
    removeFile,
    selectedFiles,
  };
};
