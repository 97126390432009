import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { ContentType, ContentView as ContentViewIcon } from '@components/icons';
import ContentView from '@detailsModal/contentView/ContentView';
import DraggableCard from '@detailsModal/sidebar/DraggableCard';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import { type ContentViewTab, getContentView } from '@/types/MediaGroup';
import styles from './MediaGroupPreview.module.scss';

type MediaGroupPreviewProps = {
  className?: string;
  containerClassName?: string;
  hasStickyHeader?: boolean;
  mediaGroup: MediaGroupDetailDTO;
  menu?: React.ReactNode;
};

export default function MediaGroupPreview({
  className,
  containerClassName,
  hasStickyHeader = true,
  mediaGroup,
  menu,
}: MediaGroupPreviewProps) {
  const tabs = getContentView(mediaGroup);
  const [activeTab, setActiveTab] = useState<ContentViewTab | null>(
    tabs?.length ? tabs[0] : null
  );

  useEffect(() => {
    setActiveTab(tabs?.length ? tabs[0] : null);
  }, [mediaGroup.id]);

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div className={clsx(styles.card, className)}>
        <div className={clsx(styles.header, hasStickyHeader && styles.stickyHeader)}>
          <DraggableCard className={styles.titleContainer} mediaGroup={mediaGroup}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <ContentType
                  contentType={mediaGroup.contentType}
                  mediaGroup={mediaGroup}
                />
              </div>
              <span>{mediaGroup.label || mediaGroup.linkUrlSource}</span>
            </div>
          </DraggableCard>
          {menu && <div className={styles.menu}>{menu}</div>}
        </div>
        <ViewSwitcher activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        <div
          className={clsx(
            styles.content,
            activeTab && styles[activeTab],
            mediaGroup.contentType && styles[`${mediaGroup.contentType}ContentType`],
            mediaGroup.contentType === 'document' && styles.lightBackground
          )}
        >
          <ContentView mediaGroup={mediaGroup} view={activeTab} />
        </div>
      </div>
    </div>
  );
}

const ViewSwitcher = ({
  activeTab,
  setActiveTab,
  tabs,
}: {
  activeTab: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<ContentViewTab | null>>;
  tabs: ReturnType<typeof getContentView>;
}) => {
  if (!tabs) return null;
  const active = activeTab ?? tabs?.[0] ?? null;

  return (
    <div className={styles.tabButtonContainer}>
      <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
        {tabs.map((tab) => {
          return (
            <TabButton
              isActive={active === tab}
              key={tab}
              onClick={() => setActiveTab(tab)}
              variant="dark"
            >
              <ContentViewIcon view={tab} />
              {upperFirst(tab)}
            </TabButton>
          );
        })}
      </TabButtonWrapper>
    </div>
  );
};
