import { type MutableRefObject, useCallback, useEffect, useRef } from 'react';
import BaseAudioPlayer from 'react-h5-audio-player';

import { Icon32, Icon48 } from '@spaceduck/icons';

import createToast from '@/utils/createToast';
import 'react-h5-audio-player/src/styles.scss';
import styles from './AudioPlayer.module.scss';

const { LoopPlayback, Volume } = Icon32;
const { Play, Pause } = Icon48;

const ICON_DISABLED_COLOR = 'var(--on-surface-4)';

const EmptyNode = () => <></>;

const CUSTOM_ICONS = {
  play: <Play />,
  pause: <Pause />,
  loop: <LoopPlayback />,
  loopOff: <LoopPlayback color={ICON_DISABLED_COLOR} />,
  volume: <Volume />,
  volumeMute: <Volume color={ICON_DISABLED_COLOR} />,
  // Default icons are download from remote server. We have these
  // features disabled, but also marking these as null to ensure the
  // client does not attempt to fetch these.
  rewind: <EmptyNode />,
  forward: <EmptyNode />,
  previous: <EmptyNode />,
  next: <EmptyNode />,
};

export const AudioPlayer = ({
  src,
  seekRef,
}: {
  src: string;
  seekRef?: MutableRefObject<
    ((toSeconds: number, autoplay?: boolean) => Promise<boolean>) | null
  >;
}) => {
  const handlePlayError = useCallback((error: unknown) => {
    console.error('Error playing back audio', error);
    createToast({
      iconVariant: 'warning',
      titleText: 'Playback error',
      bodyText: 'Unable to play audio. Please try again later.',
    });
  }, []);

  const ref = useRef<BaseAudioPlayer | null>(null);

  const seek = useCallback(async (toSeconds: number, autoplay = true) => {
    const element = ref.current?.audio.current ?? null;

    if (element === null) {
      return false;
    }

    element.currentTime = toSeconds;

    if (autoplay && element.paused) {
      element.play();
    }

    return true;
  }, []);

  useEffect(() => {
    if (seekRef) {
      seekRef.current = seek;
    }
  }, [seek]);

  return (
    <div className={styles.audioPlayer}>
      <BaseAudioPlayer
        layout="stacked"
        src={src}
        ref={ref}
        showJumpControls={false}
        showSkipControls={false}
        onPlayError={handlePlayError}
        customIcons={CUSTOM_ICONS}
      />
    </div>
  );
};
