import { type DragEvent, useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { useCreateMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace } from '@api/workspace';
import {
  type ProcessingResult,
  type UploadingItem,
  useProcessAssets,
} from '@hooks/useProcessAssets';
import useWorkspaceId from '@hooks/useWorkspaceId';
import createToast from '@utils/createToast';

export const useFileUploadToBoard = ({
  createTemporaryNode,
  mediaGroup,
  removeTemporaryNode,
}: {
  createTemporaryNode: ({
    refId,
    coordinates,
  }: {
    refId: string;
    coordinates: { x: number; y: number };
    options?: { parentId?: string };
  }) => void;
  mediaGroup: MediaGroupDetailDTO;
  removeTemporaryNode: (refId: string) => void;
}) => {
  const [serverAssets, setServerAssets] = useState<ProcessingResult[]>([]);
  const [mediaGroupIds, setMediaGroupIds] = useState<Record<string, string>>({});

  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const canIncreaseUsedStorage = isCapable(
    'useStorage',
    workspace?.workspace?.capabilities
  );

  const maxTotalSize = canIncreaseUsedStorage?.capable
    ? (canIncreaseUsedStorage.limitLeft ?? undefined)
    : undefined;

  const onSuccess = useCallback((item: ProcessingResult) => {
    setServerAssets((serverImages) => [...serverImages, item]);
  }, []);

  const onError = useCallback((item: UploadingItem) => {
    createToast({
      titleText: 'Upload failed',
      bodyText: 'Please try again later.',
      iconVariant: 'danger',
    });
    removeTemporaryNode(item.key);
  }, []);

  const { insert } = useProcessAssets({
    pollInterval: 1500,
    onSuccess,
    onError,
  });

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();

  useEffect(() => {
    if (serverAssets.length && mediaGroup) {
      for (const serverImage of serverAssets) {
        if (mediaGroupIds[serverImage.key]) {
          continue;
        }

        if (!serverImage?.request.result?.id) {
          return;
        }

        createMediaGroup({
          assets: [serverImage.request.result.id],
          kind: 'gallery',
          projectId: mediaGroup?.project?.id,
          workspaceId: mediaGroup?.project?.id ? undefined : mediaGroup?.workspace.id,
        }).then((createdMediaGroup) => {
          setMediaGroupIds({
            ...mediaGroupIds,
            ...{ [serverImage.key]: createdMediaGroup.mediaGroupId },
          });
        });
      }
    }
  }, [serverAssets, mediaGroup]);

  const insertFiles = useCallback(
    (files: File[], posX: number, posY: number, options?: { parentId?: string }) => {
      if (!workspaceId) return true;
      const keys = files.map(() => v4());
      keys.forEach((id) =>
        createTemporaryNode({ refId: id, coordinates: { x: posX, y: posY }, options })
      );

      insert({
        workspaceId,
        files,
        keys,
        maxTotalSize,
      });

      return true;
    },
    [workspaceId, createTemporaryNode, insert, maxTotalSize]
  );

  const handleDrop = useCallback(
    (ev: DragEvent, posX: number, posY: number, options?: { parentId?: string }) => {
      if (ev?.dataTransfer?.files?.[0]) {
        const files = Array.from(ev.dataTransfer.files) as File[];
        ev.stopPropagation();
        ev.preventDefault();

        return insertFiles(files, posX, posY, options);
      }
      return false;
    },
    [workspaceId, createTemporaryNode, insert, maxTotalSize]
  );

  const handlePasteFiles = useCallback(
    (
      event: ClipboardEvent,
      posX: number,
      posY: number,
      options?: { parentId?: string }
    ) => {
      if (!event.clipboardData) {
        return false;
      }
      if (event.clipboardData.files?.[0]) {
        const files = Array.from(event.clipboardData.files) as File[];
        event.stopPropagation();
        event.preventDefault();

        return insertFiles(files, posX, posY, options);
      }
      return false;
    },
    [workspaceId, createTemporaryNode, insert, maxTotalSize]
  );
  return {
    handleDrop,
    mediaGroupIds,
    handlePasteFiles,
  };
};
