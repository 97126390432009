import { memo, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/shallow';

import { Icon16 } from '@spaceduck/icons';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import { edgeWidths, type EdgeWidth } from '@reactFlow/types/board';
import { MenuButton } from '../menu';
import type { EdgeManagementProps } from '../SideMenu';
import styles from './Width.module.scss';

const { SolidLine } = Icon16;

export const EdgeWidthMenu = memo((props: EdgeManagementProps) => {
  const { edges, selectedEdges, setEdges, patch } = props;

  const { defaultConnectionLineWidth, setDefaultConnectionLineWidth } = useBoardStore(
    useShallow((state) => ({
      defaultConnectionLineWidth: state.defaultConnectionLineWidth,
      setDefaultConnectionLineWidth: state.setDefaultConnectionLineWidth,
    }))
  );

  const activeWidth = useMemo(() => {
    if (selectedEdges.length === 1 && selectedEdges[0]) {
      const edge = edges.find((edge) => edge.id === selectedEdges[0]);
      if (edge?.data) return edge.data.width as EdgeWidth;
    }

    return undefined;
  }, [selectedEdges, edges, defaultConnectionLineWidth]);

  const handleClick = useCallback(
    (width: EdgeWidth) => {
      if (!width) return;
      setDefaultConnectionLineWidth(width);

      const updatedEdges = setEdges((edges) => {
        return edges.map((edge) => {
          if (!selectedEdges.includes(edge.id)) return edge;

          return {
            ...edge,
            data: { ...edge.data, width },
          };
        });
      });

      patch({ edges: updatedEdges });
    },
    [setEdges, selectedEdges, setDefaultConnectionLineWidth, patch]
  );

  return (
    <>
      {edgeWidths.map((width) => {
        return (
          <WidthButton
            key={width}
            activeWidth={activeWidth}
            handleClick={handleClick}
            width={width}
          />
        );
      })}
    </>
  );
});

const WidthButton = memo(
  ({
    activeWidth,
    handleClick,
    width,
  }: {
    activeWidth?: EdgeWidth;
    handleClick: (width: EdgeWidth) => void;
    width: EdgeWidth;
  }) => {
    const tooltipContent = useMemo(() => {
      return `Connection width ${width}`;
    }, [width]);

    const onClick = useCallback(() => {
      handleClick(width);
    }, [width, handleClick]);

    const isActive = useMemo(() => {
      return width === activeWidth;
    }, [activeWidth, width]);

    return (
      <MenuButton isActive={isActive} onClick={onClick} tooltipContent={tooltipContent}>
        <SolidLine className={styles[width]} />
      </MenuButton>
    );
  }
);
