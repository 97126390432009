import type { Editor } from '@tiptap/core';
import { type FocusEvent, type ReactNode, useEffect, useRef } from 'react';

import styles from './SearchBar.module.scss';

const DEFAULT_WHITELIST_KEYS = ['Enter', 'ArrowUp', 'ArrowDown'];

export default function SearchBar({
  editor,
  icon = '+',
  passThroughKeyDownEvents,
  placeholder = 'search for items by title, or create a new item',
  setQuery,
  value,
  whitelistKeys = DEFAULT_WHITELIST_KEYS,
}: {
  editor: Editor | null;
  icon?: string | ReactNode;
  passThroughKeyDownEvents?: (event: KeyboardEvent) => boolean;
  placeholder?: string;
  setQuery?: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  whitelistKeys?: string[];
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const focusTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const handleClick = () => {
    if (inputRef.current === document.activeElement) return true;

    editor?.commands.blur();

    focusTimeout.current = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return true;
  };

  useEffect(() => {
    handleClick();

    return () => {
      clearTimeout(focusTimeout.current);
      editor?.commands.focus();
    };
  }, []);

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (whitelistKeys.includes(ev.key)) {
      ev.preventDefault();
      passThroughKeyDownEvents?.(ev.nativeEvent as KeyboardEvent);
    }

    return false;
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setQuery?.(ev.currentTarget.value);
  };

  return (
    <div className={styles.searchBar} onClick={handleClick}>
      <span className={styles.icon}>{icon}</span>
      <input
        className={styles.input}
        onFocus={lockFocus}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputRef}
        type="search"
        value={value}
      />
    </div>
  );
}

function lockFocus(ev: FocusEvent<HTMLInputElement>) {
  ev.preventDefault();
  ev.stopPropagation();
}
