import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M.5 1.5v14M15.5 1.5v14M.5 5.5h3M.5 8.5h3M12.5 8.5h3M.5 2.5h3M.5 11.5h3M.5 14.5h3M12.5 5.5h3M12.5 2.5h3M12.5 11.5h3M12.5 14.5h3M12.5 1.5h-9v14h9zM3.5 8.5h9"
      />
    </svg>
  );
}
