import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4446_51496)">
        <path
          d="M9 1H2C1.44772 1 1 1.44772 1 2V22C1 22.5523 1.44772 23 2 23H9C9.55229 23 10 22.5523 10 22V2C10 1.44772 9.55229 1 9 1Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 15L22 15C22.5523 15 23 14.5523 23 14L23 2C23 1.44771 22.5523 1 22 1L15 1C14.4477 1 14 1.44772 14 2L14 14C14 14.5523 14.4477 15 15 15Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4446_51496">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
