import { Fragment, memo, type ReactNode } from 'react';

import { useTypewriter } from '@hooks/useTypewriter';
import styles from './Typewriter.module.scss';

const Typewriter = ({
  isComplete,
  isLoadingState,
  cursor,
  text,
  delay = text?.length ? text.length / 3000 : 15,
}: {
  isComplete?: boolean;
  isLoadingState?: boolean;
  cursor?: ReactNode;
  text?: string;
  delay?: number;
}) => {
  const { finishedTyping, typedText } = useTypewriter({ isComplete, text, delay });

  return (
    <Fragment>
      {typedText}
      {(!finishedTyping || isLoadingState) && <Cursor>{cursor}</Cursor>}
    </Fragment>
  );
};

export const Cursor = ({ children = '|' }: { children?: ReactNode }) => {
  return <span className={styles.cursor}>{children}</span>;
};

export default memo(Typewriter);
