import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'transparent',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M13.7524 0.809082H2.2468C1.4525 0.809082 0.808594 1.45299 0.808594 2.24728V13.7529C0.808594 14.5472 1.4525 15.1911 2.2468 15.1911H13.7524C14.5467 15.1911 15.1906 14.5472 15.1906 13.7529V2.24728C15.1906 1.45299 14.5467 0.809082 13.7524 0.809082Z"
          fill={color}
        />
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.24719 1.61798C1.89969 1.61798 1.61798 1.89969 1.61798 2.24719V13.7528C1.61798 14.1003 1.89969 14.382 2.24719 14.382H13.7528C14.1003 14.382 14.382 14.1003 14.382 13.7528V2.24719C14.382 1.89969 14.1003 1.61798 13.7528 1.61798H2.24719ZM0 2.24719C0 1.0061 1.0061 0 2.24719 0H13.7528C14.9939 0 16 1.0061 16 2.24719V13.7528C16 14.9939 14.9939 16 13.7528 16H2.24719C1.0061 16 0 14.9939 0 13.7528V2.24719Z"
          fill={color}
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.05409 3.42756C7.16484 3.09827 7.47347 2.87646 7.82088 2.87646H8.18043C8.52784 2.87646 8.83646 3.09827 8.94721 3.42756L11.8495 12.0568C11.9919 12.4803 11.7641 12.939 11.3406 13.0814C10.9171 13.2239 10.4584 12.996 10.3159 12.5726L8.00065 5.68866L5.68536 12.5726C5.54293 12.996 5.08417 13.2239 4.66069 13.0814C4.23721 12.939 4.00937 12.4803 4.1518 12.0568L7.05409 3.42756Z"
          fill="currentcolor"
        />
        <path
          id="Vector (Stroke)_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.07617 9.43838C5.07617 8.99159 5.43837 8.62939 5.88516 8.62939H10.1135C10.5603 8.62939 10.9225 8.99159 10.9225 9.43838C10.9225 9.88518 10.5603 10.2474 10.1135 10.2474H5.88516C5.43837 10.2474 5.07617 9.88518 5.07617 9.43838Z"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
