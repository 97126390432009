import { memo, type ReactNode } from 'react';
import clsx from 'clsx';

import styles from './ContentBlockListItem.module.scss';

type ContentBlockListItemProps = {
  bucket?: ReactNode;
  isActive?: boolean;
  icon?: ReactNode;
  label?: string | null;
  onClick?: () => void;
  onDoubleClick?: () => void;
};

const ContentBlockListItem = memo(
  ({
    bucket,
    isActive,
    icon,
    label,
    onClick,
    onDoubleClick,
  }: ContentBlockListItemProps) => {
    return (
      <div
        className={clsx(
          styles.contentBlockListItem,
          isActive && `${styles.active} active`
        )}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.mediaGroupInfo}>
          {label && <div className={styles.label}>{label}</div>}
          {bucket && <div className={styles.bucket}>{bucket}</div>}
        </div>
      </div>
    );
  }
);

export default ContentBlockListItem;
