import UserAvatar from '@ui/UserAvatar';
import styles from './CommentPin.module.scss';

export default function CommentPin({
  name,
  imageUrl,
  onClick,
}: {
  name: string;
  imageUrl: string | null;
  onClick?: () => void;
}) {
  return (
    <div className={styles.commentPin} onClick={onClick}>
      <UserAvatar size="xxs" name={name} imageUrl={imageUrl} />
    </div>
  );
}
