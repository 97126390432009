import clsx from 'clsx';
import { useState } from 'react';
import { upperFirst } from 'lodash';

import type { MediaDTO, MediaGroupDetailDTO } from '@spaceduck/api';

import { ContentView } from '@components/icons';
import { AudioPlayer } from '@detailsModal/media/AudioPlayer';
import VideoPlayer from '@detailsModal/media/Video';
import { EmbedView } from '@detailsModal/contentView/EmbedView';
import { IframeView } from '@detailsModal/contentView/IFrameView';
import { SummaryModeView } from '@detailsModal/contentView/SummaryModeView';
import { ReadingModeView } from '@detailsModal/contentView/ReadingModeView';
import { TranscriptionModeView } from '@detailsModal/contentView/TranscriptionModeView';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import {
  articleContentViews,
  type ArticleContentView,
  audioContentViews,
  type AudioContentView,
  bookmarkContentViews,
  type BookmarkContentView,
  filmContentViews,
  type FilmContentView,
  wikiContentViews,
  type WikiContentView,
  videoContentViews,
  type VideoContentView,
  youtubeContentViews,
  type YoutubeContentView,
} from '@/types/MediaGroup';
import styles from './PreviewCard.module.scss';

type PreviewCardProps = {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
};

export default function PreviewCard(props: PreviewCardProps) {
  switch (props.mediaGroup.contentType) {
    case 'audio':
      return <Audio {...props} />;
    case 'bookmark':
      return <Bookmark {...props} />;
    case 'film':
      return <Film {...props} />;
    case 'social':
      return <Embedded {...props} />;
    case 'video':
      return <VideoOrYoutube {...props} />;
    case 'wiki':
      return <Wiki {...props} />;
    default:
      return <Article {...props} />;
  }
}

const Audio = ({
  activeTab = 'audio',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: AudioContentView;
}) => {
  const [view, setView] = useState<AudioContentView>(activeTab);

  if (mediaGroup.kind === 'bookmark') {
    return (
      <div className={styles.container}>
        <div className={styles.scrollArea}>
          <EmbedView mediaGroup={mediaGroup} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {audioContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`article${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <AudioView
          media={media}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const AudioView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: AudioContentView;
}) => {
  if (!media?.mediaType.startsWith('audio/')) return null;

  const { assetUrl } = media;

  if (view === 'transcript') {
    return (
      <TranscriptionModeView
        containerClassName={styles.transcriptionText}
        mediaGroup={mediaGroup}
        nonStickyPlayer
      />
    );
  }

  return (
    <div onDoubleClick={onDoubleClick}>
      <AudioPlayer src={assetUrl} />
    </div>
  );
};

const VideoOrYoutube = (
  props: PreviewCardProps & {
    activeTab?: VideoContentView | YoutubeContentView;
  }
) => {
  if (props.mediaGroup.embed?.kind === 'youtube') {
    return <Youtube {...props} activeTab={props.activeTab as YoutubeContentView} />;
  }

  return <Video {...props} activeTab={props.activeTab as VideoContentView} />;
};

const Youtube = ({
  activeTab = 'video',
  mediaGroup,
}: PreviewCardProps & {
  activeTab?: YoutubeContentView;
}) => {
  const [view, setView] = useState<YoutubeContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {youtubeContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`youtube${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <YoutubeView mediaGroup={mediaGroup} view={view} />
      </div>
    </div>
  );
};

const YoutubeView = ({
  mediaGroup,
  view,
}: {
  mediaGroup: MediaGroupDetailDTO;
  view: YoutubeContentView;
}) => {
  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <EmbedView
      className={styles.youtubeEmbed}
      containerClassName={styles.youtubeEmbedContainer}
      mediaGroup={mediaGroup}
    />
  );
};

const Video = ({
  activeTab = 'media',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: VideoContentView;
}) => {
  const [view, setView] = useState<VideoContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {videoContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`video${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <VideoView
        media={media}
        mediaGroup={mediaGroup}
        onDoubleClick={onDoubleClick}
        view={view}
      />
    </div>
  );
};

const VideoView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: VideoContentView;
}) => {
  if (!media?.mediaType.startsWith('video/')) return null;

  if (view === 'transcript') {
    return (
      <div className={styles.scrollArea}>
        <TranscriptionModeView
          containerClassName={styles.transcriptionText}
          mediaGroup={mediaGroup}
          nonStickyPlayer
        />
      </div>
    );
  }

  return (
    <div className={styles.videoWrapper}>
      <VideoPlayer mediaGroup={mediaGroup} onDoubleClick={onDoubleClick} />
    </div>
  );
};

const Article = ({
  activeTab = 'screenshot',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: ArticleContentView;
}) => {
  const [view, setView] = useState<ArticleContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {articleContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`article${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <ArticleView
          media={media}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const ArticleView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: ArticleContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'reading mode') {
    return <ReadingModeView className={styles.readingMode} mediaGroup={mediaGroup} />;
  }

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};

const Bookmark = ({
  activeTab = 'screenshot',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: BookmarkContentView;
}) => {
  const [view, setView] = useState<BookmarkContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {bookmarkContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`bookmark${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <BookmarkView
          media={media}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const BookmarkView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: ArticleContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};

const Film = ({
  activeTab = 'embed',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: FilmContentView;
}) => {
  const [view, setView] = useState<FilmContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {filmContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`film${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <FilmView
          media={media}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const FilmView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: FilmContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'embed') {
    return <EmbedView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};

const Embedded = ({
  mediaGroup,
  onDoubleClick,
  allowPointerEvents = false,
}: PreviewCardProps & {
  allowPointerEvents?: boolean;
}) => {
  return (
    <div className={styles.container} onDoubleClick={onDoubleClick}>
      <div className={styles.scrollArea}>
        <EmbedView
          className={styles.noPadding}
          containerClassName={clsx(
            styles.embed,
            !allowPointerEvents && styles.disallowPointerEvents
          )}
          mediaGroup={mediaGroup}
        />
      </div>
    </div>
  );
};

const Wiki = ({
  activeTab = 'screenshot',
  media,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: WikiContentView;
}) => {
  const [view, setView] = useState<WikiContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {wikiContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`wiki${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <WikiView
          media={media}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const WikiView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: WikiContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'iframe') {
    return <IframeView className={styles.iframe} mediaGroup={mediaGroup} />;
  }

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};
