import {
  type InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import {
  type CreateMediaGroupSchema,
  type MediaGroupContentType,
  type MediaGroupDTO,
  type MediaGroupKind,
  type MediaGroupQuerySchema,
  type MediaGroupSearchFilterProperties,
  type MediaGroupSortOption,
  type MediaGroupSuggestionFilterProperties,
  bulkDeleteMediaGroups,
  bulkTagMediaGroups,
  copyMediaGroups,
  createMediaGroup,
  deleteMediaGroup,
  downloadScreen,
  enqueueMediaGroupSummary,
  enqueueMediaGroupTranscription,
  getMediaGroupAiSummary,
  getMediaGroupDetail,
  getMediaGroupReadableContent,
  getMediaGroupSummary,
  getMediaGroupTranscription,
  getSearchDateSuggestions,
  getSearchSuggestions,
  getSimilarMediaGroups,
  listMediaGroups,
  listMediaGroupsByCategoryWithFields,
  patchMediaGroup,
  regenerateMediaGroupThumbnail,
  restoreMediaGroup,
} from '@spaceduck/api';

import { asMilliseconds, exists } from '@spaceduck/utils';

import { useSearch } from '@hooks/useSearch';
import createToast, { type CreateToastProps } from '@utils/createToast';
import { urlFor } from '@/urls';
import { QueryDisabledError } from './errors';
import { projectKeys } from './project';
import { toastApiErrorOr } from './util';
import { useBackgroundLocation } from '@/hooks/useBackgroundLocation';

export const mediaGroupKeys = {
  all: ['mediaGroups'] as const,
  list: ['mediaGroups', 'list'] as const,
  filtered: (
    workspaceId: string | null,
    filters: Omit<MediaGroupQuerySchema, 'workspace'>
  ) => [...mediaGroupKeys.list, workspaceId, filters] as const,
  detail: (id: string | null) => [...mediaGroupKeys.all, 'detail', id] as const,
  summary: (id: string | null) => [...mediaGroupKeys.all, 'summary', id] as const,
  readableSummary: (id: string | null) =>
    [...mediaGroupKeys.all, 'readableSummary', id] as const,
  aiSummary: (id: string | null) => [...mediaGroupKeys.all, 'aiSummary', id] as const,
  transcription: (id: string | null) =>
    [...mediaGroupKeys.all, 'transcription', id] as const,
  similar: (id: string | null) => [...mediaGroupKeys.all, 'similar', id] as const,
  filterSuggestion: (
    filter: MediaGroupSearchFilterProperties,
    mediaGroupFilters: MediaGroupQuerySchema
  ) => [...mediaGroupKeys.all, 'suggestion', filter, mediaGroupFilters],
  dateFilterSuggestion: (mediaGroupFilters: MediaGroupQuerySchema) => [
    mediaGroupKeys.all,
    'suggestion',
    'date',
    mediaGroupFilters,
  ],
  byCategory: (categoryId: string | null) => [
    ...mediaGroupKeys.all,
    'category',
    categoryId,
  ],
};

export const useListMediaGroups = (
  workspaceId: string | null,
  filters: Omit<MediaGroupQuerySchema, 'workspace'>,
  enabled = true,
  setIsLoading?: (state: boolean) => void
) => {
  const enabledAndWorkspace = !!workspaceId && enabled;
  const { setIsLoading: _setIsLoading } = useSearch();
  const queryClient = useQueryClient();
  const query = useInfiniteQuery({
    enabled: enabledAndWorkspace,
    queryKey: mediaGroupKeys.filtered(workspaceId, {
      ...filters,
      query: filters.query ?? '',
    }),
    queryFn: async ({ pageParam }) => {
      if (!enabledAndWorkspace) {
        throw new QueryDisabledError();
      }
      const result = await listMediaGroups(pageParam, {
        ...filters,
        workspace: workspaceId,
      });
      if (setIsLoading) {
        setIsLoading(false);
      } else {
        _setIsLoading(false);
      }
      for (const mediaGroup of result.mediaGroups) {
        queryClient.setQueryData(mediaGroupKeys.summary(mediaGroup.id), {
          mediaGroup,
        });
      }
      return result;
    },
    getNextPageParam: (lastPage) => (lastPage.hasNext ? lastPage.page + 1 : null),
    initialPageParam: 1,
    staleTime: asMilliseconds({ seconds: 30 }),
  });
  return { enabled: enabledAndWorkspace, ...query };
};

export const useMediaGroupCategoryItems = (
  categoryId: string | null,
  sort?: MediaGroupSortOption,
  q?: string
) => {
  const enabled = !!categoryId;
  const query = useInfiniteQuery({
    enabled,
    queryKey: mediaGroupKeys.byCategory(categoryId),
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return await listMediaGroupsByCategoryWithFields(categoryId, pageParam, sort, q);
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
    initialPageParam: undefined as string | undefined,
  });
  return { enabled, ...query };
};

export const useGetSearchSuggestions = (
  filter: MediaGroupSuggestionFilterProperties,
  mediaGroupFilters: MediaGroupQuerySchema
) => {
  const serverFilters = {
    ...mediaGroupFilters,
    query: ['status', 'contentType'].includes(filter)
      ? undefined
      : mediaGroupFilters.query,
  };
  const enabled = !!serverFilters.workspace;
  const { data: suggestionsFromServerQuery } = useQuery({
    enabled,
    queryKey: mediaGroupKeys.filterSuggestion(filter, serverFilters),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getSearchSuggestions(filter, serverFilters);
    },
  });

  return useQuery({
    enabled: !!suggestionsFromServerQuery,
    queryKey: mediaGroupKeys.filterSuggestion(filter, mediaGroupFilters),
    queryFn: async () => {
      if (!suggestionsFromServerQuery) {
        throw new QueryDisabledError();
      }
      const result: typeof suggestionsFromServerQuery = {
        kind: suggestionsFromServerQuery.kind,
        suggestions: [],
      };
      result.suggestions = suggestionsFromServerQuery.suggestions;

      const query = mediaGroupFilters.query;
      if (['status', 'contentType'].includes(filter) && query) {
        result.suggestions = result.suggestions.filter((suggestion) =>
          suggestion.label.toLowerCase().includes(query.toLowerCase())
        );
      }
      return result;
    },
  });
};

export const useGetSearchDateSuggestions = (
  mediaGroupFilters: MediaGroupQuerySchema
) => {
  const enabled = !!mediaGroupFilters.workspace;
  return useQuery({
    enabled,
    queryKey: mediaGroupKeys.dateFilterSuggestion(mediaGroupFilters),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getSearchDateSuggestions(mediaGroupFilters);
    },
  });
};

export const useSimilarMediaGroups = (
  mediaGroupId: string,
  contentTypes: MediaGroupContentType[] | undefined
) => {
  const enabled = contentTypes !== undefined && !!contentTypes.length;
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: mediaGroupKeys.similar(mediaGroupId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const similarMediaGroups = await getSimilarMediaGroups(
        mediaGroupId,
        contentTypes
      );
      for (const mediaGroup of similarMediaGroups.mediaGroups) {
        queryClient.setQueryData(mediaGroupKeys.summary(mediaGroup.id), {
          mediaGroup,
        });
      }

      return similarMediaGroups;
    },
  });
};

export const useMediaGroupDetail = (
  mediaGroupId: string | null,
  options?: {
    enabled?: boolean;
    refetchInterval?: number | null;
    retry?: number | boolean | null;
    refetchOnWindowFocus?: boolean;
  }
) => {
  const enabled = (options?.enabled ?? true) && !!mediaGroupId;
  const queryClient = useQueryClient();
  const query = useQuery({
    enabled,
    queryKey: mediaGroupKeys.detail(mediaGroupId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const detail = await getMediaGroupDetail(mediaGroupId);
      queryClient.setQueryData(mediaGroupKeys.summary(mediaGroupId), detail);
      return detail;
    },
    refetchInterval: options?.refetchInterval ?? false,
    retry: options?.retry ?? undefined,
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
  return query;
};

export const useMediaGroupSummary = (
  mediaGroupId: string | null,
  refetchInterval?: UseQueryOptions<
    Awaited<ReturnType<typeof getMediaGroupSummary>>
  >['refetchInterval']
) => {
  const enabled = !!mediaGroupId;
  const query = useQuery({
    enabled,
    queryKey: mediaGroupKeys.summary(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupSummary(mediaGroupId);
    },
    staleTime: asMilliseconds({ seconds: 30 }),
    refetchInterval,
  });
  return query;
};

export const useMediaGroupReadableSummary = (mediaGroupId: string | null) => {
  const enabled = !!mediaGroupId;
  const query = useQuery({
    enabled,
    queryKey: mediaGroupKeys.readableSummary(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupReadableContent(mediaGroupId);
    },
    staleTime: asMilliseconds({ seconds: 30 }),
  });
  return query;
};

export const useMediaGroupAiSummary = (mediaGroupId: string | null) => {
  const enabled = !!mediaGroupId;
  const query = useQuery({
    enabled,
    queryKey: mediaGroupKeys.aiSummary(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupAiSummary(mediaGroupId);
    },
    staleTime: asMilliseconds({ seconds: 30 }),
  });
  return query;
};

export const useEnqueueMediaGroupAiSummary = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: enqueueMediaGroupSummary,
    onSuccess: (_, mediaGroupId) => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.aiSummary(mediaGroupId),
      });
    },
  });

  return mutation;
};

export const useMediaGroupTranscription = (mediaGroupId: string | null) => {
  const enabled = !!mediaGroupId;
  const query = useQuery({
    enabled,
    queryKey: mediaGroupKeys.transcription(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupTranscription(mediaGroupId);
    },
    staleTime: asMilliseconds({ seconds: 30 }),
  });
  return query;
};

export const useEnqueueMediaGroupTranscription = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: enqueueMediaGroupTranscription,
    onSuccess: (_, mediaGroupId) => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.transcription(mediaGroupId),
      });
    },
  });

  return mutation;
};

export const useCreateMediaGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createMediaGroup,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.list,
      });
      if (vars.categoryId) {
        queryClient.invalidateQueries({
          queryKey: mediaGroupKeys.byCategory(vars.categoryId),
        });
      }
    },
  });

  return mutation;
};

export const useCreateAndNavigateToMediaGroup = (kind: MediaGroupKind) => {
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const navigate = useNavigate();
  const backgroundLocation = useBackgroundLocation();

  return useCallback(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;

      let response: Awaited<ReturnType<typeof createMediaGroup>>;

      try {
        response = await createMediaGroup({
          kind,
          projectId: projectId,
          workspaceId: !projectId ? workspaceId : undefined,
          ...rest,
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to create media group', {
          iconVariant: 'warning',
          titleText: 'Create item failed',
          bodyText: 'Failed to create item, please try again later.',
        });
      }
      const { mediaGroupId } = response;
      const currentLocation = `${location.pathname}${location.search}${location.hash}`;

      navigate(
        urlFor('mediaGroup', {
          mediaGroupId,
        }),
        {
          state: {
            backgroundLocation: [currentLocation, ...backgroundLocation],
          },
        }
      );
    },
    [kind, navigate, createMediaGroup]
  );
};

export const usePatchMediaGroup = (props?: { onError?: () => void }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: patchMediaGroup,
    onSuccess: (
      response: Awaited<ReturnType<typeof patchMediaGroup>>,
      variables: Parameters<typeof patchMediaGroup>[0]
    ) => {
      if (response.mediaGroup?.id) {
        queryClient.setQueryData(
          mediaGroupKeys.detail(response.mediaGroup.id),
          response
        );
        queryClient.setQueryData(
          mediaGroupKeys.summary(response.mediaGroup.id),
          response
        );
      }
      queryClient.setQueriesData(
        { queryKey: mediaGroupKeys.list },
        (
          oldData: InfiniteData<{ mediaGroups: MediaGroupDTO[] }, unknown> | undefined
        ) => {
          if (!oldData) {
            return;
          }
          oldData.pages = oldData.pages.map((page) => {
            page.mediaGroups = page.mediaGroups.map((mediaGroup) => {
              return mediaGroup.id === response.mediaGroup?.id
                ? response.mediaGroup
                : mediaGroup;
            });
            return page;
          });
          return oldData;
        }
      );
      if (variables.patch.status !== undefined && response.mediaGroup?.project?.id) {
        queryClient.invalidateQueries({
          queryKey: projectKeys.one(response.mediaGroup.project.id),
        });
        queryClient.invalidateQueries({
          queryKey: mediaGroupKeys.list,
        });
      }
      if (variables.patch.isStarred !== undefined) {
        queryClient.invalidateQueries({
          queryKey: mediaGroupKeys.list,
        });
      }
    },
    onError: props?.onError,
  });

  return mutation;
};

export const useDeleteMediaGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteMediaGroup,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.list,
      });
      if (data.projectId) {
        queryClient.invalidateQueries({
          queryKey: projectKeys.one(data.projectId),
        });
      }
    },
  });

  return mutation;
};

export const useRestoreMediaGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: restoreMediaGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.list,
      });
    },
  });

  return mutation;
};

export const useRegenerateMediaGroupThumbnail = () => {
  const mutation = useMutation({
    mutationFn: regenerateMediaGroupThumbnail,
  });
  return mutation;
};

const bulkActionMessage = (
  action: {
    past: string;
    present: string;
  },
  unableToActionCount: number,
  actionCount: number
): CreateToastProps => {
  if (actionCount === 0) {
    return {
      iconVariant: 'warning',
      titleText: `Could not ${action.present} any of these items`,
      bodyText: `You do not have permission to ${action.present} any of these items.`,
    };
  }
  if (unableToActionCount > 0) {
    const successfullyActionedMessage =
      actionCount > 0
        ? `${actionCount} item${
            actionCount === 1 ? ' was' : 's were'
          } successfully ${action.past}.`
        : '';
    return {
      iconVariant: 'warning',
      titleText: `Could not ${action.present} some of these items`,
      bodyText: `You do not have permission to ${action.present} some of these items. ${successfullyActionedMessage}`,
    };
  }
  return {
    iconVariant: 'success',
    bodyText: `Successfully ${action.past} ${actionCount} item${
      actionCount > 1 ? 's' : ''
    }.`,
  };
};

export const useBulkDeleteMediaGroups = (options?: {
  hideDefaultToasts?: boolean;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: bulkDeleteMediaGroups,
    onSuccess: (res) => {
      const unableToDeleteCount = res.unableToDeleteIds.length + res.unknownIds.length;
      const deletedCount = res.deletedIds.length;
      if (!options?.hideDefaultToasts) {
        const message = bulkActionMessage(
          { past: 'deleted', present: 'delete' },
          unableToDeleteCount,
          deletedCount
        );
        createToast(message);
      }
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.all,
      });
    },
    onError: () => {
      if (!options?.hideDefaultToasts) {
        createToast({
          iconVariant: 'danger',
          titleText: 'Unable to delete items',
          bodyText: 'Please try again later or contact support',
        });
      }
    },
  });

  return mutation;
};

export const useBulkTagMediaGroups = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: bulkTagMediaGroups,
    onSuccess: (res) => {
      const unableToTagCount = res.unableToTagIds.length + res.unknownIds.length;
      const taggedCount = res.taggedIds.length;
      const message = bulkActionMessage(
        { past: 'tagged', present: 'tag' },
        unableToTagCount,
        taggedCount
      );
      createToast(message);
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.all,
      });
    },
  });

  return mutation;
};

export type TransferMode = 'copy' | 'move';

export const useCopyMediaGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      mediaGroupIds,
      projectId,
      mode,
    }: {
      mediaGroupIds: string[];
      projectId?: string;
      mode: TransferMode;
    }) => copyMediaGroups({ mediaGroupIds, projectId, mode }),
    onSuccess: async (data, req) => {
      createToast({
        iconVariant: 'success',
        bodyText: `${
          req.mode === 'copy' ? 'Copied' : 'Moved'
        } to ${req.projectId ? 'space' : 'drafts'} successfully`,
      });
      void queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.list,
      });
      const categories = Object.values(data.copied)
        .map((mediaGroup) => mediaGroup.category?.id)
        .filter(exists);
      categories.push(
        ...data.moved.map((mediaGroup) => mediaGroup.category?.id).filter(exists)
      );
      for (const categoryId of new Set(categories)) {
        void queryClient.invalidateQueries({
          queryKey: mediaGroupKeys.byCategory(categoryId),
        });
      }
    },
    onError: (error, req) => {
      console.error('Failed to copy/move media group', error, req);
      createToast({
        iconVariant: 'warning',
        titleText: `Could not add to ${req.projectId ? 'space' : 'drafts'}`,
        bodyText: 'Please try again later or contact support',
      });
    },
  });
  return mutation;
};

export { downloadScreen };
