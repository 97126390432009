import { Icon16 } from '@spaceduck/icons';

import { TopNav as BaseTopNav } from '@pages/common';

const { Add, Home, Profile, Settings } = Icon16;

type Owner = 'account' | 'home' | 'workspace' | 'workspaceIntegrations';

type TopNavProps = {
  buttonIsDisabled?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
  children?: React.ReactNode;
  currentBreadcrumb: string;
  iconBefore?: React.ReactNode;
  showAddIcon?: boolean;
  owner: Owner;
  title: string;
};

const createAccountBreadcrumb = (currentBreadcrumb: string) => [
  {
    icon: <Profile />,
    text: 'Settings',
  },
  {
    text: currentBreadcrumb,
  },
];

const createHomeBreadcrumb = (currentBreadcrumb: string) => [
  {
    icon: <Home />,
    text: 'Home',
  },
  {
    text: currentBreadcrumb,
  },
];

const createWorkspaceBreadcrumb = (currentBreadcrumb: string) => [
  {
    icon: <Settings />,
    text: 'Workspace Settings',
  },
  {
    text: currentBreadcrumb,
  },
];

const createIntegrationsBreadcrumb = (currentBreadcrumb: string) => [
  {
    icon: <Settings />,
    text: 'Workspace Settings',
  },
  {
    text: 'Integrations',
  },
  {
    text: currentBreadcrumb,
  },
];

const getBreadcrumbByOwner = (owner: Owner, currentBreadcrumb: string) => {
  switch (owner) {
    case 'account':
      return createAccountBreadcrumb(currentBreadcrumb);
    case 'home':
      return createHomeBreadcrumb(currentBreadcrumb);
    case 'workspace':
      return createWorkspaceBreadcrumb(currentBreadcrumb);
    case 'workspaceIntegrations':
      return createIntegrationsBreadcrumb(currentBreadcrumb);
    default:
      return [{ text: currentBreadcrumb }];
  }
};

export default function TopNav(topNavProps: TopNavProps) {
  const { currentBreadcrumb, owner, iconBefore, showAddIcon, ...baseProps } =
    topNavProps;
  const breadcrumb = getBreadcrumbByOwner(owner, currentBreadcrumb);
  const triggerIcon = (iconBefore ?? showAddIcon) ? <Add /> : undefined;

  return <BaseTopNav {...baseProps} breadcrumb={breadcrumb} iconBefore={triggerIcon} />;
}
