import { useForm } from 'react-hook-form';

import type { MediaGroupCategory } from '@spaceduck/api';

import {
  useCreateMediaGroupCategory,
  usePatchMediaGroupCategory,
} from '@api/mediaGroupCategory';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import createToast from '@utils/createToast';
import styles from './ManageCategoryModal.module.scss';

type FormData = {
  label: string;
};

const ManageCategoryModal = ({
  closeModal,
  existingCategory,
  heading = 'Edit category',
  onSubmit,
  saveText = 'Save',
}: {
  closeModal: () => void;
  existingCategory?: MediaGroupCategory;
  heading?: string;
  onSubmit: (data: FormData) => void;
  saveText?: string;
}) => {
  'use no memo';
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<FormData>();

  const onOpenAutoFocus = (ev: Event) => {
    ev.preventDefault();
    setFocus('label');
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      ev.currentTarget.blur();
    }
  };

  return (
    <Dialog
      className={styles.categoryModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={heading}
      onOpenAutoFocus={onOpenAutoFocus}
      padding="lg"
    >
      <form
        className={styles.categoryForm}
        onSubmit={handleSubmit(async (data) => {
          await onSubmit(data);
          reset();
        })}
      >
        <div className="formGroup">
          <label htmlFor="categoryName">Category name</label>
          <input
            defaultValue={existingCategory?.label}
            onKeyDown={handleKeyDown}
            id="categoryName"
            placeholder="e.g. Meetings"
            type="text"
            {...register('label', {
              required: 'Category name is required',
              setValueAs: (value: string) => value.trim(),
            })}
          />
        </div>
        {errors?.label?.message && (
          <p className="errorMessage fieldError">{errors.label.message}</p>
        )}
        <div className={styles.categoryFormFooter}>
          <Button size="sm" type="button" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit" variant="primary">
            {saveText}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export const CreateCategoryModal = ({
  closeModal,
  projectId,
}: { closeModal: () => void; projectId: string }) => {
  const { mutateAsync: createCategory } = useCreateMediaGroupCategory();

  const onSubmit = async (data: FormData) => {
    if (!projectId) {
      console.error('Failed to create media group category due to missing projectId');
      createToast({
        titleText: 'Error creating category',
        bodyText: 'Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }

    if (!data.label) {
      createToast({
        titleText: 'Error creating category',
        bodyText: 'A category name is required.',
        iconVariant: 'warning',
      });
      return;
    }

    // TODO: backend fix 'MediaGroupCategory' object has no attribute 'fields_count'
    const { created, category } = await createCategory({
      projectId: projectId,
      label: data.label,
    });

    closeModal();

    if (created) {
      createToast({
        titleText: 'Category added successfully',
        bodyText: `Added "${category.label}" as a category.`,
        iconVariant: 'success',
      });
    } else {
      createToast({
        titleText: 'Category already exists',
        bodyText: `The category name "${category.label}" already exists and was not added; categories must be unique.`,
        iconVariant: 'warning',
      });
    }
  };

  return (
    <ManageCategoryModal
      closeModal={closeModal}
      heading="New category"
      onSubmit={onSubmit}
      saveText="Create"
    />
  );
};

export const EditCategoryModel = ({
  category,
  closeModal,
}: {
  category: MediaGroupCategory;
  closeModal: () => void;
}) => {
  const { mutateAsync: patchMediaGroupCategory } = usePatchMediaGroupCategory();

  const onSubmit = async (data: FormData) => {
    const response = await patchMediaGroupCategory({
      id: category.id,
      patch: data,
    });

    closeModal();
    createToast({
      titleText: 'Category edited successfully',
      bodyText: `Category name was changed to "${response.category.label}".`,
      iconVariant: 'success',
    });
  };

  return (
    <ManageCategoryModal
      closeModal={closeModal}
      existingCategory={category}
      onSubmit={onSubmit}
    />
  );
};
