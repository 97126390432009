import { memo, useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import { MenuButton } from '../menu';
import type { NodeManagementProps } from '../SideMenu';

const { DownArrow, RightArrow } = Icon16;

export const FrameCollapseMenu = memo((props: NodeManagementProps) => {
  const { selectedNodeItems, setNodes, patch } = props;

  const isFolded = useMemo(() => {
    if (selectedNodeItems.length !== 1 || selectedNodeItems[0]?.type !== 'groupNode')
      return undefined;
    const targetNode = selectedNodeItems[0];

    return !targetNode.data.expanded;
  }, [selectedNodeItems]);

  const isFoldActive = useMemo(() => {
    return isFolded === true;
  }, [isFolded]);

  const isFoldInactive = useMemo(() => {
    // Differentiate between false and undefined
    return isFolded === false;
  }, [isFolded]);

  const handleFold = useCallback(() => {
    const selectedNodeIds = selectedNodeItems
      .filter((node) => node.type === 'groupNode')
      .map((node) => node.id);

    const updatedNodes = setNodes((nodes) => {
      return nodes.map((node) => {
        if (!selectedNodeIds.includes(node.id)) {
          if (!selectedNodeIds.includes(node.parentId ?? '')) return node;

          return { ...node, hidden: true, data: { ...node.data } };
        }

        return {
          ...node,
          className: toggleCollapseClass(true, node.className),
          data: { ...node.data, expanded: false },
        };
      });
    });

    patch({ nodes: updatedNodes });
  }, [selectedNodeItems, setNodes, patch]);

  const handleExpand = useCallback(() => {
    const selectedNodeIds = selectedNodeItems
      .filter((node) => node.type === 'groupNode')
      .map((node) => node.id);

    const updatedNodes = setNodes((nodes) => {
      return nodes.map((node) => {
        if (!selectedNodeIds.includes(node.id)) {
          if (!selectedNodeIds.includes(node.parentId ?? '')) return node;

          return { ...node, hidden: false, data: { ...node.data } };
        }

        return {
          ...node,
          className: toggleCollapseClass(false, node.className),
          data: { ...node.data, expanded: true },
        };
      });
    });

    patch({ nodes: updatedNodes });
  }, [selectedNodeItems, setNodes, patch]);

  return (
    <>
      <ExpandButton isActive={isFoldInactive} onClick={handleExpand} />
      <FoldButton isActive={isFoldActive} onClick={handleFold} />
    </>
  );
});

const FoldButton = memo(
  ({
    isActive,
    onClick,
  }: {
    isActive?: boolean;
    onClick: () => void;
  }) => {
    return (
      <MenuButton isActive={isActive} onClick={onClick} tooltipContent="Fold">
        <RightArrow />
      </MenuButton>
    );
  }
);

const ExpandButton = memo(
  ({
    isActive,
    onClick,
  }: {
    isActive?: boolean;
    onClick: () => void;
  }) => {
    return (
      <MenuButton isActive={isActive} onClick={onClick} tooltipContent="Expand">
        <DownArrow />
      </MenuButton>
    );
  }
);

function toggleCollapseClass(collapse: boolean, className?: string) {
  if (!className) {
    return collapse ? 'collapsed' : className;
  }

  if (!collapse) {
    if (/collapsed/.test(className)) {
      return className.replace('collapsed', '').replace('  ', ' ');
    }
    return className;
  }

  if (/collapsed/.test(className)) return className;
  return `${className} collapsed`;
}
