import clsx from 'clsx';
import type { ReactNode } from 'react';

import type { MediaGroupContentType } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import { ContentType } from '@components/icons';
import { DetailsModalTooltip } from '@detailsModal/comments/DetailsModalTooltip';
import type { BaseColor } from '@reactFlow/types/colors';
import Button from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import styles from './CardHeader.module.scss';

const { DownArrow, Info, Open2, OptionsThick, RightArrow, RightDock } = Icon16;

type CardHeaderProps = {
  children: ReactNode;
  color?: BaseColor | string;
  contentType?: MediaGroupContentType;
  contextMenu: ContextMenuItemProps[];
  expanded: boolean;
  icon?: ReactNode;
  isDragging?: boolean;
  openInNewTab?: () => void;
  openInfoPopup?: () => void;
  openInSidebar?: () => void;
  toggleExpanded?: () => void;
  title: string;
};

const DOCUMENT_HEADER_SUBMENU_PROPS = { isPadded: true };

export const CardHeader = ({
  children,
  color,
  contentType,
  contextMenu,
  expanded,
  icon,
  isDragging,
  openInNewTab,
  openInfoPopup,
  openInSidebar,
  toggleExpanded,
  title,
}: CardHeaderProps) => {
  return (
    <>
      <div className={clsx(styles.headerBar, color && styles[color])} />
      <div className={clsx(styles.toolbar, !toggleExpanded && styles.noExpand)}>
        {toggleExpanded && (
          <DetailsModalTooltip content={expanded ? 'Fold' : 'Expand'}>
            <Button onClick={toggleExpanded} variant="ghost">
              {expanded ? <DownArrow /> : <RightArrow />}
            </Button>
          </DetailsModalTooltip>
        )}
        <div
          className={clsx(
            styles.toolbarHeading,
            (contentType || icon) && styles.hasContentType
          )}
        >
          {contentType ? <ContentType contentType={contentType} size={20} /> : icon}
          <span>{title || 'Untitled'}</span>
        </div>
        <div className={styles.toolbarButtons}>
          {openInNewTab && (
            <DetailsModalTooltip content="Open in new tab">
              <Button onClick={openInNewTab} variant="ghost">
                <Open2 />
              </Button>
            </DetailsModalTooltip>
          )}
          {openInfoPopup && (
            <DetailsModalTooltip content="Info">
              <Button onClick={openInfoPopup} variant="ghost">
                <Info />
              </Button>
            </DetailsModalTooltip>
          )}
          {openInSidebar && (
            <DetailsModalTooltip content="Open in sidebar">
              <Button onClick={openInSidebar} variant="ghost">
                <RightDock />
              </Button>
            </DetailsModalTooltip>
          )}
          {isDragging ? (
            <Button variant="ghost">
              <OptionsThick />
            </Button>
          ) : (
            <DetailsModalTooltip content="Options">
              <span>
                <RecursiveDropdownMenu
                  className={styles.menu}
                  dropdownMenuProps={DOCUMENT_HEADER_SUBMENU_PROPS}
                  items={contextMenu}
                  isUnstyled
                >
                  <Button variant="ghost">
                    <OptionsThick />
                  </Button>
                </RecursiveDropdownMenu>
              </span>
            </DetailsModalTooltip>
          )}
        </div>
      </div>
      {expanded && children}
    </>
  );
};
