import { useMediaGroupSummary } from '@/api/mediaGroup';
import { asMilliseconds } from '@spaceduck/utils';
import { type ReactNode, useEffect, useState } from 'react';
import { ContentType } from '../icons';
import Spinner from '../ui/Spinner';
import styles from './InlineSource.module.scss';
import { useSourcesStore, type SelectedSourceType } from './useSourcesStore';
import Tag from '../ui/Tag';
import { useShallow } from 'zustand/shallow';

export const SelectedSource = (
  props: SelectedSourceType & {
    onDelete: () => void;
  }
) => {
  const { addReadySource, removeSource } = useSourcesStore(
    useShallow((state) => ({
      addReadySource: state.addReadySource,
      removeSource: state.removeSource,
    }))
  );
  const [icon, setIcon] = useState<ReactNode>();
  const [resolved, setResolved] = useState(false);
  const [label, setLabel] = useState(props.label);
  const { data: mediaGroupData } = useMediaGroupSummary(
    props.id || null,
    asMilliseconds({ seconds: 3 })
  );
  useEffect(() => {
    return () => {
      removeSource(props);
    };
  }, []);

  useEffect(() => {
    if (mediaGroupData) {
      setLabel(mediaGroupData.mediaGroup.label);
    }

    if (mediaGroupData?.mediaGroup.isResearchAssistantReady) {
      //  TODO: failed state for media group chunking
      addReadySource({
        label: mediaGroupData.mediaGroup.label,
        description: '',
        container: mediaGroupData.mediaGroup.project?.label || 'Drafts',
        contentType: mediaGroupData.mediaGroup.contentType,
        id: mediaGroupData.mediaGroup.id,
        kind: mediaGroupData.mediaGroup.kind,
        linkUrl: mediaGroupData.mediaGroup.linkUrl,
        linkUrlSource: mediaGroupData.mediaGroup.linkUrlSource,
        pending: !mediaGroupData.mediaGroup.isResearchAssistantReady,
      });
      setIcon(<ContentType contentType={mediaGroupData.mediaGroup.contentType} />);
      setResolved(true);
    }
  }, [mediaGroupData, addReadySource]);
  if (!resolved) {
    return (
      <Tag
        className={styles.selectedSource}
        onRemoveClick={props.onDelete}
        removeIconIsHidden
        size="lg"
        variant="quaternary"
      >
        <Spinner size={20} />
        <span className={styles.label}>{label}</span>
      </Tag>
    );
  }
  return (
    <Tag
      className={styles.selectedSource}
      onRemoveClick={props.onDelete}
      removeIconIsHidden
      size="lg"
      variant="quaternary"
    >
      {' '}
      {icon}
      <span className={styles.label}>{label}</span>
    </Tag>
  );
};
