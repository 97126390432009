import { useCallback, useId } from 'react';
import { useForm } from 'react-hook-form';

import { usePatchMediaGroup } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import { useModalManager } from '@context/ModalManagerContext';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import createToast from '@utils/createToast';
import styles from './ManageMediaGroupLinkModal.module.scss';

export type ManageLinkFormData = {
  url: string | null;
};

type ManageMediaGroupLinkModalProps = {
  closeModal?: () => void;
  isOpen?: boolean;
  mediaGroupId: string;
  existingURL?: string;
};

const schemeRegExp = /^[a-z0-9]+:\/\//;

function ManageMediaGroupLinkModal({
  closeModal,
  isOpen = true,
  mediaGroupId,
  existingURL,
}: ManageMediaGroupLinkModalProps) {
  'use no memo';
  const mode = existingURL === undefined ? 'add' : 'edit';
  const formId = useId();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ManageLinkFormData>({
    defaultValues: {
      url: mode === 'add' ? null : existingURL,
    },
  });

  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();

  const onCancel = () => {
    reset();
    closeModal?.();
  };

  const onSubmit = useCallback(
    async (data: ManageLinkFormData) => {
      let linkUrl = data.url === '' ? null : data.url;
      if (linkUrl) {
        linkUrl = schemeRegExp.test(linkUrl) ? linkUrl : `https://${linkUrl}`;
      }

      try {
        await patchMediaGroup({
          mediaGroupId,
          patch: { linkUrl },
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to create media group link', {
          iconVariant: 'warning',
          titleText: 'Error creating link',
          bodyText: 'An unknown error occurred creating link. Please try again later',
        });
      }
      reset();
      closeModal?.();
      createToast({
        bodyText: data.url ? 'Link updated' : 'Link removed',
        iconVariant: 'success',
      });
    },
    [reset, closeModal, patchMediaGroup]
  );

  return (
    <Dialog
      className={styles.manageMediaGroupLinkModal}
      closeModal={closeModal}
      isOpen={isOpen}
      maxWidth="32.5rem"
      modalHeading={`${mode === 'add' ? 'Add a link' : 'Update link'}`}
      padding="lg"
      overlayClassName={styles.overlay}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formBody">
          <div className="formGroup">
            <label htmlFor={`${formId}url`}>URL</label>
            <input
              autoComplete="off"
              id={`${formId}url`}
              placeholder="e.g. https://spaceduck.com"
              type="text"
              {...register('url', {
                required: mode === 'add' ? 'URL is required' : false,
                validate: {
                  isValid: (url) => {
                    if (!url) return true;
                    try {
                      new URL(schemeRegExp.test(url) ? url : `https://${url}`);
                      return true;
                    } catch {
                      return 'URL is invalid';
                    }
                  },
                },
              })}
            />
            {errors.url?.message && (
              <p className="errorMessage">{errors.url?.message}</p>
            )}
          </div>
          <div className={styles.actions}>
            <Button type="button" variant="outlined" size="sm" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" size="sm">
              {mode === 'add' ? 'Add' : 'Update'}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export const useManageMediaGroupLinkModal = ({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) => {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (existingURL?: string) => {
      openModal({
        component: (
          <ManageMediaGroupLinkModal
            mediaGroupId={mediaGroupId}
            existingURL={existingURL}
          />
        ),
      });
    },
    close: closeModal,
  };
};
