import { useEffect, useState } from 'react';

import type { MediaGroupDTO } from '@spaceduck/api';

import { useMediaGroupSummary } from '@api/mediaGroup';
import Spinner from '@ui/Spinner';

export default function NewMediaGroupLoading({
  closeParentPopup,
  handleSelectItem,
  mediaGroupId,
}: {
  closeParentPopup?: () => void;
  handleSelectItem: (item: MediaGroupDTO) => void;
  mediaGroupId: string;
}) {
  const { data } = useMediaGroupSummary(mediaGroupId);
  const [inserted, setInserted] = useState(false);

  useEffect(() => {
    if (data) {
      if (!inserted) {
        setInserted(true);
        handleSelectItem(data.mediaGroup);
        closeParentPopup?.();
      }
    }
  }, [data, inserted]);

  return <Spinner />;
}
