import type { MediaGroupContentType, MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24, type IconProps } from '@spaceduck/icons';
import { MediaType } from '@spaceduck/utils';

const {
  Article,
  Audio,
  Document,
  File,
  Film,
  Frame,
  Image,
  Multimedia,
  PDF,
  Video,
  Wiki,
} = Icon16;
const { Bookmark, Highlight, QuoteBottom, Social, Zip } = Icon24;

type ContentTypeProps = {
  contentType: MediaGroupContentType;
  mediaGroup?: MediaGroupDTO;
} & IconProps;

export default function ContentType({
  className,
  color,
  contentType,
  size = 16,
  mediaGroup,
}: ContentTypeProps) {
  const iconProps = { className, color, size };
  switch (contentType) {
    case 'image':
      return <Image {...iconProps} />;
    case 'mixed':
      return <Multimedia {...iconProps} />;
    case 'video':
      return <Video {...iconProps} />;
    case 'document':
      return <Document {...iconProps} />;
    case 'pdf':
      return <PDF {...iconProps} />;
    case 'bookmark':
      return <Bookmark {...iconProps} />;
    case 'article':
      return <Article {...iconProps} />;
    case 'wiki':
      return <Wiki {...iconProps} />;
    case 'social':
      return <Social {...iconProps} />;
    case 'film':
      return <Film {...iconProps} />;
    case 'audio':
      return <Audio {...iconProps} />;
    case 'file':
      if (mediaGroup?.media?.mediaType === MediaType.ZIP) {
        return <Zip {...iconProps} />;
      }
      return <File {...iconProps} />;
    case 'highlight':
      return <Highlight {...iconProps} />;
    case 'quote':
      return <QuoteBottom {...iconProps} />;
    case 'board':
      return <Frame {...iconProps} />;
    default:
      return <File {...iconProps} />;
  }
}
