import type { WorkspaceDetail } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { urlFor } from '@/urls';
import { isCapable, toastApiErrorOr } from '@api/util';
import { useDeleteWorkspace, usePatchWorkspace, useWorkspace } from '@api/workspace';
import { useModalManager } from '@context/ModalManagerContext';
import { type ProcessingResult, useProcessAssets } from '@hooks/useProcessAssets';
import useWorkspaceId from '@hooks/useWorkspaceId';
import {
  acceptedProfileImageMediaTypes,
  acceptedProfileImagePattern,
} from '@lib/const';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import Dialog from '@ui/Dialog';
import Spinner from '@ui/Spinner';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import createToast from '@utils/createToast';
import sharedStyles from './Settings.module.scss';
import styles from './WorkspaceManager.module.scss';
import { Container, Header, SettingsError, TopNav } from './common';

type WorkspaceData = {
  label: string;
  image?: FileList | null;
};

const { Close } = Icon16;

const WorkspaceManager = ({ workspace }: { workspace: WorkspaceDetail }) => {
  'use no memo';
  const navigate = useNavigate();
  const { mutateAsync: patchWorkspace } = usePatchWorkspace();
  const [newAvatarAssetId, setNewAvatarAssetId] = useState<string | null>();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const formId = useId();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const imageRef = useRef<HTMLInputElement | null>(null);
  const { mutateAsync: deleteWorkspace } = useDeleteWorkspace();

  const {
    clearErrors,
    control,
    formState: { dirtyFields, errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<WorkspaceData>({
    defaultValues: {
      label: workspace.label,
    },
  });

  const setImageUploadError = (message: string) => {
    setIsImageUploading(false);
    setError('image', {
      type: 'validate',
      message,
    });
    createToast({
      titleText: 'Could not upload image',
      iconVariant: 'warning',
      bodyText: message,
    });
  };

  useEffect(() => {
    reset({ label: workspace.label });
    setImageSrc(workspace?.avatarUrl ?? null);
  }, [workspace]);

  const onSuccess = useCallback((item: ProcessingResult) => {
    if (item.request.result?.url) {
      setImageSrc(item.request.result.url);
      clearErrors('image');
      setNewAvatarAssetId(item.request.result.id);
      setIsImageUploading(false);
    }
  }, []);

  const onError = useCallback(() => {
    setImageUploadError('Error uploading image. Please try again.');
  }, []);

  const onReject = useCallback((result: ProcessingResult) => {
    setImageUploadError(result.request.message);
  }, []);

  const onFail = useCallback((result: ProcessingResult) => {
    setImageUploadError(result.request.message);
  }, []);

  const { insert } = useProcessAssets({
    pollInterval: 1000,
    onSuccess,
    onError,
    onReject,
    onFail,
  });

  const handleFileChange = useCallback(
    async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const files = ev.currentTarget.files;
      const file = files?.[0] ?? null;
      if (file === null) {
        return null;
      }
      if (!acceptedProfileImageMediaTypes.has(file.type)) {
        setError('image', {
          type: 'validate',
          message: 'File type is not allowed. Please upload an image.',
        });
        return null;
      }
      setIsImageUploading(true);
      insert({
        files: [file],
        kind: 'avatar',
      });
    },
    [insert]
  );

  const openImageUpload = (ev: React.MouseEvent) => {
    ev.preventDefault();
    imageRef.current?.click();
  };

  const clearImageUpload = () => {
    setImageSrc(null);
    setNewAvatarAssetId(null);
  };

  const onSubmit = async (data: WorkspaceData) => {
    if (!workspace) return;

    const labelChanged = workspace.label !== data.label;

    if ((newAvatarAssetId === undefined && !labelChanged) || !data.label) {
      return;
    }

    const patch: {
      label?: string;
      avatarAssetId?: string | null;
    } = {};

    if (newAvatarAssetId !== undefined) {
      patch.avatarAssetId = newAvatarAssetId;
    }

    if (labelChanged) {
      patch.label = data.label;
    }

    await patchWorkspace({
      workspaceId: workspace.id,
      patch,
    });

    const toastText =
      newAvatarAssetId && labelChanged
        ? 'Workspace logo and name'
        : newAvatarAssetId
          ? 'Workspace logo'
          : 'Workspace name';

    createToast({
      iconVariant: 'success',
      titleText: `${toastText} updated!`,
      bodyText: `${toastText} changed successfully`,
    });

    setNewAvatarAssetId(undefined);
  };

  const { open: openConfirmWorkspaceDeletionModal } =
    useConfirmWorkspaceDeletionModal();

  const deleteCapability = isCapable('workspaceDelete', workspace.capabilities);

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={clsx('formGroup', sharedStyles.formGroup)}>
          <label htmlFor={`${formId}Image`}>Workspace logo</label>
          <div className={styles.imageUpload}>
            <div className={styles.imageViewer}>
              {imageSrc && (
                <Button
                  className={styles.btnRemoveImage}
                  onClick={clearImageUpload}
                  size="xs"
                  type="button"
                  variant="icon"
                >
                  <Close size={12} />
                </Button>
              )}
              <div className={styles.imagePreview}>
                <div className={styles.hiddenField}>
                  <Controller
                    control={control}
                    name={'image'}
                    render={({ field: { onChange, ref } }) => {
                      return (
                        <input
                          {...register('image')}
                          id={`${formId}Image`}
                          accept={acceptedProfileImagePattern}
                          ref={(el) => {
                            ref(el);
                            imageRef.current = el;
                          }}
                          type="file"
                          multiple={false}
                          onChange={(ev) => onChange(handleFileChange(ev))}
                        />
                      );
                    }}
                  />
                </div>
                <span className={styles.icon} onClick={openImageUpload}>
                  {workspace.label && !isImageUploading ? (
                    <WorkspaceAvatar
                      size="xxl"
                      workspaceAvatar={imageSrc}
                      className={styles.avatar}
                      workspaceName={workspace.label}
                    />
                  ) : (
                    <Spinner />
                  )}
                </span>
              </div>
            </div>
            <div className={styles.imageControl}>
              <Button
                onClick={openImageUpload}
                size="sm"
                variant="outlined"
                type="button"
              >
                {isImageUploading ? (
                  <>
                    <Spinner size={16} /> Processing...
                  </>
                ) : (
                  'Replace'
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className={clsx('formGroup', sharedStyles.formGroup)}>
          <label htmlFor={`${formId}Label`}>Workspace name</label>
          <input
            {...register('label')}
            className={clsx(errors.label && 'hasError')}
            defaultValue={workspace.label}
            id={`${formId}Label`}
            type="text"
          />
          {errors.label?.message && (
            <p className="errorMessage">{errors.label.message}</p>
          )}
        </div>
        <div className={styles.formFooter}>
          <Button
            disabled={
              !(Object.keys(dirtyFields).length || imageSrc !== workspace.avatarUrl) ||
              isSubmitting ||
              isImageUploading
            }
            size="sm"
            type="submit"
            variant="primary"
          >
            Update
          </Button>
        </div>
      </form>
      {deleteCapability.capable && (
        <>
          <hr />
          <div className={styles.deleteWorkspace}>
            <h2 className="subtitle5">Delete this Workspace</h2>
            <p>
              This will remove all users in this workspace and delete all of its content
            </p>
            <Button
              className={styles.deleteWorkspaceButton}
              onClick={() =>
                openConfirmWorkspaceDeletionModal({
                  onConfirm: async () => {
                    try {
                      await deleteWorkspace(workspace.id);
                    } catch (error) {
                      return toastApiErrorOr(error, 'Failed to delete workspace', {
                        iconVariant: 'warning',
                        titleText: 'Workspace Delete Failed',
                        bodyText:
                          'An unknown error occurred while deleting workspace. Please try again later',
                      });
                    }
                    navigate(urlFor('userSettingsWorkspaces'));
                  },
                })
              }
              size="sm"
              variant="outlined"
            >
              Delete workspace
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default function WorkspaceManagerPage() {
  const workspaceId = useWorkspaceId();
  const { data, status } = useWorkspace(workspaceId);

  return (
    <>
      <TopNav
        currentBreadcrumb="General"
        owner="workspace"
        title="Workspace settings"
      />
      <Container>
        <Header>
          <h1>General</h1>
          <p>Manage your workspace details.</p>
        </Header>
        {status === 'pending' ? (
          <Spinner />
        ) : status === 'error' ? (
          <SettingsError />
        ) : (
          <WorkspaceViewer workspace={data.workspace} />
        )}
      </Container>
    </>
  );
}

const WorkspaceViewer = ({ workspace }: { workspace: WorkspaceDetail }) => {
  const canEdit = isCapable('workspaceEdit', workspace.capabilities).capable;

  if (canEdit) {
    return <WorkspaceManager workspace={workspace} />;
  }

  return (
    <div className={styles.viewer}>
      <div className={styles.row}>
        <p className={styles.label}>Workspace logo</p>
        <div className={styles.image}>
          <span className={styles.icon}>
            <WorkspaceAvatar
              size="xxl"
              workspaceAvatar={workspace.avatarUrl}
              className={styles.avatar}
              workspaceName={workspace.label}
            />
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <p className={styles.label}>Workspace name</p>
        <p className={styles.value}>{workspace.label}</p>
      </div>
    </div>
  );
};

const ConfirmWorkspaceDeletionModal = ({
  closeModal,
  isOpen = true,
  onConfirm,
}: {
  closeModal?: () => void;
  isOpen?: boolean;
  onConfirm?: () => void;
}) => {
  const [userHasAgreed, setUserHasAgreed] = useState(false);

  return (
    <Dialog
      className={styles.confirmWorkspaceDeactivationModal}
      closeModal={closeModal}
      maxWidth="30rem"
      modalHeading="Confirm delete workspace"
      isOpen={isOpen}
      padding="lg"
    >
      <div className={styles.content}>
        <p>
          This operation is irreversible and will permanently delete all data associated
          with this workspace
        </p>
        <div className={styles.deleteForm}>
          <Checkbox
            checked={userHasAgreed}
            className={styles.checkbox}
            onChange={(ev) => setUserHasAgreed(ev.currentTarget.checked)}
            size="md"
          >
            <div className={styles.textSm}>
              I acknowledge that all workspace data and its users will be deleted
            </div>
          </Checkbox>
          <Button
            className={styles.confirmButton}
            disabled={!userHasAgreed}
            onClick={() => {
              onConfirm?.();
              closeModal?.();
            }}
            size="md"
            type="button"
            variant="outlined"
          >
            Delete workspace
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const useConfirmWorkspaceDeletionModal = () => {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({ onConfirm }: { onConfirm: () => void }) => {
      openModal({
        component: <ConfirmWorkspaceDeletionModal onConfirm={onConfirm} />,
      });
    },
    close: closeModal,
  };
};
