const countSegments = (segments: Intl.Segments) => {
  let count = 0;
  for (const _ of segments) {
    count++;
  }
  return count;
};

const createCounter = (granularity: 'grapheme' | 'word') => (text: string) => {
  const segmenter = new Intl.Segmenter(undefined, { granularity });
  const segments = segmenter.segment(text);
  return countSegments(segments);
};
export const graphemeCounter = createCounter('grapheme');
export const wordCounter = (text: string) =>
  text.split(/\s+/).filter((word) => word !== '').length;
