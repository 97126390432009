import clsx from 'clsx';
import { memo, useCallback, useMemo, useRef, useState, type MouseEvent } from 'react';
import type { Edge, Node } from '@xyflow/react';

import { exists } from '@spaceduck/api';

import { usePersist } from '@reactFlow/hooks/usePersist';
import type { EdgeSetter, NodeSetter } from '@reactFlow/stores/boardStore';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import { ActionsMenu } from './ActionsMenu';
import { ColorMenu } from './ColorMenu';
import { EdgeMenu } from './EdgeMenu';
import { NodeMenu } from './NodeMenu';
import styles from './SideMenu.module.scss';
import { useMutationObserver } from '@/hooks/useMutationObserver';

export type NodeManagementProps = {
  nodes: Node[];
  setNodes: NodeSetter;
  selectedNodes: string[];
  selectedNodeItems: Node[];
  patch: PatchFn;
};

export type EdgeManagementProps = {
  edges: Edge[];
  setEdges: EdgeSetter;
  selectedEdges: string[];
  patch: PatchFn;
};

export type PatchFnParams = {
  nodes?: Node[] | undefined;
  edges?: Edge[] | undefined;
};

export type PatchFn = ({ nodes, edges }: PatchFnParams) => void;

export const SideMenu = memo(
  (
    props: Omit<NodeManagementProps, 'selectedNodeItems' | 'patch'> &
      Omit<EdgeManagementProps, 'selectedEdgeItems' | 'patch'> & {
        mediaGroupId: string;
      }
  ) => {
    const { nodes, setNodes, selectedNodes, edges, setEdges, selectedEdges } = props;
    const { updateEntry } = usePersist({
      mediaGroupId: props.mediaGroupId,
    });

    const patch = useCallback(
      ({ nodes, edges }: { nodes?: Node[]; edges?: Edge[] }) => {
        updateEntry({
          patch: {
            board: {
              nodes,
              edges,
            },
          },
          showToast: false,
        });
      },
      [updateEntry]
    );

    const selectedNodeItems = useMemo(() => {
      return selectedNodes
        .map((nodeId) => nodes.find((node) => node.id === nodeId))
        .filter(exists);
    }, [selectedNodes, nodes]);

    const selectedEdgeItems = useMemo(() => {
      return selectedEdges
        .map((edgeId) => edges.find((edge) => edge.id === edgeId))
        .filter(exists);
    }, [selectedEdges, edges]);

    const handleMouseDown = useCallback((ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
    }, []);

    const [hasShadow, setHasShadow] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const menuContentRef = useRef<HTMLDivElement | null>(null);

    useMutationObserver(menuContentRef, () => {
      if (!(containerRef.current && menuContentRef.current)) return setHasShadow(false);

      setHasShadow(
        containerRef.current.offsetHeight < menuContentRef.current.offsetHeight
      );
    });

    const shouldShowColorMenu = useMemo(() => {
      return !!selectedNodeItems.length || !!selectedEdges.length;
    }, [selectedNodeItems, selectedEdges, nodes]);

    return (
      <div
        className={clsx(styles.menu, hasShadow && styles.hasShadow)}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.container} ref={containerRef}>
          <ScrollArea
            className={styles.scrollArea}
            style={css({
              '--width': '100%',
              '--maxHeight': '100%',
            })}
          >
            <div className={styles.menuContent} ref={menuContentRef}>
              {shouldShowColorMenu && (
                <ColorMenu
                  selectedNodes={selectedNodes}
                  selectedNodeItems={selectedNodeItems}
                  setNodes={setNodes}
                  selectedEdges={selectedEdges}
                  selectedEdgeItems={selectedEdgeItems}
                  setEdges={setEdges}
                  patch={patch}
                />
              )}
              <div id="boardNotesSideMenu" />
              {!!selectedNodes.length && (
                <NodeMenu
                  setNodes={setNodes}
                  nodes={nodes}
                  selectedNodes={selectedNodes}
                  selectedNodeItems={selectedNodeItems}
                  patch={patch}
                />
              )}
              {!!selectedEdges.length && (
                <EdgeMenu
                  setEdges={setEdges}
                  edges={edges}
                  selectedEdges={selectedEdges}
                  patch={patch}
                />
              )}
              {!!(selectedNodes.length || selectedEdges.length) && (
                <ActionsMenu
                  {...props}
                  selectedNodeItems={selectedNodeItems}
                  patch={patch}
                />
              )}
            </div>
          </ScrollArea>
        </div>
      </div>
    );
  }
);
