import type { Edge, Node } from '@xyflow/react';
import { memo, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { upperFirst } from 'lodash';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import type { EdgeSetter, NodeSetter } from '@reactFlow/stores/boardStore';
import { baseColors, boardColors, type BaseColor } from '@reactFlow/types/colors';
import {
  MenuContainer as Container,
  MenuTitle as Title,
  MenuButtonGrid as ButtonGrid,
  ColorSwatchMenu,
} from './menu';
import type { PatchFn, PatchFnParams } from './SideMenu';

type ColorMenuProps = {
  selectedNodes: string[];
  selectedNodeItems: Node[];
  setNodes: NodeSetter;
  selectedEdges: string[];
  selectedEdgeItems: Edge[];
  setEdges: EdgeSetter;
  patch: PatchFn;
};

export const ColorMenu = memo((props: ColorMenuProps) => {
  const { selectedNodeItems, selectedEdgeItems } = props;

  const activeColor = useMemo(() => {
    if (selectedNodeItems.length + selectedEdgeItems.length === 1) {
      if (selectedNodeItems[0]) {
        const node = selectedNodeItems[0];
        if (node.type === 'floatingTextNode') return node.data.boxColor as BaseColor;
        return node.data.color as BaseColor;
      }

      if (selectedEdgeItems[0]) {
        return selectedEdgeItems[0].data?.color as BaseColor;
      }

      return undefined;
    }
  }, [selectedNodeItems, selectedEdgeItems]);

  return (
    <Container>
      <Title>Border Color</Title>
      <ButtonGrid>
        <SelectedColorMenu {...props} activeColor={activeColor} />
      </ButtonGrid>
    </Container>
  );
});

export const SelectedColorMenu = memo(
  (
    props: ColorMenuProps & {
      activeColor?: BaseColor;
    }
  ) => {
    const { setEdges, setNodes, activeColor, selectedEdges, selectedNodes, patch } =
      props;
    const updatedState: PatchFnParams = {
      nodes: undefined,
      edges: undefined,
    };

    const setDefaultConnectionLineColor = useBoardStore(
      (state) => state.setDefaultConnectionLineColor
    );

    const handleClick = useCallback(
      (_color: string) => {
        const color = _color as BaseColor;
        if (!color) return;

        if (selectedNodes.length) {
          updatedState.nodes = setNodes((nodes) => {
            return nodes.map((node) => {
              if (!selectedNodes.includes(node.id)) return node;

              if (node.type === 'floatingTextNode') {
                return {
                  ...node,
                  data: { ...node.data, boxColor: color },
                };
              }

              if (node.type === 'groupNode') {
                return {
                  ...node,
                  data: { ...node.data, color },
                  className: clsx(color, !node.data.expanded && 'collapsed'),
                };
              }

              return {
                ...node,
                data: { ...node.data, color },
              };
            });
          });
        }

        if (selectedEdges.length) {
          updatedState.edges = setEdges((edges) => {
            return edges.map((edge) => {
              if (!selectedEdges.includes(edge.id)) return edge;

              const newEdge = { ...edge, data: { ...edge.data, color } };

              if (typeof newEdge.markerStart === 'object') {
                newEdge.markerStart = {
                  ...newEdge.markerStart,
                  color: boardColors[color],
                };
              } else if (newEdge.markerStart) {
                if (/markerTypeCircle/.test(newEdge.markerStart)) {
                  newEdge.markerStart = `markerTypeCircle${upperFirst(color)}`;
                } else if (/markerTypeDiamond/.test(newEdge.markerStart)) {
                  newEdge.markerStart = `markerTypeDiamond${upperFirst(color)}`;
                }
              }

              if (typeof newEdge.markerEnd === 'object') {
                newEdge.markerEnd = {
                  ...newEdge.markerEnd,
                  color: boardColors[color],
                };
              } else if (newEdge.markerEnd) {
                if (/markerTypeCircle/.test(newEdge.markerEnd)) {
                  newEdge.markerEnd = `markerTypeCircle${upperFirst(color)}`;
                } else if (/markerTypeDiamond/.test(newEdge.markerEnd)) {
                  newEdge.markerEnd = `markerTypeDiamond${upperFirst(color)}`;
                }
              }

              return newEdge;
            });
          });

          setDefaultConnectionLineColor(color);
        }

        patch(updatedState);
      },
      [
        selectedNodes,
        setNodes,
        selectedEdges,
        setEdges,
        setDefaultConnectionLineColor,
        patch,
      ]
    );

    return (
      <ColorSwatchMenu active={activeColor} colors={baseColors} onClick={handleClick} />
    );
  }
);
