import { Icon16, Icon24 } from '@spaceduck/icons';
import { EditorContent, type FocusPosition, type JSONContent } from '@tiptap/react';
import type { SuggestionProps } from '@tiptap/suggestion';
import { clsx } from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { type AliveCommentDTO, type CommentAttachment, exists } from '@spaceduck/api';

import { useMakeComment } from '@api/comment';
import { useCommentsStore } from '@stores/useCommentsStore';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import type { ActionableInvitesInComment } from './Comment';
import styles from './CommentTextbox.module.scss';
import { EmojiPicker } from './EmojiButton';
import FileList, { ViewFileList } from './FileList';
import FileUpload from './FileUpload';
import { MentionPopover, type Mention as MentionSuggestion } from './MentionPopover';
import { useTiptap } from './Tiptap';
import { useFileUpload } from './useFileUpload';
import { textNode } from '../tiptap/utils';

const { Mention } = Icon16;
const { AddReaction, SendComment } = Icon24;

type CommentTextBoxProps = {
  autofocus?: FocusPosition;
  className?: string;
  containerClassName?: string;
  comment?: AliveCommentDTO;
  emojiPickerAsTippy?: boolean;
  isInEditMode?: boolean;
  isLoading: boolean;
  onCancel?: () => void;
  onSubmit: (
    content: string,
    document?: JSONContent,
    newAttachmentAssetIds?: string[],
    removeAttachmentIds?: string[]
  ) => Promise<void>;
  placeholder?: string;
};

export function CommentTextBox({
  autofocus,
  className,
  containerClassName,
  comment,
  emojiPickerAsTippy = false,
  isInEditMode,
  isLoading,
  onCancel,
  onSubmit,
  placeholder,
}: CommentTextBoxProps) {
  'use no memo';
  const scrollToRef = useCommentsStore((state) => state.scrollToRef);
  const textContainerRef = useRef<HTMLDivElement | null>(null);

  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const [isPosting, setIsPosting] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<MentionSuggestion[]>(
    []
  );
  const [selectSuggestionHandler, setSuggestionHandler] =
    useState<(suggestion: MentionSuggestion) => void>();
  const updateSuggestionHandler = (props?: SuggestionProps<MentionSuggestion>) => {
    if (!props) {
      setSuggestionHandler(undefined);
      return;
    }
    const handler = (suggestion: MentionSuggestion) => {
      if (!suggestion) {
        return;
      }
      props.command(suggestion);
    };

    setSuggestionHandler(() => handler);
  };

  const content: JSONContent | string | undefined = comment?.document
    ? comment.document
    : comment?.content?.split('\n').join('<br/>');
  const { register, handleSubmit, setValue, watch } = useForm<{
    content: string;
    fileChange: boolean;
  }>({
    defaultValues: {
      content: '',
      fileChange: false,
    },
  });
  register('content', {
    validate: (value, formValue) => {
      if (formValue.fileChange) return true;
      return !!value;
    },
  });
  const { ref: fileChangeRef } = register('fileChange');
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);

  const setFileChangeToTrue = useCallback(() => {
    setValue('fileChange', true, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [setValue]);

  const {
    addFiles,
    clear: clearFiles,
    fileInputRef,
    processedFiles,
    remainingFileCount,
    removeFile,
    selectedFiles,
  } = useFileUpload();

  useEffect(() => {
    if (processedFiles.length && selectedFiles.size) {
      setFileChangeToTrue();
    }
  }, [processedFiles.length > 0, selectedFiles.size > 0]);

  // Check for CMD/CTRL + Enter to submit
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter' && !isPosting) {
        if (remainingFileCount) {
          return true;
        }
        handleSubmit(submit)();
        return true;
      }

      if (event.key === 'Escape') {
        onCancel?.();
        return true;
      }

      if (event.key === 'Enter') {
        return true;
      }

      return false;
    },
    [remainingFileCount, handleSubmit, onCancel]
  );

  const editor = useTiptap({
    suggestionConfiguration: {
      open: () => setSuggestionsOpen(true),
      close: () => setSuggestionsOpen(false),
      updateSuggestionHandler,
      setFilteredSuggestions,
      handleKeyDown,
    },
    options: {
      content,
      autofocus,
      onUpdate: ({ editor }) => {
        if (editor.getText() === '') {
          setValue('content', '');
          return;
        }
        const text = JSON.stringify(editor.getJSON());
        setValue('content', text);
      },
      editorProps: {
        handleDOMEvents: {
          keydown: (_, event) => {
            // Prevent extra space from being created
            if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) return true;
          },
        },
      },
    },
    placeholder,
  });

  const [existingAttachments, setExistingAttachments] = useState<CommentAttachment[]>(
    comment?.attachments ?? []
  );

  const removeExistingAttachment = useCallback((id: string) => {
    setExistingAttachments((existingAttachments) =>
      existingAttachments.filter((attachment) => attachment.id !== id)
    );
    setFileChangeToTrue();
  }, []);

  const removeAttachmentIds = useMemo(() => {
    if (!comment?.attachments) return undefined;
    if (comment.attachments.length === existingAttachments.length) return undefined;

    const originalAttachmentIds = comment.attachments.map(
      (attachment) => attachment.id
    );

    const remainingAttachmentIds = existingAttachments.map(
      (attachment) => attachment.id
    );

    return originalAttachmentIds.filter((id) => !remainingAttachmentIds.includes(id));
  }, [existingAttachments, comment?.attachments]);

  const newAttachmentAssetIds = useMemo(() => {
    if (!processedFiles.length) return undefined;

    return processedFiles.map((file) => file.request.result?.id).filter(exists);
  }, [processedFiles]);

  const submit = async () => {
    setIsPosting(true);
    try {
      await onSubmit('', editor?.getJSON(), newAttachmentAssetIds, removeAttachmentIds);
      editor?.commands.clearContent();
      setValue('content', '');
      clearFiles();
    } finally {
      setValue('fileChange', false);
      setIsPosting(false);
    }
  };

  const watchContent = watch('content');
  const watchFileChange = watch('fileChange');

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, watchContent]);

  useEffect(() => {
    if (textAreaRef.current) {
      scrollToRef(textAreaRef);
    }
  }, [textAreaRef]);

  const appendEmoji = (emoji: string) => {
    if (textAreaRef.current) {
      editor?.commands.insertContent(textNode(emoji));
      editor?.commands.focus();
    }
  };

  const appendAt = () => {
    if (textAreaRef.current && editor) {
      const beforeNode = editor.view.state.selection.$to.nodeBefore;
      const beforeText = beforeNode?.text;

      if (beforeText?.length && beforeText.charAt(beforeText.length - 1) !== ' ') {
        editor.commands.insertContent(textNode(' '));
      }
      editor.commands.insertContent(textNode('@'));
      editor.commands.focus();
    }
  };

  useEffect(() => {
    if (editor && autofocus) {
      editor.commands.focus(autofocus);
    }
  }, [editor, autofocus]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div ref={textContainerRef} className={containerClassName}>
      <div className={clsx('commentBox', styles.commentBox, className)}>
        <EditorContent
          editor={editor}
          className={styles.textarea}
          ref={textAreaRef}
          onKeyDown={(event) => {
            handleKeyDown(event.nativeEvent);
          }}
          rows={1}
        />
        <FileList
          files={selectedFiles}
          isInEditMode={isInEditMode}
          removeFile={removeFile}
          processedFiles={processedFiles}
        />
        {isInEditMode && !!existingAttachments.length && (
          <ViewFileList
            files={existingAttachments}
            isInEditMode
            onClick={removeExistingAttachment}
          />
        )}
        <input type="hidden" ref={fileChangeRef} />
        {suggestionsOpen && selectSuggestionHandler && (
          <MentionPopover
            isOpen={suggestionsOpen}
            selectSuggestion={selectSuggestionHandler}
            suggestions={filteredSuggestions}
            textAreaRef={textAreaRef}
            anchor={<span className={styles.popoverAnchor} />}
            containerRef={textContainerRef}
          />
        )}
        <div className={clsx(styles.actions, isInEditMode && styles.twoRows)}>
          <div className={styles.auxActions}>
            <FileUpload addFiles={addFiles} fileInputRef={fileInputRef} />
            <EmojiPicker
              onClick={appendEmoji}
              asTippy={emojiPickerAsTippy}
              containerRef={emojiPickerAsTippy ? textContainerRef : undefined}
            >
              <Button size="sm" variant="icon">
                <AddReaction size={20} />
              </Button>
            </EmojiPicker>
            <Button size="sm" variant="icon" onClick={appendAt}>
              <Mention size={20} />
            </Button>
          </div>
          <div className={styles.mainActions}>
            {!!onCancel && (
              <Button
                type="button"
                onClick={() => onCancel()}
                size="sm"
                variant="ghost"
              >
                Cancel
              </Button>
            )}
            {isInEditMode ? (
              <Button
                className={styles.saveButton}
                disabled={
                  isPosting ||
                  (watchContent.length === 0 && !watchFileChange) ||
                  !!remainingFileCount
                }
                onClick={handleSubmit(submit)}
                size="sm"
                variant="ghost"
              >
                Save
              </Button>
            ) : (
              <Button
                className={styles.submitButton}
                disabled={
                  isPosting || watchContent.length === 0 || !!remainingFileCount
                }
                isSquare={true}
                onClick={handleSubmit(submit)}
                size="sm"
                variant="ghost"
              >
                <SendComment />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function NewCommentTextBox({
  isLoading,
  mediaGroupId,
  onCancel,
  parentCommentId,
  placeholder,
  onComment,
  onCommentCreated,
}: {
  isLoading: boolean;
  mediaGroupId: string;
  onCancel?: () => void;
  parentCommentId?: string;
  placeholder?: string;
  onComment?: (invitable: ActionableInvitesInComment) => void;
  onCommentCreated?: ({ commentId }: { commentId: string }) => void;
}) {
  const { mutateAsync } = useMakeComment(mediaGroupId);

  const handleSubmit = async (
    content: string,
    document?: JSONContent,
    attachmentAssetIds?: string[]
  ) => {
    const { invitable, id, projectId } = await mutateAsync({
      content,
      parentId: parentCommentId,
      document,
      attachmentAssetIds,
    });

    if (projectId) {
      onComment?.({
        commentId: id,
        userIds: invitable,
        projectId,
      });
    }

    onCommentCreated?.({ commentId: id });
  };

  return (
    <CommentTextBox
      isLoading={isLoading}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      placeholder={placeholder}
    />
  );
}
