import { memo, useCallback } from 'react';

import { Icon16 } from '@spaceduck/icons';

import {
  handleTransformation,
  type Transformation,
} from '@detailsModal/tiptap/transformations';
import { MenuButton } from '../menu';
import type { NotesMenuProps } from '../NotesMenu';

const { BulletedList, CodeBlock, NumberedList, Quote, TodoList } = Icon16;

export const TextStyleMenu = memo((props: NotesMenuProps) => {
  const { editor } = props;

  const handleClick = useCallback(
    (transformation: Transformation) => {
      if (!editor) return null;

      handleTransformation(editor, transformation);
    },
    [editor]
  );

  if (!editor) return null;

  return (
    <>
      <MenuButton
        isActive={editor.isActive('bulletList')}
        onClick={() => handleClick('bulletedList')}
        tooltipContent="Bulleted list"
      >
        <BulletedList />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('orderedList')}
        onClick={() => handleClick('numberedList')}
        tooltipContent="Numbered list"
      >
        <NumberedList />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('taskList')}
        onClick={() => handleClick('todoList')}
        tooltipContent="Todo list"
      >
        <TodoList />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('codeBlock')}
        onClick={() => handleClick('codeBlock')}
        tooltipContent="Code block"
      >
        <CodeBlock />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('blockquote')}
        onClick={() => handleClick('quote')}
        tooltipContent="Quote"
      >
        <Quote />
      </MenuButton>
    </>
  );
});
