import type { Editor } from '@tiptap/core';
import { useCallback, useSyncExternalStore } from 'react';

export const useEditorText = (editor: Editor | null) => {
  const snapshot = useCallback(() => editor?.getText() ?? '', [editor]);

  const subscribe = useCallback(
    (callback: () => void) => {
      editor?.on('update', callback);
      return () => editor?.off('update', callback);
    },
    [editor]
  );

  return useSyncExternalStore(subscribe, snapshot);
};
