import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { EditorContent } from '@tiptap/react';
import { useShallow } from 'zustand/shallow';

import {
  type AiProvider,
  type Chat,
  type ChatMediaGroupSource,
  type ChatSessionMode,
  type MediaGroupDTO,
  providerModels,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { useTriggerMediaGroupProcessing } from '@api/ai';
import MediaGroupSourcePopup from '@components/ai/MediaGroupSourcePopover';
import {
  itemSourceOption,
  ResearchSourcesDropdown,
  sourceOptions,
  type SourceOption,
} from '@components/ai/ResearchSourcesDropdown';
import { useResearchAssistantEditor } from '@components/ai/Tiptap';
import {
  type SelectedSourceType,
  useSourcesStore,
} from '@components/ai/useSourcesStore';
import { textNode } from '@components/detailsModal/tiptap/utils';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import { eqSet } from '@utils/set';
import { SelectedSource } from './InlineSource';
import styles from './ChatMessageInput.module.scss';
import DropdownMenu, { DropdownMenuItem } from '../ui/DropdownMenu';

const { AI, StatusInfo } = Icon16;
const { At, SendComment } = Icon24;

type FilterContext =
  | {
      sourceType: ChatSessionMode;
      suggestionQuery: string;
      context: 'suggestion';
    }
  | {
      filterQuery: string;
      context: 'filter';
    }
  | {
      context: undefined;
    };

const extractFilterAndSuggestionQuery = (value: string): FilterContext => {
  let context: 'filter' | 'suggestion' | undefined;
  if (value.includes(':')) {
    context = 'suggestion';
  } else if (value.startsWith('@')) {
    context = 'filter';
  }

  const [filter, suggestionQuery] = value
    .replace('@', '')
    .split(':')
    .map((f) => f.trim());
  const filterParsed = sourceOptions.filter(
    (property) => property.label.toLowerCase() === filter?.toLowerCase()
  );

  if (filterParsed.length && filterParsed[0] && context === 'suggestion') {
    return {
      sourceType: filterParsed[0].mode,
      suggestionQuery: suggestionQuery ?? '',
      context,
    };
  }
  if (context === 'filter') {
    return {
      filterQuery: filter?.replace('-', '') ?? '',
      context,
    };
  }

  return {
    context: undefined,
  };
};

export default function ChatMessageInput({
  readOnly,
  onSubmit,
  showTools = false,
  fixedSource,
  projectId,
  currentChatSession,
  onChangeMode,
  onChangeProvider,
  provider,
  mode = 'library',
}: {
  mode?: ChatSessionMode;
  readOnly: boolean;
  onSubmit: (
    query: string,
    mediaGroups: ChatMediaGroupSource[]
  ) => PromiseLike<boolean | undefined>;
  provider?: AiProvider;
  onChangeProvider?: (provider: AiProvider) => void;
  showTools?: boolean;
  fixedSource?: SelectedSourceType;
  placeholder?: string;
  projectId?: string;
  currentChatSession?: Chat | null;
  onChangeMode?: (mode: ChatSessionMode) => void;
}) {
  const { readySources, setReadySources } = useSourcesStore(
    useShallow((state) => ({
      readySources: state.readySources,
      setReadySources: state.setReadySources,
    }))
  );
  const [selectedSource, setSelectedSource] = useState<SourceOption | undefined>(
    sourceOptions.filter((s) => s.mode === mode)[0]
  );
  const [filterContext, setFilterContext] = useState<FilterContext>();
  const inputField = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const sendButtonRef = useRef<HTMLButtonElement>(null);
  const [editorSources, setEditorSources] = useState<SelectedSourceType[]>([]);

  const placeholder = useMemo(() => {
    if (mode === 'direct' && fixedSource) {
      const directItem = fixedSource.label ? `'${fixedSource.label}'` : 'your item';
      return `Chat to ${directItem}...`;
    }
    if (currentChatSession) {
      return 'Ask a follow-up';
    }
    return (
      sourceOptions.filter((o) => o.mode === mode)[0]?.placeholder || 'Chat with AI'
    );
  }, [mode, fixedSource]);

  useEffect(() => {
    if (currentChatSession) {
      setEditorSources([]);
      setReadySources([]);
    }
  }, [currentChatSession]);

  const selectSource = useCallback(
    async (sourceLookup: SourceOption) => {
      if (!inputField.current) {
        return;
      }
      setSelectedSource(sourceLookup);
      onChangeMode?.(sourceLookup.mode);

      const placeholderOptions = editor?.extensionManager.extensions.find(
        (e) => e.name === 'placeholder'
      )?.options;
      if (placeholderOptions) {
        placeholderOptions.placeholder = sourceLookup.placeholder;
      }
      if (sourceLookup.mode === 'item') {
        const toInsert = sourceLookup.label
          .toLowerCase()
          .replace(inputValue.toLowerCase().replace('@', ''), '');
        editor?.commands.insertContent(textNode(`${toInsert}:`));
        return;
      }

      editor?.commands.clearContent();
    },
    [inputValue]
  );

  const { mutateAsync: triggerMediaGroupProcessing } = useTriggerMediaGroupProcessing();
  const selectMediaGroup = (mediaGroup: MediaGroupDTO) => {
    addEditorSources([{ id: mediaGroup.id, label: mediaGroup.label }]);
    if (!mediaGroup.isResearchAssistantReady) {
      triggerMediaGroupProcessing(mediaGroup.id);
    }
    editor?.commands.clearContent();
  };

  const pendingSources = useMemo(() => {
    const includedSourcesIds = new Set(readySources.map((source) => source.id));
    const editorSourcesIds = new Set(editorSources.map((source) => source.id));

    return !eqSet(includedSourcesIds, editorSourcesIds);
  }, [readySources, editorSources]);

  const isPending = pendingSources;

  useEffect(() => {
    if (!isPending) {
      setFilterContext(extractFilterAndSuggestionQuery(inputValue));
    }
  }, [inputValue, isPending]);

  const editor = useResearchAssistantEditor({
    onMentionUpdate: (value) => setInputValue(value),
    onSend: () => {
      sendButtonRef.current?.click();
      return true;
    },
    placeholder,
    currentChatSession,
  });

  const submitDisabled =
    (editor?.getText()?.trim()?.length ?? 0) < 3 ||
    isPending ||
    !!filterContext?.context;

  const handleSubmit = useCallback(async () => {
    if (submitDisabled || !editor) {
      return;
    }

    const text = editor.getText()?.trim() || '';
    if (text.length === 0 || isPending) {
      return;
    }
    const content = editor.getJSON();
    editor.commands.clearContent(true);
    let result: boolean | undefined;
    try {
      result = await onSubmit(text, readySources);
    } catch (error) {
      console.error('Chat message submission failed', error);
      editor.commands.setContent(content);
      return;
    }

    if (!(result ?? true)) {
      editor.commands.setContent(content);
    }
  }, [editor, readySources, isPending, submitDisabled, onSubmit]);

  const addEditorSources = useCallback((newSources: SelectedSourceType[]) => {
    setEditorSources((sources) => {
      const allSources = [...sources, ...newSources];
      return [...allSources].filter((s, idx) => {
        return allSources.findIndex((source) => source.id === s.id) === idx;
      });
    });
  }, []);

  const handleRemoveSource = useCallback((id: string | null) => {
    if (!id) return;

    setEditorSources((sources) => sources.filter((source) => source.id !== id));
  }, []);

  const handleSourceButtonClick = useCallback(() => {
    if (!editor) return;
    editor.commands.focus();

    const string = editor.state.selection.$from.nodeBefore?.textContent;

    if (!string || string.trim().length === 0 || /\s$/.test(string)) {
      editor.commands.insertContent(textNode('@'));
      return;
    }

    if (/.*\s@$/.test(string) || /^@$/.test(string)) {
      if (!inputValue) {
        setInputValue('@');
        setFilterContext(extractFilterAndSuggestionQuery('@'));
        return;
      }

      setFilterContext(extractFilterAndSuggestionQuery(inputValue));
      return;
    }

    editor.commands.insertContent(textNode(' @'));
  }, [editor]);

  return (
    <>
      <div className={styles.widget}>
        <div className={clsx(styles.inputBar, !showTools && styles.noPadding)}>
          <div className={styles.inputWrapper}>
            {!!editorSources.length && (
              <ScrollArea
                orientation="horizontal"
                style={{
                  maxWidth: '100%',
                }}
              >
                <div className={styles.sources}>
                  {editorSources.map(({ id, label }) => {
                    return (
                      <SelectedSource
                        id={id}
                        key={id}
                        label={label}
                        onDelete={() => handleRemoveSource(id)}
                      />
                    );
                  })}
                </div>
              </ScrollArea>
            )}
            <div className={styles.content}>
              <ScrollArea
                orientation="vertical"
                rootClassName={clsx(!showTools && styles.paddedScrollArea)}
                style={css({
                  '--width': '100%',
                  '--maxHeight': '30vh',
                })}
              >
                <EditorContent
                  editor={editor}
                  className={clsx(styles.input, !showTools && styles.hasSiblingSubmit)}
                  innerRef={inputField}
                  onKeyDown={() => editor?.commands.scrollIntoView()}
                />
                {!showTools && (
                  <Button
                    ref={sendButtonRef}
                    className={clsx(styles.submitButton, styles.inlineSubmit)}
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                    size="sm"
                    variant="ghost"
                    isSquare={true}
                  >
                    <SendComment />
                  </Button>
                )}
              </ScrollArea>
            </div>
            {!fixedSource && filterContext?.context === 'filter' && (
              <ResearchSourcesDropdown
                availableSources={
                  editorSources.length > 0 ? [itemSourceOption] : undefined
                }
                filter={filterContext.filterQuery}
                inputRef={inputField}
                setSelectedSource={selectSource}
                onDismiss={() => {
                  setFilterContext({ context: undefined });
                }}
              />
            )}
            {projectId &&
              !fixedSource &&
              filterContext?.context === 'suggestion' &&
              filterContext.sourceType === 'item' && (
                <MediaGroupSourcePopup
                  inputRef={inputField}
                  handleSelectItem={selectMediaGroup}
                  searchTitle={filterContext.suggestionQuery}
                  onEscapeKeyDown={() => {
                    editor?.commands.clearContent();
                  }}
                  excludeMediaGroupIds={editorSources
                    .map((mediaGroup) => mediaGroup.id)
                    .filter(exists)}
                  projectId={projectId}
                />
              )}
          </div>
        </div>
        {showTools && (
          <div className={styles.tools}>
            <div className={styles.sources}>
              {selectedSource && (
                <Button
                  className={styles.sourceButton}
                  disabled={readOnly || isPending}
                  onClick={handleSourceButtonClick}
                  size="md"
                  variant="ghost"
                  iconBefore={<At size={20} />}
                >
                  {selectedSource.label}
                </Button>
              )}
              {provider && (
                <DropdownMenu
                  triggerContent={
                    <Button
                      className={styles.sourceButton}
                      size="md"
                      variant="ghost"
                      iconBefore={<AI size={20} />}
                    >
                      {providerModels[provider]}
                    </Button>
                  }
                >
                  {(['open-ai', 'google', 'anthropic'] as AiProvider[]).map(
                    (provider) => (
                      <DropdownMenuItem
                        key={provider}
                        onSelect={() => {
                          onChangeProvider?.(provider);
                        }}
                      >
                        <div
                          className={clsx(styles.sourceButton, styles.choiceWithIcon)}
                        >
                          <AI size={20} />
                          {providerModels[provider]}
                        </div>
                      </DropdownMenuItem>
                    )
                  )}
                </DropdownMenu>
              )}
            </div>
            <Button
              ref={sendButtonRef}
              className={styles.submitButton}
              disabled={submitDisabled}
              onClick={handleSubmit}
              size="sm"
              variant="ghost"
              isSquare={true}
            >
              <SendComment />
            </Button>
          </div>
        )}
      </div>
      {!readOnly && (
        <span className={clsx(styles.pendingNotice, isPending && styles.active)}>
          <AlertBanner />
        </span>
      )}
    </>
  );
}

const AlertBanner = () => {
  return (
    <div className={styles.alertBanner}>
      <StatusInfo />
      <p>Working on your content. This may take a moment...</p>
    </div>
  );
};
