import type { MediaGroupDetailDTO } from '@spaceduck/api';

export type DetailsModelTab =
  | 'aiSettings'
  | 'comment'
  | 'info'
  | 'browseRepository'
  | 'references'
  | 'tableOfContent'
  | 'researchChat'
  | 'activity';

export type ContentViewTab =
  | 'audio'
  | 'embed'
  | 'iframe'
  | 'media'
  | 'pdf'
  | 'reading mode'
  | 'screenshot'
  | 'summary'
  | 'transcript'
  | 'video';

export const articleContentViews = [
  'screenshot',
  'reading mode',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const audioContentViews = [
  'audio',
  'transcript',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const bookmarkContentViews = [
  'screenshot',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const filmContentViews = [
  'embed',
  'screenshot',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const socialContentViews = [
  'screenshot',
  'embed',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const videoContentViews = [
  'media',
  'transcript',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const youtubeContentViews = [
  'video',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const wikiContentViews = [
  'screenshot',
  'iframe',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;

export type ArticleContentView = (typeof articleContentViews)[number];
export type AudioContentView = (typeof audioContentViews)[number];
export type BookmarkContentView = (typeof bookmarkContentViews)[number];
export type FilmContentView = (typeof filmContentViews)[number];
export type SocialContentView = (typeof socialContentViews)[number];
export type WikiContentView = (typeof wikiContentViews)[number];
export type VideoContentView = (typeof videoContentViews)[number];
export type YoutubeContentView = (typeof youtubeContentViews)[number];

export const getContentView = (mediaGroup: MediaGroupDetailDTO) => {
  const contentType = mediaGroup.contentType;

  switch (contentType) {
    case 'audio':
      if (mediaGroup.kind === 'bookmark') {
        return null;
      }
      return audioContentViews;
    case 'article':
      return articleContentViews;
    case 'bookmark':
      return bookmarkContentViews;
    case 'film':
      return filmContentViews;
    case 'video':
      if (mediaGroup.embed?.kind === 'youtube') {
        return youtubeContentViews;
      }
      return videoContentViews;
    case 'wiki':
      return wikiContentViews;
    default:
      return null;
  }
};
