import { useCallback, useMemo, useRef, useSyncExternalStore } from 'react';

const getTimestamp = () => new Date().getTime();

export type UseTypewriterParams = {
  isComplete?: boolean;
  text?: string;
  delay?: number;
  maxTotalTime?: number;
};

export const useTypewriter = ({
  isComplete,
  text,
  delay: _delay,
  maxTotalTime,
}: UseTypewriterParams) => {
  const offsetRef = useRef(0);

  const delay = useMemo(() => {
    const length = text?.length ?? 0;

    const target = _delay ?? 15;

    if (length === 0) {
      return target;
    }
    const maxDelay = (maxTotalTime ?? 3000) / length;
    return Math.min(maxDelay, target);
  }, [_delay, maxTotalTime, text]);

  const snapshot = useCallback(() => {
    const corpus = text ?? '';
    const offset = offsetRef.current;
    return offset < corpus.length ? corpus.slice(0, offset) : corpus;
  }, [text]);

  const subscribe = useCallback(
    (callback: () => void) => {
      if (isComplete || !text) {
        offsetRef.current = text?.length ?? 0;
        return () => {};
      }
      offsetRef.current = 0;
      const startedAt = getTimestamp();

      const intervalId = setInterval(() => {
        const delta = getTimestamp() - startedAt;
        const steps = Math.floor(delta / delay);
        offsetRef.current = Math.min(steps, text.length);
        callback();
        if (offsetRef.current >= text.length) {
          clearInterval(intervalId);
        }
      }, delay);
      return () => clearInterval(intervalId);
    },
    [isComplete, text, delay]
  );

  const typedText = useSyncExternalStore(subscribe, snapshot);

  const finishedTyping = !!isComplete || typedText === text;

  return { finishedTyping, typedText };
};
