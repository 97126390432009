import * as HeroIconSolid from '@heroicons/react/20/solid';
import type { ComponentProps } from 'react';
import clsx from 'clsx';

import type { SpaceColor } from '@/types/Colors';
import type { HeroIconName } from '@/types/Icons';
import styles from './HeroIcon.module.scss';

type HeroIconProps = {
  name: HeroIconName;
  spaceColor?: SpaceColor;
} & ComponentProps<typeof HeroIconSolid.AcademicCapIcon>;

export default function HeroIcon({
  name,
  spaceColor,
  className,
  ...props
}: HeroIconProps) {
  const IconComponent = HeroIconSolid[name];

  return (
    <IconComponent
      {...props}
      className={clsx(spaceColor && styles[spaceColor], className)}
    />
  );
}
