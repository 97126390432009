import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M2.25 2.25v20M2.25 12.25h4M18.25 12.25h4M6.25 12.25h12M2.25 8.25h4M2.25 4.25h4M18.25 8.25h4M18.25 4.25h4M18.25 20.25h4M18.25 16.25h4M2.25 20.25h4M2.25 16.25h4M22.25 2.25v20M18.25 2.25h-12v20h12z"
      />
    </svg>
  );
}
