import type { MediaGroupDetailDTO } from '@spaceduck/api';

type ViewName = 'media' | 'reading' | 'iframe' | 'embed' | 'summary' | 'transcription';

export const getSupportedViews = ({
  kind,
  contentType,
  embed,
}: Pick<MediaGroupDetailDTO, 'kind' | 'contentType' | 'embed'>) => {
  const views = new Set<ViewName>();

  if (kind === 'bookmark' && contentType !== 'video') {
    if (contentType !== 'audio') {
      views.add('media');
    }
    if (contentType === 'article') {
      views.add('reading');
    }
    if (contentType === 'wiki') {
      views.add('iframe');
    }
    if (contentType === 'social' || contentType === 'audio' || contentType === 'film') {
      views.add('embed');
    }
    if (contentType !== 'audio' && contentType !== 'film') {
      views.add('summary');
    }
  } else {
    if (canHaveTranscription({ kind, contentType })) {
      views.add('transcription');
    }

    if (embed?.kind === 'youtube') {
      views.add('embed');
      views.add('summary');
    } else {
      views.add('media');
    }
  }

  return views;
};

export const canHaveTranscription = ({
  kind,
  contentType,
}: Pick<MediaGroupDetailDTO, 'kind' | 'contentType'>) => {
  if (kind !== 'gallery') {
    return false;
  }
  if (contentType !== 'audio' && contentType !== 'video') {
    return false;
  }
  return true;
};

export const getDefaultView = (
  mediaGroup: Pick<MediaGroupDetailDTO, 'kind' | 'contentType' | 'embed'>
) => {
  const views = getSupportedViews(mediaGroup);
  if (views.has('embed')) {
    return 'embed';
  }
  if (views.has('reading')) {
    return 'reading';
  }
  return null;
};
