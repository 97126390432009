import { type Dispatch, useCallback, useEffect, useId, useState } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import clsx from 'clsx';
import AutoHeight from 'embla-carousel-auto-height';
import Fade from 'embla-carousel-fade';

import { Icon16 } from '@spaceduck/icons';

import { Header } from '@components/Header';
import createToast from '@utils/createToast';
import {
  ContinueButton,
  Dots,
  Frame,
  LoginLink,
  PlanSelection,
  ProfessionSelection,
  Slide,
} from './index';

import styles from './Wizard.module.scss';
import { useRefreshSession, useSession, useUserInfo } from '@/hooks/useAuth';
import { useCreateWorkspace } from '@/api/workspace';
import type { BillingInterval, CreateWorkspaceParams } from '@spaceduck/api';
import { attemptAsync } from '@spaceduck/utils';

const { StatusSuccess } = Icon16;

export default function OnboardingWizard() {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { align: 'center', containScroll: false },
    [AutoHeight(), Fade()]
  );

  const [industry, setIndustry] = useState<string>('');
  const [memberProfession, setMemberProfession] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [billingInterval, setBillingInterval] = useState<BillingInterval>('year');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [highlightEmptyFields, setHighlightEmptyFields] = useState(false);

  const goToSlide = (index: number) => {
    emblaApi?.scrollTo(index);
  };

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  const { mutateAsync: createWorkspace } = useCreateWorkspace();
  const user = useUserInfo();
  const handleSubmit = useCallback(async () => {
    setDisableSubmit(true);
    setHighlightEmptyFields(true);

    if (user?.isNew) {
      if (!(name && workspaceName)) {
        goToSlide(1);

        if (!name) {
          createToast({
            titleText: 'Name is required',
            bodyText: 'Name cannot be blank',
            iconVariant: 'danger',
          });
        } else {
          createToast({
            titleText: 'Workspace name is required',
            bodyText: 'Workspace name cannot be blank',
            iconVariant: 'danger',
          });
        }

        setDisableSubmit(false);
        return;
      }
    } else {
      if (!workspaceName) {
        goToSlide(1);

        createToast({
          titleText: 'Workspace name is required',
          bodyText: 'Workspace name cannot be blank',
          iconVariant: 'danger',
        });

        setDisableSubmit(false);
        return;
      }
    }

    if (!billingInterval) {
      goToSlide(2);
      setDisableSubmit(false);
      return;
    }

    const aiSettings: NonNullable<CreateWorkspaceParams['aiSettings']> = {};

    if (industry.trim()) {
      aiSettings.industry = industry.trim();
    }

    if (memberProfession.trim()) {
      aiSettings.memberProfession = memberProfession.trim();
    }

    const result = await attemptAsync(() =>
      createWorkspace({
        interval: billingInterval,
        label: workspaceName,
        plan: 'pro',
        aiSettings,
        userName: name,
      })
    );

    if (!result.success) {
      setDisableSubmit(false);
      createToast({
        iconVariant: 'warning',
        titleText: 'Workspace Creation Failed',
        bodyText:
          'Sorry! There was an error creating your workspace. Please try again in a moment',
      });
      return;
    }

    const response = result.data;

    if (response.kind === 'success') {
      window.location.href = response.paymentUrl;
    }
  }, [
    user,
    industry,
    memberProfession,
    name,
    workspaceName,
    billingInterval,
    goToSlide,
    setDisableSubmit,
    createWorkspace,
  ]);

  if (!user?.id) {
    return;
  }
  return (
    <>
      <div className={styles.carousel} ref={emblaRef}>
        <div className={styles.container}>
          <Step1
            onContinue={emblaApi?.scrollNext}
            industry={industry}
            setIndustry={setIndustry}
            memberProfession={memberProfession}
            setMemberProfession={setMemberProfession}
          />
          <Step2
            highlightEmptyFields={highlightEmptyFields}
            isExistingUser={!user.isNew}
            onContinue={emblaApi?.scrollNext}
            name={name}
            setName={setName}
            workspaceName={workspaceName}
            setWorkspaceName={setWorkspaceName}
          />
          <Step3
            onContinue={handleSubmit}
            billingInterval={billingInterval}
            setBillingInterval={setBillingInterval}
            disableSubmit={disableSubmit}
          />
        </div>
      </div>
      <Dots goToSlide={goToSlide} numberOfSlides={3} selectedIndex={selectedIndex} />
    </>
  );
}

const Step1 = (props: {
  onContinue?: () => void;
  industry?: string;
  setIndustry: Dispatch<React.SetStateAction<string>>;
  memberProfession?: string;
  setMemberProfession: Dispatch<React.SetStateAction<string>>;
}) => {
  const { onContinue, ...selectionProps } = props;

  return (
    <Slide>
      <Header>
        Your <span className={styles.highlight}>Profession</span>
      </Header>
      <Frame>
        <ProfessionSelection {...selectionProps} />
        <ContinueButton onClick={onContinue} />
      </Frame>
    </Slide>
  );
};

const Step2 = ({
  highlightEmptyFields,
  isExistingUser,
  name,
  onContinue,
  setName,
  setWorkspaceName,
  workspaceName,
}: {
  highlightEmptyFields: boolean;
  isExistingUser?: boolean;
  name: string;
  onContinue?: () => void;
  setName: Dispatch<React.SetStateAction<string>>;
  setWorkspaceName: Dispatch<React.SetStateAction<string>>;
  workspaceName: string;
}) => {
  const id = useId();

  return (
    <Slide>
      <Header>
        Name your <span className={styles.highlight}>Workspace</span>
      </Header>
      <Frame>
        {!isExistingUser && (
          <div
            className={clsx(
              styles.formControl,
              highlightEmptyFields && !name && styles.error
            )}
          >
            <label htmlFor={`name${id}`}>Name</label>
            <input
              id={`name${id}`}
              onChange={(ev) => setName(ev.currentTarget.value)}
              type="text"
              value={name}
            />
          </div>
        )}
        <div
          className={clsx(
            styles.formControl,
            highlightEmptyFields && !workspaceName && styles.error
          )}
        >
          <label htmlFor={`workspaceName${id}`}>Workspace name</label>
          <input
            id={`workspaceName${id}`}
            onChange={(ev) => setWorkspaceName(ev.currentTarget.value)}
            type="text"
            value={workspaceName}
          />
        </div>
        <ContinueButton className={styles.marginTop6} onClick={onContinue} />
      </Frame>
    </Slide>
  );
};

const Step3 = ({
  disableSubmit,
  onContinue,
  billingInterval,
  setBillingInterval,
}: {
  disableSubmit: boolean;
  onContinue?: () => void;
  billingInterval: BillingInterval;
  setBillingInterval: Dispatch<React.SetStateAction<BillingInterval>>;
}) => {
  const refreshSession = useRefreshSession();

  useEffect(() => {
    refreshSession();
  }, []);

  const session = useSession();
  if (session?.kind !== 'authenticated') {
    return null;
  }
  const trialUsed = session.user.trialUsed;
  return (
    <Slide>
      <Header>
        {trialUsed ? (
          <div>
            Choose the plan
            <br />
            <span className={styles.highlight}>for your new workspace</span>
          </div>
        ) : (
          <div>
            Choose the plan that will apply{' '}
            <span className={styles.highlight}>after your 7-day free trial</span>{' '}
          </div>
        )}
        <div className={styles.trialInfo}>
          <ul>
            {!trialUsed && (
              <li>
                <StatusSuccess />
                <span>7-day free trial</span>
              </li>
            )}
            <li>
              <StatusSuccess />
              <span>Cancel anytime</span>
            </li>
          </ul>
        </div>
      </Header>
      <Frame>
        <PlanSelection
          billingInterval={billingInterval}
          setBillingInterval={setBillingInterval}
        />
        <ContinueButton disabled={disableSubmit} onClick={onContinue}>
          {trialUsed ? 'Create your new workspace' : 'Start your free trial'}
        </ContinueButton>
        <p className={styles.clarification}>
          {trialUsed
            ? 'Your trial has already been used on another workspace'
            : "You won't be charged until the end of your 7-day trial."}
        </p>
      </Frame>
      <LoginLink />
    </Slide>
  );
};
