import type { FieldError } from 'react-hook-form';

import styles from './Form.module.scss';
import clsx from 'clsx';

export const Footer = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.footer}>{children}</div>;
};

export const FieldWrapper = ({
  children,
  error,
}: { children: React.ReactNode; error?: FieldError }) => {
  return (
    <div className={clsx(styles.fieldWrapper, error && styles.hasError)}>
      {children}
      {error?.message && <div className="errorMessage">{error.message}</div>}
    </div>
  );
};
