import clsx from 'clsx';

import { Icon12, Icon24 } from '@spaceduck/icons';

import type { MenuItem } from './Item';
import Tooltip from '@ui/Tooltip';
import styles from './Trigger.module.scss';

const { TinyDownArrow } = Icon12;
const { Check } = Icon24;

export const MenuTrigger = ({
  className,
  hasSubmenu,
  icon,
  isActive,
  label,
  showCheck,
  tooltip,
}: { className?: string; hasSubmenu?: boolean } & Pick<
  MenuItem,
  'isActive' | 'icon' | 'label' | 'showCheck' | 'tooltip'
>) => {
  if (!(icon || label)) return null;

  const trigger = (
    <div
      className={clsx(
        'trigger',
        styles.trigger,
        icon && styles.hasIcon,
        label && styles.hasLabel,
        hasSubmenu && styles.hasArrow,
        !hasSubmenu && showCheck && styles.hasActiveCheck,
        isActive && styles.isActive,
        className
      )}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.text}>{label}</span>}
      {hasSubmenu && (
        <span className={styles.arrow}>
          <TinyDownArrow />
        </span>
      )}
      {!hasSubmenu && showCheck && (
        <span className={styles.activeCheck}>
          <Check />
        </span>
      )}
    </div>
  );

  if (!tooltip) {
    return trigger;
  }

  return (
    <Tooltip content={tooltip} align="start">
      <span className={styles.tooltip}>{trigger}</span>
    </Tooltip>
  );
};
