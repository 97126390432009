import { Handle, Position } from '@xyflow/react';
import clsx from 'clsx';

import { useBoardStore } from '../hooks/useBoardStore';

import styles from './Handles.module.scss';

export type HandlesProps = {
  id: string;
  className?: string;
  isVisible?: boolean;
};
export const Handles = ({ id, className, isVisible = false }: HandlesProps) => {
  const isDraggingHandle = useBoardStore((state) => state.isDraggingHandle);

  const cls = clsx(
    styles.handle,
    isVisible && styles.isVisible,
    isDraggingHandle && styles.enlarge,
    className
  );

  return (
    <>
      <Handle
        id={`${id}--handle-source-top`}
        type="source"
        position={Position.Top}
        className={cls}
      />
      <Handle
        id={`${id}--handle-source-right`}
        type="source"
        position={Position.Right}
        className={cls}
      />
      <Handle
        id={`${id}--handle-source-bottom`}
        type="source"
        position={Position.Bottom}
        className={cls}
      />
      <Handle
        id={`${id}--handle-source-left`}
        type="source"
        position={Position.Left}
        className={cls}
      />
      <Handle
        id={`${id}--handle-target-top`}
        type="target"
        position={Position.Top}
        className={cls}
      />
      <Handle
        id={`${id}--handle-target-right`}
        type="target"
        position={Position.Right}
        className={cls}
      />
      <Handle
        id={`${id}--handle-target-bottom`}
        type="target"
        position={Position.Bottom}
        className={cls}
      />
      <Handle
        id={`${id}--handle-target-left`}
        type="target"
        position={Position.Left}
        className={cls}
      />
    </>
  );
};
