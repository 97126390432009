import { ConnectionLineType } from '@xyflow/react';
import { memo, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/shallow';

import { Icon16 } from '@spaceduck/icons';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import { type EdgeType, edgeTypes } from '@reactFlow/types/board';
import { MenuButton } from '../menu';
import type { EdgeManagementProps } from '../SideMenu';

const { ConnectorCurved, ConnectorElbowed, ConnectorStraight } = Icon16;

export const EdgeTypeMenu = memo((props: EdgeManagementProps) => {
  const { edges, selectedEdges, setEdges, patch } = props;

  const { defaultConnectionLineType, setDefaultConnectionLineType } = useBoardStore(
    useShallow((state) => ({
      defaultConnectionLineType: state.defaultConnectionLineType,
      setDefaultConnectionLineType: state.setDefaultConnectionLineType,
    }))
  );

  const activeType = useMemo(() => {
    if (selectedEdges.length === 1 && selectedEdges[0]) {
      const edge = edges.find((edge) => edge.id === selectedEdges[0]);
      if (edge?.data) return edge.data.type as `${ConnectionLineType}`;
    }

    return undefined;
  }, [edges, selectedEdges, defaultConnectionLineType]);

  const handleClick = useCallback(
    (type: EdgeType) => {
      if (!type) return;
      const storedType: `${ConnectionLineType}` = getConnectionType(type);
      setDefaultConnectionLineType(storedType);

      const updatedEdges = setEdges((edges) => {
        return edges.map((edge) => {
          if (!selectedEdges.includes(edge.id)) return edge;

          return {
            ...edge,
            data: { ...edge.data, type: storedType },
          };
        });
      });

      patch({ edges: updatedEdges });
    },
    [setEdges, selectedEdges, setDefaultConnectionLineType, getConnectionType, patch]
  );

  return (
    <>
      {edgeTypes.map((type) => {
        return (
          <TypeButton
            activeType={activeType}
            key={type}
            handleClick={handleClick}
            type={type}
          />
        );
      })}
    </>
  );
});

const TypeButton = memo(
  ({
    activeType,
    handleClick,
    type,
  }: {
    activeType?: `${ConnectionLineType}`;
    handleClick: (type: EdgeType) => void;
    type: EdgeType;
  }) => {
    const tooltipContent = useMemo(() => {
      return `Connection type ${type}`;
    }, [type]);

    const onClick = useCallback(() => {
      handleClick(type);
    }, [type, handleClick]);

    const isActive = useMemo(() => {
      return getConnectionType(type) === activeType;
    }, [activeType, type]);

    return (
      <MenuButton isActive={isActive} onClick={onClick} tooltipContent={tooltipContent}>
        <TypeIcon type={type} />
      </MenuButton>
    );
  }
);

const TypeIcon = memo(({ type }: { type: EdgeType }) => {
  switch (type) {
    case 'curved':
      return <ConnectorCurved />;
    case 'elbowed':
      return <ConnectorElbowed />;
    default:
      return <ConnectorStraight />;
  }
});

function getConnectionType(type: EdgeType): `${ConnectionLineType}` {
  switch (type) {
    case 'curved':
      return ConnectionLineType.Bezier;
    case 'elbowed':
      return ConnectionLineType.Step;
    default:
      return ConnectionLineType.Straight;
  }
}
