import type { z } from 'zod';
import { useCallback, useMemo, useSyncExternalStore } from 'react';

import { attempt, safeParseJSON } from '@spaceduck/utils';

type Transform<T> = (previousValue: T) => T;
type ValueOrTransform<T> = T | Transform<T>;
const isTransform = <T>(
  valueOrTransform: T | Transform<T>
): valueOrTransform is Transform<T> => typeof valueOrTransform === 'function';

/**
 * Generate a hook implementation for directly accessing local a storage value.
 * You should generate this at the module level, doing so in a component is an
 *  error.
 * The resulting hook behaves like `useState`, E.g.,
 * @example
 * const useName = createLocalStorageHook("name");
 *
 * const MyComponent = () =>
 *   const [value, setValue] = useName();
 *   return <p>Hello, {name ?? "User"}</p>
 * }
 *
 * @param key - name of the local storage key
 * @returns the hook function.
 */
export const createLocalStorageHook = (key: string) => {
  const subscribeEmitter = new EventTarget();

  const subscribe = (callback: () => void) => {
    const storageEventHandler = (event: StorageEvent) => {
      if (event.key === key) {
        callback();
      }
    };
    const customEventHandler = (event: CustomEventInit<{ key: string }>) => {
      if (event.detail?.key === key) {
        callback();
      }
    };
    window.addEventListener('storage', storageEventHandler);
    subscribeEmitter.addEventListener('localStorageUpdate', customEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
      subscribeEmitter.removeEventListener('localStorageUpdate', customEventHandler);
    };
  };

  const snapshot = () => window.localStorage.getItem(key);

  const resolveTransform = (
    valueOrTransform: ValueOrTransform<string | null>
  ): string | null => {
    if (isTransform(valueOrTransform)) {
      return valueOrTransform(snapshot());
    }
    return valueOrTransform;
  };

  const setValue = (newValueOrTransform: ValueOrTransform<string | null>): void => {
    const newValue = resolveTransform(newValueOrTransform);

    if (newValue === null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, newValue);
    }
    // storage events do not fire in the tab that they are initiated from, so
    //  we need to trigger it ourselves.
    subscribeEmitter.dispatchEvent(
      new CustomEvent('localStorageUpdate', { detail: { key } })
    );
  };

  return () => {
    const value = useSyncExternalStore(subscribe, snapshot);
    return [value, setValue] as const;
  };
};

type DeserializeContext<T> = {
  key: string;
  initial: T;
};

export type CreateSerdeLocalStorageHookOptions<T> = {
  key: string;
  initial: T;
  serialize: (value: T) => string | null;
  deserialize: (serialized: string, context: DeserializeContext<T>) => T;
};

/**
 * Generate a hook implementation for accessing localStorage content, with a
 *  serialize/deserialize workflow..
 * You should generate this at the module level, doing so in a component is an
 *  error.
 * The resulting hook behaves like `useState`, E.g.,
 * @example
 * type Options = { name: string }
 * const useOptions = createSerdeLocalStorageHook<Options | null>({
 *   key: "options",
 *   initial: null,
 *   serialize: JSON.stringify,
 *   deserialize: (value) => JSON.parse(value)
 * });
 *
 * const MyComponent = () =>
 *   const [options, setOptions] = useOptions();
 *   return <p>Hello, {options?.name ?? "User"}</p>
 * }
 *
 * For most use-cases, you will generally want to use `createZodLocalStorageHook`.
 *
 * @param key - name of the local storage key
 * @returns the hook function.
 */
export const createSerdeLocalStorageHook = <T>({
  key,
  initial,
  serialize,
  deserialize,
}: CreateSerdeLocalStorageHookOptions<T>) => {
  const useLocalStorage = createLocalStorageHook(key);

  const deserializeContext: DeserializeContext<T> = { key, initial };

  const parse = (serialized: string | null) => {
    if (serialized === null) {
      return initial;
    }
    const deserializeResult = attempt(() =>
      deserialize(serialized, deserializeContext)
    );

    if (!deserializeResult.success) {
      console.warn('Failed to deserialize localStorage value', {
        key,
        serialized,
        error: deserializeResult.error,
      });
      return initial;
    }

    return deserializeResult.data;
  };

  const wrapTransform = (
    newValueOrTransform: ValueOrTransform<T>
  ): ValueOrTransform<string | null> => {
    if (isTransform(newValueOrTransform)) {
      return (previousSerialized: string | null) => {
        const previous = parse(previousSerialized);
        const transformed = newValueOrTransform(previous);
        return serialize(transformed);
      };
    }
    return serialize(newValueOrTransform);
  };

  return () => {
    const [serializedValue, setSerializedValue] = useLocalStorage();
    const value = useMemo(() => parse(serializedValue), [serializedValue]);
    const setValue = useCallback(
      (valueOrTransform: ValueOrTransform<T>) =>
        setSerializedValue(wrapTransform(valueOrTransform)),
      [setSerializedValue]
    );
    return [value, setValue] as const;
  };
};

/**
 * Create a `deserialize` function compatible for use with
 * `createSerdeLocalStorageHook`.
 * Generally, you will want to use `createZodLocalStorageHook` instead of
 *  interacting with this directly, but this can be used if you need to
 *  pre-process the saved value before parsing.
 *
 * @example
 * const optionsSchema = z.object({ name: z.string() });
 * const optionsDeserializer = createDeserializerFromZodSchema(optionsSchema)
 * const useOptions = createSerdeLocalStorageHook({
 *   key: "options",
 *   initial: null,
 *   serialize: JSON.stringify,
 *   deserialize: (value, context) => {
 *     // Previous error may have saved empty string instead of null.
 *     return optionsDeserializer(value === "" ? null : value, context)
 *   }
 * })
 *
 * @param schema Zod schema to be used
 * @returns deserialize function
 */
export const createDeserializerFromZodSchema = <S extends z.ZodTypeAny = z.ZodNever>(
  schema: S
) => {
  return (serialized: string, context: DeserializeContext<z.infer<S>>): z.infer<S> => {
    const jsonResult = safeParseJSON(serialized);

    if (!jsonResult.success) {
      console.warn('Invalid JSON stored in localStorage', {
        key: context.key,
        serialized,
        error: jsonResult.error,
      });
      return context.initial;
    }

    const parseResult = schema.safeParse(jsonResult.data);

    if (!parseResult.success) {
      console.warn('Unknown schema stored in localStorage', {
        key: context.key,
        serialized,
        error: parseResult.error,
      });
      return context.initial;
    }

    return parseResult.data;
  };
};

const defaultSerializer = <T>(value: T): string | null =>
  value === null ? null : JSON.stringify(value);

type CreateZodLocalStorageOptions<S extends z.ZodTypeAny> = {
  key: string;
  initial: z.infer<S>;
  schema: S;
  serialize?: (value: z.infer<S>) => string | null;
};

/**
 * Generate a hook implementation for accessing localStorage content, with a
 *  zod-based serialize/deserialize workflow..
 * You should generate this at the module level, doing so in a component is an
 *  error.
 * The resulting hook behaves like `useState`, E.g.,
 * @example
 *
 * const optionsSchema = z.object({ name: z.string() });
 * const useOptions = createZodLocalStorageHook({
 *   key: "options",
 *   initial: null,
 *   schema: optionsSchema.nullable(),
 * })
 *
 * const MyComponent = () =>
 *   const [options, setOptions] = useOptions();
 *   return <p>Hello, {options?.name ?? "User"}</p>
 * }
 *
 * @param key - name of the local storage key
 * @returns the hook function.
 */
export const createZodLocalStorageHook = <S extends z.ZodTypeAny = z.ZodNever>({
  key,
  initial,
  schema,
  serialize = defaultSerializer,
}: CreateZodLocalStorageOptions<S>) => {
  const deserialize = createDeserializerFromZodSchema(schema);
  return createSerdeLocalStorageHook({ key, initial, serialize, deserialize });
};
