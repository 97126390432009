import { mediaGroupSearchFilterPropertySchema } from '@spaceduck/api';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';

import { isCapable } from '@/api/util';
import GhostTown from '@/components/GhostTown';
import { NewMediaGroupDropdown } from '@/components/NewMediaGroupDropdown';
import { knownErrors } from '@/const';
import useDeleteMediaGroupConfirmModal from '@/hooks/useDeleteMediaGroupConformModal';
import { useFileUploadWrapper } from '@/hooks/useFileUploadWrapper';
import {
  useBulkDeleteMediaGroups,
  useBulkTagMediaGroups,
  useCopyMediaGroup,
  useListMediaGroups,
} from '@api/mediaGroup';
import { useProject } from '@api/project';
import { BulkSelectionToolbar } from '@components/BulkSelectionToolbar';
import FileUploadWrapper from '@components/FileUploadWrapper';
import Head from '@components/Head';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import MediaGroupGrid from '@components/MediaGroupGrid';
import EmptyLibrary from '@components/projects/EmptyLibrary';
import Searchbar from '@components/Searchbar';
import { useBatchUpdate } from '@hooks/useBatchUpdate';
import useMoveMediaGroupConfirmModal from '@hooks/useMoveMediaGroupConfirmModal';
import { useLocalSearch } from '@hooks/useSearch';
import { useSelectedMediaGroups } from '@hooks/useSelectedMediaGroups';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useForceUpgradeModal } from '@ui/ForceUpgradeModal';
import styles from './Library.module.scss';
import sharedStyles from './Shared.module.scss';
import PageHeader from './common/PageHeader';
import ProjectMenu from './common/ProjectMenu';
import ProjectTabs from './common/ProjectTabs';
import TwoColumnContent, { useProjectLayoutStore } from './common/TwoColumnContent';
import { StarredMediaGroupCarousel } from './StarredMediaGroupCarousel';

export default function LibraryPage() {
  useForceUpgradeModal();
  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId: string }>().projectId!;
  const {
    data: project,
    error: projectError,
    isLoading: projectIsLoading,
  } = useProject(projectId);
  const canEditProject = isCapable('edit', project?.project.capabilities).capable;
  const { selectedMediaGroupIds, selectedMediaGroups, onSelected, clearSelected } =
    useSelectedMediaGroups();
  const drawerIsOpen = useProjectLayoutStore((state) => state.drawerIsOpen);
  const setCurrentMediaGroupPageCount = useDetailsModalStore(
    (state) => state.setCurrentMediaGroupPageCount
  );

  const {
    clearSearch,
    debouncedSearchValue,
    handleInputUpdate,
    handleSearchClick,
    mediaGroupFilters,
    searchInputValue,
    searchQueries,
    setIsLoading,
    handleBlur,
    handleFocus,
    handleKeyDown,
    removeSearchQuery,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    showAltPlaceholder,
    setSimilarToFilter,
  } = useLocalSearch();

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    excludeProjectLibraries: false,
    ...mediaGroupFilters,
    project: [projectId],
  });

  const searchProps = {
    isLoading,
    handleKeyDown,
    handleBlur,
    handleFocus,
    removeSearchQuery,
    searchInputValue,
    searchQueries,
    setSearchElementRef,
    setSearchQueries,
    showAltPlaceholder,
    setSearchInputValue,
    handleInputUpdate,
    clearSearch,
    mediaGroupFilters,
    excludeProjectLibraries: false,
  };

  const { mutateAsync: bulkCopyMediaGroups } = useCopyMediaGroup();
  const { mutateAsync: bulkTagMediaGroups } = useBulkTagMediaGroups();
  const { mutateAsync: bulkDeleteMediaGroups } = useBulkDeleteMediaGroups();
  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (ids: Set<string>) => {
      bulkDeleteMediaGroups(Array.from(ids));
    },
    onSuccess: () => {
      clearSelected();
    },
  });
  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal({
    onConfirm: clearSelected,
  });

  const { handleCategoryChange, handleStatusChange } = useBatchUpdate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({ workspaceId, projectId });

  const { ref, inView } = useInView();
  const mediaGroups = data?.pages?.flatMap((page) => page.mediaGroups) || [];
  const placeholder = project?.project.label
    ? `Search space ${project?.project.label}...`
    : 'Search space...';

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data?.pages?.length) {
      setCurrentMediaGroupPageCount(data.pages.length);
    }
  }, [data?.pages]);

  const noEntries =
    mediaGroups.length === 0 &&
    searchQueries.length === 0 &&
    !searchInputValue &&
    !debouncedSearchValue;

  if (projectError) {
    throw new Error(knownErrors.projectError, { cause: projectError });
  }

  if (!project?.project || projectIsLoading) {
    return (
      <div className={sharedStyles.loadingWrapper}>
        <LoadingPlaceholder />
      </div>
    );
  }

  return (
    <>
      <Head title={`${project.project?.label || 'Space'} Library`} />
      <PageHeader />
      <ProjectTabs cta={<NewMediaGroupDropdown />} />
      <TwoColumnContent
        className={clsx(sharedStyles.content)}
        contentToolbar={
          <div
            className={clsx(
              sharedStyles.searchMenu,
              selectedMediaGroupIds.size > 0 && sharedStyles.bulkSelectionEnabled
            )}
          >
            {selectedMediaGroupIds.size === 0 ? (
              <Searchbar
                alwaysFocused
                placeholder={placeholder}
                availableFilters={(
                  [...mediaGroupSearchFilterPropertySchema.options, 'date'] as const
                ).filter((f) => f !== 'project')}
                defaultFilters={{ project: [projectId] }}
                isLocalSearch
                localSearchProps={searchProps}
              />
            ) : (
              <BulkSelectionToolbar
                count={selectedMediaGroupIds.size}
                onCopyMediaGroup={(projectId) => {
                  bulkCopyMediaGroups({
                    mediaGroupIds: Array.from(selectedMediaGroupIds),
                    projectId,
                    mode: 'copy',
                  });
                  clearSelected();
                }}
                onMoveMediaGroup={(projectId) =>
                  moveMediaGroupsConfirmModal.open({
                    mediaGroupIds: Array.from(selectedMediaGroupIds),
                    projectId,
                  })
                }
                onAddTag={(tag) => {
                  bulkTagMediaGroups({
                    mediaGroupIds: Array.from(selectedMediaGroupIds),
                    tags: { add: [tag] },
                  });
                }}
                onDelete={() => {
                  openDeleteMediaGroupConfirmModal(selectedMediaGroupIds);
                }}
                onCancel={clearSelected}
                selectedMediaGroups={selectedMediaGroups}
                onUpdateStatus={(status) =>
                  handleStatusChange(selectedMediaGroups, status)
                }
                onUpdateCategory={(category) =>
                  handleCategoryChange(selectedMediaGroups, category)
                }
                currentProjectId={project.project.id}
              />
            )}
          </div>
        }
        sidebar={<ProjectMenu project={project.project} />}
      >
        {isLoading && (
          <div className={sharedStyles.spinnerContainer}>
            <LoadingPlaceholder />
          </div>
        )}
        {!isLoading && project.project && (
          <FileUploadWrapper className={styles.fileUploadWrapper}>
            {noEntries && <NoItems />}
            {!noEntries && (
              <>
                {!debouncedSearchValue && searchQueries.length === 0 && workspaceId && (
                  <StarredMediaGroupCarousel
                    workspaceId={workspaceId}
                    projectId={projectId}
                  />
                )}
                <MediaGroupGrid
                  breakpoints={
                    drawerIsOpen
                      ? {
                          sm: 1200,
                          md: 1441,
                          lg: 1600,
                          xl: 2000,
                        }
                      : undefined
                  }
                  isError={isError}
                  isFetchingNextPage={isFetchingNextPage}
                  isLoading={isLoading}
                  onSearchClick={handleSearchClick}
                  mediaGroups={mediaGroups}
                  noResults={
                    <GhostTown
                      {...(canEditProject
                        ? {
                            description: 'Add a new entry to your library!',
                            ctaText: 'New entry',
                            onCtaClick: () => fileInputRef?.current?.click(),
                          }
                        : {
                            description: '',
                          })}
                    />
                  }
                  onSelected={onSelected}
                  selected={selectedMediaGroupIds}
                  onShowSimilar={setSimilarToFilter}
                  showPinning
                  showCTACard
                />
                <input
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
                <div ref={ref} style={{ width: '100%', height: '10px' }}>
                  {/* Triggers page fetch when in view */}
                </div>
              </>
            )}
          </FileUploadWrapper>
        )}
      </TwoColumnContent>
    </>
  );
}

const NoItems = () => {
  return <EmptyLibrary />;
};
