import type { Editor } from '@tiptap/core';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { isProject, type MediaGroupDTO, type ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { useWorkspaceProjects } from '@api/workspace';
import MenuSearch from '@components/MenuSearch';
import { useMediaGroupTransfer } from '@hooks/useMediaGroupTransfer';
import { useMediaGroupPermissions } from '@hooks/usePermissions';
import { SpaceIconOrProjectMode } from '@icons/SpaceIcon';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { type Theme, ThemeContext } from '@themes/Theme';
import Button from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu, Separator, TriggerButton } from '@ui/DropdownMenu';
import dropdownMenuStyles from '@ui/DropdownMenu.module.scss';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getTagHTML } from '@utils/dom';
import { intoFilenameLike } from '@utils/files';
import { htmlToMarkdown } from '@utils/markdown';
import { downloadUrl } from '@utils/download';
import { stringContains } from '@utils/string';
import { activeProjectKeys } from '@/const';
import { DetailsModalTooltip } from './comments/DetailsModalTooltip';

const { Drafts, Move } = Icon16;
const { Copy, DownArrow, Document, Download, Link, Menu, PDF, Right, TrashCan } =
  Icon24;

const MENU_ITEM_COPY_LINK: ContextMenuItemProps = {
  content: <TriggerButton iconBefore={<Link size={20} />}>Copy link</TriggerButton>,
  onClick: () => copyUrlToClipboard(),
};

const SEPARATOR: ContextMenuItemProps = {
  content: <Separator color={1} />,
  isSeparator: true,
};

export default function DetailsModalActionsDropdown({
  editor,
  mediaGroupId,
}: {
  editor?: Editor | null;
  mediaGroupId: string;
}) {
  const isDraggingOnBoard = useDetailsModalStore((state) => state.isDraggingOnBoard);
  const { data, status } = useMediaGroupDetail(mediaGroupId, {
    enabled: !isDraggingOnBoard,
  });

  if (status !== 'success') {
    return null;
  }

  return (
    <DetailsModalActionsDropdownImpl mediaGroup={data.mediaGroup} editor={editor} />
  );
}

const DetailsModalActionsDropdownImpl = ({
  editor,
  mediaGroup,
}: {
  editor?: Editor | null;
  mediaGroup: MediaGroupDTO;
}) => {
  const mediaGroupId = mediaGroup.id;
  const projectId = mediaGroup.project?.id ?? null;

  const { canEdit } = useMediaGroupPermissions(mediaGroup);
  const { data: projectsData } = useWorkspaceProjects(mediaGroup.workspace.id ?? null, {
    status: activeProjectKeys,
    flattenStacks: true,
  });
  const projects = projectsData?.projects ?? [];
  const { copy, move, delete: deleteMG } = useMediaGroupTransfer(mediaGroup);
  const { setTemporaryTheme } = useContext(ThemeContext);
  const [projectFilterText, setProjectFilterText] = useState('');

  const handleThemeChange = useCallback(
    (theme: Theme | null) => {
      if (!setTemporaryTheme) return;
      setTemporaryTheme(theme);
    },
    [setTemporaryTheme]
  );

  const handleInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setProjectFilterText(ev.target.value);
  }, []);

  const handleInputKeyDown = useCallback((ev: React.KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      ev.stopPropagation();
    }
  }, []);

  const beforePrint = useCallback(async () => {
    await Promise.resolve();
    handleThemeChange('light');
  }, [handleThemeChange]);

  const afterPrint = useCallback(async () => {
    await Promise.resolve();
    handleThemeChange(null);
  }, [handleThemeChange]);

  useEffect(() => {
    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);

    () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterPrint', afterPrint);
    };
  }, [setTemporaryTheme]);

  const otherProjects = useMemo(
    () =>
      projectId
        ? projects
            .filter(isProject)
            .filter((workspaceProject) => workspaceProject.id !== projectId)
        : projects,
    [projectId, projects]
  );

  const listedProjects = useMemo(() => {
    return (
      otherProjects.filter(isProject).filter(({ label, id }) => {
        return (
          stringContains(label, projectFilterText, { ignoreCase: true }) &&
          id !== mediaGroup.project?.id
        );
      }) ?? []
    );
  }, [mediaGroup, otherProjects, projectFilterText, stringContains]);

  const menuItemCopy = useMemo(
    (): ContextMenuItemProps => ({
      content: (
        <TriggerButton iconBefore={<Copy size={16} />} iconAfter={<Right />}>
          Copy to...
        </TriggerButton>
      ),
      subMenu: [
        {
          content: (
            <MenuSearch
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Search spaces"
              value={projectFilterText}
            />
          ),
          isMenuItem: false,
          isSticky: true,
        },
        ...makeAddToProjectMenu({
          projects: listedProjects,
          onAddToProject: (projectId: string) => {
            copy({ mediaGroupId, projectId });
          },
          onAddToRepository: () => {
            copy({ mediaGroupId });
          },
        }),
      ],
    }),
    [
      handleInputChange,
      handleInputKeyDown,
      listedProjects,
      mediaGroupId,
      otherProjects,
      copy,
      projectFilterText,
    ]
  );

  const menuItemMove = useMemo(
    (): ContextMenuItemProps => ({
      content: (
        <TriggerButton iconBefore={<Move size={20} />} iconAfter={<Right />}>
          Move to...
        </TriggerButton>
      ),
      subMenu: [
        {
          content: (
            <MenuSearch
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Search spaces"
              value={projectFilterText}
            />
          ),
          isMenuItem: false,
          isSticky: true,
        },
        ...makeAddToProjectMenu({
          projects: listedProjects,
          onAddToProject: (projectId: string) => {
            move({ mediaGroupIds: [mediaGroupId], projectId });
          },
          onAddToRepository:
            (projectId &&
              (() => {
                move({
                  mediaGroupIds: [mediaGroupId],
                });
              })) ||
            undefined,
        }),
      ],
    }),
    [
      handleInputChange,
      handleInputKeyDown,
      listedProjects,
      mediaGroupId,
      projectFilterText,
      projectId,
      otherProjects,
      move,
    ]
  );

  const originalAssetUrl = mediaGroup.media?.originalAssetUrl ?? null;
  const processedAssetUrl = mediaGroup.media?.assetUrl ?? null;

  const menuItemDownload: ContextMenuItemProps | null = useMemo(
    () =>
      originalAssetUrl || processedAssetUrl
        ? {
            content: (
              <TriggerButton iconBefore={<Download size={20} />} iconAfter={<Right />}>
                Download
              </TriggerButton>
            ),
            width: 212,
            subMenu: [
              {
                content: (
                  <TriggerButton
                    iconBefore={<Download size={20} />}
                    onClick={() => downloadUrl(originalAssetUrl || processedAssetUrl!)}
                  >
                    Download {originalAssetUrl && 'original'}
                  </TriggerButton>
                ),
              },
            ],
          }
        : null,
    [originalAssetUrl]
  );

  const menuItemConvert: ContextMenuItemProps[] = useMemo(() => {
    if (mediaGroup.contentType !== 'document' || !editor) return [];

    return [
      SEPARATOR,
      {
        content: (
          <TriggerButton iconBefore={<DownArrow size={20} />} iconAfter={<Right />}>
            Export as...
          </TriggerButton>
        ),
        width: 212,
        subMenu: [
          {
            content: (
              <TriggerButton
                iconBefore={<PDF size={20} />}
                onClick={async () => {
                  // Calling print() is handled differently from user action
                  await beforePrint();
                  setTimeout(() => {
                    print();
                  }, 0);
                }}
              >
                PDF
              </TriggerButton>
            ),
          },
          {
            content: (
              <TriggerButton
                iconBefore={<Document size={20} />}
                onClick={async () => {
                  const html = editor.getHTML();
                  const label = getTagHTML('h1', mediaGroup.label);
                  const res = await htmlToMarkdown(`${label}\n${html}`);
                  const link = document.createElement('a');
                  const file = new Blob([res], { type: 'text/markdown' });
                  const basename = intoFilenameLike(mediaGroup.label) || 'document';
                  link.href = URL.createObjectURL(file);
                  link.download = `${basename}.md`;
                  link.click();
                  URL.revokeObjectURL(link.href);
                }}
              >
                Markdown
              </TriggerButton>
            ),
          },
        ],
      },
    ];
  }, [mediaGroup.contentType, mediaGroup.label, editor]);

  const viewersMenuItem: ContextMenuItemProps[] = useMemo(
    () => [MENU_ITEM_COPY_LINK, SEPARATOR, menuItemDownload].filter(exists),
    [menuItemDownload]
  );

  const editorsMenuItems: ContextMenuItemProps[] = useMemo(
    () =>
      [
        MENU_ITEM_COPY_LINK,
        ...menuItemConvert,
        ...(otherProjects.length ? [SEPARATOR, menuItemCopy, menuItemMove] : []),
        SEPARATOR,
        menuItemDownload,
        {
          content: (
            <TriggerButton iconBefore={<TrashCan size={20} />}>Delete</TriggerButton>
          ),
          onClick: () => deleteMG(mediaGroup.id),
        },
      ].filter(exists),
    [
      menuItemCopy,
      menuItemConvert,
      menuItemMove,
      menuItemDownload,
      mediaGroup,
      deleteMG,
    ]
  );

  return (
    <RecursiveDropdownMenu
      isUnstyled={false}
      items={canEdit ? editorsMenuItems : viewersMenuItem}
      dropdownMenuProps={{
        width: 240,
        isPadded: true,
        align: 'end',
      }}
      showIndicator={false}
      dropdownMenuItemClassName={dropdownMenuStyles.menuItem}
    >
      <span>
        <DetailsModalTooltip content="Options" size="md" variant="secondary">
          <Button variant="icon">
            <Menu size={20} />
          </Button>
        </DetailsModalTooltip>
      </span>
    </RecursiveDropdownMenu>
  );
};

const makeAddToProjectMenu = ({
  projects,
  onAddToProject,
  onAddToRepository,
}: {
  projects: ProjectDTO[];
  onAddToProject: (projectId: string) => void;
  onAddToRepository?: () => void;
}): ContextMenuItemProps[] => {
  // Prevent hovering on menu item from passing focus
  // Prevent accidental selection when menu height changes
  const preventDefaultAndPropagation = (
    ev: Pick<Event, 'preventDefault' | 'stopPropagation'>
  ) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const addToRepositoryOption: ContextMenuItemProps[] = [];
  if (onAddToRepository) {
    addToRepositoryOption.push({
      content: <TriggerButton iconBefore={<Drafts size={20} />}>Drafts</TriggerButton>,
      onClick: onAddToRepository,
      contextMenuItemProps: {
        onPointerLeave: preventDefaultAndPropagation,
        onPointerMove: preventDefaultAndPropagation,
      },
    });
  }
  const projectOptions: ContextMenuItemProps[] = projects.map((project) => ({
    content: (
      <TriggerButton
        iconBefore={<SpaceIconOrProjectMode project={project} size={20} />}
        truncate
      >
        {project.label}
      </TriggerButton>
    ),
    onClick: () => {
      onAddToProject?.(project.id);
    },
    contextMenuItemProps: {
      onPointerLeave: preventDefaultAndPropagation,
      onPointerMove: preventDefaultAndPropagation,
    },
  }));

  return [...addToRepositoryOption, ...projectOptions];
};
