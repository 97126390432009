import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useState } from 'react';
import { useParams } from 'react-router';

import {
  useBulkDeleteMediaGroups,
  useBulkTagMediaGroups,
  useCopyMediaGroup,
} from '@/api/mediaGroup';
import { BulkSelectionToolbar } from '@/components/BulkSelectionToolbar';
import Button from '@/components/ui/Button';
import { useDeleteConfirmModal } from '@/components/ui/ConfirmModal';
import DropdownMenu, { DropdownMenuItem } from '@/components/ui/DropdownMenu';
import { useForceUpgradeModal } from '@/components/ui/ForceUpgradeModal';
import { useSpaceWorkflowModal } from '@/components/ui/SpaceWorkflowModal';
import { knownErrors } from '@/const';
import { useBatchUpdate } from '@/hooks/useBatchUpdate';
import useMoveMediaGroupConfirmModal from '@/hooks/useMoveMediaGroupConfirmModal';
import { useNavigateWithState } from '@/hooks/useNavigateWithState';
import { useSelectedMediaGroups } from '@/hooks/useSelectedMediaGroups';
import { urlFor } from '@/urls';
import { useProject } from '@api/project';
import Head from '@components/Head';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import type { MediaGroupDTO, MediaGroupSortOption } from '@spaceduck/api';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import styles from './Progress.module.scss';
import PageHeader from './common/PageHeader';
import ProjectTabs from './common/ProjectTabs';
import TwoColumnContent from './common/TwoColumnContent';
import { ProgressKanban } from './progress/Kanban';
import { ProgressList } from './progress/List';
import { NewMediaGroupDropdown } from '@/components/NewMediaGroupDropdown';
import ProjectMenu from './common/ProjectMenu';

const { Workflow, Sort, DownArrow, ListView, KanbanView } = Icon16;

type ProgressTab = 'list' | 'kanban';

export default function ProgressPage({ active }: { active: ProgressTab }) {
  useForceUpgradeModal();
  const projectId = useParams<{ projectId: string }>().projectId!;
  const {
    data: projectData,
    error: projectError,
    isLoading: projectIsLoading,
  } = useProject(projectId);
  const { selectedMediaGroupIds, onSelected, clearSelected, selectedMediaGroups } =
    useSelectedMediaGroups();
  const { project } = projectData || {};

  const navigateWithState = useNavigateWithState();
  const [sortBy, setSortBy] = useState<MediaGroupSortOption>('newest');
  const setActiveTab = (active: ProgressTab) => {
    if (active === 'kanban') {
      navigateWithState(urlFor('spaceProgressKanban', { projectId }));
    } else {
      navigateWithState(urlFor('spaceProgressList', { projectId }));
    }
  };
  if (projectError) {
    throw new Error(knownErrors.projectError, { cause: projectError });
  }

  if (!projectData || projectIsLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingPlaceholder />
      </div>
    );
  }

  if (!project) {
    // TODO: Handle no project state
    return null;
  }

  return (
    <>
      <Head title={`${project.label || 'Space'} Overview`} />
      <PageHeader />
      <ProjectTabs cta={<NewMediaGroupDropdown />} />
      <TwoColumnContent
        className={clsx(styles.content)}
        contentToolbar={
          <Toolbar
            selectedMediaGroupIds={selectedMediaGroupIds}
            projectId={projectId}
            clearSelected={clearSelected}
            selectedMediaGroups={selectedMediaGroups}
            activeTab={active}
            setActiveTab={setActiveTab}
            onSortByChange={setSortBy}
          />
        }
        sidebar={<ProjectMenu project={projectData.project} />}
      >
        {active === 'list' && (
          <ProgressList
            onSelected={onSelected}
            selectedMediaGroupIds={selectedMediaGroupIds}
            projectId={project.id}
            sortBy={sortBy}
          />
        )}
        {active === 'kanban' && (
          <ProgressKanban projectId={project.id} sortBy={sortBy} />
        )}
      </TwoColumnContent>
    </>
  );
}

type ToolbarProperties = {
  projectId: string;
  selectedMediaGroupIds: Set<string>;
  clearSelected: () => void;
  selectedMediaGroups: Set<MediaGroupDTO>;
  activeTab: ProgressTab;
  setActiveTab: (tab: ProgressTab) => void;
  onSortByChange: (sortBy: MediaGroupSortOption) => void;
};

const Toolbar = ({
  projectId,
  selectedMediaGroupIds,
  clearSelected,
  selectedMediaGroups,
  activeTab,
  setActiveTab,
  onSortByChange,
}: ToolbarProperties) => {
  const { open: openManageWorkflowModal } = useSpaceWorkflowModal({
    projectId,
  });
  const { mutateAsync: bulkCopyMediaGroups } = useCopyMediaGroup();
  const { mutateAsync: bulkTagMediaGroups } = useBulkTagMediaGroups();
  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal({
    onConfirm: clearSelected,
  });
  const { mutateAsync: bulkDeleteMediaGroups } = useBulkDeleteMediaGroups();
  const deleteMediaGroupsConfirmModal = useDeleteConfirmModal({
    title: 'Delete cards',
    subtitle: 'This action cannot be undone. Your cards will be permanently deleted.',
    confirmText: 'Yes, delete cards',
    onConfirm: async (ids: Set<string>) => {
      bulkDeleteMediaGroups(Array.from(ids));
      clearSelected();
    },
  });
  const { handleCategoryChange, handleStatusChange } = useBatchUpdate();
  const [sortBy, setSortBy] = useState<MediaGroupSortOption>('newest');
  const filters: Array<{
    label: string;
    value: MediaGroupSortOption;
  }> = [
    { label: 'Newest', value: 'newest' },
    { label: 'Oldest', value: 'oldest' },
    { label: 'Name', value: 'name' },
  ];
  return (
    <>
      {!selectedMediaGroupIds.size && (
        <div className={styles.toolbar}>
          <div>
            <DropdownMenu
              triggerContent={
                <Button
                  className={styles.sortButton}
                  variant="outlined"
                  iconBefore={<Sort size={16} />}
                  iconAfter={<DownArrow />}
                  size="sm"
                >
                  <span>Sort by: {sortBy}</span>
                </Button>
              }
              className={styles.sortMenu}
            >
              {filters.map((menuItem) => (
                <DropdownMenuItem
                  key={menuItem.value}
                  onSelect={() => {
                    setSortBy(menuItem.value);
                    onSortByChange(menuItem.value);
                  }}
                >
                  {menuItem.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          </div>
          <div className={styles.toolbarGroup}>
            <TabButtonWrapper className={styles.listModeSelector} variant="dark">
              <TabButton
                iconBefore={<ListView />}
                onClick={() => setActiveTab('list')}
                size="sm"
                isActive={activeTab === 'list'}
                variant="dark"
              >
                List
              </TabButton>
              <TabButton
                iconBefore={<KanbanView />}
                onClick={() => setActiveTab('kanban')}
                size="sm"
                isActive={activeTab === 'kanban'}
                variant="dark"
              >
                Kanban
              </TabButton>
            </TabButtonWrapper>
            <Button
              variant="outlined"
              size="sm"
              iconBefore={<Workflow />}
              onClick={openManageWorkflowModal}
            >
              Edit workflow
            </Button>
          </div>
        </div>
      )}
      {!!selectedMediaGroupIds.size && (
        <div>
          <BulkSelectionToolbar
            className={styles.bulkSelectionToolbar}
            count={selectedMediaGroupIds.size}
            onCopyMediaGroup={(projectId) => {
              bulkCopyMediaGroups({
                mediaGroupIds: Array.from(selectedMediaGroupIds),
                projectId,
                mode: 'copy',
              });
              clearSelected();
            }}
            onMoveMediaGroup={(projectId) =>
              moveMediaGroupsConfirmModal.open({
                mediaGroupIds: Array.from(selectedMediaGroupIds),
                projectId,
              })
            }
            onAddTag={(tag) => {
              bulkTagMediaGroups({
                mediaGroupIds: Array.from(selectedMediaGroupIds),
                tags: { add: [tag] },
              });
              clearSelected();
            }}
            onDelete={() => {
              deleteMediaGroupsConfirmModal.open(selectedMediaGroupIds);
            }}
            onCancel={clearSelected}
            selectedMediaGroups={selectedMediaGroups}
            onUpdateStatus={(status) => {
              handleStatusChange(selectedMediaGroups, status);
              clearSelected();
            }}
            onUpdateCategory={(category) => {
              handleCategoryChange(selectedMediaGroups, category);
              clearSelected();
            }}
            currentProjectId={projectId}
          />
        </div>
      )}
    </>
  );
};
