import { memo, useCallback, useEffect, useState } from 'react';

import { baseTextColors, type TextColor, textColors } from '@reactFlow/types/colors';
import { TextSwatchMenu } from '../menu';
import type { NotesMenuProps } from '../NotesMenu';

export const TextColorMenu = memo((props: NotesMenuProps) => {
  const { editor } = props;
  const [activeColor, setActiveColor] = useState<TextColor>(() => {
    if (!editor) return 'default';

    const currentColor = editor.getAttributes('textStyle').color;
    const activeColor = baseTextColors.find(
      (baseTextColor) => textColors[baseTextColor] === currentColor
    );

    return activeColor ?? 'default';
  });

  useEffect(() => {
    if (!editor) return;

    const currentColor = editor.getAttributes('textStyle').color;
    const colorName = baseTextColors.find(
      (baseTextColor) => textColors[baseTextColor] === currentColor
    );

    setActiveColor(colorName ?? 'default');
  }, [editor?.getAttributes('textStyle').color]);

  const handleClick = useCallback(
    (_color: string) => {
      if (!_color || !editor) return;
      const color = _color as TextColor;

      // Mask delay of editor
      setActiveColor(color);
      editor.chain().focus().setColor(textColors[color]).run();
    },
    [editor]
  );

  if (!editor) return null;

  return (
    <TextSwatchMenu
      active={activeColor}
      colors={baseTextColors}
      onClick={handleClick}
    />
  );
});
