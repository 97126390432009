import { useId, useMemo } from 'react';
import type { Instance } from 'tippy.js';
import clsx from 'clsx';
import Tippy from '@tippyjs/react';

import { Icon12 } from '@spaceduck/icons';

import { DetailsModalTooltip } from '@detailsModal/comments/DetailsModalTooltip';
import Button from '@ui/Button';
import styles from './HorizontalMenu.module.scss';

const { TinyDownArrow } = Icon12;

export type MenuItemProps = {
  id?: string;
  rootId?: string;
  title: string;
  shortKeys?: string[];
  buttonIcon: React.ReactNode;
  subMenu?: MenuItemProps[];
  onClick?: (ev?: React.MouseEvent) => void;
  isActive?: boolean;
  className?: string;
  shouldHide?: boolean;
  showSeparatorAfter?: boolean;
};

export type MenuButtonProps = Pick<
  MenuItemProps,
  'title' | 'shortKeys' | 'buttonIcon' | 'onClick' | 'isActive'
> & {
  className?: string;
  hasSubmenu: boolean;
};

export default function HorizontalMenu({ menu }: { menu: MenuItemProps[] }) {
  const id = useId();

  const menuItems = useMemo(
    () =>
      menu.map((menuItem) => (
        <MenuItem {...menuItem} key={menuItem.title} id={`tippy${id}`} />
      )),
    [menu]
  );

  return <div className={styles.menu}>{menuItems}</div>;
}

const MenuItem = (props: MenuItemProps) => {
  const {
    className,
    id,
    rootId,
    shouldHide,
    showSeparatorAfter,
    subMenu,
    ...buttonProps
  } = props;
  const ariaUtils = {
    onShow: (instance: Instance) =>
      instance.reference.setAttribute('aria-expanded', 'true'),
    onHide: (instance: Instance) =>
      instance.reference.setAttribute('aria-expanded', 'false'),
  };

  const onClick = () => {
    if (rootId) {
      const el = document.getElementById(rootId);

      if (el && '_tippy' in el) {
        (el._tippy as Instance).hide();
      }
    }
  };

  const submenuContent = useMemo(() => {
    if (!subMenu?.length) {
      return null;
    }
    return (
      <div className={clsx(styles.menu, className)}>
        {subMenu.map((subMenuItem) => (
          <MenuItem
            {...subMenuItem}
            key={subMenuItem.title}
            rootId={id || rootId}
            shouldHide={!subMenuItem.subMenu?.length}
          />
        ))}
      </div>
    );
  }, [subMenu, id, rootId]);

  if (submenuContent) {
    return (
      <>
        <span>
          <Tippy
            {...ariaUtils}
            content={submenuContent}
            interactive={true}
            onShown={(instance: Instance) => {
              instance.popper
                .querySelectorAll('button.isLeaf')
                .forEach((button) => button.addEventListener('click', onClick));
            }}
            onHidden={(instance: Instance) => {
              instance.popper
                .querySelectorAll('button.isLeaf')
                .forEach((button) => button.removeEventListener('click', onClick));
            }}
            trigger="click"
          >
            <span id={id}>
              <MenuButton {...buttonProps} hasSubmenu={true} />
            </span>
          </Tippy>
        </span>
        {showSeparatorAfter && <div className={styles.separator} />}
      </>
    );
  }

  return (
    <>
      <span>
        <MenuButton {...buttonProps} className="isLeaf" hasSubmenu={false} />
      </span>
      {showSeparatorAfter && <div className={styles.separator} />}
    </>
  );
};

const MenuButton = ({
  buttonIcon,
  className,
  isActive,
  onClick,
  shortKeys,
  hasSubmenu,
  title,
}: MenuButtonProps) => {
  return (
    <DetailsModalTooltip content={title} shortKeys={shortKeys} variant="tertiary">
      <Button
        className={clsx(styles.button, isActive && styles.active, className)}
        size="sm"
        type="button"
        variant="menu"
        iconAfter={hasSubmenu ? <TinyDownArrow /> : undefined}
        onClick={onClick}
      >
        {buttonIcon}
      </Button>
    </DetailsModalTooltip>
  );
};
