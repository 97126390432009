import { Icon12, Icon16 } from '@spaceduck/icons';
import { Node } from '@tiptap/core';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import { useEffect, useState } from 'react';

import { useExtractMetaAttributes } from '@hooks/useExtractMetaAttributes';
import styles from './MediaCard.module.scss';

type CardProps = {
  description?: string;
  favIcon?: string;
  href: string;
  image?: string;
  showPreview?: string;
  title?: string;
};

const { OpenNewWindow } = Icon12;
const { Links } = Icon16;

export default Node.create({
  name: 'media-card',
  priority: 1000,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      'data-href': {
        default: null,
      },
      'data-target': {
        default: null,
      },
      'data-content': {
        default: null,
      },
      'data-show-preview': {
        default: null,
      },
      'data-image': {
        default: null,
      },
      'data-title': {
        default: null,
      },
      'data-description': {
        default: null,
      },
      'data-icon': {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'media-card',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['media-card', mergeAttributes(HTMLAttributes)];
  },
});

const Component = (props: NodeViewRendererProps) => {
  const href: string = props.node.attrs['data-href'];
  const [image, setImage] = useState(props.node.attrs['data-image']);
  const [description, setDescription] = useState(props.node.attrs['data-description']);
  const [favIcon, setFavIcon] = useState(props.node.attrs['data-icon']);
  const [title, setTitle] = useState(props.node.attrs['data-title']);

  const {
    data: metadata,
    isLoading: loading,
    error,
    refetch,
  } = useExtractMetaAttributes(href, !title);

  useEffect(() => {
    if (props.editor.isEditable) {
      refetch();
    }
  }, [href]);

  const target = props.node.attrs['data-target'];
  const content = props.node.attrs['data-content'];
  const showPreview = props.node.attrs['data-show-preview']
    ? String(props.node.attrs['data-show-preview'])
    : 'false';

  useEffect(() => {
    if (metadata) {
      setImage(metadata.image);
      setDescription(metadata.description);
      setFavIcon(metadata.icon);
      setTitle(metadata.title);

      queueMicrotask(() => {
        if (typeof props.getPos === 'function') {
          props.editor
            .chain()
            .focus()
            .setNodeSelection(props.getPos())
            .setMeta('addToHistory', false)
            .updateAttributes('media-card', {
              'data-image': metadata.image,
              'data-title': metadata.title,
              'data-description': metadata.description,
              'data-icon': metadata.icon,
            })
            .run();
        }
      });
    }
  }, [metadata]);

  if (error) {
    return (
      <NodeViewWrapper>
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
        >{`Preview could not be generated for ${href}`}</a>
      </NodeViewWrapper>
    );
  }
  if (loading) {
    return (
      <NodeViewWrapper>
        <div>Loading...</div>
      </NodeViewWrapper>
    );
  }

  const cardProps = { favIcon, title, description, image, href, showPreview };

  return (
    <NodeViewWrapper>
      <div
        data-media-card
        data-href={href}
        data-target={target}
        data-content={content}
        data-show-preview={showPreview}
        className={styles.cardWrapper}
      >
        {image ? (
          <ImageCard {...cardProps} />
        ) : description ? (
          <BlockCard {...cardProps} />
        ) : (
          <InlineCard {...cardProps} />
        )}
      </div>
    </NodeViewWrapper>
  );
};

const ImageCard = ({
  description,
  favIcon,
  href,
  image,
  showPreview,
  title,
}: CardProps) => {
  return (
    <div className={styles.imageCard}>
      <Preview href={href} showPreview={showPreview} />
      <div className={styles.twoColumn}>
        <div className={styles.text}>
          <div className={styles.header}>
            <FavIcon image={favIcon} />
            <h3>{title}</h3>
          </div>
          {!!description && <div className={styles.description}>{description}</div>}
          <Link href={href} />
        </div>
        <div className={styles.image}>
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

const BlockCard = ({ description, favIcon, href, showPreview, title }: CardProps) => {
  return (
    <div className={styles.blockCard}>
      <Preview href={href} showPreview={showPreview} />
      <div className={styles.header}>
        <FavIcon image={favIcon} />
        <h3>{title}</h3>
      </div>
      {!!description && <div className={styles.description}>{description}</div>}
      <Link href={href} />
    </div>
  );
};

const InlineCard = ({ favIcon, href, showPreview, title }: CardProps) => {
  return (
    <div className={styles.inlineCard}>
      <Preview href={href} showPreview={showPreview} />
      <div className={styles.header}>
        <FavIcon image={favIcon} />
        <h3>{title}</h3>
        <Link href={href} />
      </div>
    </div>
  );
};

const FavIcon = ({ image }: Pick<CardProps, 'image' | 'showPreview'>) => {
  return (
    <span className={styles.favIcon}>
      {image ? <img src={image} alt="" /> : <Links />}
    </span>
  );
};

const Link = ({ href }: Pick<CardProps, 'href' | 'showPreview'>) => {
  if (!href) return null;
  return (
    <a
      href={href}
      target="_blank"
      className={styles.link}
      title={href}
      rel="noreferrer"
    >
      <span className={styles.linkText}>{href}</span>
      <OpenNewWindow />
    </a>
  );
};

const Preview = ({ href, showPreview }: Pick<CardProps, 'href' | 'showPreview'>) => {
  if (!showPreview || showPreview === 'false') return null;

  const { id, isYoutube } = parseURL(href);
  if (!(id && isYoutube)) return null;

  const clearInstance = () => {
    (window as any).tippyLinkQuickMenu?.destroy?.();
    (window as any).tippyLinkQuickMenu = null;
  };

  return (
    <div className={styles.preview} onMouseOver={clearInstance}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${id}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export function parseURL(url?: string) {
  if (!url) return {};

  // TODO: Add support for other media
  const youtubeURLs = ['https://youtu.be/', 'https://www.youtube.com/embed/'];

  let id = '';
  let isYoutube = false;

  youtubeURLs.forEach((youtubeURL) => {
    if (url.startsWith(youtubeURL)) {
      const videoId = url.substring(youtubeURL.length, url.indexOf('?'));

      if (videoId) {
        id = videoId;
        isYoutube = true;
      }
    }
  });

  return {
    id,
    isYoutube,
  };
}
