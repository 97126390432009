import type { WorkspaceMember } from '@spaceduck/api';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { css } from '@/lib/css';
import { useWorkspaceMembers } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import UserAvatar from '@ui/UserAvatar';
import { SearchBar } from '../contentBlockMenu/index';
import { textNode } from '../../utils';
import styles from './MentionMenuList.module.scss';

export type CommandListRef = {
  onKeyDown: NonNullable<
    ReturnType<NonNullable<SuggestionOptions['render']>>['onKeyDown']
  >;
};

const MentionMenuList = forwardRef<CommandListRef, SuggestionProps>(
  (props: SuggestionProps, _) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const mentionMenuListRef = useRef<HTMLDivElement>(null);
    const { ref: loadMoreRef, inView } = useInView();
    const [activeMember, setActiveMember] = useState<WorkspaceMember | null>(null);
    const [debounceIsLoading, setDebounceIsLoading] = useState(false);
    const [query, setQuery] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearch = useCallback(
      debounce(async (value: string) => {
        setDebounceIsLoading(false);
        setSearchValue(value);
      }, 150),
      []
    );

    useEffect(() => {
      setDebounceIsLoading(true);
      debouncedSearch(query);
    }, [query]);

    const workspaceId = useWorkspaceId();
    const {
      data: workspaceMemberData,
      isLoading,
      isError,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    } = useWorkspaceMembers(workspaceId, searchValue);

    const members = workspaceMemberData?.pages.flatMap((page) => page.members);

    useEffect(() => {
      if (inView && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [inView, isFetchingNextPage, hasNextPage]);

    useEffect(() => {
      const activeElement = mentionMenuListRef.current?.querySelector('.active');
      activeElement?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, [activeMember]);

    useEffect(() => {
      const firstResult =
        members?.length && members.length > 0 ? members[0] : undefined;

      if (!firstResult) {
        setActiveMember(null);
      } else if (!activeMember && firstResult) {
        setActiveMember(firstResult);
      } else if (activeMember) {
        const found = members?.find((member) => member.id === activeMember.id);
        if (!found) {
          setActiveMember(firstResult);
        }
      }
    }, [members]);

    const insertContentBlock = (memberId: string, memberLabel: string) => {
      // TODO: Check if line is empty and replace
      props.editor
        .chain()
        .focus()
        .insertContentAt(props.range, [
          {
            type: 'mention',
            attrs: {
              id: memberId,
              label: memberLabel,
            },
          },
          textNode(' '),
        ])
        .run();
    };

    const upHandler = () => {
      if (members && members.length > 0 && activeMember) {
        const activeIndex = members.findIndex(
          (members) => members.id === activeMember.id
        );

        if (activeIndex > 0 && members?.[activeIndex - 1]) {
          setActiveMember(members[activeIndex - 1] ?? null);
        }
      }
    };

    const downHandler = () => {
      if (members && members.length > 0) {
        if (activeMember) {
          const activeIndex = members.findIndex(
            (members) => members.id === activeMember.id
          );

          if (members.length - activeIndex < 3 && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }

          if (activeIndex < 0) return;

          if (activeIndex + 1 < members.length) {
            const nextActiveMember = members[activeIndex + 1];
            if (nextActiveMember) {
              setActiveMember(nextActiveMember);
            }
          }
        }
      }
    };

    const enterHandler = () => {
      if (activeMember) {
        insertContentBlock(activeMember.id, activeMember.name);
      }
    };

    const handleListItemClick = (member: WorkspaceMember | null) => {
      if (member?.id) {
        insertContentBlock(member.id, member.name);
      }
    };

    const myWindow = window as any;

    const onKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (containerRef.current?.checkVisibility?.()) {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      if (event.key === 'Escape' && myWindow.tippyMentionMenuList?.[0].popperInstance) {
        myWindow.tippyMentionMenuList?.[0]?.destroy();
      }

      return false;
    };

    if (isError) return null;

    return (
      <div ref={containerRef}>
        <SearchBar
          editor={props.editor}
          icon="@"
          placeholder="search for team members"
          setQuery={setQuery}
          value={query}
          passThroughKeyDownEvents={onKeyDown}
          whitelistKeys={['Enter', 'ArrowUp', 'ArrowDown', 'Escape']}
        />
        <div className={styles.container}>
          {debounceIsLoading || isLoading ? (
            <div className={styles.isLoading}>
              <Spinner />
            </div>
          ) : !members?.length ? (
            <div className={styles.noResults}>No matching options</div>
          ) : (
            <div className={styles.mentionsList} ref={mentionMenuListRef}>
              <ScrollArea
                className={styles.scrollArea}
                style={css({
                  '--width': '100%',
                  '--maxHeight': '100%',
                })}
              >
                {members?.map((member) => {
                  return (
                    <div
                      className={clsx(
                        styles.membersListItem,
                        activeMember?.id === member.id && `${styles.active} active`
                      )}
                      key={member.id}
                      onClick={() => handleListItemClick(member)}
                      onMouseOver={() => setActiveMember(member)}
                    >
                      <div className={styles.avatar}>
                        <UserAvatar
                          name={member.name}
                          imageUrl={member.avatarUrl}
                          size="xs"
                          className={styles.avatar}
                        />
                      </div>
                      <div className={styles.name}>{member.name}</div>
                    </div>
                  );
                })}
                {hasNextPage && (
                  <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
                    {/* Triggers page fetch when in view */}
                  </div>
                )}
              </ScrollArea>
            </div>
          )}
        </div>
      </div>
    );
  }
);

MentionMenuList.displayName = 'MentionMenuList';

export default MentionMenuList;
