import type { Editor } from '@tiptap/core';
import type { Node } from '@xyflow/react';
import { useShallow } from 'zustand/shallow';

export type NotesMenuProps = {
  editor: Editor | null;
  node?: Node;
  updateNode: (patch: {
    className?: string;
    height?: number;
    data?: { autoHeightDisabled?: boolean; boxColor?: string };
  }) => void;
};

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import {
  MenuButtonGrid as ButtonGrid,
  MenuContainer as Container,
  MenuSeparator as Separator,
  MenuTitle as Title,
} from './menu';
import {
  TextAlignmentMenu,
  TextColorMenu,
  TextFormatMenu,
  TextHeadingMenu,
  TextStyleMenu,
} from './notes';

export default function NotesMenu(props: NotesMenuProps) {
  const { editor, node } = props;

  const { selectedEdges, selectedNodes } = useBoardStore(
    useShallow((state) => ({
      selectedEdges: state.selectedEdges,
      selectedNodes: state.selectedNodes,
      setSelectedEdges: state.setSelectedEdges,
    }))
  );

  if (!editor?.isEditable || !node) return null;
  if (selectedNodes[0] !== node.id) return null;
  if (!selectedEdges && selectedNodes.length !== 1) return null;

  return (
    <Container>
      <Title>Text Color</Title>
      <ButtonGrid>
        <TextColorMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Text Alignment</Title>
      <ButtonGrid>
        <TextAlignmentMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Text Style</Title>
      <ButtonGrid>
        <TextStyleMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Headings</Title>
      <ButtonGrid>
        <TextHeadingMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Text Format</Title>
      <ButtonGrid>
        <TextFormatMenu {...props} />
      </ButtonGrid>
    </Container>
  );
}
