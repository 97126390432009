import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { type Node, useReactFlow, type NodeProps } from '@xyflow/react';
import type { Editor } from '@tiptap/core';
import { EditorContent, useEditor } from '@tiptap/react';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';

import { createMediaGroup } from '@spaceduck/api';
import { exists } from '@spaceduck/utils';

import { mediaGroupKeys } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import NotesMenu from '@reactFlow/components/sideMenu/NotesMenu';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import { useMetaKey } from '@hooks/useMetaKey';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import ScrollArea from '@ui/ScrollArea';
import { Handles } from '../components/Handles';
import Resizer from '../components/Resizer';
import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from '../hooks/useBoardStore';
import { DEFAULT_FLOATING_NOTE_WIDTH, useNodes } from '../hooks/useNodes';
import { boardFloatingTextExtensions } from '../../tiptap/extensions';
import type { FloatingTextNodeType } from '../types/board';
import styles from './FloatingTextNode.module.scss';

const findRelativePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findRelativePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const FloatingTextNode = (props: NodeProps<FloatingTextNodeType>) => {
  const [showResizer, setShowResizer] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(props.data.isInEditMode ?? false);
  const editor = useEditor(
    {
      extensions: boardFloatingTextExtensions,
      content: props.data.content ?? undefined,
      editable: isInEditMode,
    },
    [isInEditMode, props.data.content]
  );

  const handleClickOutside = useCallback(() => setIsInEditMode(false), []);
  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: handleClickOutside,
  });

  useEffect(() => {
    const editorContent = editor?.getJSON();
    if (editor && !isInEditMode && props.data.content !== editorContent) {
      updateNode(props.id, {
        data: {
          content: editorContent ?? null,
        },
      });
    }
  }, [isInEditMode]);

  useEffect(() => {
    if (isInEditMode && editor) {
      editor.commands.focus();
    }
  }, [editor, isInEditMode]);

  const { copyNode, cutNode, deleteNode, duplicateNode, updateNode } = useNodes();
  const selectedNodes = useBoardStore((state) => state.selectedNodes);
  const { flowToScreenPosition, getNode, setNodes } = useReactFlow();

  const node = getNode(props.id);
  const parent = node?.parentId ? getNode(node.parentId) : undefined;
  const isDragging = node?.dragging || parent?.dragging;
  const isSelected = selectedNodes.includes(props.id);

  const removeFromFrame = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (selectedNodes.includes(node.id) && node.type !== 'groupNode') {
          const { x, y } = node ? findRelativePosition(node, nodes) : { x: 0, y: 0 };

          return {
            ...node,
            extent: undefined,
            parentId: undefined,
            position: {
              x,
              y,
            },
          };
        }

        return node;
      });
    });
  }, [selectedNodes, setNodes]);

  const boardContext = useContext(BoardStoreContext);
  const workspaceId = useWorkspaceId();
  const { addDocumentNode } = useNodes();
  const queryClient = useQueryClient();

  const copy = useCallback(() => copyNode(props.id), [props.id]);
  const cut = useCallback(() => cutNode(props.id), [props.id]);
  const deleteSelf = useCallback(() => deleteNode(props.id), [props.id]);
  const convertToDocument = useCallback(async () => {
    if ((!boardContext?.projectId && !workspaceId) || !editor || !node) return;

    try {
      // Create document with existing content
      const mediaGroup = await createMediaGroup({
        workspaceId: boardContext?.projectId ? undefined : workspaceId,
        projectId: boardContext?.projectId ?? undefined,
        kind: 'document',
        document: editor.getJSON(),
        plainText: editor.getText(),
      });

      // Replace self with new Document node
      const { x, y } = flowToScreenPosition({ x: node.position.x, y: node.position.y });
      const documentNode = await addDocumentNode(mediaGroup.mediaGroupId, x, y, {
        appendNode: false,
        parentId: node.parentId,
      });

      setNodes((nodes) =>
        nodes.map((_node) => (node.id === _node.id ? documentNode : _node))
      );

      await queryClient.invalidateQueries({ queryKey: mediaGroupKeys.list });
    } catch (error) {
      return toastApiErrorOr(error, 'Failed to create document from text', {
        iconVariant: 'warning',
        titleText: 'Failed to Create Document',
        bodyText:
          'An unknown error occurred while creating item. Please try again later',
      });
    }
  }, [props.id, boardContext?.projectId, workspaceId, editor, node, setNodes]);
  const duplicate = useCallback(() => {
    duplicateNode(props.id);
    setShowResizer(false);
  }, [duplicateNode, setShowResizer]);

  const colorStyle = useMemo(() => {
    return node?.data?.boxColor ? styles[`${node.data.boxColor}`] : undefined;
  }, [node?.data]);

  const autoHeightDisabled = node?.data.autoHeightDisabled;
  const height = !autoHeightDisabled
    ? undefined
    : (node?.height ?? node.measured?.height);

  return (
    <div
      className={clsx(
        styles.container,
        isInEditMode && 'nodrag',
        isInEditMode && styles.isInEditMode,
        isSelected && styles.selected
      )}
      onMouseOver={() => setShowResizer(true)}
      onMouseOut={() => setShowResizer(false)}
      onFocus={() => setShowResizer(true)}
      onBlur={() => setShowResizer(false)}
      ref={containerRef}
      style={{
        width: node?.width ?? DEFAULT_FLOATING_NOTE_WIDTH,
        height,
      }}
    >
      <Resizer
        isVisible={isSelected && !isDragging}
        maxHeight={autoHeightDisabled ? undefined : height}
      />
      <Card
        colorStyle={colorStyle}
        convertToDocument={convertToDocument}
        copy={copy}
        cut={cut}
        deleteSelf={deleteSelf}
        duplicate={duplicate}
        editor={editor}
        id={props.id}
        isInEditMode={isInEditMode}
        isSelected={isSelected}
        node={node}
        removeFromFrame={removeFromFrame}
        setIsInEditMode={setIsInEditMode}
        showResizer={showResizer}
        updateNode={updateNode}
      />
    </div>
  );
};

function useContextMenu({
  convertToDocument,
  copy,
  cut,
  deleteSelf,
  duplicate,
  isInFrame,
  removeFromFrame,
}: {
  convertToDocument: () => void;
  copy: () => void;
  cut: () => void;
  deleteSelf: () => void;
  duplicate: () => void;
  isInFrame: boolean;
  removeFromFrame: () => void;
}) {
  const metaKey = useMetaKey();
  const isInWebviewRoute = useIsInWebViewRoute();

  const contextMenu: ContextMenuItemProps[] = [
    {
      content: 'Cut',
      onClick: cut,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} X`,
    },
    {
      content: 'Copy',
      onClick: copy,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} C`,
    },
    {
      content: 'Duplicate',
      onClick: duplicate,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} D`,
    },
    {
      content: 'Convert to document',
      onClick: convertToDocument,
    },
    {
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: isInWebviewRoute ? undefined : 'Del',
    },
    isInFrame
      ? {
          content: 'Remove from Frame',
          onClick: removeFromFrame,
        }
      : undefined,
  ].filter(exists);

  return contextMenu;
}

const Card = memo(
  ({
    colorStyle,
    convertToDocument,
    copy,
    cut,
    deleteSelf,
    duplicate,
    editor,
    id,
    isInEditMode,
    isSelected,
    node,
    removeFromFrame,
    setIsInEditMode,
    showResizer,
    updateNode,
  }: {
    colorStyle?: string;
    convertToDocument: () => void;
    copy: () => void;
    cut: () => void;
    deleteSelf: () => void;
    duplicate: () => void;
    editor: Editor | null;
    isInEditMode: boolean;
    isSelected: boolean;
    id: string;
    node?: Node;
    removeFromFrame: () => void;
    setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    showResizer: boolean;
    updateNode: (id: string, patch: Partial<Node>) => void;
  }) => {
    const contextMenu = useContextMenu({
      convertToDocument,
      copy,
      cut,
      deleteSelf,
      duplicate,
      isInFrame: !!node?.parentId,
      removeFromFrame,
    });

    const autoHeightDisabled = node?.data?.autoHeightDisabled;
    const content = useMemo(() => {
      if (!editor?.getText().trim() && !isInEditMode) {
        return <div className={styles.editorPlaceholder}>Text...</div>;
      }

      return <EditorContent className={styles.editor} editor={editor} />;
    }, [editor]);

    const handleDoubleClick = useCallback(
      () => setIsInEditMode(true),
      [setIsInEditMode]
    );
    const handleUpdateNode = useCallback(
      (patch: Partial<Node>) => updateNode(id, patch),
      [id, updateNode]
    );

    const { isDraggingOnBoard } = useDetailsModalStore();

    const portalTarget = document.getElementById('boardNotesSideMenu');

    return (
      <ContextMenu
        contentClassName={styles.menu}
        items={contextMenu}
        renderMenu={!isDraggingOnBoard}
      >
        <Handles id={id} isVisible={!isSelected && showResizer} />
        <div
          className={clsx(styles.textbox, colorStyle)}
          onDoubleClick={handleDoubleClick}
        >
          {portalTarget &&
            createPortal(
              <NotesMenu editor={editor} node={node} updateNode={handleUpdateNode} />,
              portalTarget
            )}
          <div
            className={clsx(
              styles.content,
              autoHeightDisabled ? styles.fixedHeight : undefined
            )}
          >
            {autoHeightDisabled ? (
              <ScrollArea
                orientation="vertical"
                style={css({
                  '--width': '100%',
                  '--maxHeight': '100%',
                })}
              >
                {content}
              </ScrollArea>
            ) : (
              content
            )}
          </div>
        </div>
      </ContextMenu>
    );
  }
);
