import { memo, useCallback } from 'react';

import { Icon16 } from '@spaceduck/icons';

import {
  handleTransformation,
  type Transformation,
} from '@detailsModal/tiptap/transformations';
import { MenuButton } from '../menu';
import type { NotesMenuProps } from '../NotesMenu';

const { TextCenter, TextLeft, TextRight } = Icon16;

export const TextAlignmentMenu = memo((props: NotesMenuProps) => {
  const { editor } = props;

  const handleClick = useCallback(
    (transformation: Transformation) => {
      if (!editor) return null;

      handleTransformation(editor, transformation);
    },
    [editor]
  );

  if (!editor) return null;

  return (
    <>
      <MenuButton
        isActive={editor.isActive({ textAlign: 'left' })}
        onClick={() => handleClick('textLeft')}
        tooltipContent="Align left"
      >
        <TextLeft />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive({ textAlign: 'center' })}
        onClick={() => handleClick('textCenter')}
        tooltipContent="Align center"
      >
        <TextCenter />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive({ textAlign: 'right' })}
        onClick={() => handleClick('textRight')}
        tooltipContent="Align right"
      >
        <TextRight />
      </MenuButton>
    </>
  );
});
