import type { ReactNode } from 'react';

import Tooltip from '@ui/Tooltip';

export const WrapWithTooltip = ({
  children,
  tooltip,
}: {
  children: ReactNode;
  tooltip?: string;
}) => {
  if (!tooltip) return children;

  return (
    <span>
      <Tooltip content={tooltip} align="start">
        {children}
      </Tooltip>
    </span>
  );
};
