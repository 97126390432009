import type { ConnectionLineType, Edge, Node } from '@xyflow/react';

import type { BaseColor } from './colors';

export type BaseNodeData = {
  mediaGroupId: string;
  color?: BaseColor;
  copyOrCutNode?: (id: string, action: 'copy' | 'cut') => Promise<void> | void;
  deleteNode?: (id: string) => Promise<void> | void;
  duplicateNode?: (id: string) => Promise<void> | void;
  expanded: boolean;
  updateNode?: (id: string, node: Partial<Node>) => Promise<void> | void;
  height?: number | null;
  width?: number | null;
};

export type CommentNodeData = {
  color?: BaseColor;
  commentId?: string;
  content?: string | null;
  mediaGroupId?: string;
  expanded?: boolean;
};

export type GroupNodeData = {
  label?: string | null;
  color?: BaseColor;
  expanded?: boolean;
};

export type FileNodeData = {
  mediaGroupId: string;
};

export type FloatingTextNodeData = {
  autoHeightDisabled?: boolean;
  boxColor?: string;
  content?: string | null;
  height?: number | null;
  isInEditMode?: boolean;
  width?: number | null;
};

export type MenuPlaceholderData = {
  id?: string;
};

export type TempFileUploadData = {
  id: string;
};

export type BaseNodeType = Node<BaseNodeData>;
export type ArticleNodeType = Node<BaseNodeData, 'articleNode'>;
export type AudioNodeType = Node<BaseNodeData, 'audioNode'>;
export type CommentNodeType = Node<CommentNodeData, 'commentNode'>;
export type DocumentNodeType = Node<BaseNodeData, 'documentNode'>;
export type FileNodeType = Node<FileNodeData, 'fileNode'>;
export type FloatingTextNodeType = Node<FloatingTextNodeData, 'floatingTextNode'>;
export type GroupNodeType = Node<GroupNodeData, 'groupNode'>;
export type HighlightNodeType = Node<BaseNodeData, 'highlightNode'>;
export type MenuPlaceholder = Node<MenuPlaceholderData, 'menuPlaceholder'>;
export type ImageNodeType = Node<BaseNodeData, 'imageNode'>;
export type PdfNodeType = Node<BaseNodeData, 'pdfNode'>;
export type TempFileUploadType = Node<TempFileUploadData, 'tempFileUploadNode'>;
export type UnknownNodeType = Node<BaseNodeData, 'unknownNode'>;
export type VideoNodeType = Node<BaseNodeData, 'videoNode'>;

export const edgeWidths = ['small', 'medium', 'large'] as const;
export type EdgeWidth = (typeof edgeWidths)[number];

export const edgeWidthAsNumber: Record<EdgeWidth, number> = {
  small: 3,
  medium: 4,
  large: 5,
};

export const edgeStyles = ['solid', 'dashed'] as const;
export type EdgeStyle = (typeof edgeStyles)[number];

export const edgeTypes = ['straight', 'curved', 'elbowed'] as const;
export type EdgeType = (typeof edgeTypes)[number];

export type BaseEdgeData = {
  type?: `${ConnectionLineType}`;
  color?: BaseColor;
  style?: EdgeStyle;
  width?: EdgeWidth;
};

export type BaseEdge = Edge<BaseEdgeData>;

export const edgeArrows = [
  'none',
  'arrowEnd',
  'circleEnd',
  'diamondEnd',
  'arrowStart',
  'circleStart',
  'diamondStart',
] as const;
export type EdgeArrows = (typeof edgeArrows)[number];
