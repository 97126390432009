import { useReactFlow, type NodeProps } from '@xyflow/react';
import { useCallback, useContext, useMemo, useState } from 'react';

import { exists, type CommentDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import { useListComments } from '@api/comment';
import { CommentThread } from '@detailsModal/comments/Comment';
import { NewCommentTextBox } from '@detailsModal/comments/CommentTextbox';
import { useUserInfo } from '@hooks/useAuth';
import { CardHeader } from '@reactFlow/components/CardHeader';
import ColorSelector from '@reactFlow/components/ColorSelector';
import {
  findRelativePosition,
  MENU_SEPARATOR,
  useMenuItem,
} from '@reactFlow/components/FlowCard';
import { BoardStoreContext } from '@reactFlow/context/boardContext';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import { useMetaKey } from '@hooks/useMetaKey';
import { useNodes } from '@reactFlow/hooks/useNodes';
import type { CommentNodeType } from '@reactFlow/types/board';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import Spinner from '@ui/Spinner';
import styles from './CommentNode.module.scss';
import { baseColors, type BaseColor } from '../types/colors';
import { Handles } from '../components/Handles';

const { Comment: CommentIcon } = Icon16;
const COLLAPSED_HEIGHT = 48;

export function CommentNode(props: NodeProps<CommentNodeType>) {
  const { color, expanded = true } = props.data;
  const { getNode, setNodes } = useReactFlow();

  const { cutNode, deleteNode, selectedNodes, updateNode } = useNodes();

  const boardContext = useContext(BoardStoreContext);

  const [showResizer, setShowResizer] = useState(false);
  const handleShowResizer = useCallback(() => setShowResizer(true), []);
  const handleHideResizer = useCallback(() => setShowResizer(false), []);

  const cut = useCallback(() => cutNode(props.id), [cutNode, props.id]);
  const deleteSelf = useCallback(() => deleteNode(props.id), [deleteNode, props.id]);

  const node = getNode(props.id);

  const removeFromFrame = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (selectedNodes.includes(node.id) && node.type !== 'groupNode') {
          const { x, y } = node ? findRelativePosition(node, nodes) : { x: 0, y: 0 };

          return {
            ...node,
            extent: undefined,
            parentId: undefined,
            position: {
              x,
              y,
            },
          };
        }

        return node;
      });
    });
  }, [selectedNodes, setNodes]);

  const setColor = useCallback(
    (color: BaseColor) => {
      updateNode(props.id, {
        data: {
          color,
        },
      });
    },
    [props.id, updateNode]
  );

  const toggleExpanded = useCallback(() => {
    updateNode(props.id, {
      data: {
        expanded: !expanded,
      },
    });
  }, [expanded, props.id, updateNode]);

  const contextMenu: ContextMenuItemProps[] = useMenuContext({
    color,
    cut,
    deleteSelf,
    expanded,
    isInFrame: !!node?.parentId,
    removeFromFrame,
    setColor,
    toggleExpanded,
  });

  const isSelected = useMemo(
    () => selectedNodes.includes(props.id),
    [props.id, selectedNodes]
  );

  if (!node) return null;
  if (!boardContext?.mediaGroupId) return null;

  return (
    <div
      onMouseOver={handleShowResizer}
      onMouseOut={handleHideResizer}
      onFocus={handleShowResizer}
      onBlur={handleHideResizer}
      style={{
        height: expanded ? 'auto' : COLLAPSED_HEIGHT,
      }}
    >
      <div className={styles.nodeWrapper}>
        <CardHeader
          contextMenu={contextMenu}
          color={color}
          expanded={expanded}
          title="Comment"
          toggleExpanded={toggleExpanded}
          icon={<CommentIcon size={20} />}
        >
          <div className={styles.nodeContent}>
            <Handles id={props.id} isVisible={!isSelected && showResizer} />
            <div className={styles.scrollArea}>
              <div className={styles.commentNodeContainer}>
                <Comment mediaGroupId={boardContext.mediaGroupId} {...props} />
              </div>
            </div>
          </div>
        </CardHeader>
      </div>
    </div>
  );
}

const Comment = (props: NodeProps<CommentNodeType> & { mediaGroupId: string }) => {
  const mediaGroupId = props.mediaGroupId;
  const { commentId } = props.data;
  const { data, isError, isLoading } = useListComments(mediaGroupId);

  const comment = useMemo(() => {
    if (!data?.comments) return undefined;

    return data.comments.find((comment) => comment.id === commentId);
  }, [data?.comments]);

  if (!props.id || !mediaGroupId) {
    return null;
  }

  if (!commentId) {
    return <NewComment id={props.id} mediaGroupId={mediaGroupId} />;
  }

  if (comment?.kind !== 'alive') return null;

  return (
    <ViewComment
      comment={comment}
      isError={isError}
      isLoading={isLoading}
      mediaGroupId={mediaGroupId}
    />
  );
};

const ViewComment = ({
  comment,
  isError,
  isLoading,
  mediaGroupId,
}: {
  comment?: CommentDTO;
  isError?: boolean;
  isLoading?: boolean;
  mediaGroupId: string;
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>Failed to load comment</p>;
  }

  const author = useMemo(() => {
    if (comment?.kind !== 'alive') return null;

    return comment.createdBy;
  }, [comment?.kind]);

  if (!comment || !author) return null;

  return (
    <div className={styles.commentThreadContainer}>
      <CommentThread
        textBoxContainerStyle={styles.commentThread}
        comment={comment}
        isAnnotation
        mediaGroupId={mediaGroupId}
      />
    </div>
  );
};

const NewComment = ({
  parentCommentId,
  id,
  mediaGroupId,
}: {
  parentCommentId?: string;
  id: string;
  mediaGroupId: string;
}) => {
  const { setNodes, updateNode } = useReactFlow();

  const onCancel = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
  }, [id, setNodes]);

  const onCommentCreated = useCallback(
    ({ commentId }: { commentId: string }) => {
      updateNode(id, (node) => ({
        ...node,
        data: {
          ...node.data,
          commentId,
          expanded: true,
          mediaGroupId,
        },
      }));
    },
    [id, updateNode]
  );

  const user = useUserInfo();

  const name = useMemo(() => {
    if (!user) return null;

    return user.preferredName ?? user.email;
  }, [user?.preferredName, user?.email]);

  if (!name || !user) return null;

  return (
    <NewCommentTextBox
      isLoading={false}
      mediaGroupId={mediaGroupId}
      onCancel={onCancel}
      onCommentCreated={onCommentCreated}
      parentCommentId={parentCommentId}
    />
  );
};

function useMenuContext({
  color,
  cut,
  deleteSelf,
  expanded,
  isInFrame,
  removeFromFrame,
  setColor,
  toggleExpanded,
}: {
  color: string | undefined;
  cut: () => void;
  deleteSelf: () => void;
  expanded: boolean;
  isInFrame: boolean;
  removeFromFrame: () => void;
  setColor: (color: BaseColor) => void;
  toggleExpanded?: () => void;
}) {
  const metaKey = useMetaKey();
  const selectedColor = color;
  const isInWebViewRoute = useIsInWebViewRoute();

  const menuItemCut = useMenuItem(
    () => ({
      content: 'Cut',
      onClick: cut,
      shortcut: isInWebViewRoute ? undefined : `${metaKey} X`,
    }),
    [cut, metaKey, isInWebViewRoute]
  );

  const menuItemFromFromBoard = useMenuItem(
    () => ({
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: isInWebViewRoute ? undefined : 'Del',
    }),
    [deleteSelf, isInWebViewRoute]
  );

  const menuItemFromFromFrame = useMenuItem(() => {
    if (!isInFrame) {
      return null;
    }
    return {
      content: 'Remove from Frame',
      onClick: removeFromFrame,
    };
  }, [isInFrame, removeFromFrame]);

  const menuItemToggleFold = useMenuItem(
    () => ({
      content: `${expanded ? 'Fold' : 'Expand'}`,
      onClick: toggleExpanded,
    }),
    [expanded, toggleExpanded]
  );

  const menuItemBorderColors = useMenuItem(
    () => ({
      className: styles.noHover,
      content: (
        <div className={styles.colorList}>
          {baseColors.map((color) => {
            return (
              <ColorSelector
                key={color}
                color={color}
                isActive={color === selectedColor}
                onChange={setColor}
              />
            );
          })}
        </div>
      ),
    }),
    [selectedColor, toggleExpanded, setColor]
  );

  return useMemo(() => {
    return [
      menuItemCut,
      menuItemFromFromBoard,
      menuItemFromFromFrame,
      MENU_SEPARATOR,
      menuItemToggleFold,
      menuItemBorderColors,
    ].filter(exists);
  }, [
    menuItemCut,
    menuItemFromFromBoard,
    menuItemFromFromFrame,
    menuItemToggleFold,
    menuItemBorderColors,
  ]);
}
