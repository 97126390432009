import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { debounce, upperFirst } from 'lodash';
import { useParams } from 'react-router';

import type {
  MediaGroupContentType,
  MediaGroupDTO,
  MediaGroupQuerySchema,
} from '@spaceduck/api';

import { useCreateMediaGroup, useListMediaGroups } from '@api/mediaGroup';
import { useProject } from '@api/project';
import {
  ContentBlockListItem,
  MenuContainer,
  NewMediaGroupButton,
  NewMediaGroupLoading,
  NoSearchResult,
  NoSearchResultWithCreateMenu,
} from '@components/detailsModal/tiptap/nodes/contentBlockMenu/';
import { ContentType } from '@components/icons';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import Card from '@ui/Card';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import styles from './MediaGroupPreviewList.module.scss';

export const newItemOptions: Extract<MediaGroupContentType, 'document' | 'board'>[] = [
  'document',
  'board',
] as const;
export type NewItemOption = (typeof newItemOptions)[number];

export type KeyDownHandler = (event: KeyboardEvent) => boolean;

export type MediaGroupPreviewListProps = {
  searchTitle: string;
  handleSelectItem: (item: MediaGroupDTO) => void;
  setOnKeyDown: (setHandler: () => KeyDownHandler) => void;
  defaultFilters?: Omit<MediaGroupQuerySchema, 'workspace'>;
  excludeMediaGroupIds?: string[];
  spaceLabel?: string;
  closeParentPopup?: () => void;
  showCreateOptions?: boolean;
};

const LOAD_MORE_ITEMS_THRESHOLD = 3;

const MediaGroupPreviewList = (props: MediaGroupPreviewListProps) => {
  const {
    searchTitle,
    handleSelectItem,
    setOnKeyDown,
    defaultFilters,
    showCreateOptions,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [debounceIsLoading, setDebounceIsLoading] = useState(false);
  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebounceIsLoading(false);
      setSearchValue(value);
    }, 150),
    []
  );

  useEffect(() => {
    setDebounceIsLoading(true);
    debouncedSearch(searchTitle);
  }, [searchTitle]);

  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId: string }>().projectId!;
  const project = useProject(projectId);

  const {
    data: mediaGroupData,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    ...defaultFilters,
    title: [searchValue],
  });
  const mediaGroups = mediaGroupData?.pages
    .flatMap((page) => page.mediaGroups)
    .filter((mediaGroup) =>
      props.excludeMediaGroupIds?.length
        ? !props.excludeMediaGroupIds.includes(mediaGroup.id)
        : true
    );

  const containerRef = useRef<HTMLDivElement>(null);
  const mediaGroupListListRef = useRef<HTMLDivElement>(null);
  const { ref: loadMoreRef, inView } = useInView();
  const [activeEmbeddable, setActiveEmbeddable] = useState<string | null>(null);

  const mediaGroupIds = useMemo(() => {
    return (mediaGroups ?? [])
      .map((mediaGroup) => mediaGroup.id)
      .concat(['newDocument', 'newBoard']);
  }, [mediaGroups, searchValue]);

  useEffect(() => {
    const activeElement = mediaGroupListListRef.current?.querySelector('.active');
    activeElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [activeEmbeddable]);

  useEffect(() => {
    const firstEmbeddable =
      mediaGroups?.length && mediaGroups.length > 0 ? mediaGroups[0] : undefined;
    if (!firstEmbeddable) {
      setActiveEmbeddable('newDocument');
    } else if (!activeEmbeddable && firstEmbeddable) {
      setActiveEmbeddable(firstEmbeddable.id);
    } else if (activeEmbeddable) {
      const found = mediaGroups?.find(
        (embeddable) => embeddable.id === activeEmbeddable
      );
      if (!found) {
        setActiveEmbeddable(firstEmbeddable.id);
      }
    }
  }, [mediaGroupData?.pages]);

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  const handleListItemClick = (embeddableId: string | null) => {
    setActiveEmbeddable(embeddableId || null);
  };

  const handleCardClick = (embeddableId: string) => {
    const mediaGroup = mediaGroups?.find(
      (mediaGroup) => mediaGroup.id === embeddableId
    );
    if (mediaGroup) {
      handleSelectItem(mediaGroup);
    }
  };

  const upHandler = () => {
    if (mediaGroupIds.length > 0 && activeEmbeddable) {
      const activeIndex = mediaGroupIds.indexOf(activeEmbeddable);

      if (activeIndex > 0 && mediaGroupIds[activeIndex - 1]) {
        setActiveEmbeddable(mediaGroupIds[activeIndex - 1] ?? null);
      }
    }
  };

  const downHandler = () => {
    if (mediaGroupIds.length > 0 && activeEmbeddable) {
      const activeIndex = mediaGroupIds.indexOf(activeEmbeddable);
      if (
        mediaGroupIds.length - activeIndex < LOAD_MORE_ITEMS_THRESHOLD &&
        enabled &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }

      if (activeIndex < 0) return;

      if (activeIndex + 1 < mediaGroupIds.length) {
        const nextActiveMediaGroupId = mediaGroupIds[activeIndex + 1];
        if (nextActiveMediaGroupId) {
          setActiveEmbeddable(nextActiveMediaGroupId);
        }
      }
    }
  };

  const [newMediaGroupId, setNewMediaGroupId] = useState<string | null>(null);
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();

  const handleCreate = async (kind: NewItemOption) => {
    const { mediaGroupId } = await createMediaGroup({
      kind,
      workspaceId: workspaceId,
      label: searchValue,
    });

    if (mediaGroupId) {
      setNewMediaGroupId(mediaGroupId);
    }
  };

  const enterHandler = () => {
    if (activeEmbeddable) {
      if (activeEmbeddable === 'newDocument') {
        handleCreate('document');
        return;
      }

      if (activeEmbeddable === 'newBoard') {
        handleCreate('board');
        return;
      }

      const mediaGroup = mediaGroups?.find(
        (mediaGroup) => mediaGroup.id === activeEmbeddable
      );

      if (mediaGroup) {
        handleSelectItem(mediaGroup);
      }
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (containerRef.current?.checkVisibility?.()) {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!mediaGroups?.length) return;

    setOnKeyDown(() => onKeyDown);
  }, [activeEmbeddable, mediaGroups?.length]);

  if (isError) return null;

  if (isLoading || debounceIsLoading) {
    return (
      <MenuContainer isLoading>
        <Spinner />
      </MenuContainer>
    );
  }

  if (!mediaGroups?.length) {
    if (!searchValue?.trim().length) {
      return (
        <MenuContainer isLoading onClick={props.closeParentPopup}>
          <div className={styles.noContent}>
            <div>
              <p>
                {!props.excludeMediaGroupIds?.length
                  ? 'Your library is empty. Upload content to get started.'
                  : 'No more items available.'}
              </p>
              {props.spaceLabel && (
                <p>
                  <span>{props.spaceLabel}</span>
                </p>
              )}
            </div>
          </div>
        </MenuContainer>
      );
    }

    if (showCreateOptions) {
      return (
        <NoSearchResultWithCreateMenu
          closeParentPopup={props.closeParentPopup}
          handleSelectItem={handleSelectItem}
          previousDownHandler={() => onKeyDown}
          searchValue={searchValue}
          setOnKeyDown={setOnKeyDown}
        />
      );
    }

    return (
      <NoSearchResult
        onClick={props.closeParentPopup}
        space={project.data?.project.label}
      />
    );
  }

  return (
    <MenuContainer ref={containerRef}>
      <div className={styles.mediaGroupList} ref={mediaGroupListListRef}>
        <ScrollArea
          className={styles.scrollArea}
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          {!!mediaGroups.length && (
            <>
              <h3>Items</h3>
              {mediaGroups?.map((mediaGroup) => {
                return (
                  <ContentBlockListItem
                    key={mediaGroup.id}
                    bucket={getBucketLabel(mediaGroup)}
                    isActive={activeEmbeddable === mediaGroup.id}
                    icon={
                      <ContentType contentType={mediaGroup.contentType} size={24} />
                    }
                    label={mediaGroup.label || mediaGroup.linkUrlSource}
                    onClick={() => handleListItemClick(mediaGroup.id)}
                    onDoubleClick={() => {
                      handleListItemClick(mediaGroup.id);
                      handleCardClick(mediaGroup.id);
                    }}
                  />
                );
              })}
            </>
          )}
          {hasNextPage ? (
            <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
              {/* Triggers page fetch when in view */}
            </div>
          ) : showCreateOptions ? (
            <>
              <h3 className={styles.actionsHeading}>Actions</h3>
              {newMediaGroupId && (
                <NewMediaGroupLoading
                  mediaGroupId={newMediaGroupId}
                  handleSelectItem={handleSelectItem}
                  closeParentPopup={props.closeParentPopup}
                />
              )}
              {!newMediaGroupId &&
                newItemOptions.map((option) => {
                  return (
                    <ContentBlockListItem
                      key={option}
                      bucket="Drafts"
                      icon={<ContentType contentType={option} size={24} />}
                      isActive={activeEmbeddable === `new${upperFirst(option)}`}
                      label={`Create ${option}${searchValue ? ` "${searchValue}"` : ''}`}
                      onClick={() => {
                        handleListItemClick(`new${upperFirst(option)}`);
                      }}
                      onDoubleClick={() => {
                        handleListItemClick(`new${upperFirst(option)}`);
                        handleCreate(option);
                      }}
                    />
                  );
                })}
            </>
          ) : null}
        </ScrollArea>
      </div>
      <div className={styles.focusedItem}>
        {activeEmbeddable &&
        mediaGroups?.find((mediaGroup) => mediaGroup.id === activeEmbeddable) ? (
          <ScrollArea
            className={styles.scrollArea}
            orientation="vertical"
            style={css({
              '--width': '100%',
              '--maxHeight': 'var(--radix-popper-available-height, 100%)',
            })}
          >
            <div onClick={() => handleCardClick(activeEmbeddable)}>
              <Card
                mediaGroup={
                  mediaGroups.find((mediaGroup) => mediaGroup.id === activeEmbeddable)!
                }
                hideFooter
                className={styles.card}
              />
            </div>
          </ScrollArea>
        ) : activeEmbeddable === 'newDocument' ? (
          <NewMediaGroupButton
            onClick={(ev) => {
              ev.preventDefault();
              handleCreate('document');
            }}
          />
        ) : activeEmbeddable === 'newBoard' ? (
          <NewMediaGroupButton
            onClick={(ev) => {
              ev.preventDefault();
              handleCreate('board');
            }}
          />
        ) : null}
      </div>
    </MenuContainer>
  );
};

const getBucketLabel = (mediaGroup: MediaGroupDTO) => {
  if (mediaGroup.project) return mediaGroup.project.label;
  return 'Drafts';
};

export default MediaGroupPreviewList;
