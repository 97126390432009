import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3764_7722)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 2.44444C0.5 1.37056 1.37056 0.5 2.44444 0.5H13.5556C14.6294 0.5 15.5 1.37056 15.5 2.44444V13.5556C15.5 14.6294 14.6294 15.5 13.5556 15.5H2.44444C1.37056 15.5 0.5 14.6294 0.5 13.5556V2.44444ZM2.44444 2.16667C2.29103 2.16667 2.16667 2.29103 2.16667 2.44444V13.5556C2.16667 13.709 2.29103 13.8333 2.44444 13.8333H13.5556C13.709 13.8333 13.8333 13.709 13.8333 13.5556V2.44444C13.8333 2.29103 13.709 2.16667 13.5556 2.16667H2.44444Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3764_7722">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
