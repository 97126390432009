import React, { type ReactNode, useEffect, useRef } from 'react';
import type { Edge, Node } from '@xyflow/react';
import { debounce, isEqual } from 'lodash';
import { autoUpdate, autoPlacement, useFloating } from '@floating-ui/react-dom';

import { useCreateBoardStore } from '@reactFlow/stores/boardStore';
import { addClass } from '@utils/string';

export const BoardStoreContext = React.createContext<{
  boardLastUpdated: Date | null;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  floatingUIEdgeMenu: ReturnType<typeof useFloating>;
  mediaGroupId: string;
  projectId?: string;
  store: ReturnType<typeof useCreateBoardStore> | null;
} | null>(null);

type BoardStoreProviderProps = {
  activeCommentIds: string[];
  boardLastUpdated: Date | null;
  children: ReactNode;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  mediaGroupId: string;
  projectId?: string;
  initialNodes: Node[];
  initialEdges: Edge[];
};

const MAX_ERROR_BEFORE_RELOAD = 2;

export const BoardStoreProvider = ({
  activeCommentIds,
  boardLastUpdated,
  children,
  containerRef,
  mediaGroupId,
  projectId,
  initialNodes,
  initialEdges,
}: BoardStoreProviderProps) => {
  const floatingUIEdgeMenu = useFloating({
    middleware: [autoPlacement()],
    placement: 'top',
    whileElementsMounted: autoUpdate,
  });

  const store = useRef(
    useCreateBoardStore({
      initialNodes: embellishNodes(
        normalizeInitialNodes(
          initialNodes.filter((node) => {
            if (node.type !== 'commentNode') return true;
            if (!node.data.commentId) return false;

            return activeCommentIds.includes(node.data.commentId as string);
          })
        )
      ),
      initialEdges: removeDuplicateEdges(
        removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
      ),
    })
  );

  const data = {
    boardLastUpdated,
    containerRef,
    floatingUIEdgeMenu,
    mediaGroupId,
    projectId,
    store: store.current,
  };

  const selfCorrectingEdge = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  const nodeLengthErrorCount = useRef(0);
  const setNodeLengthErrorCount = debounce((count: number) => {
    nodeLengthErrorCount.current = count;
  }, 1000);

  useEffect(() => {
    // Sync with server data
    const state = store.current?.getState();
    const normalizedStoredNodes = normalizeStoredNodes(
      state?.nodes.filter((node) => {
        if (node.type === 'unknownNode') return false;
        if (node.type === 'commentNode' && !node.data.commentId) return false;
        if (
          node.type === 'commentNode' &&
          node.data.commentId &&
          !activeCommentIds.includes(node.data.commentId as string)
        )
          return false;

        return true;
      })
    );
    const normalizedStoredEdges = normalizeStoredEdges(state?.edges);
    const normalizedInitialNodes = normalizeInitialNodes(
      initialNodes.filter((node) => {
        if (node.type === 'commentNode' && !node.data.commentId) return false;
        if (
          node.type === 'commentNode' &&
          node.data.commentId &&
          !activeCommentIds.includes(node.data.commentId as string)
        )
          return false;

        return true;
      })
    );
    const embellishedStoredNodes = embellishNodes(normalizedStoredNodes);
    const embellishedInitialNodes = embellishNodes(normalizedInitialNodes);

    if (
      // Short circuit if isEqual is not needed
      normalizedStoredNodes.length !== normalizedInitialNodes.length ||
      !isEqual(embellishedStoredNodes, embellishedInitialNodes)
    ) {
      if (normalizedStoredNodes.length !== normalizedInitialNodes.length) {
        console.log(
          'Nodes length mismatch',
          normalizedInitialNodes,
          normalizedStoredNodes,
          `@${nodeLengthErrorCount.current} count`
        );

        if (nodeLengthErrorCount.current < MAX_ERROR_BEFORE_RELOAD) {
          setNodeLengthErrorCount(nodeLengthErrorCount.current + 1);
          return;
        }
      } else {
        setNodeLengthErrorCount(0);

        const mismatchDetails: (Node | undefined)[][] = [];
        for (let i = 0; i < normalizedStoredNodes.length; i++) {
          const serverNode = normalizedInitialNodes[i];
          const clientNode = normalizedStoredNodes[i];

          if (isEqual(serverNode, clientNode)) continue;

          if (
            (clientNode?.type === 'unknownNode' ||
              serverNode?.type === 'unknownNode') &&
            clientNode?.data.mediaGroupId === serverNode?.data.mediaGroupId
          ) {
            continue;
          }

          mismatchDetails.push([serverNode, clientNode]);
        }

        if (!mismatchDetails.length) return;

        console.log('Nodes mismatch: ', mismatchDetails);

        // Filter through cases that are acceptable

        // Check if content is the issue
        const hasSameContent = mismatchDetails.filter(([server, client]) => {
          if (!server || !client) return true;

          if (
            server.type !== 'floatingTextNode' ||
            client.type !== 'floatingTextNode'
          ) {
            // Continue to next check if not floatingTextNode
            return true;
          }

          const { content: _sContent } = server.data;
          const { content: _cContent } = client.data;

          // If content is the same then continue
          return _sContent === _cContent;
        });

        console.log({ hasSameContent });

        // Check if has parents issue
        const hasSameParents = hasSameContent.filter(([server, client]) => {
          if (!server || !client) return true;

          if (server.type === 'groupNode' || client.type === 'groupNode') {
            // Group nodes do not have parents... continue to next check
            return true;
          }

          const { parentId: _sParentId, extent: _sExtent } = server;
          const { parentId: _cParentId, extent: _cExtent } = client;

          // If parent id and extent is the same then continue
          return _sParentId === _cParentId && _sExtent === _cExtent;
        });

        console.log({ hasSameParents });

        // Check if autoHeight setting issue
        const hasSameAutoHeight = hasSameParents.filter(([server, client]) => {
          if (!server || !client) return true;

          if (
            server.type !== 'floatingTextNode' ||
            client.type !== 'floatingTextNode'
          ) {
            // Auto-height only applies to floating text nodes
            return true;
          }

          const { autoHeightDisabled: _sAutoHeightDisabled, ...otherServerPropsData } =
            server.data;

          const { height: _sHeight, ...otherServerProps } = server;

          otherServerProps.data = otherServerPropsData;

          const { autoHeightDisabled: _cAutoHeightDisabled, ...otherClientPropsData } =
            client.data;

          const { height: _cHeight, ...otherClientProps } = client;

          otherClientProps.data = otherClientPropsData;

          // If height and autoHeightDisabled is the same then continue
          return _cHeight === _sHeight && _sAutoHeightDisabled === _cAutoHeightDisabled;
        });

        console.log({ hasSameAutoHeight });

        // Check is position and width is the same
        const hasSamePositionAndWidth = hasSameAutoHeight.filter(([server, client]) => {
          if (!server || !client) return false;

          const {
            expanded: _sDataExpanded,
            height: _sDataHeight,
            width: _sDataWidth,
            ...otherServerPropsData
          } = server.data;

          const {
            position: _sPos,
            width: _sWidth,
            height: _sHeight,
            ...otherServerProps
          } = server;

          otherServerProps.data = otherServerPropsData;

          const {
            expanded: _cDataExpanded,
            height: _cDataHeight,
            width: _cDataWidth,
            ...otherClientPropsData
          } = client.data;

          const {
            position: _cPos,
            width: _cWidth,
            height: _cHeight,
            ...otherClientProps
          } = client;

          otherClientProps.data = otherClientPropsData;

          // Other props are equal therefore a position or height issue
          return !isEqual(otherServerProps, otherClientProps);
        });

        console.log({ hasSamePositionAndWidth });

        if (hasSamePositionAndWidth.length === 0) {
          // Assuming non-critical states will resolve as user progresses
          return;
        }

        console.log('Remaining issues: ', hasSamePositionAndWidth);
      }

      if (selfCorrectingEdge.current) {
        clearTimeout(selfCorrectingEdge.current);
        console.log('Cancelling Reloading for initialEdges...');
      }

      console.log('Reloading for initialNodes...');

      store.current = useCreateBoardStore({
        initialNodes: addSelectedState(normalizedStoredNodes, embellishedInitialNodes),
        initialEdges: addSelectedState(
          normalizedStoredEdges,
          removeDuplicateEdges(
            removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
          )
        ),
      });

      return;
    }

    setNodeLengthErrorCount(0);

    const processedInitialEdges = removeDuplicateEdges(
      removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
    );

    if (
      // Short circuit if isEqual is not needed
      normalizedStoredEdges.length !== processedInitialEdges.length ||
      !isEqual(normalizedStoredEdges, processedInitialEdges)
    ) {
      if (normalizedStoredEdges.length !== processedInitialEdges.length) {
        console.log('Edges length mismatch: ', initialEdges, normalizedStoredEdges);
      } else {
        const mismatchDetails = [];
        for (let i = 0; i < normalizedStoredNodes.length; i++) {
          if (!isEqual(normalizedStoredEdges[i], processedInitialEdges[i])) {
            mismatchDetails.push([processedInitialEdges[i], normalizedStoredEdges[i]]);
          }
        }
        console.log('Edges mismatch: ', mismatchDetails);
      }
      console.log('Reloading for initialEdges...');

      selfCorrectingEdge.current = setTimeout(() => {
        store.current = useCreateBoardStore({
          initialNodes: addSelectedState(
            normalizedStoredNodes,
            embellishedInitialNodes
          ),
          initialEdges: addSelectedState(normalizedStoredEdges, processedInitialEdges),
        });
      }, 750);
    }

    if (selfCorrectingEdge.current) {
      clearTimeout(selfCorrectingEdge.current);
      console.log('Cancelling Reloading for initialEdges...');
    }
  }, [initialNodes, initialEdges]);

  return (
    <BoardStoreContext.Provider value={data}>{children}</BoardStoreContext.Provider>
  );
};

function cloneNode(node: Node) {
  return {
    ...node,
    data: { ...node.data },
  };
}

function addSelectedState<T extends { id: string; selected?: boolean }>(
  oldElements: T[],
  newElements: T[]
) {
  const oldSelectedElementIds = oldElements
    .filter((el) => el.selected)
    .map((el) => el.id);

  return newElements.map((el) => {
    if (oldSelectedElementIds.includes(el.id)) {
      el.selected = true;
    }

    return el;
  });
}

function normalizeStoredNodes(nodes: Node[] | undefined) {
  if (!nodes) return [];

  // Remove non-determinant props
  return nodes
    .filter(
      (node) => node.id !== 'menuPlaceholder' && node.type !== 'tempFileUploadNode'
    )
    .map((node) => {
      const { className, dragging, measured, resizing, selected, style, ...coreProps } =
        node;

      const { isInEditMode, ...corePropsData } = node.data;

      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete coreProps.hidden;

      if (!coreProps.parentId) {
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete coreProps.parentId;
      }

      if (!coreProps.extent) {
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete coreProps.extent;
      }

      if (coreProps.type === 'floatingTextNode') {
        if (corePropsData.content) {
          const newContent = { ...corePropsData.content };
          removeNestedNullProperties(newContent);
          corePropsData.content = newContent;
        }

        if (typeof corePropsData.height === 'number') {
          coreProps.height = corePropsData.height;
          corePropsData.height = null;
        }

        if (!corePropsData.autoHeightDisabled) {
          coreProps.height = undefined;
        }
      } else {
        if (!corePropsData.expanded) {
          coreProps.height = undefined;
        }
      }

      if (!corePropsData.width) {
        corePropsData.width = null;
      }

      if (!corePropsData.height) {
        corePropsData.height = null;
      }

      if (coreProps.type === 'groupNode') {
        if (corePropsData.label === null) {
          // biome-ignore lint/performance/noDelete: undefined property removed for compare
          delete corePropsData.label;
        }
      }

      if (coreProps.type === 'fileNode') {
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete corePropsData.height;
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete corePropsData.expanded;
      }

      if (node.type === 'commentNode') {
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete coreProps.height;
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete corePropsData.height;
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete corePropsData.width;
      }

      return { ...coreProps, data: corePropsData };
    });
}

function normalizeStoredEdges(edges: Edge[] | undefined) {
  if (!edges) return [];

  // Remove non-determinant props
  return edges.map((edge) => {
    const { selected, ...newEdge } = edge;

    if (newEdge.label === undefined || newEdge.label === null) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.label;
    }

    if (newEdge.markerStart === undefined) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.markerStart;
    }

    if (newEdge.markerEnd === undefined) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.markerEnd;
    }

    return newEdge;
  });
}

function normalizeInitialNodes(nodes: Node[]) {
  return nodes.map((node) => {
    if (node.type === 'groupNode') {
      const { parentId, extent, ...newNode } = node;

      newNode.data = {
        ...node.data,
        expanded: node.data.expanded ?? true,
        height: node.data.height ?? null,
        width: node.data.width ?? null,
      };

      return newNode;
    }

    const { hidden, ...newNode } = { ...cloneNode(node) };

    if (node.type === 'floatingTextNode') {
      if (node.data.content) {
        const newContent = { ...node.data.content };
        removeNestedNullProperties(newContent);
        newNode.data.content = newContent;
      }

      if (!newNode.data.autoHeightDisabled) {
        newNode.height = undefined;
      } else if (typeof node.data.height === 'number') {
        newNode.height = node.data.height;
      }

      if (!newNode.data.boxColor) {
        newNode.data.boxColor = 'default';
      }

      if (!newNode.data.autoHeightDisabled) {
        newNode.data.autoHeightDisabled = false;
      }

      newNode.data.height = null;
      newNode.data.width = null;

      return newNode;
    }

    if (node.type === 'commentNode') {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.height;
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.data.height;
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.data.width;

      return newNode;
    }

    if (!newNode.parentId) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.parentId;
    }

    if (!newNode.extent) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.extent;
    }

    if (node.type === 'fileNode') {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.data.height;
    }

    if (!node.data.expanded) {
      newNode.height = undefined;
    }

    if (!node.data.height) {
      newNode.data.height = null;
    }

    if (!node.data.width) {
      newNode.data.width = null;
    }

    return newNode;
  });
}

function removeNestedNullProperties(obj: Record<string, any>) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNestedNullProperties(obj[key]);
    }
  }
}

function embellishNodes(nodes: Node[]) {
  // Add derived properties
  return nodes.map((_node) => {
    const node = cloneNode(_node);

    if (node.type === 'groupNode') {
      if (!node.data.expanded) {
        node.className = addClass('collapsed', node.className);
      }

      if (node.data.color) {
        const color = node.data.color as string;
        node.className = addClass(color, node.className);
      }
    }

    if (node.type === 'floatingTextNode') {
      node.className = addClass(
        node.data.autoHeightDisabled ? 'fixedHeight' : 'autoHeight',
        node.className
      );
    }

    node.hidden = findHiddenState(_node, nodes);

    return node;
  });
}

function findHiddenState(node: Node, allNodes: Node[]) {
  const parentFrame = allNodes.find(
    (frame) => frame.type === 'groupNode' && frame.id === node.parentId
  );

  if (parentFrame) {
    return parentFrame.data.expanded === false;
  }

  return false;
}

function removeUnusedEdges({ edges, nodes }: { edges: Edge[]; nodes: Node[] }) {
  const nodeIds = nodes.map((node) => node.id);

  return edges.filter((edge) => {
    return nodeIds.includes(edge.source) && nodeIds.includes(edge.target);
  });
}

function removeDuplicateEdges(edges: Edge[]): Edge[] {
  return edges.filter((edge, idx) => {
    return edges.findIndex((_edge) => _edge.id === edge.id) === idx;
  });
}
