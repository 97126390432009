import {
  createUploadRequest,
  getProcessingRequestDetailMany,
  uploadRequestIntoProcessingRequest,
} from '@/api/processingRequest';
import { warningToast } from '@/utils/createToast';
import {
  type ProcessingRequest,
  getWorkspace,
  uploadFileToUploadRequest,
} from '@spaceduck/api';
import { unreachable } from '@spaceduck/utils';
import prettyBytes from 'pretty-bytes';
import { useCallback, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { z } from 'zod';
import { usePoll } from './usePoll';

const KILOBYTES = 1000;
const MEGABYTES = 1000 * KILOBYTES;
const GIGABYTES = 1000 * MEGABYTES;
const MAX_FILE_SIZE = 1 * GIGABYTES;

const validPageFileSchema = (size?: number) =>
  z
    .any()
    .refine(
      (file: File) => file.size <= (size ?? MAX_FILE_SIZE),
      `max file size is ${prettyBytes(size ?? MAX_FILE_SIZE)}`
    );

const validTotalSizeSchema = (totalSize: number) =>
  z
    .any()
    .refine(
      (files: File[]) =>
        files.reduce((totalSize, file) => totalSize + file.size, 0) <= totalSize,
      `max total size is ${prettyBytes(totalSize)}`
    );

const invalidFileSizeToast = (fallbackErrorMessage: string, maxFileSize?: number) => {
  warningToast({
    title: maxFileSize ? `File size limit: ${prettyBytes(maxFileSize)}` : undefined,
    message: maxFileSize
      ? `The maximum file size is ${prettyBytes(maxFileSize)}.`
      : fallbackErrorMessage,
  });
};

const invalidTotalSizeToast = (fallbackErrorMessage: string, maxTotalSize?: number) => {
  warningToast({
    title: maxTotalSize
      ? `Storage limit left: ${prettyBytes(maxTotalSize)}`
      : undefined,
    message: maxTotalSize
      ? `The storage left for your account is ${prettyBytes(maxTotalSize)}.`
      : fallbackErrorMessage,
    buttonText: 'See plans',
  });
  return;
};

export type ProcessingResult = {
  file: File;
  key: string;
  request: ProcessingRequest;
};

export type UseProcessAssetsProps = {
  onCreate?: (item: UploadingItem) => void;
  onSuccess?: (item: ProcessingResult) => void;
  onFail?: (item: ProcessingResult) => void;
  onReject?: (item: ProcessingResult) => void;
  onComplete?: (item: ProcessingResult) => void;
  onError?: (items: UploadingItem) => void;
  onAbort?: (info: { key: string; file: File; reason: any }) => void;
  onInvalid?: (info: { key: string | undefined; file: File; reason: any }) => void;
  pollInterval?: number;
};

export type UploadingItem = {
  kind: 'uploading';
  key: string;
  file: File;
};
type UploadRequestCreatedItem = {
  kind: 'uploadRequestCreated';
  key: string;
  file: File;
  uploadRequestId: string;
};
type PerformingUploadItem = {
  kind: 'performingUpload';
  key: string;
  file: File;
  uploadRequestId: string;
};
type WaitingItem = {
  kind: 'waiting';
  key: string;
  file: File;
  request: ProcessingRequest;
};

const safeCall = <T>(fn: () => T) => {
  try {
    fn();
  } catch (error) {
    console.error('Error during event handler', error);
  }
};

const useMap = <T>() => {
  const map = useRef(new Map<string, T>());
  const register = useCallback((key: string, value: T) => {
    map.current.set(key, value);
    return key;
  }, []);
  const find = useCallback((key: string) => map.current.get(key), []);
  const forget = useCallback((key: string) => map.current.delete(key), []);
  return { register, find, forget };
};

const ensureKey = (provided?: string) => provided ?? v4();

const isAbortError = (error: unknown) =>
  error instanceof DOMException && error.name === 'AbortError';

export const useProcessAssets = ({
  onCreate,
  onSuccess,
  onFail,
  onReject,
  onComplete,
  onError,
  onAbort,
  onInvalid,
  pollInterval,
}: UseProcessAssetsProps = {}) => {
  const [pending, setPending] = useState<
    (UploadingItem | UploadRequestCreatedItem | PerformingUploadItem | WaitingItem)[]
  >([]);
  const state = useMap<{ file: File; abort: AbortController }>();

  const forget = useCallback((key: string) => {
    setPending((existing) => existing.filter((item) => item.key !== key));
    state.forget(key);
  }, []);

  const findSignal = useCallback((key: string) => state.find(key)?.abort.signal, []);

  const pump = useCallback(
    (key: string, request: ProcessingRequest) => {
      const file = state.find(key)?.file;

      if (file === undefined) {
        return;
      }

      if (request.state === 'W' || request.state === 'P') {
        setPending((existing) =>
          existing.map((item) =>
            item.key === key ? { kind: 'waiting', key, file, request } : item
          )
        );
      } else if (request.state === 'S') {
        safeCall(() => onSuccess?.({ file, key, request }));
        safeCall(() => onComplete?.({ file, key, request }));
        forget(key);
      } else if (request.state === 'F') {
        safeCall(() => onFail?.({ file, key, request }));
        safeCall(() => onComplete?.({ file, key, request }));
        forget(key);
      } else if (request.state === 'R') {
        safeCall(() => onReject?.({ file, key, request }));
        safeCall(() => onComplete?.({ file, key, request }));
        forget(key);
      } else {
        throw unreachable('Unknown request state', request.state);
      }
    },
    [forget, onSuccess, onFail, onComplete, onReject]
  );

  const performInsert = useCallback(
    async (
      key: string,
      file: File,
      workspaceId: string | undefined,
      processingKind: 'media' | 'avatar' | 'comment_attachment' | undefined
    ) => {
      const signal = findSignal(key);

      if (signal?.aborted) {
        safeCall(() => onAbort?.({ key, file, reason: signal.reason }));
        forget(key);
        return;
      }

      const { uploadRequest } = await createUploadRequest({
        sizeBytes: file.size,
        fileName: file.name,
        workspaceId,
      });

      if (signal?.aborted) {
        safeCall(() => onAbort?.({ key, file, reason: signal.reason }));
        forget(key);
        return;
      }

      try {
        await uploadFileToUploadRequest({
          uploadRequest,
          file,
          signal: findSignal(key),
        });
      } catch (error) {
        if (isAbortError(error)) {
          safeCall(() => onAbort?.({ key, file, reason: signal?.reason }));
        } else {
          console.error('Upload failed', error);
          safeCall(() => onError?.({ kind: 'uploading', key, file }));
        }
        forget(key);
        return;
      }

      if (signal?.aborted) {
        safeCall(() => onAbort?.({ key, file, reason: signal.reason }));
        forget(key);
        return;
      }

      let intoProcessingRequestResult: Awaited<
        ReturnType<typeof uploadRequestIntoProcessingRequest>
      >;
      try {
        intoProcessingRequestResult = await uploadRequestIntoProcessingRequest({
          uploadRequestId: uploadRequest.id,
          kind: processingKind,
          signal: findSignal(key),
        });
      } catch (error) {
        if (isAbortError(error)) {
          safeCall(() => onAbort?.({ key, file, reason: signal?.reason }));
        } else {
          console.error('Failed to create processing request', error);
          safeCall(() => onError?.({ kind: 'uploading', key, file }));
        }
        forget(key);
        return;
      }

      const { processingRequest } = intoProcessingRequestResult;
      pump(key, processingRequest);
    },
    [pump, forget, onAbort, onError]
  );

  const insertSingle = useCallback(
    (
      file: File,
      workspaceId?: string,
      _key?: string,
      kind?: 'media' | 'avatar' | 'comment_attachment'
    ) => {
      const key = ensureKey(_key);
      state.register(key, { file, abort: new AbortController() });
      const uploadingItem: UploadingItem = {
        kind: 'uploading',
        key,
        file,
      };

      setPending((existing) => [...existing, uploadingItem]);
      try {
        void performInsert(key, file, workspaceId, kind);
      } catch (e) {
        setPending((existing) => existing.filter((item) => item.key !== key));
        safeCall(() => onError?.(uploadingItem));
        forget(key);
        throw e;
      }
      onCreate?.(uploadingItem);
      return uploadingItem;
    },
    [performInsert, forget, onError]
  );

  const insert = useCallback(
    async (
      params: {
        files: FileList | File[];
        keys?: string[];
        maxTotalSize?: number;
      } & (
        | {
            kind: 'avatar';
          }
        | {
            kind?: 'media';
            workspaceId: string;
          }
        | {
            kind: 'comment_attachment';
            workspaceId: string;
          }
      )
    ) => {
      const { files, keys, kind } = params;
      const filesArray = [];
      for (const file of files) {
        filesArray.push(file);
      }
      let maxFileSize: number | undefined;
      let maxTotalSize: number | undefined;
      // let plan: PlanLabel | undefined;
      if (params.kind !== 'avatar' || !params.kind) {
        try {
          const { workspace } = await getWorkspace(params.workspaceId);
          // plan = PLAN_LABELS[workspace.plan];
          maxFileSize =
            workspace.capabilities.find((c) => c.capability === 'upload')?.limitLeft ??
            undefined;
          maxTotalSize =
            workspace.capabilities.find((c) => c.capability === 'useStorage')
              ?.limitLeft ?? undefined;
        } catch (e) {
          console.error('Error calculating file size limits for workspace', e);
        }
      }
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i]!;
        const result = validPageFileSchema(maxFileSize).safeParse(file);
        if (result.success) {
          continue;
        }
        invalidFileSizeToast(
          result.error.issues.map((issue) => issue.message).join('; '),
          maxFileSize
        );
        safeCall(() => onInvalid?.({ key: keys?.[i], file, reason: result.error }));
        return;
      }

      if (maxTotalSize) {
        const totalSizeValid = validTotalSizeSchema(maxTotalSize).safeParse(filesArray);
        if (!totalSizeValid.success) {
          invalidTotalSizeToast(
            totalSizeValid.error.issues.map((issue) => issue.message).join('; '),
            maxTotalSize
          );
          for (let i = 0; i < filesArray.length; i++) {
            const file = filesArray[i]!;
            safeCall(() =>
              onInvalid?.({ key: keys?.[i], file, reason: totalSizeValid.error })
            );
          }
          return;
        }
      }

      return filesArray.map((f, idx) =>
        insertSingle(
          f,
          params.kind === 'avatar' ? undefined : params.workspaceId,
          keys?.[idx],
          kind
        )
      );
    },
    [insertSingle, onInvalid]
  );

  const poll = useCallback(async () => {
    const waiting = pending.filter(
      (item): item is WaitingItem => item.kind === 'waiting'
    );
    if (waiting.length === 0) {
      return 0;
    }
    const byId = new Map(waiting.map((item) => [item.request.id, item]));

    const { processingRequests } = await getProcessingRequestDetailMany(
      waiting.map(({ request }) => request.id)
    );

    for (const request of processingRequests) {
      const item = byId.get(request.id);
      if (item === undefined) {
        continue;
      }
      try {
        pump(item.key, request);
      } catch (error) {
        console.error('Asset processing pump failed', { key: item.key, request });
      }
    }

    return waiting.length;
  }, [pump, pending]);

  usePoll(pollInterval ?? 3000, pollInterval !== undefined && pending.length > 0, poll);

  const abort = useCallback(
    (key: string, reason?: string) => state.find(key)?.abort.abort(reason),
    []
  );

  return { insert, abort, pending };
};
