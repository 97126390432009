import BulletList from '@tiptap/extension-bullet-list';
import { InputRule, wrappingInputRule } from '@tiptap/react';

export default BulletList.extend({
  addInputRules() {
    return [
      wrappingInputRule({
        // Overriding default regex to stop '+' from adding list item
        find: /^\s*([-*])\s$/,
        type: this.type,
      }),
      new InputRule({
        find: /^\[(\s|\-|x|X)?\]$/,
        handler({ state, range, chain }) {
          const { from, to } = range;

          state.doc.nodesBetween(from, to, (node, pos) => {
            // Only apply to bullet lists or ordered lists
            if (!['bulletList', 'orderedList'].includes(node.type.name)) return;

            const originalNodeTypeName = node.type.name;

            // Only affects one item
            if (node.childCount !== 1) return;

            const combination = node.child(0).textContent;
            const isChecked = ['[x'].includes(combination);
            const isUnchecked = ['[', '[ '].includes(combination);

            if (isChecked || isUnchecked) {
              const paragraph = state.schema.nodes.paragraph?.create();

              if (!paragraph) return;
              const taskItem = state.schema.nodes.taskItem?.createAndFill(
                { checked: isChecked },
                paragraph
              );

              if (!taskItem) return;
              const taskList = state.schema.nodes.taskList?.createAndFill({}, taskItem);

              if (!taskList) return;

              chain()
                .deleteNode(originalNodeTypeName)
                .insertContentAt(pos, taskList)
                .run();
            }
          });
        },
      }),
    ];
  },
});
