export const spaceColors = [
  'neutral1',
  'neutral2',
  'purple',
  'blue',
  'sky',
  'green',
  'yellow',
  'amber',
  'red',
  'pink',
] as const;

export type SpaceColor = (typeof spaceColors)[number];

export const spaceColorHex = new Map<SpaceColor, string>([
  ['neutral1', 'var(--space-color-neutral-1)'],
  ['neutral2', 'var(--space-color-neutral-2)'],
  ['purple', 'var(--space-color-purple)'],
  ['blue', 'var(--space-color-blue)'],
  ['sky', 'var(--space-color-sky)'],
  ['green', 'var(--space-color-green)'],
  ['yellow', 'var(--space-color-yellow)'],
  ['amber', 'var(--space-color-amber)'],
  ['red', 'var(--space-color-red)'],
  ['pink', 'var(--space-color-pink)'],
]);
