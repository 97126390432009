export const FALLBACK_VIDEO_WIDTH = 1200;
export const FALLBACK_VIDEO_HEIGHT = 720;
export const PORTRAIT_MIN_WIDTH = 390;
export const LANDSCAPE_MAX_WIDTH = 1440;

export type orientation = 'landscape' | 'portrait';

export const getOrientation = (width: number, height: number): orientation => {
  return width >= height ? 'landscape' : 'portrait';
};

export function getBunnyDisplayWidth(width: number) {
  return Math.max(width, PORTRAIT_MIN_WIDTH);
}

export function getBunnyDisplayHeight(width: number, height: number) {
  const ratio = width < PORTRAIT_MIN_WIDTH ? PORTRAIT_MIN_WIDTH / width : 1;

  return height * ratio;
}
