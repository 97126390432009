import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'sonner';

import { isProject, safeUrl, type ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon64 } from '@spaceduck/icons';

import { useListMediaGroups } from '@api/mediaGroup';
import { useRestoreProject } from '@api/project';
import { toastApiErrorOr } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import MediaGroupGrid from '@components/MediaGroupGrid';
import Head from '@components/Head';
import NoEntries from '@components/NoEntries';
import SpaceCard from '@components/SpaceCard';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Breadcrumb from '@ui/Breadcrumb';
import breadcrumbStyles from '@ui/Breadcrumb.module.scss';
import Toast from '@ui/Toast';
import createToast from '@utils/createToast';
import { TrashTabs } from './common/TrashTabs';
import styles from './Trash.module.scss';
import { useInView } from 'react-intersection-observer';

const { Trash } = Icon64;
const { Delete } = Icon16;

const TRASH_TOAST_ID = 'trashToast';

function DeletedProjectItem({ project }: { project: ProjectDTO }) {
  const { mutateAsync: restoreProject } = useRestoreProject();
  const projectId = project.id;
  const restore = useCallback(async () => {
    try {
      await restoreProject(projectId);
    } catch (error) {
      return toastApiErrorOr(error, 'Failed to restore space', {
        iconVariant: 'warning',
        titleText: 'Restore Error',
        bodyText:
          'An unknown error occurred while restoring space. Please try again later',
      });
    }
    createToast({
      bodyText: 'Space restored',
      iconVariant: 'success',
    });
  }, [projectId]);
  return (
    <div className={styles.deletedProject}>
      <div onClick={restore} className={styles.restoreOverlay}>
        <p>Restore</p>
      </div>
      <div className={styles.projectCard}>
        <SpaceCard key={project.id} project={project} />
      </div>
    </div>
  );
}

function DeletedProjectList({ workspaceId }: { workspaceId: string }) {
  const { data: projects } = useWorkspaceProjects(workspaceId, {
    isDeleted: true,
  });
  if (!projects?.projects.length) {
    return <EmptyTrash />;
  }
  return (
    <div className={styles.projects}>
      {projects.projects.filter(isProject).map((project) => (
        <DeletedProjectItem key={project.id} project={project} />
      ))}
    </div>
  );
}

const EmptyTrash = () => {
  return (
    <div className={styles.emptyTrashContainer}>
      <NoEntries icon={<Trash />}>
        <h1>So clean. Much good. Wow.</h1>
        <p>
          One man's trash is another man's job to take out... Yours is so clean, so
          good, so empty—just like my emotions.
        </p>
      </NoEntries>
    </div>
  );
};

export default function TrashPage({ show }: { show: 'items' | 'projects' }) {
  const [showIntroMessage, setShowIntroMessage] = useState(true);
  const workspaceId = useWorkspaceId();
  const location = useLocation();
  const {
    data,
    isError,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    isDeleted: true,
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  useEffect(() => {
    const baseUrl = workspaceId ? safeUrl`/workspaces/${workspaceId}/trash` : null;
    const { pathname } = window.location;

    if (!showIntroMessage) return;
    setShowIntroMessage(false);

    if (!(baseUrl && pathname.includes(baseUrl))) return;

    toast.custom(
      (t) => (
        <Toast
          title={'Trash'}
          body={
            'Your items will be permanently deleted after 30 days. To restore an item, simply click on it.'
          }
          iconVariant={'info'}
          onClick={() => toast.dismiss(t)}
          buttonText="Got it"
        />
      ),
      {
        id: TRASH_TOAST_ID,
      }
    );
  }, [location, showIntroMessage, workspaceId]);

  const selected = useMemo(() => new Set<string>(), []);

  if (!workspaceId) {
    return null;
  }

  const mediaGroups = data?.pages.flatMap((page) => page.mediaGroups) || [];
  return (
    <>
      <Head title={'Trash'} />
      <header className={breadcrumbStyles.headerBreadcrumbs}>
        <Breadcrumb
          breadcrumb={[
            {
              icon: <Delete />,
              text: 'Trash',
            },
          ]}
        />
      </header>
      <TrashTabs />
      {show === 'items' && (
        <>
          <MediaGroupGrid
            isError={isError}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            noResults={<EmptyTrash />}
            mediaGroups={mediaGroups}
            selected={selected}
          />
          {/* Triggers page fetch when in view */}
          <div ref={ref} style={{ width: '100%', height: '10px' }} />
        </>
      )}
      {show === 'projects' && <DeletedProjectList workspaceId={workspaceId} />}
    </>
  );
}
