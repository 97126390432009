import clsx from 'clsx';
import { useMemo } from 'react';

import type { CommentAttachment } from '@spaceduck/api';

import type { ProcessingResult } from '@hooks/useProcessAssets';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import { FileListItem, ViewFileListItem } from './FileListItem';
import styles from './FileList.module.scss';

type FileListProps = {
  files: Map<string, File>;
  isInEditMode?: boolean;
  processedFiles: ProcessingResult[];
  removeFile: (id: string) => void;
};

export default function FileList({
  files,
  isInEditMode,
  processedFiles,
  removeFile,
}: FileListProps) {
  const processedFileIds = useMemo(() => {
    return processedFiles.map((file) => file.key);
  }, [processedFiles]);

  if (!files.size) return null;

  if (isInEditMode) {
    return (
      <div
        className={clsx(styles.fileListContainer, styles.vertical, styles.isInEditMode)}
      >
        <ul className={styles.list}>
          {Array.from(files, ([fileKey, file]) => {
            return (
              <li key={fileKey}>
                <FileListItem
                  file={file}
                  fileKey={fileKey}
                  isInEditMode
                  processedFileIds={processedFileIds}
                  removeFile={removeFile}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.fileListContainer}>
      <ScrollArea
        style={css({
          '--width': '100%',
          '--maxHeight': 'var(--radix-popper-available-height, 100%)',
        })}
        orientation="horizontal"
      >
        <ul className={styles.list}>
          {Array.from(files, ([fileKey, file]) => {
            return (
              <li key={fileKey}>
                <FileListItem
                  fileKey={fileKey}
                  file={file}
                  isInEditMode={isInEditMode}
                  removeFile={removeFile}
                  processedFileIds={processedFileIds}
                />
              </li>
            );
          })}
        </ul>
      </ScrollArea>
    </div>
  );
}

type ViewFileListProps = {
  files?: CommentAttachment[] | null;
  isAnnotation?: boolean;
  isInEditMode?: boolean;
  onClick?: (id: string) => void;
};

export const ViewFileList = ({
  files,
  isAnnotation,
  isInEditMode,
  onClick,
}: ViewFileListProps) => {
  if (!files?.length) return null;

  return (
    <div
      className={clsx(
        styles.fileListContainer,
        styles.vertical,
        isInEditMode && styles.isInEditMode
      )}
    >
      <ul className={styles.list}>
        {files.map((file) => {
          return (
            <li key={file.id}>
              <ViewFileListItem
                id={file.id}
                isAnnotation={isAnnotation}
                isInEditMode={isInEditMode}
                name={file.filename}
                onClick={onClick}
                url={file.url}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
