import clsx from 'clsx';
import { type CSSProperties, forwardRef } from 'react';

import type { MenuItemProps } from './Item';
import { MenuTrigger } from './Trigger';
import { WrapWithTooltip } from './WrapWithTooltip';
import styles from './GridItem.module.scss';

type MenuGridItemProps = Pick<
  MenuItemProps,
  'isActive' | 'className' | 'disabled' | 'icon' | 'onClick' | 'tooltip'
> & { style?: CSSProperties };

export const MenuGridItem = forwardRef<HTMLButtonElement, MenuGridItemProps>(
  (
    {
      isActive,
      className,
      disabled = false,
      icon,
      onClick,
      style,
      tooltip,
    }: MenuGridItemProps,
    ref
  ) => {
    return (
      <WrapWithTooltip tooltip={tooltip}>
        <button
          className={clsx(
            'menuGridItem',
            styles.menuGridItem,
            isActive && styles.isActive,
            className
          )}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          type="button"
          style={style}
        >
          <MenuTrigger icon={icon} />
        </button>
      </WrapWithTooltip>
    );
  }
);
