import type { ProjectMode as ProjectModeType } from '@spaceduck/api';
import { Icon16, Icon24, type IconProps } from '@spaceduck/icons';

export type ProjectModeProps = {
  mode: ProjectModeType;
  set?: 16 | 24;
} & IconProps;

export default function ProjectMode(projectModeProps: ProjectModeProps) {
  const { mode, set, ...iconProps } = projectModeProps;
  const icons = set === 16 ? Icon16 : Icon24;
  const { Project, PrivateProject } = icons;

  switch (mode) {
    case 'private':
      return <PrivateProject {...iconProps} />;
    default:
      return <Project {...iconProps} />;
  }
}
