import { memo, useCallback } from 'react';

import { Icon16 } from '@spaceduck/icons';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import {
  MenuButton,
  MenuButtonGrid as ButtonGrid,
  MenuContainer as Container,
  MenuTitle as Title,
} from './menu';
import type {
  EdgeManagementProps,
  NodeManagementProps,
  PatchFnParams,
} from './SideMenu';

const { TrashDelete } = Icon16;

export const ActionsMenu = memo((props: NodeManagementProps & EdgeManagementProps) => {
  const { nodes, edges, setNodes, setEdges, selectedNodes, selectedEdges, patch } =
    props;

  const setSelectedNodes = useBoardStore((state) => state.setSelectedNodes);
  const setSelectedEdges = useBoardStore((state) => state.setSelectedEdges);

  const deleteSelectedEdges = useCallback(() => {
    const updatedState: PatchFnParams = {
      nodes: undefined,
      edges: undefined,
    };

    if (selectedEdges.length) {
      updatedState.edges = setEdges((edges) =>
        edges.filter((edge) => !selectedEdges.includes(edge.id))
      );
      setSelectedEdges([]);
    }

    if (selectedNodes.length) {
      updatedState.nodes = setNodes((nodes) =>
        nodes.filter((node) => !selectedNodes.includes(node.id))
      );
      setSelectedNodes([]);
    }

    patch(updatedState);
  }, [
    nodes,
    selectedNodes,
    setNodes,
    setSelectedNodes,
    edges,
    selectedEdges,
    setEdges,
    setSelectedEdges,
    patch,
  ]);

  return (
    <Container>
      <Title>Actions</Title>
      <ButtonGrid>
        <MenuButton onClick={deleteSelectedEdges} tooltipContent="Delete selected">
          <TrashDelete />
        </MenuButton>
      </ButtonGrid>
    </Container>
  );
});
