import { DropdownMenuCheckboxItem } from '@radix-ui/react-dropdown-menu';
import type {
  FieldInstance,
  MediaGroupCategoryDetail,
  MediaGroupCategoryFieldKind,
  MediaGroupDetailDTO,
} from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import clsx from 'clsx';
import { type ReactNode, useState } from 'react';

import { usePatchMediaGroup } from '@api/mediaGroup';
import { useSetMediaGroupCategoryFieldInstances } from '@api/mediaGroupCategory';
import {
  useListMediaGroupCategories,
  useMediaGroupCategory,
  useProject,
} from '@api/project';
import MediaGroupStatusIcon from '@components/icons/MediaGroupStatus';
import { CategoryKind } from '@icons/CategoryKind';
import Button from '@ui/Button';
import CheckboxInput from '@ui/Checkbox';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import { InfiniteDropdown } from '@ui/InfiniteDropdown';
import createToast from '@utils/createToast';
import styles from './InfoCategories.module.scss';
import {
  Checkbox,
  DateField,
  Email,
  Empty,
  NumberField,
  Phone,
  Relation,
  Tag,
  Text,
  Url,
} from './categoryFields/index';
import Spinner from '@/components/ui/Spinner';

const { ProjectCategory, Status } = Icon16;

export default function InfoCategories({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDetailDTO;
}) {
  const { category, categoryFields } = mediaGroup;
  const { data: project } = useProject(mediaGroup.project?.id || null);
  const [categoryFilterText, setCategoryFilterText] = useState('');
  const {
    data: categoryListing,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useListMediaGroupCategories(mediaGroup.project?.id ?? null, categoryFilterText);

  const categoryId = category?.id ?? null;
  const categoryLabel = useCategoryLabel(category, mediaGroup.project?.id ?? null);

  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();

  const handleStatusUpdate = async (statusId: string | null) => {
    await patchMediaGroup({
      mediaGroupId: mediaGroup.id,
      patch: { status: statusId },
    });
    createToast({
      titleText: 'Status updated',
      bodyText: 'Status was updated',
      iconVariant: 'success',
    });
  };

  const handleCategoryChange = async (categoryId: string | null) => {
    await patchMediaGroup({
      mediaGroupId: mediaGroup.id,
      patch: { categoryId },
    });
    createToast({
      titleText: 'Category updated',
      bodyText: 'Content category was updated',
      iconVariant: 'success',
    });
  };

  if (categoryId === null && !mediaGroup.project) {
    // TODO(BACK): category null may mean not possible or not set until API upgrade
    return null;
  }

  return (
    <div className={styles.container}>
      {project && (
        <div className={styles.field}>
          <div className={styles.label}>
            <Status />
            Status
          </div>
          <div className={styles.value}>
            <div>
              <DropdownMenu
                className={styles.statusMenu}
                isPadded
                triggerContent={
                  <Button
                    className={styles.status}
                    variant="outlined"
                    size="sm"
                    iconBefore={
                      <MediaGroupStatusIcon status={mediaGroup.status} size={16} />
                    }
                  >
                    {mediaGroup.status?.label || 'No status'}
                  </Button>
                }
              >
                {project.project.mediaGroupStatuses.map((status) => (
                  <MenuItem
                    key={status.id}
                    onSelect={() => {
                      handleStatusUpdate(status.id);
                    }}
                  >
                    <MediaGroupStatusIcon status={status} size={16} />
                    {status.label}
                  </MenuItem>
                ))}
              </DropdownMenu>
            </div>
          </div>
        </div>
      )}
      <div className={styles.field}>
        <div className={styles.label}>
          <ProjectCategory />
          Category
        </div>
        <div className={styles.value}>
          <div>
            <InfiniteDropdown
              className={styles.categoryMenu}
              onFilterTextChange={setCategoryFilterText}
              filterTextDebounce={250}
              initialFilterText=""
              isPadded
              triggerContent={
                <div>
                  <Button
                    className={styles.category}
                    variant="outlined"
                    size="sm"
                    iconBefore={<ProjectCategory />}
                  >
                    {categoryLabel}
                  </Button>
                </div>
              }
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
            >
              <DropdownMenuCheckboxItem onSelect={() => handleCategoryChange(null)}>
                <CheckboxInput
                  asMenuItem
                  defaultChecked={mediaGroup.category === null}
                  icon={<ProjectCategory />}
                  singleSelection={true}
                >
                  Uncategorized
                </CheckboxInput>
              </DropdownMenuCheckboxItem>
              {categoryListing?.pages
                .flatMap((page) => page.categories)
                .map(({ id, label }) => (
                  <DropdownMenuCheckboxItem
                    key={id}
                    onSelect={() => handleCategoryChange(id)}
                  >
                    <CheckboxInput
                      asMenuItem
                      defaultChecked={mediaGroup.category?.id === id}
                      key={id}
                      icon={<ProjectCategory />}
                      singleSelection={true}
                      value={id}
                    >
                      {label}
                    </CheckboxInput>
                  </DropdownMenuCheckboxItem>
                ))}
            </InfiniteDropdown>
          </div>
        </div>
      </div>
      {categoryFields.map((field) => {
        return (
          <div
            className={clsx(styles.field, styles[`${field.kind}Field`])}
            key={field.fieldId}
          >
            <div className={styles.label}>
              <CategoryKind kind={field.kind} />
              {field.label}
            </div>
            <div className={styles.value}>
              <FieldInstanceContent
                categoryId={categoryId}
                fieldId={field.fieldId}
                instances={field.instances}
                kind={field.kind}
                mediaGroupId={mediaGroup.id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const useCategoryLabel = (
  category: MediaGroupDetailDTO['category'],
  projectId: string | null
): ReactNode => {
  // TODO(BACK): In future, the media group will include the label for this
  const existingLabel = category && 'label' in category ? category.label : null;

  const { data, status } = useMediaGroupCategory(
    existingLabel ? null : (category?.id ?? null)
  );

  if (projectId === null) {
    return null;
  }

  if (existingLabel) {
    return existingLabel;
  }

  if (!category?.id) {
    return 'Uncategorized';
  }

  if (status === 'pending') {
    return <Spinner size={16} />;
  }

  if (status === 'error') {
    return '???';
  }

  return data.category.label;
};

export const getCategoryFieldById = (
  id: string,
  category: MediaGroupCategoryDetail
) => {
  return category.fields.find((field) => field.id === id) ?? null;
};

export type UpdateFieldFn = (instances: FieldInstance[]) => Promise<void>;
export type CommonProps = {
  canEdit: boolean;
  category: MediaGroupCategoryDetail;
  fieldId: string;
  instances: FieldInstance[];
  kind: MediaGroupCategoryFieldKind;
  refetchData: () => Promise<void>;
  updateField?: UpdateFieldFn;
};

export type CommonEditProps = {
  setShouldShowEditView: React.Dispatch<React.SetStateAction<boolean>>;
  updateField?: UpdateFieldFn;
};

const FieldInstanceContent = ({
  categoryId,
  fieldId,
  instances,
  kind,
  mediaGroupId,
}: {
  categoryId: string | null;
  fieldId: string;
  instances: FieldInstance[];
  kind: MediaGroupCategoryFieldKind;
  mediaGroupId: string;
}) => {
  const { data: categoryData, refetch } = useMediaGroupCategory(categoryId);
  const refetchData = async () => {
    await refetch();
  };
  const { mutateAsync: setFieldInstances } = useSetMediaGroupCategoryFieldInstances();

  if (!categoryData) return null;
  const category = categoryData.category;
  const canEdit =
    category.capabilities?.find(({ capability }) => capability === 'edit')?.capable ??
    false;
  const updateField = canEdit
    ? async (instances: FieldInstance[], callback?: (response: unknown) => void) => {
        const res = await setFieldInstances({
          fieldId,
          mediaGroupId,
          instances,
        });
        callback?.(res);
      }
    : undefined;

  const commonProps: CommonProps = {
    canEdit,
    category,
    fieldId,
    instances,
    kind,
    refetchData,
    updateField,
  };

  switch (kind) {
    case 'checkbox':
      return <Checkbox {...commonProps} />;
    case 'date':
      return <DateField {...commonProps} />;
    case 'email': {
      return <Email {...commonProps} />;
    }
    case 'multi-select':
      return <Tag {...commonProps} />;
    case 'number':
      return <NumberField {...commonProps} />;
    case 'phone':
      return <Phone {...commonProps} />;
    case 'relationship':
      return <Relation {...commonProps} />;
    case 'select':
      return <Tag {...commonProps} />;
    case 'text':
      return <Text {...commonProps} />;
    case 'url':
      return <Url {...commonProps} />;
    default:
      return <Empty />;
  }
};
