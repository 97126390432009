import {
  addWritingResource,
  type AiProvider,
  type ChatSessionListFilterParams,
  type ChatSessionMode,
  createAIWritingRequest,
  type CreateAIWritingRequestOptions,
  createChatSession,
  getAIWritingRequestDetails,
  getChatSession,
  getChatSessionHistory,
  getMediaGroupAiSettings,
  type MediaGroupAiSettingsPatch,
  patchMediagroupAiSettings,
  queryChatSession,
  removeChatSession,
  removeWritingResource,
  triggerMediaGroupProcessing,
} from '@spaceduck/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryDisabledError } from './errors';
import {
  asMilliseconds,
  attemptAsync,
  exponentialBackoff,
  sleep,
} from '@spaceduck/utils';

export const researchChatKeys = {
  list: ['chats', 'project'],
  listFiltered: (filterParams: ChatSessionListFilterParams) => [
    ...researchChatKeys.list,
    filterParams,
  ],
  one: (chatId: string | null) => ['chats', chatId],
};

export const mediaGroupAiSettingsKeys = {
  one: (mediaGroupId: string | null) => ['mediaGroupAiSettings', mediaGroupId],
};

export const aiWritingRequestKeys = {
  one: (writingRequestId: string | null) => ['aiWritingRequest', writingRequestId],
};

type UseCreateChatSessionOptions = {
  retry?: number;
  retryInterval?: number;
  retryExponent: number;
};

export const useCreateChatSession = (options?: UseCreateChatSessionOptions) => {
  return useMutation({
    retry: options?.retry ?? 3,
    retryDelay: (failureCount) =>
      exponentialBackoff(
        options?.retryInterval ?? 1000,
        options?.retryExponent ?? 1.05,
        failureCount
      ),
    mutationFn: ({
      projectId,
      provider,
      mediaGroupIds,
      initialQuery,
      mode,
    }: {
      provider: AiProvider;
      mediaGroupIds: string[];
      initialQuery: string;
      mode: ChatSessionMode;
      projectId?: string;
    }) => createChatSession(provider, mediaGroupIds, initialQuery, mode, projectId),
  });
};

export const useGetChatSession = (chatId: string | null) => {
  const enabled = !!chatId;
  return useQuery({
    enabled,
    queryKey: researchChatKeys.one(chatId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatSession(chatId);
    },
  });
};

export const useGetChatSessionList = (filterParams: ChatSessionListFilterParams) => {
  const enabled = !!filterParams.kind;
  return useQuery({
    enabled,
    queryKey: researchChatKeys.listFiltered(filterParams),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatSessionHistory(filterParams);
    },
  });
};

export const useRemoveChatSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeChatSession,
    onSuccess: (_, chatSessionId) => {
      queryClient.invalidateQueries({
        queryKey: researchChatKeys.one(chatSessionId),
      });
      queryClient.invalidateQueries({
        queryKey: researchChatKeys.list,
      });
    },
  });
};

type UseQueryChatSessionOptions = {
  retry?: number;
  retryInterval?: number;
  retryExponent: number;
};

export const useQueryChatSession = (options?: UseQueryChatSessionOptions) => {
  return useMutation({
    retry: options?.retry ?? 3,
    retryDelay: (failureCount) =>
      exponentialBackoff(
        options?.retryInterval ?? 1000,
        options?.retryExponent ?? 1.05,
        failureCount
      ),
    mutationFn: ({
      query,
      chatId,
    }: {
      chatId: string;
      query: string;
    }) => queryChatSession(query, chatId),
  });
};

export const useTriggerMediaGroupProcessing = () => {
  return useMutation({
    mutationFn: triggerMediaGroupProcessing,
  });
};

export const useMediaGroupAiSettings = (mediaGroupId: string | null) => {
  const enabled = !!mediaGroupId;
  return useQuery({
    enabled,
    queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupAiSettings(mediaGroupId);
    },
  });
};

export const usePatchMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: MediaGroupAiSettingsPatch) => {
      return patchMediagroupAiSettings(mediaGroupId, patch);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

export const useAddResourceMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (resourceId: string) => {
      return addWritingResource(mediaGroupId, resourceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

export const useRemoveResourceMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (resourceId: string) => {
      return removeWritingResource(mediaGroupId, resourceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

export class WritingRequestFailedError extends Error {}
export class WritingRequestRejectedError extends Error {}
export class WritingRequestTimeoutError extends Error {}

export type CreateAndWaitForAIWritingRequest = CreateAIWritingRequestOptions & {
  pollDelay?: number;
  pollTimeout?: number;
};
const createAndWaitForAIWritingRequest = async ({
  pollDelay = 1500,
  pollTimeout = asMilliseconds({ minutes: 10 }),
  ...options
}: CreateAndWaitForAIWritingRequest) => {
  const {
    request: { id: writingRequestId },
  } = await createAIWritingRequest(options);

  const fetch = () =>
    getAIWritingRequestDetails({ writingRequestId, signal: options?.signal });

  const startedAt = new Date().getTime();
  const abortAt = startedAt + pollTimeout;

  for (let attemptNum = 0; ; attemptNum++) {
    await sleep(pollDelay);
    options?.signal?.throwIfAborted();

    const now = new Date().getTime();
    if (now > abortAt) {
      console.error('AI Writing request poll timeout', {
        startedAt,
        abortAt,
        now,
        attemptNum,
      });
      throw new WritingRequestTimeoutError();
    }

    const fetchResult = await attemptAsync(fetch);

    if (!fetchResult.success) {
      const { error } = fetchResult;
      console.error('Failed to fetch writing request details', {
        writingRequestId,
        attemptNum,
        error,
      });
      continue;
    }

    const result = fetchResult.data.request.result;

    console.debug('Poll: Waiting for AI Writing Request', {
      writingRequestId,
      attemptNum,
      result,
    });

    if (result.status === 'success') {
      return result;
    }

    if (result.status === 'failed') {
      throw new WritingRequestFailedError();
    }

    if (result.status === 'rejected') {
      throw new WritingRequestRejectedError();
    }
  }
};

export const useCreateAndWaitForAIWritingRequest = () => {
  return useMutation({
    mutationFn: createAndWaitForAIWritingRequest,
  });
};

export const useCreateAIWritingRequest = () => {
  return useMutation({
    mutationFn: createAIWritingRequest,
  });
};

export const useAIWritingRequest = (writingRequestId: string | null) => {
  const enabled = writingRequestId !== null;

  return useQuery({
    enabled,
    queryKey: aiWritingRequestKeys.one(writingRequestId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getAIWritingRequestDetails({ writingRequestId });
    },
  });
};
