import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';
import type { FieldErrors, SubmitHandler } from 'react-hook-form';

import { Icon24 } from '@spaceduck/icons';

import { Header } from '@components/Header';
import AuthSelector from '@components/login/AuthSelector';
import Button from '@ui/Button';
import createToast from '@utils/createToast';
import { EMAIL_REGEX } from '@utils/regex';
import type { LoginPurpose } from './schema';
import styles from './Form.module.scss';

const { Email } = Icon24;

export type EmailFormData = {
  email: string;
};

type EmailFormProps = {
  onSubmit: SubmitHandler<EmailFormData>;
  isLoading?: boolean;
  nextUrl?: string;
  userHint?: string | null;
  purpose?: LoginPurpose | null;
};

const YEAR = new Date().getFullYear();

const EmailForm = ({ onSubmit, nextUrl, userHint }: EmailFormProps) => {
  'use no memo';
  const defaultEmail = userHint?.match(EMAIL_REGEX) ? userHint : null;
  const {
    register,
    handleSubmit,
    formState: { errors: emailErrors },
  } = useForm<EmailFormData>();

  const onInvalid = (err: FieldErrors<EmailFormData>) => {
    if (err.email?.type === 'required') {
      createToast({
        titleText: 'Email is required',
        bodyText: 'Please enter your email address.',
        iconVariant: 'danger',
      });
      return;
    }

    createToast({
      titleText: 'Invalid Email',
      bodyText: 'The email you entered is invalid. Please double-check and try again.',
      iconVariant: 'danger',
    });
  };

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header>
          Work. School. Life.
          <br />
          <span className={styles.highlight}>Make sense of all complex things.</span>
        </Header>
        <div className={styles.form}>
          <AuthSelector
            nextUrl={nextUrl}
            userHint={userHint}
            disableAppleLogin
            disableMSLogin
          />
          <div className={styles.separator}>or</div>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className={styles.inputGroup}>
              <span className={styles.icon}>
                <Email />
              </span>
              <input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid Email',
                  },
                })}
                type="text"
                defaultValue={defaultEmail ?? ''}
                placeholder="Enter address"
                className={clsx(emailErrors.email && 'hasError')}
              />
            </div>
            <Button className={styles.btnEmailLogin} variant="primary" size="lg">
              Continue
            </Button>
          </form>
        </div>
        <p className={styles.terms}>
          By creating an account, you are agreeing to our{' '}
          <a
            href="https://www.spaceduck.com/legal/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and acknowledging receipt of our{' '}
          <a
            href="https://www.spaceduck.com/legal/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <ul className={styles.footer}>
        <li>&copy; {YEAR} Spaceduck.com</li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://spaceduck.com" target="_blank">
            Home
          </a>
        </li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://www.spaceduck.com/support/contact" target="_blank">
            Support
          </a>
        </li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://www.spaceduck.com/legal" target="_blank">
            Legal
          </a>
        </li>
      </ul>
    </div>
  );
};

export default EmailForm;
