import clsx from 'clsx';

import HeroIcon from '@components/HeroIcon';
import Button from '@ui/Button';
import { heroIconMap } from '@/types/Icons';
import styles from './HeroIconPicker.module.scss';

export default function HeroIconPicker({
  className,
  onClick,
  query,
  style,
}: {
  className?: string;
  onClick?: (iconName: string) => void;
  query?: string;
  style?: React.CSSProperties;
}) {
  const icons = getHeroIconList(query);

  return (
    <ul className={clsx(styles.heroIconList, className)} style={style}>
      {[...icons].map(([title, iconName]) => (
        <li key={iconName}>
          <Button
            onClick={() => onClick?.(iconName)}
            size="sm"
            title={title}
            variant="icon"
          >
            <HeroIcon name={iconName} height={16} width={16} />
          </Button>
        </li>
      ))}
    </ul>
  );
}

export function getHeroIconList(query?: string) {
  if (!query) return heroIconMap;

  const pattern = query.toLowerCase();
  return new Map(
    [...heroIconMap].filter(([key]) => key.toLowerCase().includes(pattern))
  );
}
