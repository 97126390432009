import { memo, useCallback, type MouseEvent, type ReactNode } from 'react';

import styles from './ButtonGrid.module.scss';

export const MenuButtonGrid = memo(({ children }: { children: ReactNode }) => {
  const handleMouseDown = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  return (
    <div className={styles.buttonGrid} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
});
