import clsx from 'clsx';
import { upperFirst } from 'lodash';
import {
  memo,
  type MouseEvent,
  useCallback,
  type ButtonHTMLAttributes,
  type ReactNode,
  useMemo,
} from 'react';

import { DetailsModalTooltip } from '@components/detailsModal/comments/DetailsModalTooltip';
import styles from './Button.module.scss';

type MenuButtonProps = {
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  tooltipContent?: string;
  tooltipContentRaw?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const MenuButton = memo((props: MenuButtonProps) => {
  const { tooltipContent, tooltipContentRaw, ...buttonProps } = props;
  const handleMouseDown = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  const toolTipText = useMemo(() => {
    if (tooltipContentRaw) return tooltipContentRaw;
    if (tooltipContent) return upperFirst(tooltipContent.replace(/([0-9])/, ' $1'));

    return undefined;
  }, [tooltipContent, tooltipContentRaw]);

  if (toolTipText) {
    return (
      <ButtonToolTip {...props} content={toolTipText}>
        <span>
          <Button onMouseDown={handleMouseDown} {...buttonProps} />
        </span>
      </ButtonToolTip>
    );
  }

  return <Button onMouseDown={handleMouseDown} {...buttonProps} />;
});

const ButtonToolTip = memo(
  ({ children, content }: { children: ReactNode; content: string }) => {
    return <DetailsModalTooltip content={content}>{children}</DetailsModalTooltip>;
  }
);

const Button = memo(
  ({
    className,
    children,
    isActive,
    ...props
  }: {
    className?: string;
    children: ReactNode;
    isActive?: boolean;
  } & ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
      <button
        {...props}
        className={clsx(styles.button, isActive && styles.active, className)}
      >
        {children}
      </button>
    );
  }
);
