import type { CommentDTO, ListCommentsResponse } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import upperFirst from 'lodash/upperFirst';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import { useListComments } from '@api/comment';
import LoadingDuck from '@assets/img/LoadingDuck';
import { useCommentsStore } from '@stores/useCommentsStore';
import Button from '@ui/Button';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getDuckFact } from '@utils/getDuckFact';
import { type ActionableInvitesInComment, CommentThread } from '../comments/Comment';
import { NewCommentTextBox } from '../comments/CommentTextbox';
import styles from './Comments.module.scss';
import { useShallow } from 'zustand/shallow';

const { Comment, Share } = Icon16;

export default function DetailsModalSidebarComments({
  isLoading,
  mediaGroupId,
}: {
  isLoading: boolean;
  mediaGroupId: string;
}) {
  const {
    data: comments,
    isLoading: commentsIsLoading,
    isSuccess,
  } = useListComments(mediaGroupId);

  const { commentsContainerRef, scrollToBottomOfComments } = useCommentsStore(
    useShallow((state) => ({
      commentsContainerRef: state.commentsContainerRef,
      scrollToBottomOfComments: state.scrollToBottomOfComments,
    }))
  );

  const [actionableInvites, setActionableInvites] =
    useState<ActionableInvitesInComment>();

  useEffect(() => {
    scrollToBottomOfComments();
  }, [comments?.comments.length]);

  const duckFact = useMemo(getDuckFact, []);

  const onComment = (actionableInvites: ActionableInvitesInComment) => {
    if (actionableInvites.userIds.length) {
      setActionableInvites(actionableInvites);
    }
  };

  return (
    <div className={clsx(styles.comments, isLoading ?? styles.isLoading)}>
      {(isLoading || commentsIsLoading) && (
        <div className={styles.commentsLoading}>
          <LoadingDuck width={115} height={110} />
          <div className="body5">{duckFact}</div>
        </div>
      )}
      {!(isLoading || commentsIsLoading) &&
        (!comments || comments.comments.length === 0) && (
          <div className={styles.noComments}>
            <Comment size={32} />
            <div className="body5">
              <p>Collaborate with your team!</p>
              <Button
                className={styles.noCommentsShare}
                iconBefore={<Share />}
                onClick={copyUrlToClipboard}
                size="sm"
                type="button"
                variant="primary"
              >
                Share
              </Button>
            </div>
          </div>
        )}
      {!(isLoading || commentsIsLoading || !isSuccess) && (
        <CommentsBlock
          actionableInvites={actionableInvites}
          listComments={comments}
          mediaGroupId={mediaGroupId}
          ref={commentsContainerRef}
          scrollToBottomOfComments={scrollToBottomOfComments}
        />
      )}
      <div className={styles.commentBoxWrapper}>
        <div className={styles.fade} />
        <NewCommentTextBox
          mediaGroupId={mediaGroupId}
          isLoading={isLoading}
          onComment={onComment}
        />
      </div>
    </div>
  );
}

type CommentsBlockProps = {
  actionableInvites?: ActionableInvitesInComment;
  listComments: ListCommentsResponse;
  mediaGroupId: string;
  scrollToBottomOfComments: () => void;
};

const commentTabs = ['all', 'unresolved', 'resolved'] as const;
type CommentTab = (typeof commentTabs)[number];

const getFilter = (filter: CommentTab) => {
  switch (filter) {
    case 'resolved':
      return (comment: CommentDTO) => comment.kind === 'alive' && !!comment.resolved;
    case 'unresolved':
      return (comment: CommentDTO) =>
        comment.kind === 'alive' &&
        !comment.resolved &&
        comment.replies &&
        comment.replies?.length > 0;
    default:
      return (comment: CommentDTO) => comment;
  }
};

const CommentsBlock = forwardRef<HTMLDivElement, CommentsBlockProps>(
  (
    {
      actionableInvites,
      listComments,
      mediaGroupId,
      scrollToBottomOfComments,
    }: CommentsBlockProps,
    ref
  ) => {
    const [activeCommentFilter, setActiveCommentFilter] = useState<CommentTab>('all');

    const comments = useMemo(() => {
      return listComments.comments.filter(getFilter(activeCommentFilter));
    }, [listComments.comments, activeCommentFilter]);

    useEffect(() => {
      scrollToBottomOfComments();
    }, [activeCommentFilter]);

    if (!listComments?.comments || listComments.comments.length === 0) return null;

    return (
      <div className={styles.commentsContainerWrapper} ref={ref}>
        <div className={styles.tabButtonWrapper}>
          <div className={styles.fade} />
          <TabButtonWrapper className={styles.tabs} variant="dark">
            {commentTabs.map((tab) => {
              return (
                <TabButton
                  isActive={activeCommentFilter === tab}
                  key={tab}
                  onClick={() =>
                    activeCommentFilter !== tab && setActiveCommentFilter(tab)
                  }
                  variant="dark"
                >
                  {upperFirst(tab)}
                </TabButton>
              );
            })}
          </TabButtonWrapper>
        </div>
        <div className={clsx('commentsSidebar', styles.commentsContainer)}>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <CommentThread
                key={comment.id}
                comment={comment}
                mediaGroupId={mediaGroupId}
                showPreviews={true}
                actionableInvites={actionableInvites}
              />
            ))
          ) : (
            <div
              className={styles.noFilteredComments}
            >{`No ${activeCommentFilter === 'all' ? '' : upperFirst(activeCommentFilter)} comments`}</div>
          )}
        </div>
      </div>
    );
  }
);
