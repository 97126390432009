import { useCopyMediaGroup } from '@/api/mediaGroup';
import { useDeleteConfirmModal } from '@/components/ui/ConfirmModal';

export default function useMoveMediaGroupConfirmModal({
  onConfirm,
}: {
  onConfirm?: () => void;
} = {}) {
  const { mutateAsync: copyMediaGroup } = useCopyMediaGroup();
  const moveMediaGroupsConfirmModal = useDeleteConfirmModal({
    title: 'Moving space',
    subtitle: <Subtitle />,
    confirmText: 'Move',
    onConfirm: async ({
      mediaGroupIds,
      projectId,
    }: {
      mediaGroupIds: string[];
      projectId?: string;
    }) => {
      copyMediaGroup({
        mediaGroupIds,
        projectId,
        mode: 'move',
      });
      onConfirm?.();
    },
  });

  return moveMediaGroupsConfirmModal;
}

const Subtitle = () => {
  return (
    <div>
      <p>
        Be aware that transferring this asset to a different space will keep all
        associated comments linked. However, contributors without permissions in the new
        space may lose access.
      </p>
      <p>Additionally, any associated status and category fields will be cleared.</p>
    </div>
  );
};
