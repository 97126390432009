import type { AliveCommentDTO } from '@spaceduck/api';
import type { JSONContent } from '@tiptap/react';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useShallow } from 'zustand/shallow';

import { useListComments, useMakeComment } from '@api/comment';
import CommentPin from '@components/CommentPin';
import CommentSummary from '@components/CommentSummary';
import { useUserInfo } from '@hooks/useAuth';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { ControlledDropdownMenu } from '@ui/DropdownMenu';
import { CommentThread } from './Comment';
import { CommentTextBox } from './CommentTextbox';
import styles from './AnnotatedMedia.module.scss';

function Annotation({
  children,
  defaultOpen,
  name,
  imageUrl,
  summary,
  x,
  y,
}: {
  children: React.ReactNode;
  defaultOpen?: boolean;
  name: string;
  imageUrl: string | null;
  summary?: React.ReactNode;
  x: number;
  y: number;
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
  return (
    <div
      className={styles.annotation}
      style={{
        left: `${x * 100}%`,
        top: `${y * 100}%`,
      }}
    >
      <ControlledDropdownMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        defaultOpen={defaultOpen}
        side="right"
        className={styles.annotationDropdownMenu}
        modal={false}
        triggerContent={
          <div className={styles.pinContainer}>
            <CommentPin imageUrl={imageUrl} name={name} />
          </div>
        }
      >
        {children}
      </ControlledDropdownMenu>
      {summary && (
        <div
          className={clsx(styles.summaryHoverWrapper, isOpen && styles.hideSummary)}
          onClick={(event) => {
            setIsOpen(true);
            event.stopPropagation();
          }}
        >
          {summary}
        </div>
      )}
    </div>
  );
}

export default function AnnotatedMedia({
  children,
  className,
  mediaGroupId,
  mediaId,
  ...props
}: {
  children: React.ReactElement;
  className?: string;
  mediaGroupId: string;
  mediaId: string;
}) {
  const me = useUserInfo();
  const { data: commentsData } = useListComments(mediaGroupId);
  const { mutateAsync: createComment } = useMakeComment(mediaGroupId);
  const { isCommenting, newCommentPosition, setIsCommenting, setNewCommentPosition } =
    useDetailsModalStore(
      useShallow(
        ({
          isCommenting,
          newCommentPosition,
          setIsCommenting,
          setNewCommentPosition,
        }) => ({
          isCommenting,
          newCommentPosition,
          setIsCommenting,
          setNewCommentPosition,
        })
      )
    );
  const handleImageClick = async (event: React.MouseEvent) => {
    if (!isCommenting) {
      return;
    }
    const { offsetX, offsetY } = event.nativeEvent;
    const { width, height } = event.currentTarget.getBoundingClientRect();
    const x = offsetX / width;
    const y = offsetY / height;
    setNewCommentPosition({ x, y, mediaId });
    setIsCommenting(false);
  };

  const handleMakeComment = async (
    content: string,
    x: number,
    y: number,
    document?: JSONContent,
    attachmentAssetIds?: string[]
  ) => {
    await createComment({
      pointAt: { x, y, mediaId },
      content,
      document,
      attachmentAssetIds,
    });
    setNewCommentPosition(null);
    setIsCommenting(false);
  };

  const comments =
    commentsData?.comments.filter(
      (
        comment
      ): comment is AliveCommentDTO & {
        pointAt: { x: number; y: number; mediaId: string };
      } => comment.kind === 'alive' && comment.pointAt?.mediaId === mediaId
    ) ?? [];

  useHotkeys(
    'Escape',
    () => {
      setNewCommentPosition(null);
    },
    {
      enabled: !!newCommentPosition,
    },
    [newCommentPosition]
  );

  useEffect(() => {
    return setNewCommentPosition(null);
  }, []);

  return (
    <div
      className={clsx(
        styles.annotatedImage,
        className,
        isCommenting && styles.isCommenting
      )}
      {...props}
      onClick={handleImageClick}
    >
      {me && newCommentPosition?.mediaId === mediaId && (
        <Annotation
          key={`${newCommentPosition.x},${newCommentPosition.y}`}
          x={newCommentPosition.x}
          y={newCommentPosition.y}
          name={me.preferredName}
          imageUrl={me.avatarUrl}
          defaultOpen={true}
        >
          <CommentTextBox
            className={styles.createCommentTextBox}
            isLoading={false}
            autofocus="end"
            onSubmit={async (content, document, attachmentAssetIds) => {
              await handleMakeComment(
                content,
                newCommentPosition.x,
                newCommentPosition.y,
                document,
                attachmentAssetIds
              );
            }}
          />
        </Annotation>
      )}
      {comments.map((comment) => (
        <Annotation
          key={comment.id}
          name={comment.createdBy.name}
          imageUrl={comment.createdBy.avatarUrl}
          summary={<CommentSummary comment={comment} className={styles.summary} />}
          x={comment.pointAt.x}
          y={comment.pointAt.y}
        >
          <div className={styles.annotationContent}>
            <CommentThread
              className={styles.annotated}
              comment={comment}
              isAnnotation
              mediaGroupId={mediaGroupId}
            />
          </div>
        </Annotation>
      ))}
      {children}
    </div>
  );
}
