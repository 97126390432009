import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import type { BaseColor } from '@reactFlow/types/colors';
import { MenuButton } from './Button';
import styles from './ColorSwatchMenu.module.scss';

type ColorSwatchMenuComponentProps<T extends string> = {
  active?: T;
  colors: ReadonlyArray<string>;
  onClick?: (color: Readonly<string>) => void;
};

const { ShapeColor } = Icon16;

export const ColorSwatchMenu = memo(
  (props: ColorSwatchMenuComponentProps<BaseColor>) => {
    return (
      <>
        {props.colors.map((color) => {
          return <ColorButton key={color} {...props} color={color} />;
        })}
      </>
    );
  }
);

const ColorButton = memo(
  (props: ColorSwatchMenuComponentProps<BaseColor> & { color: string }) => {
    const { active, onClick, color } = props;

    const handleClick = useCallback(() => {
      onClick?.(color);
    }, [onClick, color]);

    const isActive = useMemo(() => {
      return active === color;
    }, [active, color]);

    return (
      <MenuButton
        key={color}
        className={styles[color]}
        isActive={isActive}
        onClick={handleClick}
        tooltipContent={color}
      >
        <ShapeColor className={clsx(styles.swatch, styles[color])} />
      </MenuButton>
    );
  }
);
