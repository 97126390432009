import { type Dispatch, useCallback, useEffect, useState } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import AutoHeight from 'embla-carousel-auto-height';
import Fade from 'embla-carousel-fade';

import { Icon16 } from '@spaceduck/icons';

import { Header } from '@components/Header';
import {
  ContinueButton,
  Dots,
  Frame,
  LoginLink,
  PlanSelection,
  ProfessionSelection,
  Slide,
} from './index';

import styles from './WorkspaceWizard.module.scss';
import { useSession } from '@/hooks/useAuth';
import type { BillingInterval, WorkspacePatch } from '@spaceduck/api';

import useWorkspaceId from '@/hooks/useWorkspaceId';
import { useManageSubscription } from '@/pages/settings/workspace/BillingAndPlan/useManageSubscription';
import { usePatchWorkspace, useWorkspace } from '@/api/workspace';
import { isCapable } from '@/api/util';
import { useNavigate } from 'react-router';
import { urlFor } from '@/urls';
import { useGetSubscriptionInfo } from '@/api/billing';
import Spinner from '../Spinner';

const { StatusSuccess } = Icon16;
const TRIAL_CUTOFF_DATE = '3rd March 2025';

const RedirectToWorkspaceSelector = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(urlFor('userSettingsWorkspaces'));
  }, []);
  return <Spinner />;
};

const RedirectToWorkspace = ({ workspaceId }: { workspaceId: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(urlFor('workspaceHome', { workspaceId }));
  }, []);
  return <Spinner />;
};

export default function WorkspaceOnboardingWizard() {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { align: 'center', containScroll: false },
    [AutoHeight(), Fade()]
  );
  const [industry, setIndustry] = useState<string>('');
  const [memberProfession, setMemberProfession] = useState<string>('');
  const [interval, setPlan] = useState<BillingInterval>('year');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const workspaceId = useWorkspaceId();
  const { data: workspaceData, status: workspaceStatus } = useWorkspace(workspaceId);
  const { data: subscriptionInfo, status: subscriptionStatus } =
    useGetSubscriptionInfo(workspaceId);
  const canUpgrade = isCapable(
    'workspaceSubscribe',
    workspaceData?.workspace.capabilities
  ).capable;
  const goToSlide = (index: number) => {
    emblaApi?.scrollTo(index);
  };
  const { mutateAsync: patchWorkspace } = usePatchWorkspace();

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);
  const { mutateAsync: manageSubscription } = useManageSubscription();
  const handleSubmit = useCallback(async () => {
    setDisableSubmit(true);

    if (!interval) {
      goToSlide(2);
      setDisableSubmit(false);
      return;
    }

    const industryTrimmed = industry.trim();
    const memberProfessionTrimmed = memberProfession.trim();

    if (workspaceId) {
      await manageSubscription({
        action: 'create',
        workspaceId,
        interval,
        profession: memberProfessionTrimmed || undefined,
      });

      if (industryTrimmed || memberProfessionTrimmed) {
        const aiSettings: NonNullable<WorkspacePatch['aiSettings']> = {};

        if (industryTrimmed) {
          aiSettings.industry = industryTrimmed;
        }

        if (memberProfessionTrimmed) {
          aiSettings.memberProfession = memberProfessionTrimmed;
        }

        await patchWorkspace({
          workspaceId,
          patch: { aiSettings },
        });
      }
    }
  }, [industry, memberProfession, interval, goToSlide, setDisableSubmit]);

  if (workspaceStatus === 'pending' || subscriptionStatus === 'pending') {
    return <Spinner />;
  }

  if (workspaceStatus === 'error' || subscriptionStatus === 'error') {
    return <RedirectToWorkspaceSelector />;
  }

  if (
    !canUpgrade ||
    (subscriptionInfo.active && subscriptionInfo.source === 'stripe')
  ) {
    return <RedirectToWorkspace workspaceId={workspaceData.workspace.id} />;
  }
  return (
    <>
      <div className={styles.carousel} ref={emblaRef}>
        <div className={styles.container}>
          {subscriptionInfo.transition && (
            <>
              <Step1 onContinue={emblaApi?.scrollNext} />
              <Step2
                onContinue={emblaApi?.scrollNext}
                industry={industry}
                setIndustry={setIndustry}
                memberProfession={memberProfession}
                setMemberProfession={setMemberProfession}
              />
            </>
          )}
          <Step3
            onContinue={handleSubmit}
            plan={interval}
            setPlan={setPlan}
            disableSubmit={disableSubmit}
          />
        </div>
      </div>
      {subscriptionInfo.transition && (
        <Dots goToSlide={goToSlide} numberOfSlides={3} selectedIndex={selectedIndex} />
      )}
    </>
  );
}

const Step1 = ({
  onContinue,
  trialEndDate = TRIAL_CUTOFF_DATE,
}: {
  trialEndDate?: string;
  onContinue?: () => void;
}) => {
  return (
    <Slide>
      <Header>
        Spaceduck is now <span className={styles.highlight}>Trial-Only</span>
      </Header>
      <Frame>
        <div className={styles.trialUpdate}>
          {`Spaceduck is transitioning to a trial-only model. Activate your 7-day free trial by ${trialEndDate} to retain access to your account and content. You can also use the trial to easily export your content if needed. Feel free to reach out if you have any questions!`}
        </div>
        <ContinueButton className={styles.marginTop8} onClick={onContinue} />
      </Frame>
    </Slide>
  );
};

const Step2 = (props: {
  onContinue?: () => void;
  industry?: string;
  setIndustry: Dispatch<React.SetStateAction<string>>;
  memberProfession?: string;
  setMemberProfession: Dispatch<React.SetStateAction<string>>;
}) => {
  const { onContinue, ...selectionProps } = props;

  return (
    <Slide>
      <Header>
        Your <span className={styles.highlight}>Profession</span>
      </Header>
      <Frame>
        <ProfessionSelection {...selectionProps} />
        <ContinueButton onClick={onContinue} />
      </Frame>
    </Slide>
  );
};

const Step3 = ({
  disableSubmit,
  onContinue,
  plan,
  setPlan,
}: {
  disableSubmit: boolean;
  onContinue?: () => void;
  plan: BillingInterval;
  setPlan: Dispatch<React.SetStateAction<BillingInterval>>;
}) => {
  const session = useSession();

  if (session?.kind !== 'authenticated') {
    return null; // TODO: logoff?
  }
  const trialUsed = session.user.trialUsed;
  return (
    <Slide>
      <Header>
        {trialUsed ? (
          <div>
            Choose the plan
            <br />
            <span className={styles.highlight}>for your new workspace</span>
          </div>
        ) : (
          <div>
            Choose the plan that will apply{' '}
            <span className={styles.highlight}>after your 7-day free trial</span>{' '}
          </div>
        )}
        <div className={styles.trialInfo}>
          <ul>
            {!trialUsed && (
              <li>
                <StatusSuccess />
                <span>7-day free trial</span>
              </li>
            )}
            <li>
              <StatusSuccess />
              <span>Cancel anytime</span>
            </li>
          </ul>
        </div>
      </Header>
      <Frame>
        <PlanSelection billingInterval={plan} setBillingInterval={setPlan} />
        <ContinueButton disabled={disableSubmit} onClick={onContinue}>
          {trialUsed ? 'Continue to your workspace' : 'Start your free trial'}
        </ContinueButton>
        <p className={styles.clarification}>
          {trialUsed
            ? 'Your trial has already been used on another workspace'
            : "You won't be charged until the end of your 7-day trial."}
        </p>
      </Frame>
      <LoginLink />
    </Slide>
  );
};
