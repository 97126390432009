import { useMemo } from 'react';
import dayjs from '@lib/dayjs';
import type { Editor } from '@tiptap/core';
import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';
import { Link } from 'react-router';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import { AuthorAvatar } from '@ui/UserAvatar';
import { urlFor } from '@/urls';
import styles from './InfoDetails.module.scss';
import { graphemeCounter, wordCounter } from '@/utils/text';
import { useEditorText } from '@/hooks/useEditorText';

type InfoDetailsProps = {
  editor?: Editor | null;
  mediaGroup: MediaGroupDetailDTO;
};

const { Details } = Icon16;

export default function InfoDetails({ editor, mediaGroup }: InfoDetailsProps) {
  const {
    author,
    createdAt,
    project,
    workspace: { id: workspaceId },
  } = mediaGroup;

  const originLabel = project ? project.label : 'Draft';
  const originLink = project
    ? urlFor('space', { projectId: project.id })
    : workspaceId
      ? urlFor('workspaceRepository', { workspaceId })
      : undefined;

  return (
    <div className={styles.details}>
      <h3 className={styles.heading}>
        <Details />
        Details
      </h3>
      <dl>
        <dt>Author</dt>
        <dd className={styles.author}>
          <AuthorAvatar author={author} size="xxs" />
          {author.name}
        </dd>
        <dt>Location</dt>
        <dd>
          {originLink ? (
            <Link className={styles.highlighted} to={originLink}>
              {originLabel}
            </Link>
          ) : (
            originLabel
          )}
        </dd>
        <dt>Created</dt>
        <dd>
          <span title={dayjs(createdAt).format('DD/MM/YYYY')}>
            {dayjs(createdAt).format('MMM D')}
          </span>
        </dd>
        {editor && <DocumentDetails editor={editor} mediaGroup={mediaGroup} />}
        {/* TODO: Get size of document (@sergio) */}
        {!!mediaGroup.sizeBytes && (
          <>
            <dt>Storage used</dt>
            <dd>{prettyBytes(mediaGroup.sizeBytes)}</dd>
          </>
        )}
      </dl>
    </div>
  );
}

const DocumentDetails = ({
  editor,
  mediaGroup,
}: { editor: Editor; mediaGroup: MediaGroupDetailDTO }) => {
  const text = useEditorText(editor);
  const chars = useMemo(() => graphemeCounter(text), [text]);
  const words = useMemo(() => wordCounter(text), [text]);

  if (!mediaGroup.document) return null;

  return (
    <>
      {/* TODO: Get last updated timestamp (@sergio) */}
      {/* <dt>Updated</dt> */}
      {/* <dd></dd> */}
      <dt className={styles.dt}>Word count</dt>
      <dd className={clsx(styles.dd, styles.twoColumns)}>
        <span>{words} Words</span>
        <span>{chars} Chars</span>
      </dd>
    </>
  );
};
