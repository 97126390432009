import { type EdgeMarkerType, MarkerType } from '@xyflow/react';
import { isEqual, upperFirst } from 'lodash';
import { memo, type ReactNode, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/shallow';

import { Icon16 } from '@spaceduck/icons';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import type { DefaultMarkerType } from '@reactFlow/stores/boardStore';
import { edgeWidthAsNumber, type EdgeWidth } from '@reactFlow/types/board';
import { boardColors, type BaseColor } from '@reactFlow/types/colors';
import { MenuButton } from '../menu';
import type { EdgeManagementProps } from '../SideMenu';

type Marker = {
  value: DefaultMarkerType;
  placement?: 'start' | 'end';
};

type MenuOption = {
  label: string;
  icon: ReactNode;
  payload: Marker;
};

const {
  ConnectorSimple,
  ConnectorArrowLeft,
  ConnectorArrowRight,
  ConnectorCircleLeft,
  ConnectorCircleRight,
  ConnectorDiamondLeft,
  ConnectorDiamondRight,
} = Icon16;

const menuOptions: MenuOption[] = [
  {
    label: 'No edge marker',
    icon: <ConnectorSimple />,
    payload: { value: null },
  },
  {
    label: 'Arrow start edge marker',
    icon: <ConnectorArrowRight />,
    payload: { value: 'arrow', placement: 'start' },
  },
  {
    label: 'Circle start edge marker',
    icon: <ConnectorCircleRight />,
    payload: { value: 'markerTypeCircle', placement: 'start' },
  },
  {
    label: 'Diamond start edge marker',
    icon: <ConnectorDiamondRight />,
    payload: { value: 'markerTypeDiamond', placement: 'start' },
  },
  {
    label: 'Arrow end edge marker',
    icon: <ConnectorArrowLeft />,
    payload: { value: 'arrow', placement: 'end' },
  },
  {
    label: 'Circle end edge marker',
    icon: <ConnectorCircleLeft />,
    payload: { value: 'markerTypeCircle', placement: 'end' },
  },
  {
    label: 'Diamond end edge marker',
    icon: <ConnectorDiamondLeft />,
    payload: { value: 'markerTypeDiamond', placement: 'end' },
  },
];

export const EdgeMarkerStyleMenu = memo((props: EdgeManagementProps) => {
  const { edges, selectedEdges, setEdges, patch } = props;

  const {
    defaultMarkerStartType,
    setDefaultMarkerStartType,
    defaultMarkerEndType,
    setDefaultMarkerEndType,
  } = useBoardStore(
    useShallow((state) => ({
      defaultMarkerStartType: state.defaultMarkerStartType,
      setDefaultMarkerStartType: state.setDefaultMarkerStartType,
      defaultMarkerEndType: state.defaultMarkerEndType,
      setDefaultMarkerEndType: state.setDefaultMarkerEndType,
    }))
  );

  const setDefaultMarkerType = useCallback(
    (marker: Marker) => {
      if (marker.placement === 'start') {
        setDefaultMarkerEndType(null);
      }

      if (marker.placement === 'end') {
        setDefaultMarkerStartType(null);
      }

      if (marker.value === 'arrow' && marker.placement === 'start') {
        setDefaultMarkerStartType('arrow');
        return;
      }

      if (marker.value === 'arrow' && marker.placement === 'end') {
        setDefaultMarkerEndType('arrow');
        return;
      }

      if (marker.value === 'markerTypeCircle' && marker.placement === 'start') {
        setDefaultMarkerStartType('circle');
        return;
      }

      if (marker.value === 'markerTypeCircle' && marker.placement === 'end') {
        setDefaultMarkerEndType('circle');
        return;
      }

      if (marker.value === 'markerTypeDiamond' && marker.placement === 'start') {
        setDefaultMarkerStartType('diamond');
        return;
      }

      if (marker.value === 'markerTypeDiamond' && marker.placement === 'end') {
        setDefaultMarkerEndType('diamond');
        return;
      }

      setDefaultMarkerStartType(null);
      setDefaultMarkerEndType(null);
    },
    [setDefaultMarkerStartType, setDefaultMarkerEndType]
  );

  const activeMarker: Marker | undefined = useMemo(() => {
    if (selectedEdges.length === 1 && selectedEdges[0]) {
      const edge = edges.find((edge) => edge.id === selectedEdges[0]);
      if (edge?.markerStart) {
        return {
          value: transformMarkerTypeToValue(edge.markerStart),
          placement: 'start',
        };
      }

      if (edge?.markerEnd) {
        return {
          value: transformMarkerTypeToValue(edge.markerEnd),
          placement: 'end',
        };
      }
    }

    return undefined;
  }, [selectedEdges, edges, defaultMarkerStartType, defaultMarkerEndType]);

  const updateSelectedEdges = useCallback(
    (marker: Marker) => {
      if (!selectedEdges.length) return;

      const updatedEdges = setEdges((edges) => {
        return edges.map((edge) => {
          if (!selectedEdges.includes(edge.id)) return edge;

          const svgId =
            marker.value &&
            ['markerTypeCircle', 'markerTypeDiamond'].includes(marker.value)
              ? marker.value
              : undefined;

          const isArrow = marker.value === 'arrow';

          const markerStart =
            marker.placement === 'start'
              ? getMarker({
                  color: edge.data?.color as BaseColor,
                  width: edge.data?.width as EdgeWidth,
                  svgId,
                  isArrow,
                })
              : undefined;

          const markerEnd =
            marker.placement === 'end'
              ? getMarker({
                  color: edge.data?.color as BaseColor,
                  width: edge.data?.width as EdgeWidth,
                  svgId,
                  isArrow,
                })
              : undefined;

          return {
            ...edge,
            markerStart,
            markerEnd,
          };
        });
      });

      patch({ edges: updatedEdges });
    },
    [selectedEdges, edges, patch]
  );

  const handleClick = useCallback(
    (marker: Marker) => {
      setDefaultMarkerType(marker);
      updateSelectedEdges(marker);
    },
    [setEdges, selectedEdges, setDefaultMarkerType, updateSelectedEdges]
  );

  return (
    <>
      {menuOptions.map((option) => (
        <MarkerButton
          key={option.label}
          activeMarker={activeMarker}
          handleClick={handleClick}
          option={option}
        />
      ))}
    </>
  );
});

const MarkerButton = ({
  activeMarker,
  handleClick,
  option,
}: {
  activeMarker?: Marker;
  handleClick: (marker: Marker) => void;
  option: MenuOption;
}) => {
  const onClick = useCallback(() => {
    handleClick(option.payload);
  }, [option.payload, handleClick]);

  const isActive = useMemo(
    () => isEqual(option.payload, activeMarker),
    [option.payload, activeMarker]
  );

  return (
    <MenuButton
      key={option.label}
      isActive={isActive}
      onClick={onClick}
      tooltipContent={option.label}
    >
      {option.icon}
    </MenuButton>
  );
};

function getMarker({
  color,
  width,
  svgId,
  isArrow,
}: {
  color?: BaseColor;
  width?: EdgeWidth;
  svgId?: string;
  isArrow: boolean;
}): EdgeMarkerType | undefined {
  if (isArrow && color && width) {
    return {
      type: MarkerType.Arrow,
      color: boardColors[color],
      strokeWidth: edgeWidthAsNumber[width],
    };
  }

  if (svgId && color) {
    return `${svgId}${upperFirst(color)}`;
  }

  return undefined;
}

function transformMarkerTypeToValue(type: EdgeMarkerType): DefaultMarkerType {
  if (typeof type === 'string') {
    if (type.startsWith('markerTypeCircle')) return 'markerTypeCircle';
    if (type.startsWith('markerTypeDiamond')) return 'markerTypeDiamond';
  }

  return 'arrow';
}
