import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Mention, { type MentionOptions } from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { Extension, useEditor } from '@tiptap/react';
import { useEffect } from 'react';

import type { Chat } from '@spaceduck/api';

import type { SelectedSourceType } from './useSourcesStore';

const mentionConfiguration = ({
  onMentionUpdate,
}: ResearchAssistantTiptapProps): Partial<MentionOptions> => {
  return {
    deleteTriggerWithBackspace: false,
    suggestion: {
      render: () => {
        return {
          onStart: (props) => {
            onMentionUpdate(props.text);
          },
          onUpdate: (props) => {
            onMentionUpdate(props.text);
          },
          onExit: () => {
            onMentionUpdate('');
          },
          onKeyDown: (e) => {
            if (
              e.event.key === 'Enter' ||
              e.event.key === 'ArrowDown' ||
              e.event.key === 'ArrowUp'
            ) {
              // Just handle it so it doesn't bubble and insert a new line
              return true;
            }

            return false;
          },
        };
      },
    },
  };
};

type ResearchAssistantTiptapProps = {
  currentChatSession?: Chat | null;
  initialSources?: SelectedSourceType[];
  onMentionUpdate: (value: string) => void;
  onSend: () => boolean;
  placeholder?: string;
};

const tiptapExtensions = (props: ResearchAssistantTiptapProps) => {
  const extensions = [
    Document,
    Text,
    HardBreak,
    Placeholder.configure({
      placeholder: props.placeholder,
      showOnlyCurrent: false,
    }),
    Extension.create({
      name: 'OverrideEscape',
      addKeyboardShortcuts() {
        return {
          Escape: (props) => {
            props.editor.commands.clearContent();
            return false;
          },
          Enter: () => {
            (() => props.onSend())();
            return true;
          },
        };
      },
    }),
    Paragraph,
    Mention.extend({
      renderText: () => {
        return '';
      },
      addAttributes() {
        return {
          id: {
            isRequired: true,
          },
          label: {
            isRequired: true,
          },
        };
      },
    }).configure(mentionConfiguration(props)),
  ];
  return extensions;
};

export const useResearchAssistantEditor = (props: ResearchAssistantTiptapProps) => {
  const extensions = tiptapExtensions(props);
  const editor = useEditor(
    {
      extensions,
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content:
              props.initialSources?.flatMap((source) => {
                return [
                  {
                    type: 'mention',
                    attrs: source,
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                ];
              }) || [],
          },
        ],
      },
    },
    [props.currentChatSession]
  );

  useEffect(() => {
    editor?.commands.focus('end');
  }, [editor]);

  return editor;
};
