import { memo, type MouseEvent } from 'react';

import { Icon24 } from '@spaceduck/icons';

import styles from './NewMediaGroupButton.module.scss';

const { New } = Icon24;

type NewMediaGroupButtonProps = {
  onClick?: (ev: MouseEvent) => void;
};

const NewMediaGroupButton = memo(({ onClick }: NewMediaGroupButtonProps) => {
  return (
    <button className={styles.newMediaGroupButton} onClick={onClick}>
      <New />
    </button>
  );
});

export default NewMediaGroupButton;
