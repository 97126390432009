import clsx from 'clsx';

import type { ProjectDetailDTO, ProjectDTO } from '@spaceduck/api';

import HeroIcon from '@components/HeroIcon';
import ProjectMode, { type ProjectModeProps } from '@icons/ProjectMode';
import { type SpaceColor, spaceColors } from '@/types/Colors';
import { type HeroIconName, heroIconNames } from '@/types/Icons';

type SpaceIconProps = {
  className?: string;
  fallbackColor?: SpaceColor;
  fallbackIcon?: HeroIconName;
  project: ProjectDTO | ProjectDetailDTO;
  size?: number;
};

export default function SpaceIcon({
  className,
  fallbackColor = 'neutral1',
  fallbackIcon,
  project,
  size = 16,
}: SpaceIconProps) {
  const { iconColor, iconName } = project;

  if (!iconName && !fallbackIcon) return null;
  const icon = (iconName as HeroIconName) || fallbackIcon;
  if (!heroIconNames.includes(icon)) return null;

  const _color = iconColor as SpaceColor;
  const color = iconColor && spaceColors.includes(_color) ? _color : fallbackColor;

  return (
    <HeroIcon
      className={className}
      name={icon}
      spaceColor={color}
      height={size}
      width={size}
    />
  );
}

export const SpaceIconOrProjectMode = (
  props: SpaceIconProps &
    Pick<ProjectModeProps, 'set'> & { projectModeClassName?: string }
) => {
  const { projectModeClassName, set, ...spaceIconProps } = props;
  const { iconName, mode } = props.project;

  if (!iconName) {
    return (
      <ProjectMode
        className={clsx(props.className, projectModeClassName)}
        mode={mode}
        size={props.size}
        set={set}
      />
    );
  }

  return <SpaceIcon {...spaceIconProps} />;
};
