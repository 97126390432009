import { z } from 'zod';

import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import {
  type BillingInterval,
  billingIntervalSchema,
  billingPlanSchema,
} from './schemas';

export const getManageSubscriptionLink = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/portal/`,
    method: 'GET',
    params: {
      workspaceId,
    },
    responseSchema: successfulResponseSchema.extend({
      redirectUrl: z.string(),
    }),
  });

const usageCategoriesSchema = z.enum([
  'fileStorage',
  'aiWritingWordsPerDay',
  'aiAssistantInteractionsPerDay',
  'aiSummaryCount',
]);

export type UsageCategories = z.infer<typeof usageCategoriesSchema>;

export const workspaceUsageSchema = z.object({
  planLimit: z.number().nullable(),
  currentUsage: z.number(),
  limitName: usageCategoriesSchema.nullable().catch(null),
});

export type WorkspaceUsage = z.infer<typeof workspaceUsageSchema>;

export const refetchSubscriptionInfo = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/refetch/`,
    method: 'POST',
    body: {
      workspaceId,
    },
    responseSchema: successfulResponseSchema,
  });

export const subscriptionInfoSchema = successfulResponseSchema.extend({
  interval: billingIntervalSchema,
  plan: billingPlanSchema,
  seats: z.number(),
  assignedSeats: z.number(),
  usage: workspaceUsageSchema.array(),
  // TODO(BACK): remove catch
  active: z.boolean().catch(false),
  transition: z.boolean().catch(true),
  currentPeriodEndTimestamp: z.number().nullable().default(null),
  // TODO(BACK): Remove default.
  source: z.enum(['stripe', 'internal']).nullable().default(null),
  // TODO(BACK): Remove default
  cancelAtPeriodEnd: z.boolean().default(false),
});

export const getSubscriptionInfo = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/info/`,
    method: 'GET',
    params: {
      workspaceId,
    },
    responseSchema: subscriptionInfoSchema,
  });

export type SubscriptionInfo = z.infer<typeof subscriptionInfoSchema>;

export const getUpgradeSubscriptionLink = async (
  workspaceId: string,
  interval: BillingInterval,
  profession?: string
) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/checkout/`,
    body: {
      workspaceId,
      interval,
      profession,
    },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      redirectUrl: z.string(),
    }),
  });

export const sendRenewalRequest = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/request-renewal/`,
    method: 'POST',
    responseSchema: successfulResponseSchema,
  });
