export const baseColors = [
  'yellow',
  'amber',
  'orange',
  'red',
  'pink',
  'purple',
  'green',
  'sky',
  'blue',
  'neutral1',
  'neutral2',
  'neutral3',
] as const;

export type BaseColor = (typeof baseColors)[number];

export const boardColors: Record<BaseColor, string> = {
  yellow: 'var(--react-flow-group-gold-border)',
  amber: 'var(--react-flow-group-roasted-sesame-border)',
  orange: 'var(--react-flow-group-peach-border)',
  red: 'var(--react-flow-group-red-border)',
  pink: 'var(--react-flow-group-pink-border)',
  purple: 'var(--react-flow-group-perry-winkle-border)',
  green: 'var(--react-flow-group-green-border)',
  sky: 'var(--react-flow-group-blue-border)',
  blue: 'var(--react-flow-group-lavender-border)',
  neutral1: 'var(--react-flow-group-neutral-1-border)',
  neutral2: 'var(--react-flow-group-neutral-2-border)',
  neutral3: 'var(--react-flow-group-neutral-3-border)',
};

export const baseTextColors = [
  'default',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'purple',
  'gray',
] as const;

export type TextColor = (typeof baseTextColors)[number];

export const textColors: Record<TextColor, string> = {
  default: 'none',
  red: '#FB7185',
  orange: '#FDBA74',
  yellow: '#FDDF74',
  green: '#6EE599',
  teal: '#57DDCC',
  blue: '#80B7FB',
  purple: '#A29AFA',
  gray: '#B5B7CA',
};
