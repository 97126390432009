import { memo, type ReactNode, useCallback, type MouseEvent } from 'react';

import styles from './Title.module.scss';

export const MenuTitle = memo(({ children }: { children: ReactNode }) => {
  const handleMouseDown = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  return (
    <div className={styles.title} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
});
