import { useCallback, useMemo } from 'react';
import type { Editor } from '@tiptap/core';
import { Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import styles from './AIMenuFollowUp.module.scss';
import { useEditorText } from '@/hooks/useEditorText';
import { graphemeCounter, wordCounter } from '@/utils/text';

const { Add, Repeat, TrashCan } = Icon24;

export default function AIMenuFollowUp({
  discard,
  editor,
  isLoading = false,
  resultsEditor,
  retry,
}: {
  discard?: () => void;
  editor: Editor;
  isLoading?: boolean;
  resultsEditor: Editor | null;
  retry?: () => void;
}) {
  const handleInsertBelow = () => {
    if (!resultsEditor) return;

    /* cspell:disable-next-line */
    editor.commands.selectTextblockEnd();
    editor.commands.insertContent(resultsEditor.getJSON());
  };

  const handleRetry = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      retry?.();
    },
    [retry]
  );
  const text = useEditorText(resultsEditor);
  const chars = useMemo(() => graphemeCounter(text), [text]);
  const words = useMemo(() => wordCounter(text), [text]);

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <div className={styles.aiMenuFollowUpContainer}>
      <div className={styles.counter}>
        <strong>{words}</strong> words, <strong>{chars}</strong> chars
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.button}
          iconBefore={<TrashCan size={20} />}
          onClick={discard}
          size="sm"
          variant="outlined"
        >
          Discard
        </Button>
        <Button
          className={styles.button}
          disabled={!retry}
          iconBefore={<Repeat size={20} />}
          onClick={handleRetry}
          size="sm"
          variant="outlined"
        >
          Try again
        </Button>
        <Button
          className={styles.button}
          disabled={!resultsEditor}
          iconBefore={<Add size={20} />}
          onClick={handleInsertBelow}
          size="sm"
          variant="outlined"
        >
          Add to document
        </Button>
      </div>
    </div>
  );
}
