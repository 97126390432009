export const getTagHTML = (tag: string, text: string) => {
  const element = document.createElement(tag);
  element.innerText = text;
  return element.outerHTML;
};

export function* iterHtmlAncestors(element: HTMLElement) {
  for (let current = element.parentElement; current; current = current.parentElement) {
    yield current;
  }
}
