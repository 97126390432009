import clsx from 'clsx';
import { useMemo, type ReactNode } from 'react';

import type { MediaGroupDTO, ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon64 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import CollectionsCTACard from '@components/CollectionsCTACard';
import DeletedMediaGroupCard from '@components/DeletedMediaGroupCard';
import MediaGroupCard from '@components/MediaGroupCard';
import NoResults from '@components/NoResults';
import PlaceholderCard from '@components/PlaceholderCard';
import ProductLoading from '@components/ProductLoading';
import { useLastBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import { SpaceIconOrProjectMode } from '@icons/SpaceIcon';
import MasonryGrid, { type Breakpoints } from '@ui/MasonryGrid';
import { ButtonLink } from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { getMailToLink } from '@utils/openMailTo';
import { urlFor } from '@/urls';
import styles from './MediaGroupGrid.module.scss';

const { Drafts } = Icon16;
const { ErrorIcon } = Icon64;

type MediaGroupGridProps = {
  breakpoints?: Breakpoints;
  containerClassName?: string;
  isError: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onSearchClick?: (id: string) => void;
  onShowSimilar?: (id: string) => void;
  mediaGroups: MediaGroupDTO[];
  noResults?: ReactNode;
  selected: Set<string>;
  onSelected?: (selected: MediaGroupDTO) => void;
  showCTACard?: boolean;
  showPinning?: boolean;
};

type CTACard = { card: JSX.Element; isCTACard: boolean };

const CTA_CARD = {
  card: <CollectionsCTACard />,
  isCTACard: true,
};

// Magic numbers collected from the inspector
const FILE_ICON_WIDTH = 512;
const FILE_ICON_HEIGHT = 128;

const MediaGroupGrid = ({
  breakpoints,
  containerClassName,
  isError,
  isFetchingNextPage,
  isLoading,
  onSearchClick,
  onShowSimilar,
  mediaGroups,
  noResults,
  selected,
  onSelected,
  showCTACard = false,
  showPinning,
}: MediaGroupGridProps) => {
  const lastBackgroundLocation = useLastBackgroundLocation();
  const updatedMediaGroupMap = useCheckMediaGroupUpdated(
    lastBackgroundLocation ? [] : mediaGroups
  );

  const items = useMemo(
    (): Array<MediaGroupDTO | CTACard> =>
      showCTACard ? [CTA_CARD, ...mediaGroups] : mediaGroups,
    [showCTACard, mediaGroups]
  );

  const masonryItems = useMemo(
    () =>
      items.map((item) => {
        if ('isCTACard' in item && item.isCTACard)
          return {
            card: item.card,
            thumbHeight: 0,
            thumbWidth: 0,
            isNotes: false,
            fixedHeight: 104,
          };

        const mediaGroup = item as MediaGroupDTO;

        const thumbHeight =
          mediaGroup.thumbnail.source?.kind === 'fileIcon'
            ? FILE_ICON_HEIGHT
            : mediaGroup.thumbnail.height;
        const thumbWidth =
          mediaGroup.thumbnail.source?.kind === 'fileIcon'
            ? FILE_ICON_WIDTH
            : mediaGroup.thumbnail.width;
        return {
          card: !mediaGroup.isDeleted ? (
            <MediaGroupCard
              className={styles.card}
              key={mediaGroup.id}
              mediaGroup={mediaGroup}
              updatedMediaGroupMap={updatedMediaGroupMap}
              onSearchClick={onSearchClick}
              selected={selected?.has(mediaGroup.id)}
              onSelected={() => {
                onSelected?.(mediaGroup);
              }}
              onShowSimilar={onShowSimilar}
              inBulkSelectMode={(selected?.size ?? -1) > 0}
              showPinning={showPinning}
            />
          ) : (
            <DeletedMediaGroupCard
              key={mediaGroup.id}
              mediaGroup={mediaGroup}
              updatedMediaGroupMap={updatedMediaGroupMap}
            />
          ),
          isNotes: mediaGroup.kind === 'document',
          thumbHeight,
          thumbWidth,
        };
      }),
    [items, updatedMediaGroupMap, onSearchClick, selected, onSelected, onShowSimilar]
  );

  if (isError) {
    return (
      <div className={styles.errorContainer}>
        <PlaceholderCard
          containerClassName={styles.placeholderCard}
          icon={<ErrorIcon />}
          title="Service Interruption"
          description={
            <>
              <p>Oops! Our servers are having a moment.</p>
              <p>Please try again later</p>
            </>
          }
        >
          <div className={styles.buttons}>
            <ButtonLink size="sm" variant="primary" to={urlFor('home')}>
              New space
            </ButtonLink>
            <ButtonLink
              size="sm"
              variant="outlined"
              to={getMailToLink({ subject: 'Error listing spaceduck content' })}
            >
              Contact support
            </ButtonLink>
          </div>
        </PlaceholderCard>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <ProductLoading />
      </div>
    );
  }

  if (!mediaGroups?.length) {
    return noResults || <NoResults />;
  }

  return (
    <div className={clsx(styles.gridContainer, containerClassName)}>
      <MasonryGrid breakpoints={breakpoints} items={masonryItems} />
      {isFetchingNextPage && (
        <div className={styles.container}>
          <ProductLoading />
        </div>
      )}
    </div>
  );
};

const AddToRepository = () => (
  <div className={styles.menuItem}>
    <Drafts size={20} />
    <span className={styles.projectLabel} title="Drafts">
      Drafts
    </span>
  </div>
);

export const makeAddToProjectMenu = ({
  projects,
  onAddToProject,
  onAddToRepository,
}: {
  projects: ProjectDTO[];
  onAddToProject: (projectId: string) => void;
  onAddToRepository?: () => void;
}) => {
  // Prevent hovering on menu item from passing focus
  // Prevent accidental selection when menu height changes
  const preventDefaultAndPropagation = (
    ev: Pick<Event, 'preventDefault' | 'stopPropagation'>
  ) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const projectOptions: ContextMenuItemProps[] = projects.map((project) => ({
    content: (
      <div className={styles.menuItem}>
        <SpaceIconOrProjectMode project={project} size={20} />
        <span className={styles.projectLabel} title={project.label}>
          {project.label}
        </span>
      </div>
    ),
    onClick: () => {
      onAddToProject?.(project.id);
    },
    contextMenuItemProps: {
      onPointerLeave: preventDefaultAndPropagation,
      onPointerMove: preventDefaultAndPropagation,
    },
  }));

  const addToRepositoryOption: ContextMenuItemProps[] = [];
  if (onAddToRepository) {
    addToRepositoryOption.push({
      content: <AddToRepository />,
      onClick: () => {
        onAddToRepository();
      },
      contextMenuItemProps: {
        onPointerLeave: preventDefaultAndPropagation,
        onPointerMove: preventDefaultAndPropagation,
      },
    });
  }

  if (projectOptions.length > 0) {
    return [...addToRepositoryOption, ...projectOptions];
  }

  return [
    ...addToRepositoryOption,
    addToRepositoryOption.length === 0 && projectOptions.length === 0
      ? {
          content: (
            <div className={clsx(styles.menuItem, styles.noResults)}>
              No spaces found
            </div>
          ),
          disabled: true,
          isMenuItem: false,
          contextMenuItemProps: {
            onPointerLeave: preventDefaultAndPropagation,
            onPointerMove: preventDefaultAndPropagation,
          },
        }
      : null,
  ].filter(exists);
};

export default MediaGroupGrid;
