import {
  type InviteRole,
  type Role,
  getSubscriptionInfo,
  isProject,
} from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { activeProjectKeys } from '@/const';
import { INVITE_ROLES } from '@admin/const';
import {
  useCreateWorkspaceInvite,
  useWorkspace,
  useWorkspaceProjects,
} from '@api/workspace';
import { useModalManager } from '@context/ModalManagerContext';
import { useChangeSeats } from '@hooks/useChangeSeats';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import SearchableDropdown from '@ui/SearchableDropdown';
import Select from '@ui/Select';
import Spinner from '@ui/Spinner';
import TextToTagsInput from '@ui/TextToTagsInput';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import { EMAIL_REGEX } from '@utils/regex';
import styles from './CreateInviteModal.module.scss';

const { Project } = Icon24;

type InviteByEmailFormData = {
  email: string[];
  projectIds: string[];
  role: InviteRole;
};

const availableInviteeRoles = new Set<InviteRole>(['V', 'E']);

export const useSendInvites = (onDone: () => void) => {
  const { mutateAsync: createWorkspaceInvite } = useCreateWorkspaceInvite();
  return useChangeSeats(
    async ({
      workspaceId,
      emails,
      role,
      projectIds,
    }: {
      workspaceId: string;
      emails: string[];
      role: Role;
      projectIds: string[];
    }) => {
      const createWorkspaceInviteResult = await createWorkspaceInvite({
        workspaceId,
        emails,
        role,
        projectIds,
      });
      return createWorkspaceInviteResult.seatsUpdated;
    },
    async ({ workspaceId }) => {
      const subscriptionInfo = await getSubscriptionInfo(workspaceId);
      return subscriptionInfo.assignedSeats <= subscriptionInfo.seats;
    },
    onDone
  );
};

const InviteByEmailForm = ({
  workspaceId,
  closeModal,
}: {
  workspaceId: string;
  closeModal: () => void;
}) => {
  'use no memo';
  const id = useId();
  const { data: projectData, isLoading: projectIsLoading } = useWorkspaceProjects(
    workspaceId,
    {
      status: activeProjectKeys,
      flattenStacks: true,
    }
  );
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<InviteByEmailFormData>();

  const { do: sendInvites, complete } = useSendInvites(closeModal);
  const submitHandler = async (data: InviteByEmailFormData) => {
    const { email: emails, projectIds, role } = data;
    await sendInvites({
      workspaceId,
      emails,
      role,
      projectIds,
    });
  };

  return (
    <form className={styles.inviteForm} onSubmit={handleSubmit(submitHandler)}>
      <div className={clsx('formGroup')}>
        <label htmlFor={`${id}InviteEmail`}>Invite by email</label>
        <div className={styles.inlineFields}>
          <div className={styles.emailField}>
            <Controller
              control={control}
              defaultValue={[]}
              name="email"
              rules={{
                required: 'An email address is required',
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextToTagsInput
                    errorMessage="Invalid email address"
                    htmlId={`${id}InviteEmail`}
                    initialValue={value.join(',')}
                    inputPlaceholder="email@example.com, email2@example.com"
                    onValueChange={onChange}
                    pattern={EMAIL_REGEX}
                  />
                );
              }}
            />
          </div>
          <div className={styles.roleField}>
            <Controller
              control={control}
              defaultValue={'V'}
              name="role"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    className={styles.formDropdown}
                    contentClassName={styles.selectBoxContent}
                    onValueChange={onChange}
                    placeholder={INVITE_ROLES[value]}
                    selectContentProps={{ align: 'end' }}
                    selectGroups={[
                      {
                        options: Array.from(availableInviteeRoles).map((role) => ({
                          label: INVITE_ROLES[role],
                          value: role,
                        })),
                      },
                    ]}
                    showIndicator
                    value={value}
                  />
                );
              }}
            />
          </div>
        </div>
        {errors.email?.message && (
          <div className="errorMessage fieldError">{errors.email?.message}</div>
        )}
      </div>
      {projectIsLoading ? (
        <p className="formGroup body5">Loading spaces...</p>
      ) : (
        <div className={clsx('formGroup', styles.projectField)}>
          <label htmlFor={`${id}InviteProject`}>Add to space (optional)</label>
          {projectData?.kind === 'success' && (
            <Controller
              control={control}
              name="projectIds"
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <SearchableDropdown
                  dropdownMenuProps={{
                    className: styles.projectSelection,
                  }}
                  includeSearchIcon
                  initialValue={value}
                  placeholderText="Select spaces..."
                  onValueChange={onChange}
                  options={projectData.projects
                    .filter(isProject)
                    .map(({ id, label }) => ({
                      icon: <Project size={20} />,
                      label,
                      value: id,
                    }))}
                  resultsView="list"
                />
              )}
            />
          )}
        </div>
      )}
      <div className={clsx('formFooter', styles.formFooter)}>
        <Button size="sm" type="submit" variant="primary" disabled={!complete}>
          Send invites
          {!complete && <Spinner size={16} />}
        </Button>
      </div>
    </form>
  );
};

const CreateInviteModal = ({
  workspaceId,
  closeModal,
}: {
  workspaceId: string | null;
  closeModal: () => void;
}) => {
  const { data } = useWorkspace(workspaceId);

  if (!workspaceId) return null;
  const { avatarUrl, label } = data?.workspace ?? {};

  return (
    <Dialog
      className={styles.createInviteModal}
      closeModal={closeModal}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={
        <div className={styles.modalHeader}>
          <span className={styles.companyLogo}>
            <WorkspaceAvatar workspaceAvatar={avatarUrl} workspaceName={label} />
          </span>
          <span>Invite to {label ?? 'your workspace'}</span>
        </div>
      }
      padding="md"
    >
      <div className={styles.content}>
        <InviteByEmailForm workspaceId={workspaceId} closeModal={closeModal} />
      </div>
    </Dialog>
  );
};

export function useCreateInviteModal(workspaceId: string | null) {
  const { openModal, closeModal } = useModalManager();
  return {
    open: () => {
      openModal({
        component: (
          <CreateInviteModal workspaceId={workspaceId} closeModal={closeModal} />
        ),
      });
    },
  };
}
