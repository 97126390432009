import { useCallback, useContext, useEffect, useRef } from 'react';
import { now } from 'lodash';
import { type Edge, type Node, type OnNodeDrag, useReactFlow } from '@xyflow/react';

import { exists } from '@spaceduck/utils';

import { BoardStoreContext } from '@reactFlow/context/boardContext';
import type { GroupNodeType } from '@reactFlow/types/board';
import { useBoardStore } from './useBoardStore';
import {
  COPY_INDICATOR,
  DEFAULT_X_OFFSET,
  DEFAULT_Y_OFFSET,
  ID_DELIMITER,
} from './useNodes';
import { usePersist } from './usePersist';
import { useShallow } from 'zustand/shallow';

export const DEFAULT_FRAME_HEIGHT = 600;
export const DEFAULT_FRAME_WIDTH = 800;
const DEFAULT_BORDER_COLOR = 'neutral3';

const findAbsolutePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((node) => node.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findAbsolutePosition(parentNode, allNodes);
  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

const cmpNode = (a: Node, b: Node) => {
  if (a.type === 'groupNode' && b.type !== 'groupNode') {
    return -1;
  }
  if (b.type === 'groupNode' && a.type !== 'groupNode') {
    return 1;
  }
  return 0;
};

export const useFrames = () => {
  const boardContext = useContext(BoardStoreContext);

  const { setClipboardItems, selectedNodes, setNodes } = useBoardStore(
    useShallow((state) => ({
      setClipboardItems: state.setClipboardItems,
      selectedNodes: state.selectedNodes,
      setNodes: state.setNodes,
    }))
  );

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => {
    return () => {
      // TODO: unify position change with add / remove from frame
      // Patch fires when nodes moved and fired again when nodes are added or removed from frames.
      // We cannot guarantee order so positioning data without parentId could override frame change.
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const {
    addNodes,
    deleteElements,
    getEdges,
    getIntersectingNodes,
    getNode,
    getNodes,
    screenToFlowPosition,
    updateNode,
  } = useReactFlow();

  const { updateEntry, updateEntryNodes } = usePersist({
    mediaGroupId: boardContext?.mediaGroupId,
  });

  const updateFrame = useCallback(
    (id: string, patch: Partial<Node>) => {
      if (!boardContext?.mediaGroupId) return;

      const nodes = getNodes();
      const frame = nodes.find((node) => node.id === id);

      if (!frame) return;

      const updatedNodes = setNodes(
        nodes.map((node) => {
          if (node.id !== id) return node;

          return { ...node, ...patch, data: { ...node.data, ...patch.data } };
        })
      );

      timeoutRef.current = setTimeout(() => {
        updateEntryNodes(updatedNodes, false);
      }, 0);
    },
    [boardContext, getNodes, setNodes, updateEntryNodes]
  );

  const moveSelectionIntoGroup = useCallback(
    (target: Node | null) => {
      if (target?.type !== 'groupNode') {
        return false;
      }

      const updatedNodes = setNodes((nodes) => {
        const targetAbsPos = findAbsolutePosition(target, nodes);
        const newNodes = nodes.map((node) => {
          if (node.type === 'groupNode' || !selectedNodes.includes(node.id)) {
            return node;
          }
          const nodeAbsPos = findAbsolutePosition(node, nodes);
          const x = nodeAbsPos.x - targetAbsPos.x;
          const y = nodeAbsPos.y - targetAbsPos.y;

          return {
            ...node,
            parentId: target.id,
            extent: undefined,
            position: { x, y },
          };
        });
        return newNodes.sort(cmpNode);
      });

      timeoutRef.current = setTimeout(() => {
        updateEntryNodes(updatedNodes, false);
      }, 0);
    },
    [selectedNodes, setNodes, updateEntryNodes]
  );

  const moveSelectionOutOfGroup = useCallback(() => {
    const updatedNodes = setNodes((nodes) => {
      const newNodes = nodes.map((node) => {
        if (
          node.type === 'groupNode' ||
          !node.parentId ||
          !selectedNodes.includes(node.id)
        ) {
          return node;
        }
        return {
          ...node,
          extent: undefined,
          parentId: undefined,
          position: findAbsolutePosition(node, nodes),
        };
      });
      return newNodes.sort(cmpNode);
    });

    timeoutRef.current = setTimeout(() => {
      updateEntryNodes(updatedNodes, false);
    }, 0);
  }, [selectedNodes, setNodes, updateEntryNodes]);

  const onNodeDragStop = useCallback<OnNodeDrag<Node>>(
    (_, node) => {
      if (node.type === 'groupNode') {
        return;
      }
      if (!(node.measured?.width && node.measured.height)) {
        return;
      }

      const target =
        getIntersectingNodes(node).find((node) => node.type === 'groupNode') ?? null;

      if (target) {
        moveSelectionIntoGroup(target);
      } else {
        moveSelectionOutOfGroup();
      }
    },
    [moveSelectionIntoGroup, moveSelectionOutOfGroup]
  );

  const addFrame = useCallback(
    (
      x: number,
      y: number,
      options?: {
        nodes?: string[];
        width?: number;
        height?: number;
      }
    ) => {
      const node: GroupNodeType = {
        id: `group${now()}${ID_DELIMITER}${now()}`,
        data: {
          label: null,
          color: DEFAULT_BORDER_COLOR,
          expanded: true,
        },
        position: screenToFlowPosition({
          x,
          y,
        }),
        className: 'neutral3',
        type: 'groupNode',
        width: options?.width ?? DEFAULT_FRAME_WIDTH,
        height: options?.height ?? DEFAULT_FRAME_HEIGHT,
      };

      const allNodes = getNodes().sort(cmpNode);

      if (options?.nodes?.length) {
        const targetAbsPos = findAbsolutePosition(node, allNodes);

        const updatedNodes = allNodes.map((_node) => {
          if (!options.nodes?.includes(_node.id)) return _node;

          const nodeAbsPos = findAbsolutePosition(_node, allNodes);
          const x = nodeAbsPos.x - targetAbsPos.x;
          const y = nodeAbsPos.y - targetAbsPos.y;

          return {
            ..._node,
            data: {
              ..._node.data,
            },
            parentId: node.id,
            extent: 'parent',
            position: {
              x,
              y,
            },
          } as Node;
        });

        node.selected = true;

        updateEntryNodes(setNodes([node, ...updatedNodes]), false);
      } else {
        updateEntryNodes(setNodes([node, ...allNodes]), false);
      }
    },
    [screenToFlowPosition, getNodes, setNodes, updateEntryNodes]
  );

  const getRemainingNodesAndEdges = useCallback(
    ({
      deletedEdges,
      deletedNodes,
    }: { deletedEdges: Edge[]; deletedNodes: Node[] }) => {
      const edges = getEdges();
      const nodes = getNodes();

      const deletedEdgesIds = deletedEdges.map((edge) => edge.id);
      const deletedNodesIds = deletedNodes.map((node) => node.id);

      const remainingEdges = edges.filter((edge) => !deletedEdgesIds.includes(edge.id));
      const remainingNodes = nodes.filter((node) => !deletedNodesIds.includes(node.id));

      return {
        edges: remainingEdges,
        nodes: remainingNodes,
      };
    },
    [getEdges, getNodes]
  );

  const cutFrame = useCallback(
    async (id: string) => {
      const frameData = getFrameAndChildren(id, getNodes());

      if (!frameData?.frame) return null;

      const nodesToRemove = [frameData.frame, ...frameData.children].filter(exists);
      const selectedNodes = nodesToRemove.map((node) => node.id);
      if (!selectedNodes?.length) return;

      const edgesToRemove = getEdges().filter((edge) => {
        const handles = edge.id.split('->');
        if (handles.length !== 2 || !handles[0] || !handles[1]) return false;

        if (selectedNodes.includes(handles[0]) || selectedNodes.includes(handles[1]))
          return true;

        return false;
      });

      setClipboardItems({ nodes: nodesToRemove, edges: edgesToRemove });

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: nodesToRemove,
        edges: edgesToRemove,
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [getNode, deleteElements, getRemainingNodesAndEdges, updateEntry]
  );

  const copyFrame = useCallback(
    (id: string) => {
      const frameData = getFrameAndChildren(id, getNodes());

      if (!frameData?.frame) return null;

      const nodesToRemove = [frameData.frame, ...frameData.children].filter(exists);
      const selectedNodes = nodesToRemove.map((node) => node.id);

      if (!selectedNodes?.length) return;

      const edgesToRemove = getEdges().filter((edge) => {
        const handles = edge.id.split('->');
        if (handles.length !== 2 || !handles[0] || !handles[1]) return false;

        if (selectedNodes.includes(handles[0]) || selectedNodes.includes(handles[1]))
          return true;

        return false;
      });

      setClipboardItems({
        nodes: nodesToRemove
          .map((node) => {
            if (!node) return undefined;

            return {
              ...node,
              id: `${node.id}${COPY_INDICATOR}`,
            };
          })
          .filter(exists),
        edges: edgesToRemove
          .map((edge) => {
            if (!edge) return undefined;

            return edge;
          })
          .filter(exists),
      });
    },
    [getFrameAndChildren, getNodes, getEdges, setClipboardItems]
  );

  const deleteFrame = useCallback(
    async (id: string) => {
      const allNodes = getNodes();
      const deletedNodesIds = allNodes
        .filter((node) => node.id === id || node.parentId === id)
        .map((node) => node.id);

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: deletedNodesIds.map((id) => ({ id })),
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [getNodes, deleteElements, getRemainingNodesAndEdges, updateEntry]
  );

  const duplicateFrame = useCallback(
    async (id: string) => {
      const node = getNode(id);

      if (!node) {
        console.error('Node copy failed - could not find node');
        return;
      }

      addNodes(
        duplicateNodes(
          {
            ...node,
            position: {
              x: node.position.x + DEFAULT_X_OFFSET,
              y: node.position.y + DEFAULT_Y_OFFSET,
            },
          },
          getNodes()
        )
      );

      updateNode(node.id, { ...node, selected: false });
    },
    [getNode, addNodes, updateNode]
  );

  return {
    addFrame,
    copyFrame,
    cutFrame,
    deleteFrame,
    duplicateFrame,
    getFrameAndChildren,
    onNodeDragStop,
    updateFrame,
    getIntersectingNodes,
  };
};

function getFrameAndChildren(id: string, allNodes: Node[]) {
  const frameData = allNodes.reduce<{ frame: Node | null; children: Node[] }>(
    (acc, curr) => {
      if (curr.id === id) {
        acc.frame = curr;
        return acc;
      }

      if (curr.parentId === id) {
        acc.children.push(curr);
        return acc;
      }

      return acc;
    },
    { frame: null, children: [] }
  );

  if (!frameData.frame) return null;

  return frameData;
}

function duplicateNodes(frame: Node, allNodes: Node[]): Node[] {
  const timeStamp = now();
  const newId = `${frame.id.split(ID_DELIMITER)[0]}${ID_DELIMITER}${timeStamp}`;

  const frameData = getFrameAndChildren(frame.id, allNodes);

  if (!frameData) {
    console.log('Cannot retrieve frame data');
    return [];
  }

  const newFrame = {
    ...frame,
    id: newId,
  };

  const newChildren = frameData?.children
    .map((node, idx) => {
      return {
        ...node,
        parentId: newId,
        id: `${node.id.split(ID_DELIMITER)[0]}${ID_DELIMITER}${now() + idx}`,
      };
    })
    .filter(exists);

  return [newFrame, ...newChildren];
}
