import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import {
  NodeViewContent,
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { type ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { createLowlight } from 'lowlight';

import { Icon24 } from '@spaceduck/icons';

import MenuSearch from '@components/MenuSearch';
import Button from '@ui/Button';
import DropdownMenu, { DropdownMenuItem } from '@ui/DropdownMenu';
import { register, supportedLanguages } from './codeBlock/languages';
import styles from './CodeBlock.module.scss';

const { Check, Down } = Icon24;
const lowlight = createLowlight();
register(lowlight, 'all');

export default CodeBlockLowlight.extend({
  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockComponent);
  },
}).configure({ lowlight });

const CodeBlockComponent = (
  props: NodeViewRendererProps & {
    updateAttributes: ({ language }: { language: string }) => void;
  }
) => {
  const {
    node: {
      attrs: { language: defaultLanguage },
    },
    updateAttributes,
    extension,
  } = props;

  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);

  const languageOptions = useMemo(() => {
    const allLanguages = extension.options.lowlight.listLanguages();
    if (!query.trim()) return allLanguages;

    const pattern = new RegExp(query, 'i');

    return allLanguages.filter((language: string) => {
      if (!supportedLanguages.has(language)) return false;

      const label = supportedLanguages.get(language)?.label ?? '';
      return pattern.test(label) || pattern.test(language);
    });
  }, [extension.options.lowlight, query, supportedLanguages]);

  const displayLanguage = useMemo(() => {
    return supportedLanguages.has(defaultLanguage)
      ? supportedLanguages.get(defaultLanguage)?.label
      : defaultLanguage;
  }, [defaultLanguage]);

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setQuery(ev.currentTarget.value);
    },
    [setQuery]
  );

  const menuContentRef = useRef<HTMLDivElement | null>(null);

  const handleInputKeyDown = useCallback((ev: React.KeyboardEvent) => {
    ev.stopPropagation();

    if (ev.key === 'Tab' && menuContentRef.current) {
      ev.preventDefault();
      (
        menuContentRef.current.querySelector('[role="menuitem"]') as HTMLElement
      )?.focus();
    }
  }, []);

  return (
    <NodeViewWrapper className={styles.codeBlock}>
      <DropdownMenu
        align="end"
        isPadded
        open={open}
        setOpen={setOpen}
        triggerContent={
          <Button
            iconAfter={<Down size={16} />}
            className={styles.menuButton}
            variant="outlined"
            size="sm"
          >
            {displayLanguage ?? 'Auto'}
          </Button>
        }
      >
        <div className={styles.menuContent} ref={menuContentRef}>
          <MenuSearch
            onChange={handleChange}
            onKeyDown={handleInputKeyDown}
            value={query}
          />
          {languageOptions.map((language: string, index: number) => (
            <DropdownMenuItem
              key={index}
              onClick={() => updateAttributes({ language })}
            >
              {supportedLanguages.has(language)
                ? supportedLanguages.get(language)?.label
                : language}
              {language === defaultLanguage && <Check />}
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenu>
      <div className="hljs">
        <pre className="codeblock">
          <NodeViewContent as="code" />
        </pre>
      </div>
    </NodeViewWrapper>
  );
};
