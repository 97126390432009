import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import { contentTypeSchema, mediaGroupDTO, mediaGroupKindSchema } from './schemas';

export const researchAssistantSessionCapability = z.enum([
  'researchAssistantSessionCreate',
]);
export type ResearchAssistantSessionCapability = z.infer<
  typeof researchAssistantSessionCapability
>;

export const providers = ['open-ai', 'google', 'anthropic'] as const;
const providerSchema = z.enum(providers);
export type AiProvider = z.infer<typeof providerSchema>;

// TODO: Get this from the backend
export const providerModels: Record<AiProvider, string> = {
  'open-ai': 'GPT-4o',
  google: 'Gemini',
  anthropic: 'Claude 3.7 Sonnet',
};

const chatSessionSourceMediaGroup = z.object({
  id: z.string(),
  label: z.string(),
  description: z.string(),
  contentType: contentTypeSchema,
  container: z.string(),
  linkUrl: z.string().nullable(),
  linkUrlSource: z.string().nullable(),
  kind: mediaGroupKindSchema,
  pending: z.boolean(),
});

const contentTypes = z.enum([
  'essay',
  'literature review',
  'research paper',
  'personal statement',
  'article or blog',
  'speech',
  'grant proposal',
]);

export type AiSettingsContentType = z.infer<typeof contentTypes>;

const writingStyles = z.enum([
  'academic',
  'casual',
  'persuasive',
  'bold',
  'friendly',
  'business',
]);

export type AiSettingsWritingStyle = z.infer<typeof writingStyles>;
const languages = z.enum(['EN_US', 'EN_GB']);
export type AiSettingsLanguage = z.infer<typeof languages>;
const mediaGroupAiSettings = z.object({
  documentPrompt: z.string(),
  contentType: contentTypes,
  writingStyle: writingStyles,
  language: languages,
  autoComplete: z.boolean(),
  autoCite: z.boolean(),
  writingResources: mediaGroupDTO.array(),
  writingReference: mediaGroupDTO.nullable(),
});

export type MediaGroupAiSettings = z.infer<typeof mediaGroupAiSettings>;

export type MediaGroupAiSettingsPatch = Partial<{
  documentPrompt: MediaGroupAiSettings['documentPrompt'];
  contentType: MediaGroupAiSettings['contentType'];
  writingStyle: MediaGroupAiSettings['writingStyle'];
  language: MediaGroupAiSettings['language'];
  autoComplete: MediaGroupAiSettings['autoComplete'];
  autoCite: MediaGroupAiSettings['autoCite'];
  writingReference: string | null;
}>;

export type ChatSessionListFilterParams =
  | {
      kind: 'direct';
      mediaGroupId: string;
    }
  | {
      kind: 'project';
      projectId: string;
    };

export type NamedPrompt =
  | 'clarity_and_fluency'
  | 'final_polish'
  | 'simplify'
  | 'length_more'
  | 'length_less'
  | 'summarize'
  | 'action_items'
  | 'outline'
  | 'brainstorm'
  | 'autocomplete';

export type ChatMediaGroupSource = z.infer<typeof chatSessionSourceMediaGroup>;
export type ChatSpaceSource = {
  id: string;
  type: 'space';
  label: string;
  isPrivate: boolean;
};

const responseParagraphSchema = z.object({
  text: z.string(),
  references: z.union([
    z
      .string()
      .transform((id) => {
        return { id, text: null, mediaGroupId: id };
      })
      .array()
      .optional()
      .nullable(),
    z
      .object({
        id: z.string(),
        text: z.string().nullable(),
        mediaGroupId: z.string(),
      })
      .array()
      .optional(),
  ]),
});

export type ResponseParagraph = z.infer<typeof responseParagraphSchema>;

const interactionSchema = z.object({
  id: z.string(),
  query: z.string(),
  pending: z.boolean().optional().default(true),
  response: z.object({
    parts: responseParagraphSchema.array(),
  }),
});

export type ChatInteraction = z.infer<typeof interactionSchema>;
export const chatSessionModes = ['library', 'item', 'only-chat', 'direct'] as const;
export const chatSessionModeSchema = z.enum(chatSessionModes);
export type ChatSessionMode = z.infer<typeof chatSessionModeSchema>;
export const chatSchema = z.object({
  id: z.string(),
  label: z.string(),
  provider: providerSchema,
  mediaGroups: chatSessionSourceMediaGroup.array(),
  projectId: z.string().nullable().optional(),
  // TODO(BACK): remove optional
  mode: chatSessionModeSchema.default('library').optional(),
  messages: interactionSchema.array(),
});

export const chatSummarySchema = z.object({
  id: z.string(),
  label: z.string(),
});

export type Chat = z.infer<typeof chatSchema>;

export const pendingChatInteractionSchema = successfulResponseSchema.extend({
  messageId: z.string(),
});

export type PendingChatInteractionSchema = z.infer<typeof pendingChatInteractionSchema>;

export const createChatSession = async (
  provider: AiProvider,
  mediaGroupIds: string[],
  initialQuery: string,
  mode: ChatSessionMode = 'library',
  projectId?: string
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/`,
    body: {
      projectId,
      provider,
      mediaGroupIds,
      initialQuery,
      mode,
    },
    method: 'POST',
    responseSchema: pendingChatInteractionSchema,
  });

export const queryChatSession = async (query: string, chatId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${chatId}/query/`,
    body: { query },
    method: 'POST',
    responseSchema: pendingChatInteractionSchema,
  });

export type RewriteChatMessageOptions = (
  | {
      part: 'query';
      query: string;
    }
  | {
      part: 'response';
    }
) & {
  id: string;
};

export const rewriteChatSessionMessage = async ({
  chatId,
  body,
}: {
  chatId: string;
  body: RewriteChatMessageOptions;
}) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${chatId}/rewrite/`,
    body,
    method: 'POST',
    responseSchema: pendingChatInteractionSchema,
  });

const interactionResultSchema = successfulResponseSchema.extend({
  messageParts: z
    .object({
      parts: responseParagraphSchema.array(),
    })
    .nullable(),
  chatSessionId: z.string().nullable(),
  chatMessageId: z.string().nullable(),
  status: z.enum(['success', 'failed', 'pending']),
});

export const getPendingInteractionStatus = async (messageId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/pending-interaction/status/`,
    method: 'GET',
    params: {
      messageId,
    },
    responseSchema: interactionResultSchema,
  });

export const getChatSession = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${id}/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      chat: chatSchema,
    }),
  });

export const removeChatSession = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${id}/`,
    method: 'DELETE',
    responseSchema: successfulResponseSchema.extend({
      projectId: z.string().nullable(),
    }),
  });

export const getChatSessionHistory = async (
  filterParams: ChatSessionListFilterParams
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/`,
    method: 'GET',
    params: { ...filterParams },
    responseSchema: successfulResponseSchema.extend({
      chats: chatSummarySchema.array(),
    }),
  });

export const triggerMediaGroupProcessing = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/trigger-media-group-processing/`,
    body: { mediaGroupId },
    method: 'POST',
    responseSchema: successfulResponseSchema,
  });

export const getMediaGroupAiSettings = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const patchMediagroupAiSettings = async (
  mediaGroupId: string,
  patch: MediaGroupAiSettingsPatch
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/update/`,
    method: 'PATCH',
    body: { patch },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const addWritingResource = async (mediaGroupId: string, resourceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/add-resource/`,
    method: 'POST',
    body: { mediaGroupId: resourceId },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const removeWritingResource = async (mediaGroupId: string, resourceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/remove-resource/`,
    method: 'POST',
    body: { mediaGroupId: resourceId },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

const aiWritingResultPendingSchema = z.object({ status: z.literal('created') });
const aiWritingResultSuccessSchema = z.object({
  status: z.literal('success'),
  text: z.string(),
});
const aiWritingResultFailedSchema = z.object({
  status: z.enum(['failed', 'rejected']),
});
const aiWritingResultSchema = z.discriminatedUnion('status', [
  aiWritingResultPendingSchema,
  aiWritingResultSuccessSchema,
  aiWritingResultFailedSchema,
]);

const aiWritingRequestDetailSchema = z.object({
  id: z.string(),
  result: aiWritingResultSchema,
});

const aiWritingResultDetailPayloadSchema = successfulResponseSchema.extend({
  request: aiWritingRequestDetailSchema,
});

export type CreateAIWritingRequestParams = {
  mediaGroupId: string;
  prompt: { kind: 'named'; name: NamedPrompt } | { kind: 'custom'; prompt: string };
  targetText: string;
  contextText: string;
};
export type CreateAIWritingRequestOptions = {
  body: CreateAIWritingRequestParams;
  signal?: AbortSignal;
};
export const createAIWritingRequest = async ({
  body,
  signal,
}: CreateAIWritingRequestOptions) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/ai-writing-request/`,
    method: 'POST',
    body,
    responseSchema: aiWritingResultDetailPayloadSchema,
    signal,
  });

export const getAIWritingRequestDetails = async ({
  writingRequestId,
  signal,
}: { writingRequestId: string; signal?: AbortSignal }) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/ai-writing-request/${writingRequestId}/`,
    method: 'GET',
    responseSchema: aiWritingResultDetailPayloadSchema,
    signal,
  });
