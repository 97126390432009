import clsx from 'clsx';

import type { BaseColor } from '../types/colors';
import styles from './ColorSelector.module.scss';

export default function ColorSelector({
  className,
  color,
  isActive,
  onChange,
}: {
  className?: string;
  color: BaseColor | undefined;
  isActive?: boolean;
  onChange: (color: BaseColor) => void;
}) {
  const handleClick = () => {
    if (color) {
      onChange(color);
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' && color) {
      ev.preventDefault();
      ev.stopPropagation();
      onChange(color);
    }
  };

  return (
    <div
      className={clsx(
        styles.color,
        color && styles[color],
        !!isActive && styles.active,
        className
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    />
  );
}
