import { clsx } from 'clsx';
import debounce from 'lodash/debounce';
import type { ChangeEvent, ClipboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import type { FieldErrors, SubmitHandler } from 'react-hook-form';

import { Header } from '@components/Header';
import Button from '@ui/Button';
import createToast from '@utils/createToast';
import { openMailTo } from '@utils/openMailTo';
import styles from './Form.module.scss';

export type CodeFormData = {
  code: string;
};

type CodeFormProps = {
  error: string | null;
  handleBackClick: () => void;
  isLoadingRequestId: boolean;
  isLoadingVerify: boolean;
  onResendRequest: () => Promise<void>;
  onSubmit: SubmitHandler<CodeFormData>;
  targetEmail: string;
};

const YEAR = new Date().getFullYear();

const CodeForm = ({
  error,
  handleBackClick,
  isLoadingRequestId,
  isLoadingVerify,
  onResendRequest,
  onSubmit,
  targetEmail,
}: CodeFormProps) => {
  'use no memo';
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors: codeErrors },
  } = useForm<CodeFormData>();

  const onPasteSubmit = async (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    setValue('code', pastedData.replaceAll(/[-.\s]/gu, ''));
    const isValid = await trigger('code');

    if (isValid) {
      await handleSubmit(onSubmit)();
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 8) {
      setValue('code', event.target.value);
    }
    if (event.target.value.length === 8) {
      const isValid = await trigger('code');
      if (isValid) {
        await handleSubmit(onSubmit)();
      }
    }
  };

  const onInvalid = (err: FieldErrors<CodeFormData>) => {
    if (err.code?.type === 'required') {
      createToast({
        titleText: 'Login Code Is Required',
        bodyText:
          'Please check your email for the secret code we sent and enter it above.',
        iconVariant: 'danger',
      });

      return;
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header>
          Check your <span className={styles.highlight}>Email</span>
          <div className={styles.message}>
            <p>We've sent a temporary login link.</p>
            <p>
              Please check your inbox at{' '}
              <span className={styles.email}>{targetEmail}</span>
            </p>
          </div>
        </Header>
        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          className={clsx(styles.form, styles.codeForm)}
        >
          <input
            autoComplete="off"
            {...register('code', { required: 'Code is required' })}
            className={clsx(styles.codeField, (error || codeErrors.code) && 'hasError')}
            type="text"
            placeholder="Enter the code"
            onPaste={onPasteSubmit}
            onChange={onChange}
            disabled={isLoadingVerify}
          />
          <Button
            className={styles.marginTop3}
            type="submit"
            disabled={isLoadingVerify}
            variant="primary"
            size="lg"
          >
            Continue with login code
          </Button>
        </form>
        <div className={styles.actions}>
          <Button variant="link" onClick={() => handleBackClick()}>
            Back to login
          </Button>
          <span className={styles.dot} />
          <Button
            variant="link"
            disabled={isLoadingRequestId}
            onClick={debounce(onResendRequest, 1000)}
          >
            Resend Code
          </Button>
          <span className={styles.dot} />
          <Button onClick={() => openMailTo('[SUPPORT]', '')} variant="link">
            Get in touch
          </Button>
        </div>
      </div>
      <ul className={styles.footer}>
        <li>&copy; {YEAR} Spaceduck.com</li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://spaceduck.com" target="_blank">
            Home
          </a>
        </li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://www.spaceduck.com/support/contact" target="_blank">
            Support
          </a>
        </li>
        <li>
          {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
          <a href="https://www.spaceduck.com/legal" target="_blank">
            Legal
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CodeForm;
