import type { Edge, Node } from '@xyflow/react';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/shallow';
import '@xyflow/react/dist/style.css';

import { useListComments } from '@api/comment';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import DetailsModalLoading from '@detailsModal/placeholder/DetailsModalPlaceholder';
import { BoardStoreProvider } from '@detailsModal/reactFlow/context/boardContext';
import ReactFlowBoard from '@detailsModal/reactFlow/ReactFlowBoard';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useNotesStore } from '@stores/useNotesStore';
import styles from './DetailsModalBoardContent.module.scss';

export default function DetailsModalBoardContent({
  asQuickView = false,
  className,
  mediaGroupId,
}: {
  asQuickView?: boolean;
  className?: string;
  mediaGroupId: string;
}) {
  const isDraggingOnBoard = useDetailsModalStore((state) => state.isDraggingOnBoard);
  const showCommentsOnBoard = useDetailsModalStore(
    (state) => state.showCommentsOnBoard
  );

  const { isNotesSaving, isQuickViewNotesSaving } = useNotesStore(
    useShallow((state) => ({
      isNotesSaving: state.isNotesSaving,
      isQuickViewNotesSaving: state.isQuickViewNotesSaving,
    }))
  );

  const { data: mediaGroupData, isLoading: mediaGroupDetailIsLoading } =
    useMediaGroupDetail(mediaGroupId, {
      enabled: !isDraggingOnBoard,
    });

  const canEdit = useMemo(() => {
    const capability = isCapable('edit', mediaGroupData?.userCapabilities ?? null);
    return capability.capable;
  }, [mediaGroupData]);

  const [initialEdges, setInitialEdges] = useState<Edge[] | null>(null);
  const [initialNodes, setInitialNodes] = useState<Node[] | null>(null);
  const [boardLastUpdated, setBoardLastUpdated] = useState<Date | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { data: commentsData, isLoading: commentsIsLoading } =
    useListComments(mediaGroupId);
  const activeCommentIds = useMemo(() => {
    return (
      commentsData?.comments
        .filter((comment) => comment.kind === 'alive')
        .map((comment) => comment.id) ?? []
    );
  }, [commentsData?.comments]);

  useEffect(() => {
    if (isNotesSaving || isQuickViewNotesSaving) {
      const preventLeave = (event: BeforeUnloadEvent) => event.preventDefault();
      window.addEventListener('beforeunload', preventLeave);
      return () => window.removeEventListener('beforeunload', preventLeave);
    }
  }, [isNotesSaving, isQuickViewNotesSaving]);

  const board = mediaGroupData?.mediaGroup.board;

  useEffect(() => {
    if (!board?.lastUpdated) return;

    const serverDate = new Date(board.lastUpdated);
    if (boardLastUpdated && boardLastUpdated >= serverDate) return;

    const initialNodes = board.nodes
      ? board.nodes.map((node) => {
          // Handle frames
          if (node.type === 'groupNode') {
            if (node.data.expanded !== false) {
              return { ...node, data: { ...node.data, expanded: true } };
            }

            return node;
          }

          // Handle other node types
          if (node.data.expanded || node.type === 'floatingTextNode') return node;

          return {
            ...node,
            height: undefined,
            data: {
              ...node.data,
              height:
                (node.height || node.data.height || node.measured?.height) ?? null,
            },
          };
        })
      : [];

    setInitialNodes(initialNodes);

    if (initialNodes.length) {
      setInitialEdges(board.edges ?? []);
    } else {
      setInitialEdges([]);
    }

    setBoardLastUpdated(serverDate);
  }, [board, boardLastUpdated]);

  if (mediaGroupDetailIsLoading || commentsIsLoading || !mediaGroupData) {
    return <DetailsModalLoading state="loading" />;
  }

  if (board !== null && (!initialEdges || !initialNodes)) return null;

  return (
    <div
      className={clsx(
        styles.container,
        asQuickView && 'quickView',
        showCommentsOnBoard && styles.showComments,
        className
      )}
      ref={containerRef}
    >
      <BoardStoreProvider
        activeCommentIds={activeCommentIds}
        boardLastUpdated={boardLastUpdated}
        containerRef={containerRef}
        mediaGroupId={mediaGroupData.mediaGroup.id}
        projectId={mediaGroupData.mediaGroup.project?.id}
        initialEdges={initialEdges ?? []}
        initialNodes={initialNodes ?? []}
      >
        <ReactFlowBoard
          activeCommentIds={activeCommentIds}
          mediaGroup={mediaGroupData.mediaGroup}
          readOnly={!canEdit}
        />
      </BoardStoreProvider>
    </div>
  );
}
