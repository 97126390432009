import type { Dispatch } from 'react';

import styles from './ProfessionSelection.module.scss';

type ProfessionSelectionProps = {
  industry?: string;
  setIndustry: Dispatch<React.SetStateAction<string>>;
  memberProfession?: string;
  setMemberProfession: Dispatch<React.SetStateAction<string>>;
};

export const ProfessionSelection = ({
  industry,
  setIndustry,
  memberProfession,
  setMemberProfession,
}: ProfessionSelectionProps) => {
  return (
    <div className={styles.professionSelection}>
      <div className="formGroup">
        <h2>
          <label htmlFor="workspaceIndustry">Workspace or organization industry</label>
        </h2>
        <p>Describe your workspaces role to help us tailor tags for everyone in it.</p>
        <input
          autoComplete="off"
          id="workspaceIndustry"
          onChange={(ev) => setIndustry(ev.target.value)}
          placeholder="e.g. “Psychology Research Firm”, “Furniture eCommerce”, “Architecture Firm”"
          type="text"
          value={industry}
        />
      </div>
      <div className={styles.hr} />
      <div className="formGroup">
        <h2>
          <label htmlFor="workspaceMemberProfession">Your role</label>
        </h2>
        <p>This will help tailor tags and the content you upload to your expertise.</p>
        <input
          autoComplete="off"
          id="workspaceMemberProfession"
          onChange={(ev) => setMemberProfession(ev.target.value)}
          placeholder="e.g. “Psychology Researcher”, “UX Designer”, “Documentary Filmmaker”, “Student”"
          type="text"
          value={memberProfession}
        />
      </div>
    </div>
  );
};
