import { Extension } from '@tiptap/core';
import type { Editor, Range } from '@tiptap/react';
import { ReactRenderer } from '@tiptap/react';
import Suggestion, { type SuggestionProps } from '@tiptap/suggestion';
import { type EditorState, PluginKey } from 'prosemirror-state';
import tippy from 'tippy.js';

import MentionList, { type CommandListRef } from './mentionMenu/MentionMenuList';

export default Extension.create({
  name: 'mentionMenu',
  addOptions() {
    return {
      suggestion: {
        char: '@',
        startOfLine: false,
        allowSpaces: false,
        allow: (props: {
          editor: Editor;
          state: EditorState;
          range: Range;
          isActive?: boolean;
        }) => {
          return Math.abs(props.range.from - props.range.to) === 1;
        },
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: SuggestionProps;
        }) => {
          props.command({ editor, range, props });
        },

        render: () => {
          let component: ReactRenderer<CommandListRef> | undefined;
          const myWindow = window as any;

          return {
            onStart: (props: any) => {
              component = new ReactRenderer(MentionList, {
                props,
                editor: props.editor,
              });

              myWindow.tippyMentionMenuList = tippy('body', {
                getReferenceClientRect: props.clientRect,
                content: component.element,
                onDestroy: () => {
                  component?.destroy();
                },
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                theme: 'sdPopper',
              });
            },
            onUpdate(props: any) {
              component?.updateProps(props);

              myWindow.tippyMentionMenuList?.[0]?.setProps({
                getReferenceClientRect: props.clientRect,
              });
            },
            onKeyDown(props: any) {
              if (props.event.key === 'Escape') {
                component?.destroy();

                if (myWindow.tippyMentionMenuList?.[0]?.popperInstance) {
                  myWindow.tippyMentionMenuList?.[0]?.destroy();
                }
                return true;
              }

              return component?.ref?.onKeyDown(props);
            },
            onExit() {
              component?.destroy();
              if (myWindow.tippyMentionMenuList?.[0]?.popperInstance) {
                myWindow.tippyMentionMenuList[0].destroy();
              }
            },
          };
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey('MentionMenu'),
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
