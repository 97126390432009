import { memo } from 'react';

import {
  EdgeMarkerStyleMenu,
  EdgeStyleMenu,
  EdgeTypeMenu,
  EdgeWidthMenu,
} from './edge';
import {
  MenuContainer as Container,
  MenuButtonGrid as ButtonGrid,
  MenuSeparator as Separator,
  MenuTitle as Title,
} from './menu';
import type { EdgeManagementProps } from './SideMenu';

export const EdgeMenu = memo((props: EdgeManagementProps) => {
  return (
    <Container>
      <Title>Stroke width</Title>
      <ButtonGrid>
        <EdgeWidthMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Stroke style</Title>
      <ButtonGrid>
        <EdgeStyleMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Arrow type</Title>
      <ButtonGrid>
        <EdgeTypeMenu {...props} />
      </ButtonGrid>
      <Separator />
      <Title>Arrowheads</Title>
      <ButtonGrid>
        <EdgeMarkerStyleMenu {...props} />
      </ButtonGrid>
    </Container>
  );
});
