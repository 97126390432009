import clsx from 'clsx';
import dayjs from 'dayjs';

import type { AliveCommentDTO } from '@spaceduck/api';
import { useCommentContent } from '@detailsModal/comments/Comment';
import UserAvatar from '@ui/UserAvatar';
import styles from './CommentSummary.module.scss';

export default function CommentSummary({
  className,
  comment,
}: {
  className?: string;
  comment: AliveCommentDTO;
}) {
  const { component: summary } = useCommentContent(comment, true);
  return (
    <div className={clsx(styles.comment, className)}>
      <UserAvatar
        name={comment.createdBy.name}
        imageUrl={comment.createdBy.avatarUrl}
        size="xxs"
      />
      <div className={styles.commentRight}>
        <div className={styles.meta}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={comment.createdBy.name}>
              {comment.createdBy.name}
            </span>
            <span className={styles.createdAt}>
              {dayjs(comment.createdAt).fromNow()}
            </span>
          </span>
        </div>
        <div className={styles.summary}>
          <div className={clsx(styles.content, styles.summaryContent)}>{summary}</div>
        </div>
      </div>
    </div>
  );
}
