import { memo, useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import { MenuButton } from '../menu';
import type { NodeManagementProps } from '../SideMenu';

const { FrameDiagram, Rectangle } = Icon16;

export const AutoHeightMenu = memo((props: NodeManagementProps) => {
  const { setNodes, selectedNodeItems, patch } = props;

  const textNodes = useMemo(() => {
    return selectedNodeItems.filter((node) => node.type === 'floatingTextNode');
  }, [selectedNodeItems]);

  const handleAutoHeight = useCallback(
    (disabled: boolean) => {
      const textNodeIds = textNodes.map((node) => node.id);

      const updatedNodes = setNodes((nodes) => {
        return nodes.map((node) => {
          if (!textNodeIds.includes(node.id)) return node;

          const newHeight = node.height || (node.data?.height as number);

          return {
            ...node,
            className: disabled ? 'fixedHeight' : 'autoHeight',
            data: {
              ...node.data,
              autoHeightDisabled: disabled,
            },
            height: newHeight ?? undefined,
          };
        });
      });

      patch({ nodes: updatedNodes });
    },
    [textNodes, setNodes, patch]
  );

  const isAutoHeightActive = useMemo(() => {
    if (textNodes.length !== 1) return undefined;

    const targetNode = textNodes[0];
    if (!targetNode) return undefined;

    return !targetNode.data.autoHeightDisabled;
  }, [textNodes]);

  if (!textNodes.length) return null;

  return (
    <>
      <MenuButton
        isActive={isAutoHeightActive === false}
        onClick={() => handleAutoHeight(true)}
        tooltipContent="Enable auto-height"
      >
        <FrameDiagram />
      </MenuButton>
      <MenuButton
        isActive={isAutoHeightActive}
        onClick={() => handleAutoHeight(false)}
        tooltipContent="Disabled auto-height"
      >
        <Rectangle />
      </MenuButton>
    </>
  );
});
