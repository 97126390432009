import { attemptAsync } from '@spaceduck/utils';

/**
 * Get the current status of the permission to read from the clipboard.
 * If the permission is not supported, resolves to `null`.
 */
export const getClipboardReadStatus = async () => {
  const result = await attemptAsync(() =>
    navigator.permissions.query({
      // @ts-expect-error
      name: 'clipboard-read',
    })
  );
  if (!result.success) {
    return null;
  }
  return result.data.state;
};

export const readClipboard = () => attemptAsync(() => navigator.clipboard.read());
export const readClipboardText = () =>
  attemptAsync(() => navigator.clipboard.readText());

export const clipboardHasMediaType = async (mediaType: string) => {
  const result = await readClipboard();

  if (!result.success) {
    return false;
  }

  return !!result.data.find((content) =>
    content.types.find((current) => current === mediaType)
  );
};

export const readClipboardTextOrNull = async () => {
  const result = await readClipboardText();
  if (!result.success) {
    return null;
  }
  return result.data;
};
