import { memo, useCallback } from 'react';

import { Icon16 } from '@spaceduck/icons';

import {
  handleTransformation,
  type Transformation,
} from '@detailsModal/tiptap/transformations';
import { MenuButton } from '../menu';
import type { NotesMenuProps } from '../NotesMenu';

const { Bold, CodeInline, Italics, Strikethrough, Underline } = Icon16;

export const TextFormatMenu = memo((props: NotesMenuProps) => {
  const { editor } = props;

  const handleClick = useCallback(
    (transformation: Transformation) => {
      if (!editor) return null;

      handleTransformation(editor, transformation);
    },
    [editor]
  );

  if (!editor) return null;

  return (
    <>
      <MenuButton
        isActive={editor.isActive('bold')}
        onClick={() => handleClick('bold')}
        tooltipContent="Bold"
      >
        <Bold />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('italic')}
        onClick={() => handleClick('italic')}
        tooltipContent="Italic"
      >
        <Italics />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('underline')}
        onClick={() => handleClick('underline')}
        tooltipContent="Underline"
      >
        <Underline />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('strike')}
        onClick={() => handleClick('strikethrough')}
        tooltipContent="Strikethrough"
      >
        <Strikethrough />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('code')}
        onClick={() => handleClick('inlineCode')}
        tooltipContent="Inline code"
      >
        <CodeInline />
      </MenuButton>
    </>
  );
});
