import {
  type CreateProjectParams,
  type ProjectDetailDTO,
  type ProjectMode,
  projectModeSchema,
} from '@spaceduck/api';
import { clsx } from 'clsx';
import { useCallback, useId, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

import { Icon16, Icon24 } from '@spaceduck/icons';

import { urlFor } from '@/urls';
import {
  useCreateProject,
  useDeleteProject,
  usePatchProject,
  useProject,
} from '@api/project';
import { type IsCapable, toastApiErrorOr } from '@api/util';
import HeroIcon from '@components/HeroIcon';
import HeroIconPicker from '@components/HeroIconPicker';
import { useWorkspace } from '@api/workspace';
import { useUpgradePlanModal } from '@components/UpgradePlanModal';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { spaceColorHex, type SpaceColor } from '@/types/Colors';
import Button from '@ui/Button';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import Popover from '@ui/Popover';
import RadioGroup, { RadioGroupItem } from '@ui/RadioGroup';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import createToast from '@utils/createToast';
import { css } from '@/lib/css';
import { heroIconNames, type HeroIconName } from '@/types/Icons';
import styles from './CreateProjectsModal.module.scss';

const { Add, CircleFilled, TrashCan } = Icon24;
const { Close, Project: ProjectIcon, Search } = Icon16;

type ManageProjectFormData = {
  iconColor?: string;
  iconName?: string;
  label: string;
  description: string;
  mode: ProjectMode;
  memberProfession: string;
  focus: string;
};

export const projectModes: Record<ProjectMode, { label: string; description: string }> =
  {
    private: {
      label: 'Private',
      description: 'Only people who are explicitly invited can see this space.',
    },
    open: {
      label: 'Public',
      description: 'Anyone from your workspace can see and join this space.',
    },
  };

function UpdateProjectModal({
  projectId,
  ...params
}: {
  isOpen?: boolean;
  closeModal?: () => void;
  redirectOnCreate?: boolean;
  redirectOnDelete?: boolean;
  isStarred?: boolean;
  projectId: string;
}) {
  const { data: response, status } = useProject(projectId ?? null);

  if (status === 'error') {
    return <p>Error</p>;
  }

  if (status === 'pending') {
    return <p>pending</p>;
  }

  return <CreateOrUpdateProjectModalForm project={response.project} {...params} />;
}

function CreateProjectModal(params: {
  isOpen?: boolean;
  closeModal?: () => void;
  redirectOnCreate?: boolean;
  redirectOnDelete?: boolean;
  isStarred?: boolean;
  projectId?: string;
}) {
  const workspaceId = useWorkspaceId();
  const { data, status } = useWorkspace(workspaceId);

  if (status === 'error') {
    return <p>TODO: Error fetching workspace</p>;
  }

  if (status === 'pending') {
    return <p>TODO: loading...</p>;
  }

  const { workspace } = data;

  return (
    <CreateOrUpdateProjectModalForm
      defaultMemberProfession={workspace.aiSettings.memberProfession}
      {...params}
    />
  );
}

function CreateOrUpdateProjectModalForm({
  isOpen = true,
  closeModal,
  project,
  redirectOnCreate,
  redirectOnDelete = true,
  isStarred = false,
  defaultMemberProfession,
}: {
  isOpen?: boolean;
  closeModal?: () => void;
  redirectOnCreate?: boolean;
  redirectOnDelete?: boolean;
  isStarred?: boolean;
  project?: ProjectDetailDTO;
  defaultMemberProfession?: string;
}) {
  'use no memo';

  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();

  const canDelete =
    project?.capabilities?.find(({ capability }) => capability === 'delete')?.capable ??
    false;
  const { mutateAsync: deleteProject } = useDeleteProject();
  const { open: openDeleteConfirmModal } = useDeleteConfirmModal({
    title: 'Delete space',
    subtitle: 'This action will delete this Space and move it to the trash.',
    confirmText: 'Yes, delete space',
    onConfirm: async () => {
      if (!project) {
        return;
      }
      await deleteProject(project.id);
      createToast({
        bodyText: 'Space deleted',
        iconVariant: 'success',
      });
      closeModal?.();
      if (redirectOnDelete) {
        navigate(
          workspaceId ? urlFor('workspaceSpaces', { workspaceId }) : urlFor('home')
        );
      }
    },
  });
  const [aiBoxOpen, setAIBoxOpen] = useState(!!project?.aiSettings?.focus);
  const toggleAIBoxOpen = useCallback(() => {
    setAIBoxOpen((value) => !value);
  }, [setAIBoxOpen]);

  const { control, register, handleSubmit, reset, formState, watch, getFieldState } =
    useForm<ManageProjectFormData>({
      defaultValues: {
        iconName: project?.iconName ?? undefined,
        iconColor: project?.iconColor ?? 'neutral1',
      },
    });
  const errors = formState.errors;
  const [iconName, iconColor] = watch(['iconName', 'iconColor']);
  const {
    mutateAsync: createProject,
    isPending: isPendingCreate,
    isError: isErrorCreate,
  } = useCreateProject();
  const {
    mutateAsync: patchProject,
    isPending: isPendingPatch,
    isError: isErrorPatch,
  } = usePatchProject();
  const id = useId();
  const isPending = isPendingCreate || isPendingPatch;
  const isError = isErrorCreate || isErrorPatch;

  const editCapability = project?.capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;

  const [iconPickerIsOpen, setIconPickerIsOpen] = useState(false);
  const [iconSearchQuery, setIconSearchQuery] = useState('');
  const icon = useMemo(() => {
    if (iconName && heroIconNames.includes(iconName as HeroIconName)) {
      return <HeroIcon name={iconName as HeroIconName} />;
    }

    return <ProjectIcon size={24} />;
  }, [iconName]);

  const { isDirty: isMemberProfessionDirty } = getFieldState(
    'memberProfession',
    formState
  );
  const { isDirty: isFocusDirty } = getFieldState('focus', formState);

  const extractData = useCallback(
    (data: ManageProjectFormData, includeAISettings: boolean) => {
      const { memberProfession, focus, ...rest } = data;
      const aiSettings: NonNullable<CreateProjectParams['aiSettings']> = {};

      if (isMemberProfessionDirty && includeAISettings) {
        aiSettings.memberProfession = memberProfession;
      }

      if (isFocusDirty && includeAISettings) {
        aiSettings.focus = focus;
      }

      return { ...rest, aiSettings };
    },
    [isMemberProfessionDirty, isFocusDirty]
  );

  const onSubmit = useCallback(
    async (data: ManageProjectFormData) => {
      if (project) {
        if (!canEdit) {
          createToast({
            bodyText: 'You do not have permission to edit this space',
            iconVariant: 'danger',
          });
          return;
        }
        const patch = extractData(data, aiBoxOpen);
        try {
          await patchProject({ id: project.id, patch });
        } catch (error) {
          return toastApiErrorOr(error, 'Failed to patch project', {
            iconVariant: 'warning',
            titleText: 'Space update failed',
            bodyText:
              'An unknown error occurred while updating your space. Please try again later',
          });
        }
        closeModal?.();
        createToast({
          bodyText: 'Space successfully updated',
          iconVariant: 'success',
        });
      } else {
        if (!workspaceId) {
          console.error('Failed to create space due to missing workspaceId');
          createToast({
            titleText: 'Space creation error',
            bodyText: 'Please try again later.',
            iconVariant: 'warning',
          });
          return;
        }
        let response: Awaited<ReturnType<typeof createProject>>;
        const createParams: CreateProjectParams = {
          ...extractData(data, aiBoxOpen),
          workspaceId,
          isStarred,
        };
        try {
          response = await createProject(createParams);
        } catch (error) {
          return toastApiErrorOr(error, 'Failed to create project', {
            iconVariant: 'warning',
            titleText: 'Space create failed',
            bodyText:
              'An unknown error occurred while creating your space. Please try again later',
          });
        }
        const { id: projectId } = response;
        reset();
        closeModal?.();
        createToast({
          bodyText: 'New space successfully created',
          iconVariant: 'success',
        });
        if (redirectOnCreate) {
          navigate(urlFor('space', { projectId }));
        }
      }
    },
    [project, canEdit, reset, closeModal, navigate, extractData, aiBoxOpen]
  );

  const errorMessage = project ? 'Could not update space.' : 'Error in space creation.';
  const buttonText = project ? 'Save' : 'Create space';
  const pendingText = project ? 'Saving' : 'Creating space...';

  return (
    <Dialog
      breadcrumb={[
        {
          icon: <ProjectIcon />,
          text: project ? 'Edit space' : 'New space',
        },
      ]}
      closeModal={closeModal}
      isOpen={isOpen}
      maxWidth="38.75rem"
    >
      <form
        className={clsx(isPending && styles.formDisabled)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="formBody">
          <label className="subtitle5" htmlFor={`${id}Label`}>
            Space name
          </label>
          <div className={styles.iconField}>
            <div className={styles.iconSelection}>
              <Popover
                className={styles.iconPicker}
                open={iconPickerIsOpen}
                onOpenChange={setIconPickerIsOpen}
                popoverContentProps={{
                  align: 'start',
                  sideOffset: 8,
                }}
                showArrow={false}
                trigger={
                  <Button
                    className={styles.iconButton}
                    iconBefore={icon}
                    size="md"
                    style={
                      iconColor
                        ? css({
                            '--icon-color': spaceColorHex.has(iconColor as SpaceColor)
                              ? `var(--space-color-${iconColor})`
                              : 'var(--space-color-neutral-1)',
                          })
                        : undefined
                    }
                    variant="outlined"
                  />
                }
              >
                <>
                  <Controller
                    control={control}
                    name="iconColor"
                    render={({ field: { onChange, value } }) => (
                      <div className={styles.colorBox}>
                        {[...spaceColorHex].map(([colorName, colorValue]) => {
                          return (
                            <Button
                              className={clsx(value === colorName && styles.active)}
                              onClick={() => onChange(colorName)}
                              key={colorName}
                              size="md"
                              variant="ghost"
                            >
                              <CircleFilled color={colorValue} />
                            </Button>
                          );
                        })}
                      </div>
                    )}
                  />
                  <div className={styles.searchBox}>
                    <Search />
                    <input
                      onChange={(ev) => setIconSearchQuery(ev.currentTarget.value)}
                      placeholder="Search icons..."
                      type="search"
                      value={iconSearchQuery}
                    />
                  </div>
                  <ScrollArea
                    className={styles.scrollArea}
                    style={css({
                      '--maxHeight':
                        'calc(var(--radix-popover-content-available-height) - var(--size-24) - var(--size-8))',
                    })}
                  >
                    <Controller
                      control={control}
                      name="iconName"
                      render={({ field: { onChange } }) => (
                        <HeroIconPicker
                          className={styles.iconsList}
                          onClick={(iconName) => {
                            onChange(iconName);
                            setIconPickerIsOpen(false);
                          }}
                          query={iconSearchQuery}
                          style={css({
                            '--icon-color': spaceColorHex.has(iconColor as SpaceColor)
                              ? spaceColorHex.get(iconColor as SpaceColor)
                              : 'neutral1',
                          })}
                        />
                      )}
                    />
                  </ScrollArea>
                </>
              </Popover>
            </div>
            <div className="formGroup">
              <input
                {...register('label', { required: 'Name is required' })}
                autoComplete="off"
                className={errors?.label && 'hasError'}
                defaultValue={project ? project.label : ''}
                id={`${id}Label`}
                placeholder="e.g. Homepage redesign..."
                type="text"
              />
              {errors?.label?.message && (
                <p className="errorMessage">{errors.label.message}</p>
              )}
            </div>
          </div>
          <div className="formGroup">
            <label htmlFor={`${id}Description`}>Description (optional)</label>
            <textarea
              {...register('description')}
              autoComplete="off"
              defaultValue={project ? project.description : ''}
              id={`${id}Description`}
              placeholder="e.g. Improve conversion..."
              style={{ resize: 'none' }}
            />
            {errors?.description?.message && (
              <p className="errorMessage">{errors.description.message}</p>
            )}
          </div>
          <div className="formGroup">
            <h3 className="subtitle5">Space type</h3>
            <div className="formGroup">
              <Controller
                control={control}
                defaultValue={project?.mode ?? 'private'}
                name="mode"
                render={({ field: { onChange, value: checkedValue } }) => {
                  return (
                    <RadioGroup
                      className={styles.twoColumnOptions}
                      defaultValue={checkedValue}
                      onValueChange={(value) => {
                        const result = projectModeSchema.safeParse(value);
                        if (!result.success) {
                          console.error('Failed to parse space mode', result.error);
                          return;
                        }
                        onChange(result.data);
                      }}
                    >
                      {Object.entries(projectModes).map(
                        ([key, { description, label }]) => {
                          return (
                            <div
                              key={key}
                              className={clsx(
                                'formSectionHighlight',
                                styles.radioGroupItem
                              )}
                            >
                              <RadioGroupItem
                                checked={key === checkedValue}
                                description={description}
                                value={key}
                              >
                                {label}
                              </RadioGroupItem>
                            </div>
                          );
                        }
                      )}
                    </RadioGroup>
                  );
                }}
              />
            </div>
          </div>
          {isError && <p className="errorMessage fieldError">{errorMessage}</p>}
          <div className={styles.hr} />
          <div className={styles.box}>
            <div className={styles.boxHeader}>
              <h2>Fine-Tuning AI Behavior</h2>
              <Button onClick={toggleAIBoxOpen} type="button" variant="icon">
                {aiBoxOpen ? <Close size={16} /> : <Add size={16} />}
              </Button>
            </div>
            {aiBoxOpen && (
              <div className={styles.boxContent}>
                <h3>
                  <label htmlFor="createProjectFormRole">Role</label>
                </h3>
                <p>
                  Describe your role or profession. This helps tailor the tags to your
                  expertise.
                </p>
                <div className="formGroup">
                  <Controller
                    control={control}
                    defaultValue={
                      defaultMemberProfession ??
                      project?.aiSettings?.memberProfession ??
                      ''
                    }
                    name="memberProfession"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          autoComplete="off"
                          id="createProjectFormRole"
                          placeholder="e.g. “Psychology Researcher”, “UX Designer”, “Documentary Filmmaker”, “Student”"
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>
                <h3>
                  <label htmlFor="createProjectFormFocus">Focus / Intent</label>
                </h3>
                <p>Fine-tunes tags based on what you’re analyzing.</p>
                <div className="formGroup">
                  <Controller
                    control={control}
                    defaultValue={project?.aiSettings?.focus ?? ''}
                    name="focus"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          autoComplete="off"
                          id="createProjectFormFocus"
                          placeholder="e.g. “Emotional expressions in human interactions”, “Aesthetic and artistic elements”"
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx(
            styles.formFooter,
            project && canDelete && styles.hasDeleteButton
          )}
        >
          {!!project && canDelete && (
            <Button
              className={styles.deleteButton}
              disabled={isPending}
              iconBefore={<TrashCan />}
              onClick={() => openDeleteConfirmModal()}
              size="sm"
              type="button"
              variant="outlined"
            >
              Delete space
            </Button>
          )}
          <div className={styles.formActions}>
            <Button
              disabled={isPending}
              onClick={closeModal}
              size="sm"
              type="button"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} variant="primary" size="sm">
              {isPending ? (
                <>
                  <Spinner size={16} />
                  {pendingText}
                </>
              ) : (
                buttonText
              )}
            </Button>
          </div>
        </div>
      </form>
      {isPending && <div className={styles.pendingOverlay} />}
    </Dialog>
  );
}

export function useCreateProjectModal({
  canCreateProject,
  redirectOnCreate,
  isStarred,
}: {
  canCreateProject: IsCapable;
  redirectOnCreate: boolean;
  isStarred?: boolean;
}) {
  const promptUpgradePlan =
    !canCreateProject.capable && canCreateProject.reason === 'plan';
  const { openModal, closeModal } = useModalManager();
  const { open: openUpgradeToPaidModal } = useUpgradePlanModal({
    header: 'Space management',
    title: 'Cannot create more spaces',
    message: 'Cannot create more spaces',
  });
  if (promptUpgradePlan) {
    return {
      open: () => {
        openUpgradeToPaidModal();
      },
    };
  }
  return {
    open: () => {
      openModal({
        component: (
          <CreateProjectModal
            redirectOnCreate={redirectOnCreate}
            isStarred={isStarred}
          />
        ),
      });
    },
    close: closeModal,
  };
}

export function useEditProjectModal(projectId: string, redirectOnDelete?: boolean) {
  const { openModal, closeModal } = useModalManager();

  return {
    open: () => {
      openModal({
        component: (
          <UpdateProjectModal
            projectId={projectId}
            redirectOnDelete={redirectOnDelete}
          />
        ),
      });
    },
    close: closeModal,
  };
}
