import { exists } from '@spaceduck/utils';
import { iterHtmlAncestors } from './dom';

export class BookmarkParseError extends Error {}
export class NotABookmarksFileError extends BookmarkParseError {}

export type BrowserBookmark = {
  href: string;
  label?: string;
  icon?: string;
  tags: string[];
};

export const parseBookmarksFromFile = async (file: File) => {
  if (file.type !== 'text/html') {
    throw new NotABookmarksFileError('Not HTML');
  }
  const html = await file.text();
  return parseBookmarksFromText(html);
};

export const parseBookmarksFromText = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return parseBookmarksFromDocument(doc);
};

export const parseBookmarksFromDocument = (doc: Document) => {
  if (doc.doctype?.name !== 'netscape-bookmark-file-1') {
    throw new NotABookmarksFileError('Not in netscape bookmarks file format');
  }

  return Array.from(doc.querySelectorAll('dt > a'))
    .filter((e) => e instanceof HTMLAnchorElement)
    .map((a) => {
      const href = a.href;
      if (!href) {
        return null;
      }
      const label = a.innerText;
      const icon = a.getAttribute('ICON') ?? a.getAttribute('ICON_URI');
      const tags = getTags(a);
      const bookmark: BrowserBookmark = { href, tags };

      if (icon) {
        bookmark.icon = icon;
      }
      if (label) {
        bookmark.label = label;
      }
      return bookmark;
    })
    .filter(exists);
};

const getTags = (element: HTMLElement) => {
  const tags = [];
  // return iterHTMLAncestors(element).filter((e) => e.tagName === "DT").map((e) => (e.querySelector("& > h3") as HTMLElement)?.innerText || null).filter((e) => e!== null).toArray();
  // Safari out here blocking `iterator.filter(...).map(...)`
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Iterator/map
  for (const current of iterHtmlAncestors(element)) {
    if (current.tagName === 'DT') {
      const tag = (current.querySelector('& > h3') as HTMLElement)?.innerText || null;
      if (tag) {
        tags.push(tag);
      }
    }
  }
  return tags.reverse();
};
