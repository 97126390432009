import {
  createContext,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react';
import { Outlet } from 'react-router';
import { z } from 'zod';

import {
  createDeserializerFromZodSchema,
  createSerdeLocalStorageHook,
} from '@hooks/useLocalStorage';
import Spinner from '@ui/Spinner';
import './index.scss';
import styles from './Theme.module.scss';
import { identity } from 'lodash';

export const themes = ['dark', 'light', 'system'] as const;
export type Theme = (typeof themes)[number];
export type DisplayTheme = Extract<Theme, 'dark' | 'light'>;

export const ThemeContext = createContext<{
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>> | null;
  setTemporaryTheme: React.Dispatch<React.SetStateAction<Theme | null>> | null;
}>({ theme: 'system', setTheme: null, setTemporaryTheme: null });

export default function Theme() {
  const { theme, setTheme, setTemporaryTheme } = useTheme();

  if (!theme) return <Spinner />;

  return (
    <ThemeContext.Provider value={{ theme, setTheme, setTemporaryTheme }}>
      <Outlet />
    </ThemeContext.Provider>
  );
}

// Django admin may set "auto", so we transform it into a supported value.
const autoThemeSchema = z.literal('auto').transform(() => 'system' as const);
const themeSchema = z.enum(themes).or(autoThemeSchema);

const themeDeserializer = createDeserializerFromZodSchema(themeSchema);
const useSavedTheme = createSerdeLocalStorageHook<z.infer<typeof themeSchema>>({
  key: 'theme',
  initial: 'system',
  serialize: identity,
  deserialize: (value, context) => {
    // If possible, use the django admin-compatible values
    const immediateResult = themeSchema.safeParse(value);
    // Otherwise, we need to fallback to the previous JSON encoded values
    return immediateResult.success
      ? immediateResult.data
      : themeDeserializer(value, context);
  },
});

const addBodyClass = (cls: string | undefined) => {
  if (cls === undefined) {
    return;
  }
  return document.body?.classList.add(cls);
};

const removeBodyClass = (cls: string | undefined) => {
  if (cls === undefined) {
    return;
  }
  return document.body?.classList.remove(cls);
};

const themeWatchMedia = window.matchMedia('(prefers-color-scheme: light)');
const subscribeToSystemTheme = (callback: () => void) => {
  const handler = () => {
    callback();
  };
  themeWatchMedia.addEventListener('change', handler);
  return () => themeWatchMedia.removeEventListener('change', handler);
};

const snapshotSystemTheme = (): DisplayTheme =>
  themeWatchMedia.matches ? 'light' : 'dark';

const useSystemTheme = () =>
  useSyncExternalStore(subscribeToSystemTheme, snapshotSystemTheme);

const useThemeAttribute = (theme: DisplayTheme) => {
  const clearRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    addBodyClass(styles.noTransition);
    document.documentElement.setAttribute('data-sd-theme', theme);

    clearTimeout(clearRef.current);
    clearRef.current = setTimeout(() => {
      removeBodyClass(styles.noTransition);
    }, 1);
  }, [theme]);
};

const useTheme = () => {
  const [_theme, setTheme] = useSavedTheme();
  const systemTheme = useSystemTheme();
  const [temporaryTheme, setTemporaryTheme] = useState<Theme | null>(null);
  const theme = temporaryTheme ?? _theme;
  useThemeAttribute(theme === 'system' ? systemTheme : theme);
  return { theme, setTheme, setTemporaryTheme };
};
