import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

import { Icon16 } from '@spaceduck/icons';

import Spinner from '@ui/Spinner';
import Tag from '@ui/Tag';
import styles from './FileListItem.module.scss';

const { Attachment } = Icon16;

type FileListItemProps = {
  file: File;
  fileKey: string;
  isInEditMode?: boolean;
  processedFileIds: string[];
  removeFile: (fileKey: string) => void;
};

export function FileListItem({
  file,
  fileKey,
  isInEditMode,
  processedFileIds,
  removeFile,
}: FileListItemProps) {
  const onRemoveClick = useCallback(() => {
    removeFile(fileKey);
  }, [fileKey, removeFile]);

  const isLoading = useMemo(() => {
    return !processedFileIds.includes(fileKey);
  }, [fileKey, processedFileIds]);

  return (
    <Tag
      className={clsx(styles.fileButton, isInEditMode && styles.fullWidth)}
      onClick={onRemoveClick}
      onRemoveClick={onRemoveClick}
      removeIconIsHidden
      removeIconPosition="floating"
      variant="tertiary"
    >
      {isLoading ? <Spinner size={14} /> : <Attachment size={14} />}
      <span>{file.name}</span>
    </Tag>
  );
}

type ViewFileListItemProps = {
  id?: string;
  isAnnotation?: boolean;
  isInEditMode?: boolean;
  name: string;
  onClick?: (id: string) => void;
  url: string;
};

export const ViewFileListItem = ({
  id,
  isAnnotation,
  isInEditMode,
  name,
  onClick,
  url,
}: ViewFileListItemProps) => {
  const handleClick = useCallback(() => {
    if (!isInEditMode) {
      window.open(url, '_blank');
      return true;
    }

    if (id && onClick) {
      onClick(id);
    }

    return true;
  }, [id, isInEditMode, onClick, url]);

  return (
    <Tag
      className={clsx(
        styles.fileButton,
        styles.fullWidth,
        isAnnotation && styles.bgLight
      )}
      onClick={handleClick}
      onRemoveClick={isInEditMode ? handleClick : undefined}
      removeIconIsHidden
      removeIconPosition="floating"
      variant="tertiary"
    >
      <Attachment size={14} />
      <span>{name}</span>
    </Tag>
  );
};
