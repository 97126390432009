import { memo, useCallback } from 'react';

import { Icon16 } from '@spaceduck/icons';

import {
  handleTransformation,
  type Transformation,
} from '@detailsModal/tiptap/transformations';
import { MenuButton } from '../menu';
import type { NotesMenuProps } from '../NotesMenu';

const { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, TextT } = Icon16;

export const TextHeadingMenu = memo((props: NotesMenuProps) => {
  const { editor } = props;

  const handleClick = useCallback(
    (transformation: Transformation) => {
      if (!editor) return null;

      handleTransformation(editor, transformation);
    },
    [editor]
  );

  if (!editor) return null;

  return (
    <>
      <MenuButton
        isActive={!editor.isActive('heading')}
        onClick={() => handleClick('text')}
        tooltipContent="Text"
      >
        <TextT />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 1 })}
        onClick={() => handleClick('heading1')}
        tooltipContent="Heading 1"
      >
        <Heading1 />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 2 })}
        onClick={() => handleClick('heading2')}
        tooltipContent="Heading 2"
      >
        <Heading2 />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 3 })}
        onClick={() => handleClick('heading3')}
        tooltipContent="Heading 3"
      >
        <Heading3 />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 4 })}
        onClick={() => handleClick('heading4')}
        tooltipContent="Heading 4"
      >
        <Heading4 />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 5 })}
        onClick={() => handleClick('heading5')}
        tooltipContent="Heading 5"
      >
        <Heading5 />
      </MenuButton>
      <MenuButton
        isActive={editor.isActive('heading', { level: 6 })}
        onClick={() => handleClick('heading6')}
        tooltipContent="Heading 6"
      >
        <Heading6 />
      </MenuButton>
    </>
  );
});
