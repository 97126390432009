import type { Editor } from '@tiptap/core';
import { clsx } from 'clsx';
import upperFirst from 'lodash/upperFirst';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useShallow } from 'zustand/shallow';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useManageMediaGroupLinkModal } from '@components/ManageMediaGroupLinkModal';
import TagInput from '@components/TagInput';
import { ContentType } from '@components/icons';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useCreateTags } from '@hooks/useCreateTags';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import { Favicon } from '@ui/Favicon';
import OptionalLink from '@ui/OptionalLink';
import Spinner from '@ui/Spinner';
import Tag from '@ui/Tag';
import Tooltip from '@ui/Tooltip';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { urlFor } from '@/urls';
import SingleFieldEdit from '../SingleFieldEdit';
import InfoCategories from './InfoCategories';
import InfoDetails from './InfoDetails';
import styles from './Info.module.scss';

const {
  ColorPalette,
  BacklinkIncoming: BacklinkIncoming16,
  BacklinkOutgoing: BacklinkOutgoing16,
  Hashtag,
  ReferenceParenthesis,
} = Icon16;
const { Add } = Icon24;

const GeneratingSpinner = ({ label }: { label: string }) => (
  <div className={styles.generating}>
    <Spinner size={18} />
    <span>Generating {label}...</span>
  </div>
);

const LinkDetails = ({
  linkUrl,
  linkUrlSource,
  mediaGroupId,
  userCanEdit,
}: {
  linkUrl: string;
  linkUrlSource: string;
  mediaGroupId: string;
  userCanEdit: boolean;
}) => {
  const { open } = useManageMediaGroupLinkModal({ mediaGroupId });

  if (userCanEdit) {
    return (
      <div className={clsx(styles.text, styles.editable)}>
        <div
          className={styles.trigger}
          onClick={(ev) => {
            if (userCanEdit) {
              ev.preventDefault();
              ev.stopPropagation();
              open(linkUrl);
            }
          }}
        >
          <Favicon url={linkUrl} className={styles.favicon} size={24} />
          <div className={styles.company}>{linkUrlSource}</div>
        </div>
        <OptionalLink href={linkUrl} isExternal>
          {' '}
        </OptionalLink>
      </div>
    );
  }

  return (
    <div className={styles.text}>
      <OptionalLink href={linkUrl} isExternal>
        <Favicon url={linkUrl} className={styles.favicon} size={24} />
        <div className={styles.company}>
          {new URL(linkUrl).host.replace(/^www\./, '')}
        </div>
      </OptionalLink>
    </div>
  );
};

export default function DetailsModalSidebarInfo({
  editor,
  mediaGroup,
  userCanEdit,
}: {
  editor?: Editor | null;
  mediaGroup: MediaGroupDetailDTO;
  userCanEdit: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const {
    id,
    description,
    label,
    linkUrl,
    linkUrlSource,
    tags,
    isGeneratingContent,
    colors: mgColors,
    links,
    backLinks,
    references,
  } = mediaGroup;
  const { open } = useManageMediaGroupLinkModal({ mediaGroupId: id });

  const { isDraggingOnBoard } = useDetailsModalStore(
    useShallow((state) => ({
      isDraggingOnBoard: state.isDraggingOnBoard,
    }))
  );

  const [showAllTags, setShowAllTags] = useState(false);
  const [showAllLinks, setShowAllLinks] = useState(
    (backLinks?.length ?? 0) + (links?.length ?? 0) === 0
  );

  const navigate = useNavigate();

  const {
    addTag,
    removeTag,
    highlightedTag,
    setShowTagCreationInput,
    showTagCreationInput,
    pendingTags,
  } = useCreateTags(mediaGroup);

  const [backgroundLocation] = useBackgroundLocation();
  const navigateToTagSearchUrl = useMemo(() => {
    if (!backgroundLocation) {
      if (workspaceId) {
        return urlFor('globalSearch', { workspaceId });
      }
      return null;
    }

    if (mediaGroup.project?.id) {
      if (
        backgroundLocation.includes(
          urlFor('spaceLibrary', {
            projectId: mediaGroup.project?.id,
          })
        )
      ) {
        return backgroundLocation;
      }
    }

    if (workspaceId) {
      if (backgroundLocation.includes(urlFor('workspaceRepository', { workspaceId }))) {
        return backgroundLocation;
      }
      return urlFor('globalSearch', { workspaceId });
    }
    return null;
  }, [mediaGroup.project?.id, workspaceId, backgroundLocation]);

  return (
    <div className={styles.sidebarContent}>
      <div className={styles.meta}>
        <div className={styles.summary}>
          {isGeneratingContent && !label && !description ? (
            <GeneratingSpinner label="info" />
          ) : (
            <>
              <div>
                <SingleFieldEdit
                  borderHeight={1}
                  disableQuery={isDraggingOnBoard}
                  displayAs="h2"
                  displayStyle={clsx(!label && styles.dim, styles.label)}
                  displayWrapperStyle={styles.inputPlaceholders}
                  enterToSubmit
                  fallback="Title..."
                  fieldName="label"
                  fieldLabel="title"
                  mediaGroupId={mediaGroup.id}
                  required={true}
                  textareaStyle={styles.label}
                />
              </div>
              <div>
                <SingleFieldEdit
                  borderHeight={1}
                  disableQuery={isDraggingOnBoard}
                  displayAs="p"
                  displayStyle={clsx(!description && styles.dim, styles.description)}
                  displayWrapperStyle={styles.inputPlaceholders}
                  fallback="Description..."
                  fieldName="description"
                  fieldLabel="description"
                  mediaGroupId={mediaGroup.id}
                  textareaStyle={styles.description}
                />
              </div>
            </>
          )}
        </div>
        <InfoCategories mediaGroup={mediaGroup} />
        <div className={styles.details}>
          {!!linkUrl && !!linkUrlSource && (
            <LinkDetails
              mediaGroupId={mediaGroup.id}
              linkUrl={linkUrl}
              linkUrlSource={linkUrlSource}
              userCanEdit={userCanEdit}
            />
          )}
          {!linkUrl && userCanEdit && (
            <Button
              className={styles.addAppLink}
              iconBefore={<Add />}
              onClick={() => open()}
              type="button"
              variant="link"
            >
              Add a link
            </Button>
          )}
        </div>
        <div className={styles.spec}>
          <div className={styles.kind}>
            <ContentType contentType={mediaGroup.contentType} />
            <div className="subtitle6">{upperFirst(mediaGroup.contentType)}</div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.links, showAllLinks && styles.expanded)}>
        <div className={styles.linkKind}>
          <h3 className={styles.heading}>
            <BacklinkIncoming16 />
            Backlinks
            <span>•</span>
            <span>{backLinks?.length}</span>
          </h3>
          {!!backLinks?.length && (
            <div className={styles.linkList}>
              {backLinks?.map((mediaGroup) => {
                return (
                  <a
                    key={mediaGroup.id}
                    href={urlFor('mediaGroup', {
                      mediaGroupId: mediaGroup.id,
                    })}
                    target="_blank"
                    className={styles.documentLink}
                    rel="noreferrer"
                  >
                    <ContentType contentType={mediaGroup.contentType} size={20} />
                    <span className={styles.label}>{mediaGroup.label}</span>
                  </a>
                );
              })}
            </div>
          )}
        </div>
        {['document', 'board'].includes(mediaGroup.kind) && (
          <div className={styles.linkKind}>
            <h3 className={styles.heading}>
              <BacklinkOutgoing16 />
              Outgoing links
              <span>•</span>
              <span>{links?.length}</span>
            </h3>
            {!!links?.length && (
              <div className={styles.linkList}>
                {links?.map((mediaGroup) => {
                  return (
                    <a
                      key={mediaGroup.id}
                      className={styles.documentLink}
                      href={urlFor('mediaGroup', {
                        mediaGroupId: mediaGroup.id,
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ContentType contentType={mediaGroup.contentType} size={20} />
                      <span className={styles.label}>{mediaGroup.label}</span>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className={styles.overflowOverlay} onClick={() => setShowAllLinks(true)} />
      </div>
      <div className={styles.tagManagement}>
        <h3 className={clsx(styles.heading, !tags?.length && styles.noTags)}>
          <Hashtag />
          Tags
          {userCanEdit && (
            <Button
              iconBefore={<Add size={16} />}
              onClick={() => setShowTagCreationInput((state) => !state)}
              variant="ghost"
              size="xs"
            >
              Add tag
            </Button>
          )}
        </h3>
        {showTagCreationInput && userCanEdit && (
          <TagInput
            className={styles.tagInputGroup}
            onAddTag={addTag}
            onRemoveTag={(tag) => removeTag(tag)}
            showSelectedTags={false}
            showSuggestions
            tags={tags?.filter((tag) => tag.source === 'user').map((tag) => tag.label)}
          />
        )}
        {isGeneratingContent && !tags.length && <GeneratingSpinner label="tags" />}
        {(pendingTags?.add?.length ?? 0) + (tags?.length ?? 0) > 0 && (
          <div className={clsx('tags', styles.tags, showAllTags && styles.expanded)}>
            {pendingTags?.add?.map((label) => {
              return (
                <Tag
                  key={`pending-add-${label}`}
                  className={styles.tag}
                  isRounded
                  removeIconIsHidden
                  removeIconPosition="floating"
                  variant="tertiary"
                >
                  <Hashtag />
                  {label}
                </Tag>
              );
            })}
            {tags
              .filter((tag) => !(pendingTags?.remove?.includes(tag.label) ?? false))
              .map((tag) => {
                const onRemoveClick = () => removeTag(tag.label);
                return (
                  <Tag
                    key={tag.id}
                    className={clsx(
                      styles.tag,
                      tag.label.toLowerCase() === highlightedTag.toLowerCase() &&
                        'highlightedTag'
                    )}
                    onClick={() => {
                      if (navigateToTagSearchUrl) {
                        navigate(navigateToTagSearchUrl, {
                          state: { tag },
                        });
                      }
                    }}
                    onRemoveClick={userCanEdit ? onRemoveClick : undefined}
                    isRounded
                    removeIconIsHidden
                    removeIconPosition="floating"
                    variant="tertiary"
                  >
                    {tag.source === 'user' && <Hashtag />}
                    {tag.label}
                  </Tag>
                );
              })}
            <div
              className={styles.overflowOverlay}
              onClick={() => setShowAllTags(true)}
            />
          </div>
        )}
      </div>

      {!!mgColors.length && (
        <div className={styles.colors}>
          <h3 className={clsx('body4', styles.heading)}>
            <ColorPalette />
            Colors
          </h3>
          {isGeneratingContent && !mgColors.length && (
            <GeneratingSpinner label="colors" />
          )}
          <div className={styles.colorBar} style={css({ '--slices': mgColors.length })}>
            {mgColors.map(({ hex, label }, idx) => {
              const value = `#${hex.toUpperCase()}`;
              return (
                <Tooltip
                  content={<span className="body6">{`${value} -> ${label}`}</span>}
                  key={idx}
                >
                  <button
                    style={{ backgroundColor: value }}
                    onClick={() => {
                      copyTextToClipboard(value, {
                        titleText: `Color: ${label}`,
                        bodyText: `"${value}" copied to clipboard`,
                      });
                    }}
                  />
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
      {mediaGroup.kind === 'document' && references.length > 0 && (
        <div className={styles.references}>
          <div className={styles.linkKind}>
            <h3 className={styles.heading}>
              <ReferenceParenthesis />
              References
            </h3>
            <div className={styles.linkList}>
              {references?.map((mediaGroup) => {
                return (
                  <a
                    key={mediaGroup.id}
                    className={styles.documentLink}
                    href={urlFor('mediaGroup', {
                      mediaGroupId: mediaGroup.id,
                    })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ContentType contentType={mediaGroup.contentType} size={20} />
                    <span className={styles.label}>{mediaGroup.label}</span>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <InfoDetails editor={editor} mediaGroup={mediaGroup} />
    </div>
  );
}
