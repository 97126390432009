import { Icon24, Icon32 } from '@spaceduck/icons';

import useWorkspaceId from '@hooks/useWorkspaceId';
import Button, { ButtonLink } from '@ui/Button';
import { BROWSER_EXT_URL } from '@/const';
import { urlFor } from '@/urls';
import { Container, Header, TopNav } from './common/';
import styles from './Integrations.module.scss';

const { SpaceduckExtension } = Icon24;
const { Bookmark } = Icon32;

export default function Integrations() {
  const workspaceId = useWorkspaceId();

  return (
    <>
      <TopNav title="Integrations" currentBreadcrumb="Integrations" owner="workspace" />
      <Container>
        <Header>
          <h1>Integrations</h1>
          <p>
            Enhance your Spaceduck experience with a variety of add-ons and
            integrations.
          </p>
        </Header>
        <div className={styles.list}>
          <Item
            buttonText="Download"
            icon={<SpaceduckExtension size={20} />}
            onClick={() => window.open(BROWSER_EXT_URL)}
          >
            <h3>Browser extension</h3>
            <p>
              Enhance your product research and team collaboration with our browser
              extension.
            </p>
          </Item>
          {workspaceId && (
            <Item
              buttonText="Import"
              icon={<Bookmark size={20} />}
              url={urlFor('workspaceSettingsIntegrationsImportBookmarks', {
                workspaceId,
              })}
            >
              <h3>Import bookmarks</h3>
              <p>
                Import your browser bookmarks directly into Spaceduck. Simply export
                your bookmarks as an HTML file using your browser's Bookmark Manager,
                then upload the file here.
              </p>
            </Item>
          )}
        </div>
      </Container>
    </>
  );
}

const Item = ({
  buttonText,
  children,
  icon,
  onClick,
  url,
}: {
  buttonText?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  url?: string;
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.image}>{icon}</div>
      <div className={styles.description}>{children}</div>
      <div className={styles.action}>
        <Action buttonText={buttonText} onClick={onClick} url={url} />
      </div>
    </div>
  );
};

const Action = ({
  buttonText,
  onClick,
  url,
}: {
  buttonText?: string;
  onClick?: () => void;
  url?: string;
}) => {
  if (!buttonText) return null;

  if (url) {
    return (
      <ButtonLink to={url} size="sm" variant="outlined">
        {buttonText}
      </ButtonLink>
    );
  }

  return (
    <Button onClick={onClick} size="sm" variant="outlined">
      {buttonText}
    </Button>
  );
};
