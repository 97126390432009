import * as Popover from '@radix-ui/react-popover';
import { Icon24 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import { type Ref, useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { css } from '@/lib/css';
import Checkbox from '@ui/Checkbox';
import dropdownStyles from '@ui/DropdownMenu.module.scss';
import ScrollArea from '@ui/ScrollArea';
import styles from './TagSuggestions.module.scss';

const { Add, Tags } = Icon24;

type TagSuggestionsProps = {
  addNewTag: (tag: string) => void;
  fetchNext?: () => void;
  handleTagClick: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  inputHasChangeRef: React.MutableRefObject<boolean>;
  menuMaxHeight: number | null;
  menuMaxWidth?: number;
  optionsContainerRef?: Ref<HTMLDivElement>;
  results: string[];
  setShowTagsMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTagInput: React.Dispatch<React.SetStateAction<string>>;
  showSelectedTags?: boolean;
  showTagsMenu: boolean;
  tagInput: string;
  tags: string[];
};

const TagSuggestions = ({
  addNewTag,
  fetchNext,
  handleTagClick,
  inputHasChangeRef,
  menuMaxHeight,
  menuMaxWidth,
  optionsContainerRef,
  results,
  setShowTagsMenu,
  showTagsMenu,
  showSelectedTags = true,
  tagInput,
  tags,
}: TagSuggestionsProps) => {
  const tagsInLowercase = tags.map((tag) => tag.toLowerCase());
  const renderedResults = results.filter((tag) => {
    if (showSelectedTags) return true;
    return !tagsInLowercase.includes(tag.toLowerCase());
  });
  const inputExists = !!results.find(
    (tag) => tag.toLowerCase() === tagInput.toLowerCase()
  );

  const hidePopover =
    tagInput.length > 0 &&
    tagsInLowercase.includes(tagInput.toLowerCase()) &&
    renderedResults.length < 2;

  const { ref: loadMoreRef, inView } = useInView();
  const allowFetch = useRef(false);
  const handleScroll = useCallback(() => {
    allowFetch.current = true;
  }, []);

  useEffect(() => {
    if (inputHasChangeRef.current) {
      allowFetch.current = false;
      inputHasChangeRef.current = false;
      // wait for scroll again to reactivate
      return;
    }

    if (inView && allowFetch.current) {
      const fetchTimeout = setTimeout(() => {
        allowFetch.current = false;
        fetchNext?.();
      }, 200);

      return () => clearTimeout(fetchTimeout);
    }
  }, [inView]);

  return (
    <Popover.Root open={true} onOpenChange={setShowTagsMenu} modal={false}>
      <Popover.Trigger asChild>
        <div />
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content
          align="start"
          avoidCollisions={false}
          className={clsx(
            styles.tagsMenu,
            showTagsMenu && styles.tagsMenuOpen,
            hidePopover && styles.hidden
          )}
          side="bottom"
          sideOffset={6}
          style={css({
            '--menu-width': menuMaxWidth
              ? `${menuMaxWidth}px`
              : 'var(--radix-popper-anchor-width)',
          })}
        >
          <ScrollArea
            className={styles.scrollArea}
            onScroll={handleScroll}
            style={css({
              '--width': '100%',
              '--maxHeight':
                menuMaxHeight && menuMaxHeight > 0 ? `${menuMaxHeight}px` : 0,
            })}
            type="auto"
          >
            <div className={clsx(dropdownStyles.dropdownMenuContent, styles.content)}>
              {renderedResults.length ? (
                <div className={styles.options} ref={optionsContainerRef}>
                  {renderedResults.map((tag, idx) => (
                    <Checkbox
                      asMenuItem
                      checked={tags.includes(tag)}
                      className={styles.option}
                      icon={showSelectedTags ? undefined : <Tags size={20} />}
                      key={idx}
                      onChange={handleTagClick}
                      hideCheckboxIcon={!showSelectedTags}
                      tabIndex={showTagsMenu ? 0 : -1}
                      value={tag}
                    >
                      {tag}
                    </Checkbox>
                  ))}
                  <div ref={loadMoreRef} style={{ width: '100%', height: '2px' }}>
                    {/* Triggers page fetch when in view */}
                  </div>
                </div>
              ) : (
                <div className={styles.noResults}>No matching tags</div>
              )}
              {!inputExists && !!tagInput.trim() && (
                <button className={styles.addNew} onClick={() => addNewTag(tagInput)}>
                  <Add size={20} />
                  <span>
                    add tag "<span className={styles.highlighted}>{tagInput}</span>"
                  </span>
                </button>
              )}
            </div>
          </ScrollArea>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default TagSuggestions;
