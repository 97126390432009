import { forwardRef, type HTMLAttributes, type ReactNode, type RefObject } from 'react';

import styles from './MenuContainer.module.scss';
import clsx from 'clsx';

type MenuContainerProps = {
  children: ReactNode;
  isLoading?: boolean;
  ref?: RefObject<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const MenuContainer = forwardRef<HTMLDivElement, MenuContainerProps>(
  (props: MenuContainerProps, ref) => {
    const { className, isLoading, ...divProps } = props;

    return (
      <div
        {...divProps}
        className={clsx(className, styles.menuContainer, isLoading && styles.isLoading)}
        ref={ref}
      >
        {props.children}
      </div>
    );
  }
);

export default MenuContainer;
