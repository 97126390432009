import type { ReactNode } from 'react';
import { useModalManager } from '@context/ModalManagerContext';
import Alert from '@ui/Alert';
import Button, { type ButtonVariant } from '@ui/Button';
import styles from './ConfirmModal.module.scss';

export function ConfirmModal({
  title,
  subtitle,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmVariant = 'danger',
  isOpen = true,
  closeModal,
  onConfirm,
  onCancel,
  children,
  className,
}: {
  title: string;
  subtitle?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmVariant?: ButtonVariant;
  isOpen?: boolean;
  closeModal?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  className?: string;
}) {
  const handleCancel = () => {
    closeModal?.();
    onCancel?.();
  };
  const handleConfirm = () => {
    closeModal?.();
    onConfirm?.();
  };
  return (
    <Alert className={className} isOpen={isOpen} onClose={closeModal} title={title}>
      <div className={styles.confirmModalContent}>
        <div className={styles.subtitle}>{subtitle}</div>
        {children}
        <div className={styles.buttons}>
          <Button size="sm" variant="outlined" onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button variant={confirmVariant} size="sm" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </Alert>
  );
}

type ConfirmModalConfig = {
  title?: string;
  subtitle?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmVariant?: ButtonVariant;
};

export function useConfirmModal<T = void>({
  title,
  subtitle,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmVariant = 'danger',
  onConfirm,
  onCancel,
}: ConfirmModalConfig & {
  onConfirm: (vars: T) => void;
  onCancel?: (vars: T) => void;
}) {
  const { openModal, closeModal } = useModalManager();

  return {
    open: (vars: T, overrideConfig?: ConfirmModalConfig) => {
      openModal({
        component: (
          <ConfirmModal
            title={title || overrideConfig?.title || 'Confirm'}
            subtitle={subtitle || overrideConfig?.subtitle}
            cancelText={cancelText || overrideConfig?.cancelText}
            confirmText={confirmText || overrideConfig?.confirmText}
            confirmVariant={confirmVariant || overrideConfig?.confirmVariant}
            onConfirm={() => onConfirm(vars)}
            onCancel={() => onCancel?.(vars)}
          />
        ),
      });
    },
    close: closeModal,
  };
}

// These overloads allow correct type inference of vars param passed through
// to onConfirm and onCancel from .open() call. If T is void, then vars is void.
type TextOptions = {
  title?: string;
  subtitle?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmVariant?: ButtonVariant;
};
export function useDeleteConfirmModal(
  config: {
    onConfirm: () => void;
    onCancel?: () => void;
  } & TextOptions
): ReturnType<typeof useConfirmModal<void>>;
export function useDeleteConfirmModal<T>(
  config: {
    onConfirm: (vars: T) => void;
    onCancel?: (vars: T) => void;
  } & TextOptions
): ReturnType<typeof useConfirmModal<T>>;
export function useDeleteConfirmModal<T = void>({
  onConfirm,
  onCancel,
  title,
  subtitle,
  cancelText,
  confirmText,
  confirmVariant,
}: {
  onConfirm: (vars?: T) => void;
  onCancel?: (vars?: T) => void;
} & TextOptions) {
  const { open, close } = useConfirmModal<T>({
    title,
    subtitle,
    cancelText,
    confirmText,
    confirmVariant,
    onConfirm,
    onCancel,
  });

  return {
    close,
    open: (vars: T, overrideConfig?: TextOptions) =>
      open(vars, {
        title: overrideConfig?.title || 'Are you sure?',
        cancelText: overrideConfig?.cancelText || 'Cancel',
        confirmText: overrideConfig?.confirmText || 'Yes, delete',
        confirmVariant: overrideConfig?.confirmVariant || 'danger',
        subtitle: overrideConfig?.subtitle || 'This action cannot be undone',
      }),
  };
}
