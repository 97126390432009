import { create } from 'zustand';

import type { ChatMediaGroupSource } from '@spaceduck/api';

export type SelectedSourceType = {
  id: string | null;
  label: string;
};

type SourcesStore = {
  readySources: ChatMediaGroupSource[];
  setReadySources: (includedSources: ChatMediaGroupSource[]) => void;
  addReadySource: (includedSource: ChatMediaGroupSource) => void;
  removeSource: (includedSource: SelectedSourceType) => void;
};

export const useSourcesStore = create<SourcesStore>((set) => ({
  readySources: [],
  setReadySources: (includedSources: ChatMediaGroupSource[]) => {
    set({ readySources: includedSources });
  },
  addReadySource: (includedSource: ChatMediaGroupSource) => {
    set(({ readySources: includedSources }) => {
      return {
        readySources: [
          ...includedSources.filter((source) => source.id !== includedSource.id),
          includedSource,
        ],
      };
    });
  },
  removeSource: (loadingSource: SelectedSourceType) => {
    set(({ readySources: includedSources }) => {
      return {
        readySources: includedSources.filter(
          (source) => source.id !== loadingSource.id
        ),
      };
    });
  },
}));
