import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useShallow } from 'zustand/shallow';
import type { Editor } from '@tiptap/core';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { isCapable } from '@api/util';
import { DetailsModalTooltip } from '@detailsModal/comments/DetailsModalTooltip';
import DetailsModalActionsDropdown from '@detailsModal/DetailsModalActionsDropdown';
import { getSupportedViews } from '@detailsModal/views';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useMetaKey } from '@hooks/useMetaKey';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useIsMac } from '@hooks/useOs';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useNotesStore } from '@stores/useNotesStore';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getCloseTo } from '@utils/mediaGroup';
import type { DetailsModelTab } from '@/types';
import { urlFor } from '@/urls';
import styles from './DetailsModalHeader.module.scss';

const { Close, Comment, DrawerRight } = Icon24;
const { Audio, Image, IFrame, ReadingMode, Summary, ShowEmbed, Video } = Icon16;

type DetailsModalHeaderProps = {
  mediaGroup: MediaGroupDetailDTO;
  isNote: boolean;
  activeTab: DetailsModelTab;
  view: string | null;
  editor?: Editor | null;
};

export default function DetailsModalHeader({
  mediaGroup,
  isNote,
  activeTab,
  view,
  editor,
}: DetailsModalHeaderProps) {
  const {
    contentView,
    drawerIsOpen,
    isCommenting,
    openDrawer,
    setIsCommenting,
    toggleDrawer,
    userCapabilities,
    showCommentsOnBoard,
    setShowCommentsOnBoard,
  } = useDetailsModalStore(
    useShallow((state) => ({
      contentView: state.contentView,
      drawerIsOpen: state.drawerIsOpen,
      isCommenting: state.isCommenting,
      openDrawer: state.openDrawer,
      setIsCommenting: state.setIsCommenting,
      toggleDrawer: state.toggleDrawer,
      userCapabilities: state.userCapabilities,
      showCommentsOnBoard: state.showCommentsOnBoard,
      setShowCommentsOnBoard: state.setShowCommentsOnBoard,
    }))
  );

  const [lastBackgroundLocation, ...previousLocations] = useBackgroundLocation();
  const navigateWithState = useNavigateWithState();

  const mediaGroupId = mediaGroup.id;
  const navigateToCommentTab = useCallback(() => {
    const url = urlFor('mediaGroupComments', { mediaGroupId });
    navigateWithState(url);
  }, [mediaGroupId]);

  const isMac = useIsMac();

  useHotkeys(
    'meta+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: !isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'meta+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: !isMac,
      preventDefault: true,
    }
  );

  const toggleCommenting = useCallback(() => {
    if (isCommenting) {
      setIsCommenting(false);
    } else {
      setIsCommenting(true);
      navigateToCommentTab();
      openDrawer();
    }
  }, [isCommenting, setIsCommenting, navigateToCommentTab, openDrawer]);

  const toggleShowBoardComments = useCallback(() => {
    setShowCommentsOnBoard((showCommentsOnBoard) => !showCommentsOnBoard);
  }, [setShowCommentsOnBoard]);

  useHotkeys(
    'c',
    () => {
      toggleCommenting();
      openDrawer();
    },
    {
      enabled: isCapable('comment', userCapabilities).capable,
    },
    [isCommenting, userCapabilities]
  );

  useHotkeys(
    'Escape',
    () => {
      setIsCommenting(false);
    },
    {
      enabled: isCommenting,
    },
    [isCommenting]
  );

  const handleToggleDrawer = useCallback(() => toggleDrawer(), [toggleDrawer]);

  const showCommentButton = useMemo(() => {
    return (
      isCapable('comment', userCapabilities).capable &&
      ['content'].includes(contentView ?? '') &&
      mediaGroup.media !== null &&
      !mediaGroup.isGeneratingContent
    );
  }, [isCapable, mediaGroupId, userCapabilities]);

  const showBoardCommentButton = useMemo(() => {
    return (
      mediaGroup.kind === 'board' && isCapable('comment', userCapabilities).capable
    );
  }, [isCapable, mediaGroupId, userCapabilities]);

  const closeTo = useMemo(() => {
    return lastBackgroundLocation || getCloseTo(mediaGroup);
  }, [lastBackgroundLocation, mediaGroup]);

  const closeState = useMemo(() => {
    return previousLocations.length
      ? { backgroundLocation: previousLocations }
      : undefined;
  }, [previousLocations]);

  const metaKey = useMetaKey();

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <CloseButton to={closeTo} state={closeState} />
      </nav>
      {isNote && <NoteSavingIndicator />}
      <ViewSwitcher mediaGroup={mediaGroup} view={view} activeTab={activeTab} />
      <div className={styles.utils}>
        <DetailsModalActionsDropdown mediaGroupId={mediaGroup.id} editor={editor} />
        {showCommentButton && (
          <DetailsModalTooltip
            content="Comment"
            shortKeys={['c']}
            size="md"
            variant="secondary"
          >
            <Button
              className={clsx(styles.button, isCommenting && styles.active)}
              onClick={toggleCommenting}
              variant="icon"
            >
              <Comment size={20} />
            </Button>
          </DetailsModalTooltip>
        )}
        {showBoardCommentButton && (
          <DetailsModalTooltip
            content={`${showCommentsOnBoard ? 'Hide' : 'Show'} comments`}
            size="md"
            variant="secondary"
          >
            <Button
              className={clsx(styles.button, showCommentsOnBoard && styles.active)}
              onClick={toggleShowBoardComments}
              variant="icon"
            >
              <Comment size={20} />
            </Button>
          </DetailsModalTooltip>
        )}
        <DetailsModalTooltip
          align="end"
          content={`${drawerIsOpen ? 'Hide' : 'Show'} Sidebar`}
          shortKeys={[metaKey, '.']}
          size="md"
          variant="secondary"
        >
          <Button className={styles.button} variant="icon" onClick={handleToggleDrawer}>
            <DrawerRight size={20} />
          </Button>
        </DetailsModalTooltip>
      </div>
    </div>
  );
}

const CloseButton = ({
  to,
  state,
}: { to: string; state: { backgroundLocation: string[] } | undefined }) => {
  const setQuickViewMediaGroupId = useDetailsModalStore(
    (state) => state.setQuickViewMediaGroupId
  );
  const isSaving = useNotesStore(
    (state) => state.isNotesSaving || state.isQuickViewNotesSaving
  );

  const handleCloseClick = useCallback(() => {
    setQuickViewMediaGroupId(null);
  }, [setQuickViewMediaGroupId]);

  return (
    <Tooltip
      align="start"
      content="Close"
      shortKeys={['Esc']}
      size="md"
      variant="secondary"
    >
      {isSaving ? (
        <Button disabled className={styles.button} variant="icon">
          <Close size={20} />
        </Button>
      ) : (
        <ButtonLink
          className={styles.button}
          variant="icon"
          onClick={handleCloseClick}
          to={to}
          state={state}
        >
          <Close size={20} />
        </ButtonLink>
      )}
    </Tooltip>
  );
};

const NoteSavingIndicator = () => {
  const isSaving = useNotesStore(
    (state) => state.isNotesSaving || state.isQuickViewNotesSaving
  );

  return (
    <div className={styles.notesUtils}>
      <div className={styles.savingIndicator}>
        {isSaving && (
          <>
            <Spinner size={16} />
            <span className={styles.savingLabel}>Saving...</span>
          </>
        )}
      </div>
    </div>
  );
};

type ViewSwitcherProps = {
  mediaGroup: MediaGroupDetailDTO;
  activeTab: DetailsModelTab;
  view: string | null;
};

const ViewSwitcher = ({ mediaGroup, activeTab, view }: ViewSwitcherProps) => {
  const navigateWithState = useNavigateWithState();

  const { kind, contentType, embed } = mediaGroup;

  const { embedIconComponent, embedLabel } = useMemo(() => {
    if (kind === 'bookmark' && embed?.kind === 'youtube') {
      return { embedIconComponent: <Video />, embedLabel: 'Video' };
    }

    return { embedIconComponent: <ShowEmbed />, embedLabel: 'Embed' };
  }, [kind, embed?.kind]);

  const { mediaIconComponent, mediaLabel } = useMemo(() => {
    if (kind === 'bookmark') {
      return { mediaIconComponent: <Image />, mediaLabel: 'Screenshot' };
    }

    if (kind === 'gallery') {
      if (contentType === 'audio') {
        return { mediaIconComponent: <Audio />, mediaLabel: 'Audio' };
      }
    }
    return { mediaIconComponent: <Image />, mediaLabel: 'Media' };
  }, [kind, contentType]);

  const views = getSupportedViews(mediaGroup);

  if (views.size < 2) {
    return null;
  }

  return (
    <div className={styles.noteToggle}>
      <TabButtonWrapper variant="dark" className={styles.tabButtonWrapper}>
        {views.has('embed') && (
          <TabButton
            isActive={view === 'embed'}
            iconBefore={embedIconComponent}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'embed',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            {embedLabel}
          </TabButton>
        )}
        {views.has('reading') && (
          <TabButton
            isActive={view === 'reading'}
            iconBefore={<ReadingMode />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'reading',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Reading mode
          </TabButton>
        )}
        {views.has('media') && (
          <TabButton
            isActive={view === 'media' || view === null}
            iconBefore={mediaIconComponent}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'media',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            {mediaLabel}
          </TabButton>
        )}
        {views.has('iframe') && (
          <TabButton
            isActive={view === 'iframe'}
            iconBefore={<IFrame />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'iframe',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            iFrame
          </TabButton>
        )}
        {views.has('transcription') && (
          <TabButton
            isActive={view === 'transcription'}
            iconBefore={<Summary />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'transcription',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Transcript
          </TabButton>
        )}
        {views.has('summary') && (
          <TabButton
            isActive={view === 'summary'}
            iconBefore={<Summary />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'summary',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Summary
          </TabButton>
        )}
      </TabButtonWrapper>
    </div>
  );
};
