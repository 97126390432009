import clsx from 'clsx';
import { useMemo, type ComponentProps } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import remarkRehype from 'remark-rehype';

import styles from './RenderedMarkdown.module.scss';

export type MarkdownProps = ComponentProps<typeof Markdown> & { className?: string };

export const RenderedMarkdown = ({
  remarkPlugins,
  className,
  childrenClassName,
  ...props
}: MarkdownProps & {
  childrenClassName?: string;
}) => {
  const plugins = useMemo(() => {
    return [remarkGfm, remarkRehype, rehypeHighlight, ...(remarkPlugins ?? [])];
  }, [remarkPlugins]);

  const components = useMemo(() => {
    if (!childrenClassName) {
      return undefined;
    }
    return 'a, em, h1, h2, h3, h4, h5, h6, li, ol, p, strong, ul'.split(', ').reduce(
      (acc: Record<string, any>, curr: string) => {
        const el = {
          [curr]: (props: any) => {
            const Component = curr;
            const { node, ...componentProps } = props;

            return (
              <Component
                {...componentProps}
                className={clsx(props.className, childrenClassName)}
              />
            );
          },
        };

        return Object.assign(acc, el);
      },
      {} as Record<string, React.ReactElement>
    );
  }, [childrenClassName]);

  return (
    <div className={clsx(styles.renderedMarkdown, className)}>
      <Markdown {...props} components={components} remarkPlugins={plugins} />
    </div>
  );
};
