import type { TableOfContentDataItem } from '@tiptap-pro/extension-table-of-contents';
import type { Editor } from '@tiptap/react';
import { clsx } from 'clsx';
import { ReactFlowProvider } from '@xyflow/react';
import { useShallow } from 'zustand/shallow';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import DetailsModalBoardContent from '@detailsModal/DetailsModalBoardContent';
import ModalNotesContent from '@detailsModal/DetailsModalNotesContent';
import DetailsModalSidebar from '@detailsModal/DetailsModalSidebar';
import ModalStandardContent from '@detailsModal/DetailsModalStandardContent';
import type { DetailsModelTab } from '@/types/MediaGroup';
import TableOfContents from './tiptap/nodes/TableOfContents';
import styles from './DetailsModalBody.module.scss';

export default function DetailsModalBody({
  view,
  activeTab,
  editor,
  handleImageUploadInputChange,
  imageUploadInputRef,
  mediaGroupId,
  tableOfContentItems,
}: {
  activeTab: DetailsModelTab;
  view: string | null;
  editor?: Editor | null;
  handleImageUploadInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => boolean | undefined;
  imageUploadInputRef?: React.RefObject<HTMLInputElement>;
  mediaGroupId: string;
  tableOfContentItems?: TableOfContentDataItem[];
}) {
  const { contentView, quickViewMediaGroupId, isDraggingOnBoard } =
    useDetailsModalStore(
      useShallow((state) => ({
        contentView: state.contentView,
        quickViewMediaGroupId: state.quickViewMediaGroupId,
        isDraggingOnBoard: state.isDraggingOnBoard,
      }))
    );

  const { data, isLoading } = useMediaGroupDetail(mediaGroupId, {
    enabled: !isDraggingOnBoard,
  });

  if (isLoading || !data) return null;
  const { mediaGroup } = data;
  const { kind } = mediaGroup;

  const hasLightBackground = ['notes'].includes(contentView ?? '');
  const enlargedSidebar =
    (!!quickViewMediaGroupId && activeTab === 'browseRepository') ||
    activeTab === 'researchChat';

  return (
    <div
      className={clsx(styles.dialogBody, hasLightBackground && styles.lightBackground)}
    >
      <DetailsModalSidebar
        mediaGroupId={mediaGroupId}
        view={view}
        activeTab={activeTab}
        enlarged={enlargedSidebar}
        editor={editor}
      />
      {/* TODO: Fill in the content for additional tabs */}
      {!contentView && (
        <div className={clsx(styles.visuals, enlargedSidebar && styles.shrunk)}>
          <h1 className="title4">No content available</h1>
        </div>
      )}
      {contentView === 'content' && (
        <div
          className={clsx(
            styles.visuals,
            styles[kind],
            enlargedSidebar && styles.shrunk
          )}
        >
          <ModalStandardContent
            mediaGroupId={mediaGroupId}
            activeTab={activeTab}
            view={view}
          />
        </div>
      )}
      {contentView === 'notes' && editor && (
        <div
          className={clsx(
            styles.visuals,
            styles.notes,
            enlargedSidebar && styles.shrunk
          )}
        >
          <TableOfContents
            className={styles.tableOfContents}
            editor={editor}
            items={tableOfContentItems ?? []}
          />
          <ModalNotesContent
            className={styles.notesContainer}
            editor={editor}
            imageUploadInputRef={imageUploadInputRef}
            handleImageUploadInputChange={handleImageUploadInputChange}
            mediaGroupId={mediaGroupId}
          />
        </div>
      )}
      {contentView === 'board' && (
        <div className={clsx(styles.visuals, styles.board)}>
          <ReactFlowProvider>
            <DetailsModalBoardContent mediaGroupId={mediaGroupId} />
          </ReactFlowProvider>
        </div>
      )}
      {contentView === 'transcript' && (
        <div className={clsx(styles.visuals, enlargedSidebar && styles.shrunk)}>
          <h1 className="title4">Transcript</h1>
        </div>
      )}
    </div>
  );
}
