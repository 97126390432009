import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon12, Icon16, Icon56 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import { type MouseEvent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router';

import MediaGroupStatusBadge from '@/components/MediaGroupStatusBadge';
import { css } from '@/lib/css';
import { urlFor } from '@/urls';
import { ContentType } from '@components/icons';
import { MediaGroupCategory } from '@components/icons';
import { BoardSummary } from '@components/BoardSummary';
import { ExtractBlockComponent } from '@components/ExtractBlock';
import { FileIcon } from '@components/FileIcon';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import { Favicon } from '@ui/Favicon';
import Spinner from '@ui/Spinner';
import TiptapBlock from '@ui/TiptapBlock';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import { AuthorAvatar } from '@ui/UserAvatar';
import { getContainerInfoFromMediaGroup } from '@utils/mediaGroup';
import styles from './Card.module.scss';

const { OpenNewWindow } = Icon12;
const { MenuMore } = Icon16;
const { Play } = Icon56;

const ALPHA_TRANSPARENCY_30 = '4c';
const ALPHA_TRANSPARENCY_05 = '0c';

export type Media = {
  type: 'image';
  src: string;
};

const Icon = ({
  containerInfo,
}: {
  containerInfo: ReturnType<typeof getContainerInfoFromMediaGroup>;
}) => {
  switch (containerInfo.kind) {
    case 'domain': {
      const { url } = containerInfo;
      if (url) {
        return <Favicon url={url} size={32} className={styles.icon} />;
      }
      break;
    }
    case 'workspace': {
      const { label: workspaceLabel } = containerInfo;
      if (workspaceLabel) {
        return (
          <WorkspaceAvatar
            workspaceName={workspaceLabel}
            className={styles.icon}
            workspaceAvatar={containerInfo.avatarUrl}
          />
        );
      }
      break;
    }
  }
  return null;
};

const ContainerName = ({
  containerInfo,
}: {
  containerInfo: ReturnType<typeof getContainerInfoFromMediaGroup>;
}) => {
  switch (containerInfo.kind) {
    case 'domain': {
      const { url, urlSource } = containerInfo;
      if (url) {
        return (
          <a href={url} target="_blank" className={styles.companyLink} rel="noreferrer">
            <span>{urlSource}</span>
            <OpenNewWindow />
          </a>
        );
      }
      break;
    }
    default:
      return null;
  }
};

type CardProps = {
  mediaGroup: MediaGroupDTO;
  selected?: boolean;
  onSelected?: (selected: boolean) => void;
  onClick?: () => void;
  contextMenu?: ContextMenuItemProps[];
  className?: string;
  hideFooter?: boolean;
  hideVisual?: boolean;
};

const Card = ({
  mediaGroup,
  selected,
  onSelected,
  onClick,
  contextMenu,
  className,
  hideFooter = false,
  hideVisual = false,
}: CardProps) => {
  const { isGeneratingContent, kind, thumbnail } = mediaGroup;

  const [mouseOver, setMouseOver] = useState(false);

  const backgroundLocation = useBackgroundLocation();
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;
  const nextBackgroundLocation = [currentLocation, ...backgroundLocation];

  // Only show the toolbar if there is something to display
  const toolbarContent =
    (selected || onSelected || contextMenu) && !hideVisual ? (
      <>
        {(selected || onSelected) && (
          <div className={styles.groupSelectionCheckbox}>
            <Checkbox
              size="sm"
              checked={selected}
              onChange={(event) => onSelected?.(event.target.checked)}
            />
            {selected && <span className="subtitle5">Selected</span>}
          </div>
        )}
        {contextMenu && (
          <RecursiveDropdownMenu
            dropdownMenuProps={{
              isPadded: true,
            }}
            items={contextMenu}
            isUnstyled
          >
            <Button variant="outlined" size="xs" isSquare>
              <MenuMore />
            </Button>
          </RecursiveDropdownMenu>
        )}
      </>
    ) : null;

  const handleClick = (event: MouseEvent) => {
    if (event.shiftKey) {
      event.preventDefault();
      onSelected?.(!selected);
    } else {
      onClick?.();
    }
  };

  return (
    <div
      className={clsx(
        styles.container,
        selected && styles.selected,
        mouseOver && styles.mouseOver,
        className
      )}
      onClick={handleClick}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div
        className={clsx(thumbnail.color && styles.gradientBg)}
        style={
          thumbnail.color
            ? css({
                '--bgMax': `${thumbnail.color}${ALPHA_TRANSPARENCY_30}`,
                '--bgMin': `${thumbnail.color}${ALPHA_TRANSPARENCY_05}`,
              })
            : undefined
        }
      />
      {toolbarContent && <div className={styles.toolbar}>{toolbarContent}</div>}
      {!hideVisual && (
        <div className={styles.visual}>
          <div className={clsx(styles.image, kind && styles[`${kind}`])}>
            <CardThumbnail mediaGroup={mediaGroup} />
          </div>
        </div>
      )}
      {isGeneratingContent ? (
        <div className={styles.details}>
          <Link
            to={`/media-groups/${mediaGroup.id}`}
            state={{ backgroundLocation: nextBackgroundLocation }}
            className={clsx(styles.linkOverlay, styles.loadingLink)}
            draggable="false"
          >
            <div className={clsx('title5', styles.title, styles.loading)}>
              <Spinner size={24} />
              <span>Generating info...</span>
            </div>
          </Link>
        </div>
      ) : (
        <CardDetails
          mediaGroup={mediaGroup}
          backgroundLocation={nextBackgroundLocation}
          hideFooter={hideFooter}
        />
      )}
    </div>
  );
};

const CardDetails = ({
  mediaGroup,
  backgroundLocation,
  hideFooter,
}: {
  mediaGroup: MediaGroupDTO;
  backgroundLocation: string[];
  hideFooter?: boolean;
}) => {
  const { category, status, project } = mediaGroup;

  return (
    <div className={styles.details}>
      <div className={styles.top}>
        <CardIcon mediaGroup={mediaGroup} />
        <CardTitle mediaGroup={mediaGroup} backgroundLocation={backgroundLocation} />
      </div>
      {!hideFooter && project && (
        <div className={styles.bottom}>
          <div className={styles.category}>
            <div className={styles.categoryIcon}>
              <MediaGroupCategory size={16} />
            </div>
            <div className={styles.categoryText}>
              {category?.id ? category.label : 'Uncategorized'}
            </div>
          </div>
          <MediaGroupStatusBadge
            background={3}
            iconSize={16}
            outline={1}
            size="sm"
            status={status}
          />
        </div>
      )}
    </div>
  );
};

const CardIcon = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const containerInfo = getContainerInfoFromMediaGroup(mediaGroup);

  return (
    <div className={styles.icons}>
      <Icon containerInfo={containerInfo} />
      <AuthorAvatar author={mediaGroup.author} className={styles.avatar} size="xxs" />
    </div>
  );
};

const CardTitle = ({
  mediaGroup,
  backgroundLocation,
}: {
  mediaGroup: MediaGroupDTO;
  backgroundLocation: string[];
}) => {
  const containerInfo = getContainerInfoFromMediaGroup(mediaGroup);

  if (containerInfo.kind === 'workspace') {
    return (
      <div className={clsx(styles.text, styles.columns)}>
        <div className={styles.left}>
          <CardMediaGroup
            mediaGroup={mediaGroup}
            backgroundLocation={backgroundLocation}
          />
        </div>
        <div className={styles.right}>
          <CardKind mediaGroup={mediaGroup} />
          <CardProject mediaGroup={mediaGroup} />
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.text, styles.rows)}>
      <div>
        <CardMediaGroup
          mediaGroup={mediaGroup}
          backgroundLocation={backgroundLocation}
        />
        <CardKind mediaGroup={mediaGroup} />
      </div>
      <div>
        <div className={clsx('body6', styles.collapsible, styles.companyName)}>
          <ContainerName containerInfo={containerInfo} />
        </div>
        <CardProject mediaGroup={mediaGroup} />
      </div>
    </div>
  );
};

const CardMediaGroup = ({
  mediaGroup,
  backgroundLocation,
}: {
  mediaGroup: MediaGroupDTO;
  backgroundLocation: string[];
}) => {
  const { id, label } = mediaGroup;
  return (
    <div className={clsx('title5', styles.collapsible, styles.title)}>
      <Link
        to={`/media-groups/${id}`}
        className={styles.linkOverlay}
        draggable="false"
        state={{ backgroundLocation }}
      >
        {label}
      </Link>
    </div>
  );
};

const CardKind = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const { contentType } = mediaGroup;
  return (
    <div className={styles.cardKind}>
      <div className={clsx('title6', styles.kind)}>
        <div className={styles.paddedIcon}>
          <ContentType contentType={contentType} />
        </div>
        {contentType}
      </div>
    </div>
  );
};

const CardProject = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const { project } = mediaGroup;

  return (
    <div className={clsx('body6', styles.collapsible, styles.projectDetail)}>
      {project ? (
        <Link to={urlFor('space', { projectId: project.id })}>
          <span className={styles.projectLabel} title="Saved in a space">
            {project.label}
          </span>
        </Link>
      ) : (
        // TODO: link to repository only items
        <span
          className={clsx(styles.projectLabel, styles.noLink)}
          title="Saved to your drafts"
        >
          Draft
        </span>
      )}
    </div>
  );
};

const ImageOrNull = ({
  alt,
  className,
  height,
  src,
  width,
}: {
  alt?: string;
  className?: string;
  height?: number;
  src?: string | null;
  width?: number;
}) => {
  if (!src) return null;
  return (
    <LazyLoadImage
      alt={alt}
      className={className}
      height={height}
      src={src}
      width={width}
    />
  );
};

const CardThumbnail = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const { thumbnail, contentType } = mediaGroup;

  if (thumbnail?.source.kind === 'document') {
    return (
      <NotesContent label={mediaGroup.label} document={thumbnail.source.document} />
    );
  }

  if (thumbnail?.source.kind === 'url') {
    return (
      <>
        <ImageOrNull
          src={thumbnail.source.url}
          width={thumbnail.width ?? undefined}
          height={thumbnail.height ?? undefined}
        />
        {contentType === 'video' && <Play className={styles.overlay} />}
      </>
    );
  }

  if (thumbnail.source.kind === 'fileIcon') {
    return (
      <div className={clsx(styles.fileIcon, styles.cardVisualBg)}>
        <FileIcon mediaType={thumbnail.source.mediaType} />
        <div className={styles.name}>{thumbnail.source.label}</div>
      </div>
    );
  }

  if (thumbnail.source.kind === 'extract') {
    return (
      <ExtractBlockComponent
        className={clsx(styles.extract, styles.cardVisualBg)}
        isQuote={mediaGroup.contentType === 'quote'}
        text={thumbnail.source.text}
        palette="surface2"
      />
    );
  }

  if (thumbnail.source.kind === 'board') {
    return <BoardSummary mediaGroup={mediaGroup} className={styles.cardVisualBg} />;
  }

  return null;
};

const NotesContent = ({
  label,
  document,
}: {
  label: string;
  document: any;
}) => {
  return (
    <div className={clsx(styles.notesContentWrapper)}>
      <h4 className={clsx(styles.notesTitle)}>{label}</h4>
      <TiptapBlock document={document} />
    </div>
  );
};

export default Card;
