import Tippy from '@tippyjs/react';
import clsx from 'clsx';

import { MenuItem, MenuTitle, MenuTrigger } from './menu/index';
import styles from './Menu.module.scss';

type MenuProps = {
  className?: string;
  containerClassName?: string;
  menuItems?: MenuItem[];
  offset?: [number, number];
};

export default function Menu({
  className,
  containerClassName,
  menuItems,
  offset,
}: MenuProps) {
  if (!menuItems?.length) return null;

  return (
    <div className={clsx(styles.menu, containerClassName)}>
      {menuItems?.map((menuItem, idx) => {
        const {
          children,
          className: menuItemClassName,
          disabled,
          icon,
          label,
          menuItems: submenuItems,
          onClick,
          title,
          tooltip,
          isActive,
          isSeparator,
          ref,
          placement = 'bottom-start',
          removeMenuItemStyle,
        } = menuItem;
        return (
          <span className={styles.tippyWrapper} key={idx}>
            {(submenuItems && submenuItems.length > 0) || children ? (
              <Tippy
                allowHTML
                onShow={({ reference }) => {
                  reference.setAttribute('aria-expanded', 'true');
                }}
                onHide={({ reference }) => {
                  reference.setAttribute('aria-expanded', 'false');
                }}
                interactive={true}
                trigger="click"
                placement={placement}
                offset={offset}
                content={
                  <div
                    className={clsx(
                      !removeMenuItemStyle && styles.content,
                      className,
                      menuItemClassName
                    )}
                  >
                    {title && <MenuTitle>{title}</MenuTitle>}
                    {submenuItems?.map((submenuItem, jdx) => {
                      return <MenuItem {...submenuItem} key={jdx} />;
                    })}
                    {children}
                  </div>
                }
              >
                <div className={styles.triggerWrapper}>
                  <MenuTrigger
                    className={styles.level1}
                    hasSubmenu
                    icon={icon}
                    isActive={isActive}
                    label={label}
                    tooltip={tooltip}
                  />
                </div>
              </Tippy>
            ) : (
              <MenuItem
                isActive={isActive}
                icon={icon}
                label={label}
                onClick={onClick}
                tooltip={tooltip}
                disabled={disabled}
                isSeparator={isSeparator}
                ref={ref}
              />
            )}
          </span>
        );
      })}
    </div>
  );
}
