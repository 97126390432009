import { Icon16, Icon32 } from '@spaceduck/icons';
import { useContext, useEffect, useState } from 'react';

import { BROWSER_EXT_DIV_ID, BROWSER_EXT_URL } from '@/const';
import Draft01 from '@assets/img/drafts/drafts-01.png';
import Draft02 from '@assets/img/drafts/drafts-02.png';
import Draft03 from '@assets/img/drafts/drafts-03.png';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import { FileInputContext } from '@components/FileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import styles from './EmptyCollection.module.scss';
import Card, { CardGrid } from './empty/Card';
import Lightbox from './empty/Lightbox';

const { Download, Upload } = Icon16;
const { Play } = Icon32;

const VIDEO_URL = 'https://www.youtube.com/embed/MwlAdWJQ_pQ?si=XhOhrJmwAxl_fy5b';

export default function EmptyCollection() {
  const workspaceId = useWorkspaceId();
  const [showBrowserExt, setShowBrowserExt] = useState(
    !document.getElementById(BROWSER_EXT_DIV_ID)
  );
  const [showLightbox, setShowLightbox] = useState(false);
  const { inputRef } = useContext(FileInputContext);

  useEffect(() => {
    setShowBrowserExt(!document.getElementById(BROWSER_EXT_DIV_ID));
  }, []);

  return (
    <>
      <div className={styles.emptyCollectionContainer}>
        <div className={styles.text}>
          <h1>Private Drafts</h1>
          <p>
            A safe place for all the thoughts, ideas, and materials you're still
            figuring out. Drop in your unfinished notes, sketches, files, or anything
            you're not quite ready to sort. When the time comes, you can easily move
            them to the right space.
          </p>
        </div>
        {!!VIDEO_URL && (
          <div className={styles.video}>
            <button className={styles.iconButton} onClick={() => setShowLightbox(true)}>
              <Play />
            </button>
            <button className={styles.textButton} onClick={() => setShowLightbox(true)}>
              Learn how Drafts works
            </button>
          </div>
        )}
        <CardGrid>
          {showBrowserExt && (
            <Card
              button={
                <Button
                  iconBefore={<Download size={12} />}
                  onClick={() => window.open(BROWSER_EXT_URL)}
                  size="sm"
                  variant="outlined"
                >
                  Download Now
                </Button>
              }
            >
              <img alt="" src={Draft01} />
              <div className={styles.headingRow}>
                <h3>Browser Extension</h3>
                <Badge preset="trial" size="sm">
                  Must Have!
                </Badge>
              </div>
              <p>
                Install our Browser Extension and effortlessly capture anything from the
                web on the go.
              </p>
            </Card>
          )}
          <Card
            button={
              <Button
                iconBefore={<Upload size={12} />}
                onClick={() => inputRef?.current?.click()}
                size="sm"
                variant="outlined"
              >
                Upload Content
              </Button>
            }
          >
            <img alt="" src={Draft02} />
            <h3>Upload your first media or file</h3>
            <p>
              Just drag and drop or upload your files, and watch as they are
              automatically organized for you.
            </p>
          </Card>
          <Card
            button={
              workspaceId ? (
                <NewMediaGroupDropdown
                  align="start"
                  exclude={['media', 'link']}
                  triggerText="New item"
                  variant="outlined"
                />
              ) : undefined
            }
          >
            <img alt="" src={Draft03} />
            <h3>Document or Board</h3>
            <p>
              Documents and boards auto-connect to your content for easier research and
              analysis.
            </p>
          </Card>
        </CardGrid>
      </div>
      {!!VIDEO_URL && (
        <Lightbox
          close={() => setShowLightbox(false)}
          embedUrl={VIDEO_URL}
          open={showLightbox}
        />
      )}
    </>
  );
}
