import type { Editor } from '@tiptap/core';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { NamedPrompt } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Menu, {
  MenuItem as BaseMenuItem,
  type MenuItemProps,
  RecursiveDropdownMenuItem,
  Separator,
} from '@ui/DropdownMenu';
import Spinner from '@ui/Spinner';
import styles from './AIMenuInitial.module.scss';

const { AI } = Icon16;

export default function AIMenuInitial({
  container,
  editor,
  isLoading = false,
  handleChoose,
  onEscape,
  setFocus,
}: {
  container?: HTMLElement | null;
  editor: Editor | null;
  isLoading?: boolean;
  handleChoose: (namedPrompt: NamedPrompt) => void;
  onEscape?: () => void;
  setFocus?: () => void;
}) {
  const [open, setOpen] = useState(true);
  // Controls submenu as menu default behavior has been disabled
  const [showSubmenu, setShowSubmenu] = useState(false);

  const onEscapeKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      setOpen(false);
      onEscape?.();
    },
    [onEscape, setOpen]
  );

  const preventDefaultAndPropagation = useCallback(
    (ev: Pick<Event, 'preventDefault' | 'stopPropagation'>) => {
      ev.preventDefault();
      ev.stopPropagation();
    },
    []
  );

  const pointerEventHandlers = useMemo(() => {
    return {
      onPointerLeave: preventMouseOverFocus,
      onPointerMove: preventMouseOverFocus,
    };
  }, []);

  const checkContent = useCallback(
    ({ editor }: { editor: Editor }) => {
      if (!editor.getText()) {
        setOpen(true);

        const focusTimeout = setTimeout(() => {
          editor?.commands.focus();
        }, 100);

        return () => clearTimeout(focusTimeout);
      }

      setOpen(false);
    },
    [editor]
  );

  useEffect(() => {
    if (!editor) return;

    editor.on('update', checkContent);

    return () => {
      editor.off('update', checkContent);
    };
  }, [editor]);

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <Menu
      alignOffset={-13}
      className={clsx(isLoading && styles.hidden, styles.menu)}
      container={container}
      dropdownMenuContentProps={{
        autoFocus: false,
        onCloseAutoFocus: preventDefaultAndPropagation,
        onEscapeKeyDown,
        onFocus: preventDefaultAndPropagation,
        onFocusCapture: preventDefaultAndPropagation,
        onInteractOutside: preventDefaultAndPropagation,
        onMouseOver: (ev) => {
          const target = ev.target as Element;

          if (target.role === 'menu') return false;

          if (target.role === 'menuitem') {
            if (['Make longer', 'Make shorter'].includes(target.textContent ?? '')) {
              return false;
            }
          }

          setShowSubmenu(false);
          return false;
        },
        onMouseLeave: () => {
          setShowSubmenu(false);
          return false;
        },
      }}
      isPadded
      open={open}
      setOpen={setOpen}
      sideOffset={16}
      triggerContent={
        <Button className={styles.trigger} type="button" variant="icon">
          <AI />
        </Button>
      }
      width={260}
    >
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('clarity_and_fluency');
        }}
      >
        Enhance clarity and fluency
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('final_polish');
        }}
      >
        Add final polish
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('simplify');
        }}
      >
        Simplify
      </MenuItem>
      <Separator />
      <RecursiveDropdownMenuItem
        container={container}
        className={clsx(!open && styles.hidden)}
        dropdownMenuSubContentProps={{
          sideOffset: 0,
        }}
        dropdownMenuSubTriggerProps={{
          ...pointerEventHandlers,
          onMouseEnter: () => {
            setShowSubmenu(true);
          },
          onMouseOver: preventDefaultAndPropagation,
        }}
        isPadded
        item={{
          content: 'Length',
          subMenu: [
            {
              content: 'Make longer',
              contextMenuItemProps: { ...pointerEventHandlers },
              onClick: (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                container?.querySelector('button')?.focus();
                handleChoose('length_more');
                setOpen(false);
              },
            },
            {
              content: 'Make shorter',
              contextMenuItemProps: { ...pointerEventHandlers },
              onClick: (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                container?.querySelector('button')?.focus();
                handleChoose('length_less');
                setOpen(false);
              },
            },
          ],
        }}
        open={showSubmenu}
        setOpen={setShowSubmenu}
      />
      <Separator />
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('summarize');
        }}
      >
        Summarize
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('action_items');
        }}
      >
        Create action items
      </MenuItem>
      <Separator />
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('outline');
        }}
      >
        Outline...
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('brainstorm');
        }}
      >
        Brainstorm ideas...
      </MenuItem>
      <MenuItem
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setFocus?.();
          setOpen(false);
        }}
      >
        Custom...
      </MenuItem>
    </Menu>
  );
}

function preventMouseOverFocus(ev: React.PointerEvent) {
  ev.preventDefault();
}

const MenuItem = (props: MenuItemProps) => {
  const pointerEventHandlers = useMemo(() => {
    return {
      onPointerLeave: preventMouseOverFocus,
      onPointerMove: preventMouseOverFocus,
    };
  }, []);

  return <BaseMenuItem {...pointerEventHandlers} {...props} />;
};
