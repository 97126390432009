import { Icon24 } from '@spaceduck/icons';

import ContentBlockListItem from './ContentBlockListItem';
import styles from './NoSearchResult.module.scss';

const { AlertInfo } = Icon24;

export default function NoSearchResult({
  onClick,
  space,
}: { onClick?: () => void; space?: string }) {
  return (
    <div className={styles.noSearchResult}>
      <h3>Items</h3>
      <ContentBlockListItem
        icon={<AlertInfo />}
        label="Cannot find the item you're looking for."
        bucket={space || 'Drafts'}
        onClick={onClick}
      />
    </div>
  );
}
