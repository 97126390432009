import { type Connection, type Edge, MarkerType, useReactFlow } from '@xyflow/react';
import { upperFirst } from 'lodash';
import { useShallow } from 'zustand/shallow';

import { useBoardStore } from './useBoardStore';
import { boardColors } from '../types/colors';
import { edgeWidthAsNumber } from '../types/board';

export const useEdges = () => {
  const {
    defaultConnectionLineColor,
    defaultConnectionLineStyle,
    defaultConnectionLineType,
    defaultConnectionLineWidth,
    defaultMarkerStartType,
    defaultMarkerEndType,
    onEdgesChange,
    selectedEdges,
    setSelectedEdges,
  } = useBoardStore(
    useShallow((state) => ({
      defaultConnectionLineColor: state.defaultConnectionLineColor,
      defaultConnectionLineStyle: state.defaultConnectionLineStyle,
      defaultConnectionLineType: state.defaultConnectionLineType,
      defaultConnectionLineWidth: state.defaultConnectionLineWidth,
      defaultMarkerStartType: state.defaultMarkerStartType,
      defaultMarkerEndType: state.defaultMarkerEndType,
      onEdgesChange: state.onEdgesChange,
      selectedEdges: state.selectedEdges,
      setSelectedEdges: state.setSelectedEdges,
    }))
  );

  const { addEdges } = useReactFlow();

  const onConnect = async (params: Connection | Edge) => {
    if (params.source === params.target) return;

    const newEdge = {
      ...params,
      id: `${params.sourceHandle}->${params.targetHandle}`,
      type: 'baseEdge',
      data: {
        color: defaultConnectionLineColor,
        style: defaultConnectionLineStyle,
        type: defaultConnectionLineType,
        width: defaultConnectionLineWidth,
      },
      label: null,
      markerStart:
        defaultMarkerStartType === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: boardColors[defaultConnectionLineColor],
              strokeWidth: edgeWidthAsNumber[defaultConnectionLineWidth],
            }
          : defaultMarkerStartType
            ? `${defaultMarkerStartType}${upperFirst(defaultConnectionLineColor)}`
            : undefined,
      markerEnd:
        defaultMarkerEndType === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: boardColors[defaultConnectionLineColor],
              strokeWidth: edgeWidthAsNumber[defaultConnectionLineWidth],
            }
          : defaultMarkerEndType
            ? `${defaultMarkerEndType}${upperFirst(defaultConnectionLineColor)}`
            : undefined,
    };

    addEdges([newEdge]);
  };

  return {
    onConnect,
    onEdgesChange,
    selectedEdges,
    setSelectedEdges,
  };
};
