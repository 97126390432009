export type ResultOk<T> = { success: true; data: T };
export type ResultErr<E> = { success: false; error: E };
export type Result<T, E> = ResultOk<T> | ResultErr<E>;
export type AsyncResult<T, E> = Promise<Result<T, E>>;

export const ok = <T>(data: T): ResultOk<T> => ({ success: true, data });
export const err = <E = unknown>(error: E): ResultErr<E> => ({ success: false, error });

export const attempt = <T>(fn: () => T): Result<T, unknown> => {
  let data: T;

  try {
    data = fn();
  } catch (error) {
    return err(error);
  }
  return ok(data);
};

export const attemptAsync = async <T>(
  fn: () => PromiseLike<T>
): AsyncResult<T, unknown> => {
  let data: T;

  try {
    data = await fn();
  } catch (error) {
    return err(error);
  }
  return ok(data);
};
