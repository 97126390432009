import { memo, useMemo } from 'react';

import {
  MenuContainer as Container,
  MenuButtonGrid as ButtonGrid,
  MenuSeparator as Separator,
  MenuTitle as Title,
} from './menu';
import { CollapseMenu, FrameCollapseMenu } from './node';
import { AutoHeightMenu } from './notes';
import type { NodeManagementProps } from './SideMenu';
import { pluralize } from '@spaceduck/utils';

const NON_COLLAPSIBLE_NODES = ['groupNode', 'floatingTextNode', 'fileNode'];

export const NodeMenu = memo((props: NodeManagementProps) => {
  return (
    <>
      <FrameMenu {...props} />
      <NonFrameMenu {...props} />
    </>
  );
});

const FrameMenu = memo((props: NodeManagementProps) => {
  const selectedFrames = useMemo(() => {
    return props.selectedNodeItems.filter((node) => node.type === 'groupNode');
  }, [props.selectedNodes]);

  const title = useMemo(() => {
    return pluralize(selectedFrames.length > 1, 'Fold Frames', 'Fold Frame');
  }, [selectedFrames]);

  if (!selectedFrames.length) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <ButtonGrid>
        <FrameCollapseMenu {...props} />
      </ButtonGrid>
    </Container>
  );
});

const NonFrameMenu = memo((props: NodeManagementProps) => {
  const { selectedNodeItems } = props;

  const collapsibleNodes = useMemo(() => {
    return selectedNodeItems.filter(
      (node) => !NON_COLLAPSIBLE_NODES.includes(node.type ?? '')
    );
  }, [selectedNodeItems]);

  const floatingTextNodes = useMemo(() => {
    return selectedNodeItems.filter((node) => node.type === 'floatingTextNode');
  }, [selectedNodeItems]);

  const title = useMemo(() => {
    return pluralize(collapsibleNodes.length > 1, 'Fold Cards', 'Fold Card');
  }, [collapsibleNodes]);

  if (!collapsibleNodes.length && !floatingTextNodes.length) return null;

  return (
    <Container>
      {!!collapsibleNodes.length && (
        <>
          <Title>{title}</Title>
          <ButtonGrid>
            <CollapseMenu {...props} />
          </ButtonGrid>
        </>
      )}
      {!!collapsibleNodes.length && !!floatingTextNodes.length && <Separator />}
      {!!floatingTextNodes.length && (
        <>
          <Title>Auto-height</Title>
          <ButtonGrid>
            <AutoHeightMenu {...props} />
          </ButtonGrid>
        </>
      )}
    </Container>
  );
});
