import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styles from './Carousel.module.scss';
import { throttle } from 'lodash';

const START_BREAKPOINT = 1e-4;
const END_BREAKPOINT = 1 - START_BREAKPOINT;

const useThrottled = <T extends (...args: any[]) => any>(fn: T) => {
  const ref = useRef<T>(fn);
  ref.current = fn;
  const dispatch = useCallback((...args: Parameters<T>) => ref.current(...args), []);
  return useMemo(() => throttle(dispatch), [dispatch]);
};

export type CarouselOptions = EmblaOptionsType;
export type CarouselProps = PropsWithChildren<{
  className?: string;
  containerClassName?: string;
  options?: CarouselOptions;
}>;
export const Carousel = ({
  className,
  containerClassName,
  children,
  options,
}: CarouselProps) => {
  const [showStartShadow, setShowStartShadow] = useState(false);
  const [showEndShadow, setShowEndShadow] = useState(false);
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const containerRef = useRef(null);

  const immediate = useCallback((emblaApi: EmblaCarouselType) => {
    const scrollProgress = emblaApi.scrollProgress();
    setShowStartShadow(scrollProgress >= START_BREAKPOINT);
    setShowEndShadow(scrollProgress <= END_BREAKPOINT);
  }, []);
  const throttled = useThrottled(immediate);

  useLayoutEffect(() => {
    if (containerRef.current === null || emblaApi === undefined) {
      return;
    }
    const observer = new ResizeObserver(() => {
      throttled(emblaApi);
    });
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [emblaApi, throttled]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('scroll', throttled);
      emblaApi.on('settle', immediate);
      return () => {
        emblaApi.off('scroll', throttled);
        emblaApi.off('settle', immediate);
      };
    }
  }, [emblaApi, immediate, throttled]);

  return (
    <div className={clsx(styles.carouselWrapper, className)}>
      <div className={clsx(styles.startShadow, !showStartShadow && styles.hidden)} />
      <div ref={emblaRef} className={styles.carousel}>
        <div
          ref={containerRef}
          className={clsx(styles.carouselContainer, containerClassName)}
        >
          {children}
        </div>
      </div>
      <div className={clsx(styles.endShadow, !showEndShadow && styles.hidden)} />
    </div>
  );
};
