import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { type ComponentProps, type PropsWithChildren, useRef } from 'react';

import { Icon16, Icon24 } from '@spaceduck/icons';

import { useCreateAndNavigateToMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace, useWorkspaceProjects } from '@api/workspace';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import {
  type CreateBookmarkData,
  useBookmarkModal,
} from '@components/CreateBookmarkModal';
import { useCreateMediaGroupActions } from '@hooks/useCreateMediaGroupActions';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import { activeProjectKeys } from '@/const';
import styles from './ContentManagementSection.module.scss';

const { Add, Document, Project } = Icon16;
const { Board, Bookmark, ImageUpload } = Icon24;

export type CreateItemMenuProps = PropsWithChildren<{
  projectId: string | null;
  dropdownAlign?: ComponentProps<typeof DropdownMenu>['align'];
}>;
export default function CreateItemMenu({
  projectId,
  dropdownAlign = 'start',
  children,
}: CreateItemMenuProps) {
  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const { data: workspaceProjects } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
  });
  const createDocument = useCreateAndNavigateToMediaGroup('document');
  const createBoard = useCreateAndNavigateToMediaGroup('board');
  const { createLink } = useCreateMediaGroupActions();
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({ workspaceId, projectId });

  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', workspaceProjects?.capabilities),
    redirectOnCreate: true,
  });

  const createProjectCapable = isCapable('create', workspaceProjects?.capabilities);
  const createMediaGroupCapable = isCapable(
    'create',
    workspace?.workspace.capabilities
  );
  const canRoleCreateProject =
    createProjectCapable.capable || createProjectCapable.reason !== 'role';
  const canRoleCreateMediaGroup =
    createMediaGroupCapable.capable || createMediaGroupCapable.reason !== 'role';

  return (
    <div className={styles.creationSection}>
      {(canRoleCreateMediaGroup || canRoleCreateProject) && (
        <DropdownMenu
          align={dropdownAlign}
          className={clsx('menu', sharedProjectStyles.dropdownMenu)}
          triggerContent={
            <div>
              {children ?? (
                <Button
                  className={clsx(sharedProjectStyles.createButton, styles.button)}
                  size="sm"
                  type="button"
                  variant="outlined"
                >
                  <Add size={16} />
                </Button>
              )}
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          }
        >
          {canRoleCreateMediaGroup && (
            <>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() => fileInputRef?.current?.click()}
              >
                <div className={sharedProjectStyles.icon}>
                  <ImageUpload />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Media & Files</div>
                  <div className={sharedProjectStyles.description}>
                    Images, Videos, PDFs, etc
                  </div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() =>
                  createDocument({
                    projectId,
                    workspaceId: workspace?.workspace.id,
                  })
                }
              >
                <div className={sharedProjectStyles.icon}>
                  <Document />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Document</div>
                  <div className={sharedProjectStyles.description}>Notes</div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() =>
                  createBoard({
                    projectId,
                    workspaceId: workspace?.workspace.id,
                  })
                }
              >
                <div className={sharedProjectStyles.icon}>
                  <Board />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Board</div>
                  <div className={sharedProjectStyles.description}>
                    Integrated whiteboard
                  </div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() => {
                  openCreateBookmarkModal({
                    onSubmit: (data: CreateBookmarkData) => {
                      createLink(data.url);
                    },
                  });
                }}
              >
                <div className={sharedProjectStyles.icon}>
                  <Bookmark />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Link</div>
                  <div className={sharedProjectStyles.description}>
                    Bookmark URLs, Website pages etc
                  </div>
                </div>
              </DropdownMenuItem>
            </>
          )}
          {canRoleCreateProject && (
            <DropdownMenuItem
              className={sharedProjectStyles.dropdownMenuItem}
              onSelect={openCreateProjectModal}
            >
              <div className={sharedProjectStyles.icon}>
                <Project />
              </div>
              <div className={sharedProjectStyles.text}>
                <div className={sharedProjectStyles.title}>Space</div>
                <div className={sharedProjectStyles.description}>
                  New design or research space
                </div>
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      )}
    </div>
  );
}
