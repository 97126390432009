import { memo, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/shallow';

import { Icon16 } from '@spaceduck/icons';

import { useBoardStore } from '@reactFlow/hooks/useBoardStore';
import { type EdgeStyle, edgeStyles } from '@reactFlow/types/board';
import { MenuButton } from '../menu';
import type { EdgeManagementProps } from '../SideMenu';

const { DashedLine, SolidLine } = Icon16;

export const EdgeStyleMenu = memo((props: EdgeManagementProps) => {
  const { edges, selectedEdges, setEdges, patch } = props;

  const { defaultConnectionLineStyle, setDefaultConnectionLineStyle } = useBoardStore(
    useShallow((state) => ({
      defaultConnectionLineStyle: state.defaultConnectionLineStyle,
      setDefaultConnectionLineStyle: state.setDefaultConnectionLineStyle,
    }))
  );

  const activeStyle = useMemo(() => {
    if (selectedEdges.length === 1 && selectedEdges[0]) {
      const edge = edges.find((edge) => edge.id === selectedEdges[0]);
      if (edge?.data) return edge.data.style as EdgeStyle;
    }

    return undefined;
  }, [selectedEdges, edges, defaultConnectionLineStyle]);

  const handleClick = useCallback(
    (style: EdgeStyle) => {
      if (!style) return;
      setDefaultConnectionLineStyle(style);

      const updatedEdges = setEdges((edges) => {
        return edges.map((edge) => {
          if (!selectedEdges.includes(edge.id)) return edge;

          return {
            ...edge,
            data: { ...edge.data, style },
          };
        });
      });

      patch({ edges: updatedEdges });
    },
    [setEdges, selectedEdges, setDefaultConnectionLineStyle, patch]
  );

  return (
    <>
      {edgeStyles.map((style) => {
        return (
          <StyleButton
            key={style}
            activeStyle={activeStyle}
            handleClick={handleClick}
            style={style}
          />
        );
      })}
    </>
  );
});

const StyleButton = ({
  activeStyle,
  handleClick,
  style,
}: {
  activeStyle?: EdgeStyle;
  handleClick: (style: EdgeStyle) => void;
  style: EdgeStyle;
}) => {
  const tooltipContent = useMemo(() => {
    return `Connection style ${style}`;
  }, [style]);

  const onClick = useCallback(() => {
    return handleClick(style);
  }, [style, handleClick]);

  const isActive = useMemo(() => {
    return style === activeStyle;
  }, [style, activeStyle]);

  return (
    <MenuButton
      key={style}
      isActive={isActive}
      onClick={onClick}
      tooltipContent={tooltipContent}
    >
      <StyleIcon style={style} />
    </MenuButton>
  );
};

const StyleIcon = memo(({ style }: { style: EdgeStyle }) => {
  switch (style) {
    case 'dashed':
      return <DashedLine />;
    default:
      return <SolidLine />;
  }
});
