import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import type { ListWorkspaceProject } from '@spaceduck/api';
import { Icon16, Icon24, Icon64 } from '@spaceduck/icons';
import { attempt, attemptAsync } from '@spaceduck/utils';

import { useWorkspaceProjects } from '@api/workspace';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import NoEntries, { NoEntriesFooter } from '@components/NoEntries';
import Spinner from '@components/Spinner';
import { useBatchCreateLinks } from '@hooks/useBatchCreateLinks';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button, { ButtonLink } from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import Select, { type SelectGroup } from '@ui/Select';
import Tag from '@ui/Tag';
import { type BrowserBookmark, parseBookmarksFromFile } from '@utils/browser';
import { urlFor } from '@/urls';
import { Container, Header, TopNav } from './common';
import styles from './ImportBookmarks.module.scss';

type ProcessStatus = 'success' | 'failed' | 'pending' | 'processing';
export type BookmarkData = BrowserBookmark & {
  id: string;
  shouldImport: boolean;
  status: ProcessStatus;
};

type ParsedFile = {
  id: string;
  file: File;
  data: BookmarkData[];
};

type ToggleBookmark = (id: string) => void;
type HandleRetry = (id: string) => void;

const { Bookmark: BookmarkIcon } = Icon16;
const { AlertSuccess, Close } = Icon24;
const { Library } = Icon64;
const DRAFT_KEY = '--DRAFTS--';

export default function ImportBookmark() {
  const workspaceId = useWorkspaceId();
  const { data: projectData } = useWorkspaceProjects(workspaceId, {
    flattenStacks: true,
  });
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [parsedFiles, setParsedFiles] = useState<ParsedFile[]>([]);

  const resetParsedFiles = useCallback(() => {
    setParsedFiles((parsedFiles) =>
      parsedFiles.map((parsedFile) => {
        return {
          ...parsedFile,
          data: parsedFile.data.map((data) => ({
            ...data,
            status: 'pending',
          })),
        };
      })
    );
  }, [setParsedFiles]);

  const toggleBookmark = useCallback(
    (id: string) => {
      setParsedFiles((parsedFiles) => {
        return parsedFiles.map((parsedFile) => {
          return {
            ...parsedFile,
            data: parsedFile.data.map((data) => {
              if (data.id !== id) return data;

              return { ...data, shouldImport: !data.shouldImport };
            }),
          };
        });
      });
    },
    [setParsedFiles]
  );

  const clearParsedFiles = useCallback(() => {
    setParsedFiles([]);
  }, [setParsedFiles]);

  if (!workspaceId) {
    return (
      <Container className={styles.container}>
        <LoadingPlaceholder center message="Loading" />
      </Container>
    );
  }

  if (parsedFiles.length) {
    return (
      <ImportView
        clearParsedFiles={clearParsedFiles}
        parsedFiles={parsedFiles}
        projects={projectData?.projects ?? []}
        resetParsedFiles={resetParsedFiles}
        setParsedFiles={setParsedFiles}
        toggleBookmark={toggleBookmark}
        workspaceId={workspaceId}
      />
    );
  }

  return (
    <>
      <TopNav
        title="Import bookmarks"
        currentBreadcrumb="Import bookmarks"
        owner="workspaceIntegrations"
      />
      <Container className={styles.container}>
        <DropZone
          dragActive={dragActive}
          inputRef={inputRef}
          setDragActive={setDragActive}
          setParsedFiles={setParsedFiles}
        >
          <NoEntries icon={<Library />}>
            <h1>Import bookmarks...</h1>
            <p>
              Drag and drop or click “Browse” to select an exported HTML bookmark file
              and upload it to Spaceduck.
            </p>
            <NoEntriesFooter>
              <Button onClick={() => inputRef?.current?.click()} variant="primary">
                Browse
              </Button>
              <ButtonLink
                to={urlFor('workspaceSettingsIntegrations', { workspaceId })}
                variant="outlined"
              >
                Cancel
              </ButtonLink>
            </NoEntriesFooter>
          </NoEntries>
        </DropZone>
      </Container>
    </>
  );
}

const DropZone = ({
  children,
  dragActive,
  inputRef,
  setDragActive,
  setParsedFiles,
}: {
  children: React.ReactNode;
  dragActive: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>;
  setParsedFiles: React.Dispatch<React.SetStateAction<ParsedFile[]>>;
}) => {
  const handleDrag = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { type } = ev;

    if (type === 'dragenter' || type === 'dragover') {
      setDragActive(true);
    } else if (type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const processFiles = useCallback(
    async (files: FileList) => {
      const promises = Array.from(files).map(async (file) => {
        const result = await attemptAsync(() => parseBookmarksFromFile(file));
        return { file, result };
      });

      const results = await Promise.all(promises);

      const d: ParsedFile[] = [];

      results.forEach(({ file, result }) => {
        if (!result.success) {
          console.warn('Failed to import bookmarks', result.error);
          return; // TODO(@dbowring): toast!
        }
        d.push({
          id: v4(),
          file,
          data: result.data.map((data) => ({
            ...data,
            id: v4(),
            shouldImport: true,
            status: 'pending',
          })),
        });
      });

      setParsedFiles((existing) => [...existing, ...d]);
    },
    [setParsedFiles]
  );

  const handleDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      const files = ev.dataTransfer.files;

      if (!files) {
        return;
      }

      setDragActive(false);
      processFiles(files);
    },
    [processFiles]
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (ev) => {
      const files = ev.currentTarget.files;

      if (!files) {
        return;
      }

      processFiles(files);
    },
    [processFiles]
  );

  return (
    <div
      className={clsx(styles.dropZone, dragActive && styles.highlight)}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {children}
      <input
        accept="text/html"
        className={styles.dropZoneInput}
        multiple={false}
        ref={inputRef}
        onChange={handleChange}
        type="file"
      />
    </div>
  );
};

const ImportView = ({
  clearParsedFiles,
  parsedFiles,
  projects,
  resetParsedFiles,
  setParsedFiles,
  toggleBookmark,
  workspaceId,
}: {
  clearParsedFiles: () => void;
  parsedFiles: ParsedFile[];
  projects: ListWorkspaceProject[];
  setParsedFiles: React.Dispatch<React.SetStateAction<ParsedFile[]>>;
  resetParsedFiles: () => void;
  toggleBookmark: ToggleBookmark;
  workspaceId: string;
}) => {
  const [projectId, setProjectId] = useState<string>(DRAFT_KEY);
  const [isImporting, setIsImporting] = useState(false);
  const { failedUploads, handleCreateLink, resetUploads, successfulUploads } =
    useBatchCreateLinks();

  useEffect(() => {
    resetUploads();
  }, [projectId]);

  const updateSelectedParsedFileStatus = useCallback(
    (_status: ProcessStatus) => {
      setParsedFiles((files) =>
        files.map((file) => {
          return {
            ...file,
            data: file.data.map((bookmark) => {
              const status = bookmark.shouldImport ? _status : bookmark.status;

              return {
                ...bookmark,
                status,
              };
            }),
          };
        })
      );
    },
    [setParsedFiles]
  );

  useEffect(() => {
    setParsedFiles((files) =>
      files.map((file) => {
        return {
          ...file,
          data: file.data.map((bookmark) => {
            const isSuccessfulUpload = successfulUploads.find(
              (upload) => upload.id === bookmark.id
            );

            const isFailedUploads = failedUploads.find(
              (upload) => upload.id === bookmark.id
            );

            const status = isSuccessfulUpload
              ? 'success'
              : isFailedUploads
                ? 'failed'
                : bookmark.status;

            return {
              ...bookmark,
              shouldImport: isSuccessfulUpload ? false : bookmark.shouldImport,
              status,
            };
          }),
        };
      })
    );
  }, [successfulUploads, failedUploads]);

  const onChange = useCallback(
    (projectId: string) => {
      setProjectId(projectId);
      resetParsedFiles();
    },
    [resetParsedFiles, setProjectId]
  );

  const options: SelectGroup = useMemo(() => {
    return {
      options: [
        {
          label: 'Drafts',
          value: DRAFT_KEY,
        },
        ...projects.map((project) => ({
          label: project.label,
          value: project.id,
        })),
      ],
    };
  }, [projects]);

  const intendedProjectId = useMemo(() => {
    return projectId === DRAFT_KEY ? undefined : projectId;
  }, [projectId]);

  const projectName = useMemo(() => {
    return intendedProjectId
      ? projects.find((project) => project.id === intendedProjectId)?.label
      : undefined;
  }, [intendedProjectId, projects]);

  const bookmarksToImport = useMemo(() => {
    return new Set(
      parsedFiles.flatMap((file) => file.data).filter((data) => data.shouldImport)
    );
  }, [parsedFiles]);

  const handleImport = useCallback(async () => {
    setIsImporting(true);
    updateSelectedParsedFileStatus('processing');
    try {
      await handleCreateLink(
        bookmarksToImport,
        false,
        workspaceId,
        intendedProjectId,
        projectName
      );
    } finally {
      setIsImporting(false);
    }
  }, [
    bookmarksToImport,
    handleCreateLink,
    intendedProjectId,
    parsedFiles,
    projectName,
    workspaceId,
  ]);

  const handleRetry = useCallback(
    async (id: string) => {
      const targetFile = parsedFiles
        .flatMap((file) => file.data)
        .find((data) => data.id === id);

      if (!targetFile) return;

      setParsedFiles((files) =>
        files.map((file) => ({
          ...file,
          data: file.data.map((bookmark) => {
            const status: ProcessStatus =
              bookmark.id === id ? 'processing' : bookmark.status;

            return {
              ...bookmark,
              status,
            };
          }),
        }))
      );

      setIsImporting(true);
      try {
        await handleCreateLink(
          new Set([targetFile]),
          true,
          workspaceId,
          intendedProjectId,
          projectName
        );
      } finally {
        setIsImporting(false);
      }
    },
    [handleCreateLink, intendedProjectId, projectName, setParsedFiles, workspaceId]
  );

  const isDone = useMemo(
    () =>
      !(
        parsedFiles.length === 0 ||
        parsedFiles.find((f) =>
          f.data.find((b) => b.shouldImport && b.status !== 'success')
        )
      ),
    [parsedFiles]
  );

  return (
    <>
      <TopNav
        title="Import bookmarks"
        currentBreadcrumb="Import bookmarks"
        owner="workspaceIntegrations"
      />
      <Container className={styles.importContainer}>
        <Header>
          <h1>Import bookmarks</h1>
          <p>Deselect the bookmarks you don't want to import to Spaceduck.</p>
        </Header>
        <ParsedFileList
          handleRetry={handleRetry}
          isImporting={isImporting}
          parsedFiles={parsedFiles}
          toggleBookmark={toggleBookmark}
        />
        <div className={styles.importForm}>
          <div className={styles.control}>
            {!isDone && !isImporting ? (
              projects.length ? (
                <Select
                  contentClassName={styles.selectContent}
                  onValueChange={onChange}
                  placeholder="Import to Drafts"
                  selectGroups={[options]}
                  triggerClassName={styles.selectTrigger}
                  value={projectId}
                  variant="primary"
                />
              ) : (
                <input disabled readOnly type="text" value="Import to Drafts" />
              )
            ) : null}
          </div>
          <div className={styles.actions}>
            {!isDone && !isImporting && (
              <Button
                className={styles.cancelButton}
                onClick={clearParsedFiles}
                size="sm"
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
            )}
            {!isDone && (
              <Button
                disabled={isImporting || !bookmarksToImport.size}
                onClick={handleImport}
                size="sm"
                type="button"
                variant="primary"
              >
                {isImporting ? 'Importing...' : 'Import'}
              </Button>
            )}
            {isDone && (
              <ButtonLink
                size="sm"
                type="button"
                variant="primary"
                to={urlFor('workspace', { workspaceId })}
              >
                Continue to home
              </ButtonLink>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

const ParsedFileList = ({
  handleRetry,
  isImporting,
  parsedFiles,
  toggleBookmark,
}: {
  handleRetry: HandleRetry;
  isImporting: boolean;
  parsedFiles: ParsedFile[];
  toggleBookmark: ToggleBookmark;
}) => {
  if (!parsedFiles.length) return null;

  return (
    <div className={styles.parseFileListContainer}>
      <div className={styles.parseFileList}>
        <ScrollArea
          className={styles.scrollArea}
          orientation="vertical"
          style={css({ '--width': '100%', '--maxHeight': '100%' })}
        >
          {parsedFiles.map(({ id, data }) => (
            <ParsedFilePreview
              key={id}
              data={data}
              handleRetry={handleRetry}
              isImporting={isImporting}
              toggleBookmark={toggleBookmark}
            />
          ))}
        </ScrollArea>
      </div>
    </div>
  );
};

const ParsedFilePreview = ({
  data,
  handleRetry,
  isImporting,
  toggleBookmark,
}: {
  data: BookmarkData[];
  handleRetry: HandleRetry;
  isImporting: boolean;
  toggleBookmark: ToggleBookmark;
}) => {
  return (
    <ul className={styles.parsedFilePreview}>
      {data.map((bookmark) => (
        <Bookmark
          key={bookmark.id}
          bookmark={bookmark}
          handleRetry={handleRetry}
          isImporting={isImporting}
          toggleBookmark={toggleBookmark}
        />
      ))}
    </ul>
  );
};

const Bookmark = ({
  bookmark,
  handleRetry,
  isImporting,
  toggleBookmark,
}: {
  bookmark: BookmarkData;
  handleRetry: HandleRetry;
  isImporting: boolean;
  toggleBookmark: ToggleBookmark;
}) => {
  const [hasValidIcon, setHasValidIcon] = useState(!!bookmark.icon);
  const handleImgOnError = useCallback(() => {
    setHasValidIcon(false);
  }, [setHasValidIcon]);

  const handleChange = useCallback(() => {
    toggleBookmark(bookmark.id);
  }, [bookmark.id, toggleBookmark]);

  const label = useMemo(() => {
    if (bookmark.label) {
      return bookmark.label;
    }
    const result = attempt(() => new URL(bookmark.href));
    return result.success ? result.data.hostname : '(Untitled)';
  }, [bookmark.label, bookmark.href]);

  return (
    <li key={bookmark.href} className={styles.bookmark}>
      {bookmark.status === 'success' && <AlertSuccess size={20} color="#1B9754" />}
      {bookmark.status === 'failed' && <Close size={20} color="#DF202D" />}
      {bookmark.status === 'processing' && <Spinner size={20} />}
      {bookmark.status === 'pending' && (
        <Checkbox checked={bookmark.shouldImport} onChange={handleChange} size="sm" />
      )}
      <div className={styles.bookmarkDetails}>
        <div className={styles.bookmarkLabel}>{label}</div>
        <a href={bookmark.href} rel="external noreferrer" target="_blank">
          {bookmark.href}
        </a>
        {!!bookmark.tags.length && (
          <div className={styles.tagList}>
            {bookmark.tags.map((tag) => (
              <Tag key={tag} size="sm">
                {tag}
              </Tag>
            ))}
          </div>
        )}
      </div>
      <div>
        {!isImporting && bookmark.status === 'failed' && (
          <Button
            className={styles.retryButton}
            onClick={() => handleRetry(bookmark.id)}
            size="xs"
            variant="outlined"
          >
            Retry
          </Button>
        )}
      </div>
      {bookmark.icon && hasValidIcon ? (
        <img onError={handleImgOnError} src={bookmark.icon} width="16" height="16" />
      ) : (
        <BookmarkIcon />
      )}
    </li>
  );
};
