import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.25 2.5H3.25C2.69772 2.5 2.25 2.94772 2.25 3.5V9.5C2.25 10.0523 2.69772 10.5 3.25 10.5H21.25C21.8023 10.5 22.25 10.0523 22.25 9.5V3.5C22.25 2.94772 21.8023 2.5 21.25 2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 13.5H3.25C2.69772 13.5 2.25 13.9477 2.25 14.5V20.5C2.25 21.0523 2.69772 21.5 3.25 21.5H21.25C21.8023 21.5 22.25 21.0523 22.25 20.5V14.5C22.25 13.9477 21.8023 13.5 21.25 13.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
