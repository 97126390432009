import PromisePool from '@supercharge/promise-pool';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';

import type { CreateMediaGroupSchema } from '@spaceduck/api';
import { pluralize, sleep } from '@spaceduck/utils';

import { useCreateMediaGroup } from '@api/mediaGroup';
import type { BookmarkData } from '@pages/settings/workspace/ImportBookmarks';
import createToast from '@utils/createToast';

const DEFAULT_BATCH_ITEM_COUNT = 10;
const TOAST_BEGIN_ID = 'BEGIN_CREATE_LINKS_TOAST';
const TOAST_STATUS_ID = 'CREATE_LINKS_STATUS_TOAST';

export const useBatchCreateLinks = (options?: {
  batchItemsCount: number;
}) => {
  const batchItemsCount = options?.batchItemsCount ?? DEFAULT_BATCH_ITEM_COUNT;
  const [successfulUploads, setSuccessfulUploads] = useState<BookmarkData[]>([]);
  const [failedUploads, setFailedUploads] = useState<BookmarkData[]>([]);

  const resetUploads = useCallback(() => {
    setFailedUploads([]);
    setSuccessfulUploads([]);
  }, [setFailedUploads, setSuccessfulUploads]);

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = useCallback(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;

      await createMediaGroup({
        kind: 'bookmark',
        projectId: projectId,
        workspaceId: !projectId ? workspaceId : undefined,
        ...rest,
      });
    },
    [createMediaGroup]
  );

  const handleCreateLink = async (
    selectedBookmarks: Set<BookmarkData>,
    isSingleRetry: boolean,
    workspaceId?: string,
    projectId?: string,
    projectName?: string
  ) => {
    if (selectedBookmarks.size === 0) {
      return;
    }

    if (!isSingleRetry) {
      toast.dismiss(TOAST_STATUS_ID);
      createToast({
        titleText: 'Importing bookmarks, please wait',
        bodyText: `Don't close this page while your bookmarks are importing.`,
        iconVariant: 'info',
        settings: {
          duration: 10000,
          id: TOAST_BEGIN_ID,
        },
      });
    }

    // Sleep used to show above toast and give spinner some time to display
    await sleep(200);
    const successfulBookmarks: BookmarkData[] = [];
    const failedBookmarks: BookmarkData[] = [];

    await PromisePool.for(selectedBookmarks)
      .withConcurrency(batchItemsCount)
      .handleError((_, bookmark) => {
        failedBookmarks.push(bookmark);
      })
      .process(async (bookmark) => {
        const { href: url, label, tags } = bookmark;
        await createLink({
          label,
          projectId: projectId,
          tags,
          url,
          workspaceId: workspaceId ? workspaceId : undefined,
        });

        successfulBookmarks.push(bookmark);
      })
      .finally(() => {
        toast.dismiss(TOAST_BEGIN_ID);
        setSuccessfulUploads((existingSuccessfulUploads) => [
          ...existingSuccessfulUploads,
          ...successfulBookmarks,
        ]);
        setFailedUploads((existingFailedUploads) => [
          ...existingFailedUploads.filter(
            (bookmark) => !successfulBookmarks.find((item) => item.id === bookmark.id)
          ),
          ...failedBookmarks.filter(
            (bookmark) => !existingFailedUploads.find((item) => item.id === bookmark.id)
          ),
        ]);

        if (!isSingleRetry) {
          const currentRunFailed = [...selectedBookmarks].filter((selected) =>
            failedBookmarks.find((failed) => selected.id === failed.id)
          );
          const currentRunSuccess = [...selectedBookmarks].filter((selected) =>
            successfulBookmarks.find((successful) => selected.id === successful.id)
          );

          if (currentRunFailed.length > 0 && !currentRunSuccess.length) {
            createToast({
              titleText: 'Bookmarks import unsuccessful',
              bodyText: 'Failed to upload bookmarks',
              iconVariant: 'danger',
              settings: {
                id: TOAST_STATUS_ID,
              },
            });
          } else if (currentRunFailed.length > 0) {
            createToast({
              titleText: 'Bookmarks partially imported',
              bodyText: `Failed to upload ${currentRunFailed.length} ${pluralize(currentRunFailed.length !== 1, 'bookmarks', 'bookmark')}`,
              iconVariant: 'warning',
              settings: {
                id: TOAST_STATUS_ID,
              },
            });
          } else if (currentRunSuccess.length) {
            createToast({
              titleText: 'Bookmarks imported successfully!',
              bodyText: `Your bookmarks were saved to "${projectId ? projectName || `Project: ${projectId}` : 'Drafts'}"`,
              iconVariant: 'success',
              settings: {
                id: TOAST_STATUS_ID,
              },
            });
          }
        }
      });
  };

  return {
    failedUploads,
    handleCreateLink,
    resetUploads,
    successfulUploads,
  };
};
