import type { ReactNode } from 'react';

import styles from './Title.module.scss';
import clsx from 'clsx';

type TitleProps = {
  children: ReactNode;
  className?: string;
};

export const MenuTitle = ({ children, className }: TitleProps) => {
  return <div className={clsx(styles.title, className)}>{children}</div>;
};
